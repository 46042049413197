
.joblistsave{
    cursor: 'pointer';
    font-size: 1rem; 
}
.joblistsaved{
    color: #eea21d
}

.noRecordImgtext{
    text-align: center;
}
.modalJobList{
    top:5%; left:30%; right:auto; bottom:auto;
    background: 'rgba(15,29,45,0.70)';
    overflow:visible;
}