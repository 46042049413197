.owl-carousel {
	z-index: 1;
	width: 100%;
	display: none;
	position: relative;
	-webkit-tap-highlight-color: transparent
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
	-moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
	height: 0;
	clear: both;
	content: ".";
	display: block;
	line-height: 0;
	visibility: hidden
}

.owl-carousel .owl-stage-outer {
	overflow: hidden;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px)
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item {
	float: left;
	min-height: 1px;
	position: relative;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none
}

.owl-carousel .owl-item img {
	width: 100%;
	display: block
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
	display: none
}

.owl-dots {
	width: 100%;
	float: left;
	line-height: 4px;
	padding: 30px 0 0;
	text-align: center
}

.owl-carousel .owl-dot {
	padding: 0 3px;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.owl-carousel .owl-dot span {
	width: 10px;
	height: 10px;
	display: block;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.50)
}

.owl-carousel .owl-dot:hover span,
.owl-carousel .owl-dot.active span {
	width: 12px;
	height: 12px;
	background: rgba(255, 255, 255, 1.00)
}

.owl-carousel.owl-loaded {
	display: block
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block
}

.owl-carousel.owl-hidden {
	opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
	visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab
}

.owl-carousel.owl-rtl {
	direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
	float: right
}

.no-js .owl-carousel {
	display: block
}

.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
	z-index: 0
}

.owl-carousel .owl-animated-out {
	z-index: 1
}

.owl-carousel .fadeOut {
	animation-name: fadeOut
}

@keyframes fadeOut {
	0% {
		opacity: 1
	}

	100% {
		opacity: 0
	}
}

.owl-height {
	transition: height 500ms ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease
}

.owl-carousel .owl-item img.owl-lazy {
	transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url(owl.video.play.html) no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: transform 100ms ease
}

.owl-carousel .owl-video-play-icon:hover {
	-ms-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%
}

.owl-theme .owl-nav {
	margin-top: 10px;
	-webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav [class*='owl-'] {
	color: #FFF;
	font-size: 14px;
	margin: 5px;
	padding: 4px 7px;
	background: #d6d6d6;
	display: inline-block;
	cursor: pointer;
	border-radius: 3px
}

.owl-theme .owl-nav [class*='owl-']:hover {
	background: #869791;
	color: #FFF;
	text-decoration: none
}

.owl-theme .owl-nav .disabled {
	opacity: .5;
	cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
	margin-top: 10px
}

.owl-theme .owl-dots {
	-webkit-tap-highlight-color: transparent
}

.owl-theme .owl-dots .owl-dot {
	zoom: 1;
	display: inline-block
}

.owl-theme .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	display: block;
	margin: 5px 7px;
	background: #d6d6d6;
	-webkit-backface-visibility: visible;
	transition: opacity 200ms ease;
	border-radius: 30px
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: #869791
}