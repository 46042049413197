.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: .8
}

.mfp-wrap {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden
}

.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 8px;
	box-sizing: border-box
}

.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle
}

.mfp-align-top .mfp-container:before {
	display: none
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto
}

.mfp-ajax-cur {
	cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
	cursor: auto
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none
}

.mfp-loading.mfp-figure {
	display: none
}

.mfp-hide {
	display: none !important
}

.mfp-preloader {
	color: #CCC;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -.8em;
	left: 8px;
	right: 8px;
	z-index: 1044
}

.mfp-preloader a {
	color: #CCC
}

.mfp-preloader a:hover {
	color: #fff
}

.mfp-s-ready .mfp-preloader {
	display: none
}

.mfp-s-error .mfp-content {
	display: none
}

button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	box-shadow: none;
	touch-action: manipulation
}

button::-moz-focus-inner {
	padding: 0;
	border: 0
}

.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: .65;
	padding: 0 0 18px 10px;
	color: #fff;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace
}

.mfp-close:hover,
.mfp-close:focus {
	opacity: 1
}

.mfp-close:active {
	top: 1px
}

.mfp-close-btn-in .mfp-close {
	color: #333
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	color: #fff;
	right: -6px;
	text-align: right;
	padding-right: 6px;
	width: 100%
}

.mfp-counter {
	top: 0;
	right: 0;
	color: #CCC;
	font-size: 12px;
	line-height: 18px;
	position: absolute;
	white-space: nowrap
}

.mfp-arrow {
	margin: 0;
	top: 50%;
	padding: 0;
	width: 90px;
	height: 110px;
	opacity: .65;
	margin-top: -55px;
	position: absolute;
	-webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
	margin-top: -54px
}

.mfp-arrow:hover,
.mfp-arrow:focus {
	opacity: 1
}

.mfp-arrow:before,
.mfp-arrow:after {
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	content: '';
	display: block;
	margin-top: 35px;
	margin-left: 35px;
	position: absolute;
	border: medium inset transparent
}

.mfp-arrow:after {
	top: 8px;
	border-top-width: 13px;
	border-bottom-width: 13px
}

.mfp-arrow:before {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: .7
}

.mfp-arrow-left {
	left: 0
}

.mfp-arrow-left:after {
	border-right: 17px solid #fff;
	margin-left: 31px
}

.mfp-arrow-left:before {
	margin-left: 25px;
	border-right: 27px solid #3F3F3F
}

.mfp-arrow-right {
	right: 0
}

.mfp-arrow-right:after {
	margin-left: 39px;
	border-left: 17px solid #fff
}

.mfp-arrow-right:before {
	border-left: 27px solid #3F3F3F
}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 900px
}

.mfp-iframe-holder .mfp-close {
	top: -40px
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	background: #000;
	position: absolute;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6)
}

img.mfp-img {
	width: auto;
	height: auto;
	display: block;
	line-height: 0;
	margin: 0 auto;
	max-width: 100%;
	box-sizing: border-box;
	padding: 40px 0
}

.mfp-figure {
	line-height: 0
}

.mfp-figure:after {
	top: 40px;
	left: 0;
	right: 0;
	z-index: -1;
	content: '';
	width: auto;
	height: auto;
	bottom: 40px;
	display: block;
	background: #444;
	position: absolute;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6)
}

.mfp-figure small {
	color: #BDBDBD;
	display: block;
	font-size: 12px;
	line-height: 14px
}

.mfp-figure figure {
	margin: 0
}

.mfp-bottom-bar {
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
	margin-top: -36px;
	position: absolute
}

.mfp-title {
	color: #F3F3F3;
	text-align: left;
	line-height: 18px;
	padding-right: 36px;
	word-wrap: break-word
}

.mfp-image-holder .mfp-content {
	max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0
	}

	.mfp-img-mobile img.mfp-img {
		padding: 0
	}

	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0
	}

	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px
	}

	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		box-sizing: border-box
	}

	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0
	}

	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px
	}

	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0
	}
}

@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75)
	}

	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0
	}

	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%
	}

	.mfp-container {
		padding-left: 6px;
		padding-right: 6px
	}
}