@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700|Open+Sans:400,500,500i,700);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200,300,400,500,600,,700&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

*,
ul {
    margin: 0
}

img,
table {
    max-width: 100%
}

.wp-caption.alignleft,
.wp-caption.alignnone,
iframe {
    width: 100%
}

button,
iframe {
    border: none
}

.rg-companyname h3,
.rg-companyname.jobdetails h3,
.rg-similarcompanyname h3,
.rg-similarcompanyname span {
    white-space: nowrap;
    text-overflow: ellipsis
}

* {
    padding: 0
}

body {
    color: #222;
    font-size: 13px;
    background: #fbfbfb;
    font-family: 'Roboto', sans-serif;
    overflow: auto !important
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
    color: #222;
    margin: 0 0 8px;
    font-weight: 500;
    line-height: 1.4;
    font-style: normal;
    font-family: 'Roboto', sans-serif;
}

h1.homehead {
    font-size: 20px !important
}

h1 {
    font-size: 20px
}

h2 {
    font-size: 36px
}

h3 {
    font-size: 20px
}

h4 {
    font-size: 16px
}

h5 {
    font-size: 15px
}

h6 {
    font-size: 14px
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #0e0e0e
}

p a,
p a:hover {
    text-decoration: underline
}

.social a,
a,
a.powercom,
a.powercom:hover,
a:active,
a:focus,
a:hover {
    text-decoration: none
}

p {
    margin: 0 0 20px;
    line-height: 20px;
    letter-spacing: normal
}

a:active,
a:focus,
a:hover {
    outline: 0
}

ul {
    padding-inline-start: 0
}

figure {
    margin-bottom: 6px;
    position: relative
}

img {
    height: auto
}

strong {
    color: #414141
}

iframe {
    float: left
}

.form-control,
.rg-select select,
.uneditable-input,
input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
select,
textarea {
    color: #767676;
    outline: 0;
    height: 40px;
    background: #fff;
    font-size: 14px;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 18px;
    padding: 10px 20px 10px 44px;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ddd;
    text-transform: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

textarea.form-control {
    resize: none;
    height: 110px;
    padding: 10px 20px 10px 10px
}

#react-phn-input .react-tel-input .form-control,
#react-tel-input .react-tel-input .form-control {
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #fff;
    line-height: 20px;
    height: 35px;
    position: relative;
    outline: 0
}

.form-control::-webkit-input-placeholder {
    color: #767676
}

#react-tel-input .react-tel-input .form-control {
    width: 264px !important;
    border: 1px solid #cacaca;
    border-radius: 5px
}

#react-phn-input .react-tel-input .form-control {
    width: 300px !important;
    border: 1px solid #cacaca;
    border-radius: 5px
}

#error_page,
form p,
ol li,
table {
    width: 100%
}

#react-tel-input .text-danger {
    display: block !important;
    height: auto
}

.errotextspace,
label {
    display: block
}

.form-control:-moz-placeholder {
    color: #767676
}

.form-control::-moz-placeholder {
    color: #767676
}

.form-control:-ms-input-placeholder {
    color: #767676
}

.form-control::-ms-input-placeholder {
    color: #767676
}

.form-control:focus,
.select select:focus,
input:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th,
table thead tr th {
    border-top: 1px solid #dbdbdb
}

.form-group {
    width: 100%;
    float: left;
    margin: 0 0 16px
}

dl dd,
ol ol,
ul ul {
    margin-left: 20px
}

label {
    color: #767676;
    font-weight: 400;
    margin-bottom: 10px
}

form p {
    float: left;
    position: relative
}

form p span i {
    top: 13px;
    left: 16px;
    color: #474747;
    position: absolute
}

.font-15 {
    font-size: 15px
}

.font-16 {
    font-size: 16px
}

.font-17 {
    font-size: 17px
}

.font-18 {
    font-size: 18px
}

.font-20 {
    font-size: 20px
}

.font-22 {
    font-size: 22px
}

option {
    padding: 5px 15px
}

li {
    line-height: 28px;
    list-style: disc inside
}

ol,
ol li {
    list-style: decimal
}

ol {
    float: none;
    padding-left: 15px
}

table {
    background-color: transparent;
    margin-bottom: 15px
}

th {
    text-align: left
}

table>tbody>tr>td,
table>tbody>tr>th,
table>tfoot>tr>td,
table>tfoot>tr>th,
table>thead>tr>td,
table>thead>tr>th {
    border: 1px solid #dbdbdb;
    line-height: 2.5;
    padding-left: 3px;
    text-align: center;
    vertical-align: top
}

table thead tr th {
    text-align: center;
    text-transform: capitalize
}

table>thead>tr>th {
    border-bottom: 2px solid #dbdbdb;
    vertical-align: bottom
}

table>caption+thead>tr:first-child>td,
table>caption+thead>tr:first-child>th,
table>colgroup+thead>tr:first-child>td,
table>colgroup+thead>tr:first-child>th,
table>thead:first-child>tr:first-child>td,
table>thead:first-child>tr:first-child>th {
    border-bottom: 0
}

table>tbody+tbody {
    border-top: 2px solid #dbdbdb
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 6px 10px
}

p ins {
    color: #666
}

#error_page {
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url(../images/pattern_1.svg) fixed #fff;
    color: #646464
}

#error_page h2 {
    margin-top: 30px;
    font-size: 8.75rem;
    color: #646464;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1
}

#error_page h2 i {
    color: #ffc107
}

@media (max-width:767px) {
    #error_page h2 {
        font-size: 4.5rem
    }
}

#error_page p {
    font-size: 1.3125rem;
    margin-bottom: 40px
}

@media (max-width:767px) {
    #error_page p {
        font-size: 1.125rem
    }

    #error_page {
        padding: 0;
        height: 500px
    }
}

address {
    font-style: italic
}

#keyword,
#location {
    padding: 0 0 !important;
    height: auto !important;
    width: 87% !important
}

#keyword .dropdown-item,
#location .dropdown-item {
    padding: 0 1.5rem 1.5rem !important
}

#keyword a,
#location a[aria-selected] {
    padding: 4px 10px 10px !important
}

.preferredinline {
    display: flex;
    align-self: center;
    align-items: center
}

.preferredinline label {
    padding-left: 13px;
    margin-top: 3px
}

.DocumentUploadp {
    background: #ffedec;
    padding: 16px 20px;
    margin-top: 20px
}

.social-btn {
    display: flex;
    width: 160px;
    align-items: center;
    justify-content: flex-start;
    border: 1px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    margin-bottom: 3px;
    position: relative;
    right: 0;
    transition: right 1s;
    padding: 3px 45px 3px 10px
}

.social-btn:hover {
    right: 80px;
    transition: right 1s
}

.social {
    position: fixed;
    right: -120px;
    top: 20%;
    z-index: 999
}

.ReactModal__Overlay {
    background-color: rgb(0 0 0 / 75%) !important
}

.color-facebook {
    background-color: #3b5999
}

.color-twitter {
    background-color: #1da1f2
}

.color-youtube {
    background-color: #cd201f
}

.color-linkedin {
    background-color: #0077b5
}

.google-font {
    font: 1rem Lato, sans-serif
}

.social-btn img {
    width: 40px
}

.icons8-facebook-app,
.icons8-linkedin-app,
.icons8-twitter-app,
.icons8-youtube-app {
    order: 0;
    display: inline-block;
    width: 30px;
    height: 30px
}

.social-btn p {
    color: #fff;
    margin-top: 0;
    margin-bottom: 0
}

.rg-select,
.rg-select select option {
    color: #767676
}

.icons8-facebook-app {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M9.945 22v-8.834H7V9.485h2.945V6.54c0-3.043 1.926-4.54 4.64-4.54 1.3 0 2.418.097 2.744.14v3.18h-1.883c-1.476 1.82.703-1.82 1.732v2.433h3.68l-.736 3.68h-2.944L13.685 22'/%3E%3C/svg%3E") 50% 50% no-repeat;
    background-size: 80%
}

.icons8-twitter-app {
    background: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M24,4.3086 C23.117,4.7006 22.168,4.9646 21.172,5.0836 C22.188,4.4746 22.969,3.5096 23.337,2.3596 C22.386,2.9246 21.332,3.3336 20.21,3.5556 C19.312,2.5976 18.032,1.9996 16.616,1.9996 C13.897,1.9996 11.692,4.2046 11.692,6.9236 C11.692,7.3096 11.736,7.6856 11.82,8.0456 C7.728,7.8406 4.099,5.8806 1.671,2.9006 C1.247,3.6286 1.4,4.4736 1.4,5.3766 C1.4,7.0846 1.873,8.5926 3.195,9.4756 C2.388,9.4486 1.628,9.2276 0.964,8.8596 L0.964,8.9206 C0.964,11.3066 2.661,13.2966 4.914,13.7486 C4.501,13.8626 4.065,13.9216 3.617,13.9216 C3.299,13.9216 2.991,13.8906 2.69,13.8336 C3.317,15.7896 5.135,17.2136 7.29,17.2536 C5.604,18.5736 3.481,19.3606 1.175,19.3606 C0.777,19.3606 0.385,19.3376 0,19.2926 C2.179,20.6886 4.767,21.5046 7.548,21.5046 C16.605,21.5046 21.557,14.16 21.557,7.4946 C21.557,7.2816 21.552,7.0696 21.543,6.8586 C22.505,6.1636 23.34,5.2966 24,4.3086'/%3E%3C/svg%3E") 50% 50% no-repeat;
    background-size: 80%
}

.icons8-youtube-app {
    background: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M9.522,15.553 L9.52125,8.80975 L16.575,12.193 L9.522,15.553 Z M23.76,7.64125 C23.76,7.64125 23.52525,5.9875 22.806,5.25925 C21.89325,4.303 20.87025,4.2985 20.4015,4.243 C17.043,4 12.525,4 12.525,4 L11.99475,4 C11.99475,4 6.957,4 3.5985,4.243 C3.129,4.2985 2.10675,4.303 1.19325,5.25925 C0.474,5.9875 0.24,7.64125 0.24,7.64125 C0.24,7.64125 0,9.58375 0,11.5255 L0,13.3465 C0,15.289 0.24,17.23075 0.24,17.23075 C0.24,17.23075 0.474,18.8845 1.19325,19.61275 C2.10675,20.569 3.306,20.539 3.84,20.63875 C5.76,20.82325 12,20.88025 12,20.88025 C12,20.88025 17.043,20.87275 20.4015,20.62975 C20.87025,20.5735 21.89325,20.569 22.806,19.61275 C23.52525,18.8845 23.76,17.23075 23.76,17.23075 C23.76,17.23075 24,15.289 24,13.3465 L24,11.5255 C24,9.58375 23.76,7.64125 23.76,7.64125 L23.76,7.64125 Z'/%3E%3C/svg%3E") 50% 50% no-repeat;
    background-size: 80%
}

.icons8-linkedin-app {
    background: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M22.0367422,22 L17.8848745,22 L17.8848745,15.5036305 C17.8848745,13.9543347 17.85863,11.9615082 15.7275829,11.9615082 C13.5676669,11.9615082 13.237862,13.6498994 13.237862,15.3925291 L13.237862,22 L9.0903683,22 L9.0903683,8.64071385 L13.0707725,8.64071385 L13.0707725,10.4673257 L13.1276354,10.4673257 C13.6813927,9.41667396 15.0356049,8.3091593 17.0555507,8.3091593 C21.2599073,8.3091593 22.0367422,11.0753215 22.0367422,14.6734319 L22.0367422,22 Z M4.40923804,6.81585163 C3.07514653,6.81585163 2,5.73720584 2,4.40748841 C2,3.07864579 3.07514653,2 4.40923804,2 C5.73720584,2 6.81585163,3.07864579 6.81585163,4.40748841 C6.81585163,5.73720584 5.73720584,6.81585163 4.40923804,6.81585163 L4.40923804,6.81585163 Z M6.48604672,22 L2.32980492,22 L2.32980492,8.64071385 L6.48604672,8.64071385 L6.48604672,22 Z'/%3E%3C/svg%3E") 50% 50% no-repeat;
    background-size: 80%
}

.rg-select {
    float: left;
    width: 100%;
    position: relative
}

.rg-select:after {
    z-index: 2 !important;
    top: 0;
    right: 15px;
    z-index: 2;
    color: #767676;
    display: block;
    content: '\e874';
    position: absolute;
    text-align: center;
    font-size: inherit;
    line-height: 40px;
    font-family: Linearicons-Free
}

.rg-select select {
    z-index: 1;
    width: 100%;
    position: relative;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none
}

.rg-select.padleft {
    padding-left: 40px
}

.rg-checkbox,
.rg-checkbox label,
.rg-radio,
.rg-radio label {
    margin: 0;
    width: 100%;
    float: left;
    position: relative
}

.rg-checkbox label,
.rg-radio label {
    cursor: pointer;
    display: block;
    padding: 0 0 0 20px
}

.rg-checkbox input[type=checkbox],
.rg-radio input[type=radio] {
    display: none
}

.rg-checkbox input[type=checkbox]+label:before,
.rg-radio input[type=radio]+label:before {
    left: 0;
    color: #373542;
    font: 14px/18px FontAwesome;
    position: absolute;
    top: 0;
    content: '';
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #bbb
}

.rg-haslayout {
    width: 100%;
    float: left;
    position: relative
}

.rg-location-section {
    padding: 20px 0
}

.rg-sectionspace {
    padding: 20px 0 30px
}

.rg-locationmap {
    width: 100%;
    float: left;
    height: 500px
}

.rg-btn-sm {
    padding: 0 20px;
    line-height: 30px
}

.rg-applied-btn,
.rg-btn {
    border-radius: 4px;
    font: 400 16px/38px "Open Sans", Arial, Helvetica, sans-serif;
    padding: 2px 40px;
    display: inline-block;
    text-transform: capitalize;
    vertical-align: middle;
    position: relative;
    text-align: center
}

.rg-btn {
    color: #324b6e;
    background: #fff;
    border: 1px solid #ddd
}

.rg-applied-btn {
    color: #fff;
    background: #4c4c4c;
    border: 1px solid #ddd
}

.rg-btn.rg-active,
.rg-btn:hover {
    color: #fff
}

.rg-ruit-btn {
    color: #324b6e;
    padding: 2px 40px;
    background: #e81c28;
    position: relative;
    text-align: center;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ddd;
    text-transform: capitalize;
    font: 500 18px/28px "Open Sans", Arial, Helvetica, sans-serif;
    height: 40px;
    margin-right: 20px
}

.rg-ruit-btn.rg-active,
.rg-ruit-btn:hover {
    color: #fff
}

a.powercom {
    color: #ccc;
}

a.powercom:hover {
    color: #eee
}

.rg-btn-lg {
    width: 100%
}

.fontSize18 {
    font-size: 18px;
    font-weight: 500
}

.rg-socialicons {
    overflow: hidden;
    list-style: none;
    text-align: center;
    line-height: 34px
}

.rg-socialicons li {
    float: left;
    padding: 0 4px;
    line-height: inherit;
    list-style: none
}

.rg-socialicons li a {
    width: 34px;
    height: 34px;
    color: #fff;
    display: block;
    background: #000;
    overflow: hidden;
    font-size: 13px;
    line-height: 34px;
    position: relative;
    text-align: center;
    border-radius: 34px;
    text-transform: capitalize
}

.rg-checkbox.rg-facebook,
.rg-inputwithicon.rg-facebook .rg-icon,
li.rg-facebook a {
    background: #3b5999
}

.rg-checkbox.rg-twitter,
.rg-inputwithicon.rg-twitter .rg-icon,
li.rg-twitter a {
    background: #55acee
}

.rg-checkbox.rg-linkedin,
.rg-inputwithicon.rg-linkedin .rg-icon,
li.rg-linkedin a {
    background: #0077b5
}

.rg-checkbox.rg-skype,
.rg-inputwithicon.rg-skype .rg-icon,
li.rg-skype a {
    background: #00aff0
}

.rg-checkbox.rg-dropbox,
li.rg-dropbox a {
    background: #007ee5
}

.rg-checkbox.rg-vimeo,
li.rg-vimeo a {
    background: #1ab7ea
}

.rg-checkbox.rg-tumblr,
li.rg-tumblr a {
    background: #34465d
}

.rg-checkbox.rg-yahoo,
li.rg-yahoo a {
    background: #410093
}

.rg-checkbox.rg-googleplus,
.rg-inputwithicon.rg-googleplus .rg-icon,
li.rg-googleplus a {
    background: #dd4b39
}

.rg-checkbox.rg-pinterestp,
.rg-inputwithicon.rg-pinterestp .rg-icon,
li.rg-pinterestp a {
    background: #bd081c
}

.rg-checkbox.rg-youtube,
li.rg-youtube a {
    background: #cd201f
}

.rg-checkbox.rg-stumbleupon,
li.rg-stumbleupon a {
    background: #eb4924
}

.rg-checkbox.rg-reddit,
li.rg-reddit a {
    background: #ff5700
}

.rg-checkbox.rg-quora,
li.rg-quora a {
    background: #b92b27
}

.rg-checkbox.rg-yelp,
li.rg-yelp a {
    background: #af0606
}

.rg-checkbox.rg-soundcloud,
li.rg-soundcloud a {
    background: #f30
}

.rg-checkbox.rg-whatsapp,
li.rg-whatsapp a {
    background: #25d366
}

.rg-checkbox.rg-wechat,
li.rg-wechat a {
    background: #09b83e
}

.rg-checkbox.rg-instagram,
li.rg-instagram a {
    background: #e4405f
}

.rg-checkbox.rg-dribbble,
li.rg-dribbble a {
    background: #ea4c89
}

.rg-checkbox.rg-flickr,
li.rg-flickr a {
    background: #ff0084
}

.rg-checkbox.rg-rss,
li.rg-rss a {
    background: #f60
}

.rg-socialiconsborder li.rg-facebook a {
    color: #3b5999;
    border: 1px solid #3b5999
}

.rg-socialiconsborder li.rg-twitter a {
    color: #55acee;
    border: 1px solid #55acee
}

.rg-socialiconsborder li.rg-linkedin a {
    color: #0077b5;
    border: 1px solid #0077b5
}

.rg-socialiconsborder li.rg-skype a {
    color: #00aff0;
    border: 1px solid #00aff0
}

.rg-socialiconsborder li.rg-dropbox a {
    color: #007ee5;
    border: 1px solid #007ee5
}

.rg-socialiconsborder li.rg-vimeo a {
    color: #1ab7ea;
    border: 1px solid #1ab7ea
}

.rg-socialiconsborder li.rg-tumblr a {
    color: #34465d;
    border: 1px solid #34465d
}

.rg-socialiconsborder li.rg-yahoo a {
    color: #410093;
    border: 1px solid #410093
}

.rg-socialiconsborder li.rg-googleplus a {
    color: #dd4b39;
    border: 1px solid #dd4b39
}

.rg-socialiconsborder li.rg-pinterestp a {
    color: #bd081c;
    border: 1px solid #bd081c
}

.rg-socialiconsborder li.rg-youtube a {
    color: #cd201f;
    border: 1px solid #cd201f
}

.rg-socialiconsborder li.rg-stumbleupon a {
    color: #eb4924;
    border: 1px solid #eb4924
}

.rg-socialiconsborder li.rg-reddit a {
    color: #ff5700;
    border: 1px solid #ff5700
}

.rg-socialiconsborder li.rg-quora a {
    color: #b92b27;
    border: 1px solid #b92b27
}

.rg-socialiconsborder li.rg-yelp a {
    color: #af0606;
    border: 1px solid #af0606
}

.rg-socialiconsborder li.rg-soundcloud a {
    color: #f30;
    border: 1px solid #f30
}

.rg-socialiconsborder li.rg-whatsapp a {
    color: #25d366;
    border: 1px solid #25d366
}

.rg-socialiconsborder li.rg-wechat a {
    color: #09b83e;
    border: 1px solid #09b83e
}

.rg-socialiconsborder li.rg-instagram a {
    color: #e4405f;
    border: 1px solid #e4405f
}

.rg-socialiconsborder li.rg-dribbble a {
    color: #ea4c89;
    border: 1px solid #ea4c89
}

.rg-socialiconsborder li.rg-flickr a {
    color: #ff0084;
    border: 1px solid #ff0084
}

.rg-socialiconsborder li.rg-rss a {
    color: #f60;
    border: 1px solid #f60
}

.rg-socialiconsborder li a {
    background: 0 0;
    line-height: 32px
}

.rg-socialiconsborder li.rg-facebook a i {
    color: #3b5999
}

.rg-socialiconsborder li.rg-twitter a i {
    color: #55acee
}

.rg-socialiconsborder li.rg-linkedin a i {
    color: #0077b5
}

.rg-socialiconsborder li.rg-skype a i {
    color: #00aff0
}

.rg-socialiconsborder li.rg-dropbox a i {
    color: #007ee5
}

.rg-socialiconsborder li.rg-vimeo a i {
    color: #1ab7ea
}

.rg-socialiconsborder li.rg-tumblr a i {
    color: #34465d
}

.rg-socialiconsborder li.rg-yahoo a i {
    color: #410093
}

.rg-socialiconsborder li.rg-googleplus a i {
    color: #dd4b39
}

.rg-socialiconsborder li.rg-pinterestp a i {
    color: #bd081c
}

.rg-socialiconsborder li.rg-youtube a i {
    color: #cd201f
}

.rg-socialiconsborder li.rg-stumbleupon a i {
    color: #eb4924
}

.rg-socialiconsborder li.rg-reddit a i {
    color: #ff5700
}

.rg-socialiconsborder li.rg-quora a i {
    color: #b92b27
}

.rg-socialiconsborder li.rg-yelp a i {
    color: #af0606
}

.rg-socialiconsborder li.rg-soundcloud a i {
    color: #f30
}

.rg-socialiconsborder li.rg-whatsapp a i {
    color: #25d366
}

.rg-socialiconsborder li.rg-wechat a i {
    color: #09b83e
}

.rg-socialiconsborder li.rg-instagram a i {
    color: #e4405f
}

.rg-socialiconsborder li.rg-dribbble a i {
    color: #ea4c89
}

.rg-socialiconsborder li.rg-flickr a i {
    color: #ff0084
}

.rg-socialiconsborder li.rg-rss a i {
    color: #f60
}

.rg-socialicons li a i {
    color: #fff;
    display: block;
    text-align: center;
    font-size: inherit;
    line-height: inherit
}

.rg-socialsharewithtext li a i {
    width: 34px
}

.rg-socialicons li a:hover {
    color: #fff
}

.rg-roundicontext .rg-usericonholder {
    width: 250px;
    display: block;
    overflow: hidden;
    font-style: normal
}

.rg-roundicontext i,
.rg-roundicontext span {
    float: left;
    font-size: inherit;
    line-height: inherit
}

.rg-roundicontext span {
    padding: 0 15px 0 0
}

.rg-roundicontext:focus,
.rg-roundicontext:hover {
    color: #fff;
    width: 100%
}

.rg-roundicontext:hover .rg-usericonholder {
    width: 100%
}

.rg-socialiconssimple {
    width: 100%;
    float: left;
    list-style: none;
    line-height: 20px
}

.rg-socialiconssimple li {
    float: left;
    padding: 0 10px;
    line-height: inherit;
    list-style: none
}

.rg-socialiconssimple li:first-child {
    padding-left: 0
}

.rg-socialiconssimple li a {
    width: auto;
    height: auto;
    display: block;
    background: 0 0;
    border-radius: 0;
    line-height: inherit;
    font-size: 22px
}

.rg-socialiconssimple li.rg-facebook a i {
    color: #3b5999
}

.rg-socialiconssimple li.rg-twitter a i {
    color: #55acee
}

.rg-socialiconssimple li.rg-linkedin a i {
    color: #0077b5
}

.rg-socialiconssimple li.rg-googleplus a i {
    color: #dd4b39
}

.rg-socialiconssimple li.rg-rss a i {
    color: #f60
}

.rg-socialiconssimple li.rg-vimeo a i {
    color: #1ab7ea
}

.rg-socialiconssimple li.rg-tumblr a i {
    color: #34465d
}

.rg-socialiconssimple li.rg-yahoo a i {
    color: #410093
}

.rg-socialiconssimple li.rg-yelp a i {
    color: #af0606
}

.rg-socialiconssimple li.rg-pinterestp a i {
    color: #bd081c
}

.rg-socialiconssimple li.rg-youtube a i {
    color: #cd201f
}

.rg-socialiconssimple li.rg-stumbleupon a i {
    color: #eb4924
}

.rg-socialiconssimple li.rg-reddit a i {
    color: #ff5700
}

.rg-socialiconssimple li.rg-bloggerb a i {
    color: #f57d00
}

.rg-socialiconssimple li.rg-clone a i {
    color: #878787
}

.rg-verticalmiddle {
    float: none !important;
    display: inline-block;
    vertical-align: middle
}

.rg-description {
    width: 100%;
    float: left
}

.rg-description .skilllist {
    position: relative;
    padding: 4px 0
}

.rg-description .skilllist li {
    list-style: none;
    padding-right: 4px;
    padding-left: 4px;
    display: inline-block;
    font-size: 12px;
    border-right: 1px solid #252525;
    line-height: 10px
}

.rg-description .skilllist li:first-child {
    padding-left: 0
}

.rg-description .skilllist li:last-child {
    border-right: 0
}

.rg-bglight {
    background: #fafafa none
}

.rg-bgdark {
    background: #373542
}

.rg-stars,
.rg-stars span {
    width: 100px;
    height: 16px;
    float: left;
    line-height: 16px;
    position: relative
}

.rg-stars span:after,
.rg-stars:before {
    top: 0;
    left: 0;
    font: 16px/16px FontAwesome;
    position: absolute;
    letter-spacing: 4px;
    color: rgba(218, 218, 218, .8);
    content: '\f005 \f005 \f005 \f005 \f005'
}

.rg-stars span {
    width: 75%;
    overflow: hidden
}

.rg-stars span:after {
    color: #000
}

.no-gutters>[class*=col-],
.no-gutters>[class^=col-],
.row.no-gutters>[class*=col-],
.row.no-gutters>[class^=col-] {
    padding-right: 0;
    padding-left: 0
}

.mega-menu-col li a,
.mega-menu-col li.menhead {
    padding: 2px 12px 8px !important
}

.rg-dropdowarrow {
    top: 15px;
    z-index: 9;
    right: 15px;
    color: #fff;
    width: 20px;
    height: 20px;
    display: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    text-align: center
}

.mega-menu-row,
.rg-formtheme {
    width: 100%;
    float: left
}

.menu-item-has-children,
.menu-item-has-mega-menu,
.rg-reportIssue {
    position: relative
}

.rg-dropdowarrow i {
    display: block;
    font-size: inherit;
    line-height: inherit
}

.rg-inputwithicon i,
.rg-reportIssue i {
    z-index: 2;
    line-height: 50px;
    text-align: center
}

.rg-open .rg-dropdowarrow i:before {
    content: '\f106'
}

.head-line:after,
.head-line:before {
    content: '';
    left: 0
}

.mega-menu-row {
    table-layout: fixed;
    display: flex
}

.mega-menu-col {
    display: inline-block;
    vertical-align: top;
    flex: 1;
    box-shadow: 1px 0 0 #eff2f5;
}

.mega-menu-col:last-child {
    box-shadow: 0 0 0 #dbe8ef
}

.mega-menu-col li.menhead {
    font-size: 14px;
    font-weight: 600
}

.rg-btnviewall,
.rg-inputwithiconno label {
    font-weight: 500
}

.mega-menu-col li a:hover {
    color: #ea4a53
}

.br-radiu-mega-menu {
    border: 1px solid #eff2f5;
    border-radius: 6px
}

.mega-menu-group {
    padding: 6px 2px;
    margin: 12px 6px
}

.navbar-toggle {
    border: 0;
    margin: 3px 0;
    border-radius: 0;
    padding: 10px 12px
}

.navbar-toggle .icon-bar {
    background: #fff
}

.rg-formtheme fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    position: relative
}

.rg-reportIssue i {
    top: -4px;
    left: 0;
    width: 36px;
    height: 36px;
    position: absolute;
    font-size: 16px
}

.rg-reportIssue .form-control {
    padding: 10px 20px 10px 30px;
    border: 1px solid #bdbdbd;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 -1px 1px rgb(0 0 0 / 8%)
}

.rg-inputwithicon {
    position: relative
}

.rg-inputwithicon i {
    top: -2px;
    left: 0;
    width: 30px;
    height: 50px;
    position: absolute;
    font-size: 20px
}

.rg-inputwithicon .form-control {
    z-index: 1;
    position: relative
}

.rg-inputwithiconno .form-control {
    z-index: 1;
    padding: 0 14px;
    position: relative
}

.rg-sectionhead {
    width: 100%;
    float: left;
    padding: 0 0 6px;
}

.rg-sectionhead h2 {
    margin: 0;
    float: left;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px
}

.rg-sectionhead.lpf-job-pt {
    margin-top: -15px;
    padding-bottom: 14px;
}

.rg-btnviewall {
    float: right;
    color: #303233;
    display: block;
    font-size: 13px;
    line-height: 14px;
    border: 1.4px solid #303233;
    padding: 2px 10px;
    border-radius: 50px
}

.rg-langnotification,
.rg-languagedropdown li+li {
    border-top: 1px solid #eff2f5
}

.rg-btnviewall:focus,
.rg-btnviewall:hover {
    color: #fff;
    background: #303233
}

.rg-title {
    width: 100%;
    float: left
}

.head-line {
    position: relative;
    padding-bottom: 10px
}

.head-line:before {
    position: absolute;
    bottom: 3px;
    height: 1.5px;
    background: gray;
    width: 100px
}

.head-line:after {
    position: absolute;
    bottom: 2.5px;
    height: 2px;
    background: red;
    width: 40px
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-5 {
    margin-bottom: 5px !important
}

.mb-15 {
    margin-bottom: 15px !important
}

.mb-20 {
    margin-bottom: 20px !important
}

.mb-25 {
    margin-bottom: 25px !important
}

.mb-30 {
    margin-bottom: 30px !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-5 {
    margin-top: 5px !important
}

.mt-10 {
    margin-top: 10px !important
}

.mt-15 {
    margin-top: 15px !important
}

.mt-20 {
    margin-top: 20px !important
}

.mt-25 {
    margin-top: 25px !important
}

.mt-30 {
    margin-top: 30px !important
}

.mr-15 {
    margin-right: 15px !important
}

.pb-15 {
    padding-bottom: 15px !important
}

.pb-20 {
    padding-bottom: 20px !important
}

.pb-25 {
    padding-bottom: 25px !important
}

.pb-30 {
    padding-bottom: 30px !important
}

.pt-15 {
    padding-top: 15px !important
}

.pt-25 {
    padding-top: 25px !important
}

.pt-30 {
    padding-top: 30px !important
}

.plr-0,
.ptb-0 {
    padding: 0 0 !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pt-0 {
    padding-top: 0 !important
}

.pr-0 {
    padding-left: 0 !important
}

.pb-10 {
    padding-bottom: 10px !important
}

.pt-10 {
    padding-top: 10px !important
}

.pr-10 {
    padding-right: 10px !important
}

.pl-10 {
    padding-left: 10px !important
}

.pr-15 {
    padding-right: 15px !important
}

.pl-15 {
    padding-left: 15px !important
}

.bb {
    border-bottom: 1px solid #e5e5e5
}

.rg-pagination {
    width: 100%;
    float: left;
    font: 500 14px/20px "Open Sans", Arial, Helvetica, sans-serif
}

.rg-pagination ul {
    margin: 0;
    padding: 0;
    float: right;
    list-style: none;
    text-align: center;
    line-height: inherit
}

.rg-pagination ul li {
    float: left;
    margin: 0 6px;
    line-height: inherit;
    list-style: none
}

.rg-pagination ul li.rg-prevpage {
    margin: 0 14px 0 0
}

.rg-pagination ul li.rg-nextpage {
    margin: 0 0 0 14px
}

.rg-pagination ul li.rg-activepagination a {
    color: #324b6e
}

.rg-pagination ul li a {
    width: auto;
    color: #767676;
    display: block;
    text-align: center;
    position: relative;
    line-height: inherit
}

.rg-pagination ul li.rg-nextpage a,
.rg-pagination ul li.rg-prevpage a {
    width: auto;
    font-size: 13px;
    overflow: hidden;
    position: relative
}

.rg-btnarea,
.rg-topbar {
    width: 100%;
    float: left
}

.rg-pagination ul li.rg-nextpage:hover a,
.rg-pagination ul li.rg-prevpage:hover a {
    color: #55acee
}

.rg-pagination ul li.rg-active a,
.rg-pagination ul li:hover a {
    color: #324b6e
}

.rg-pagination ul li.rg-prevpage a i {
    margin: 0 4px 0 0
}

.rg-pagination ul li.rg-nextpage a i {
    margin: 0 0 0 4px
}

.rg-breadcrumb {
    font: 400 12px/44px "Open Sans", Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    color: #767676;
    list-style: none;
    text-align: right;
    text-transform: capitalize
}

.rg-breadcrumb li {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    float: left;
    padding: 0 8px;
    position: relative;
    line-height: inherit;
    list-style: none
}

.rg-breadcrumb li:last-child {
    padding: 0 0 0 15px
}

.rg-wrapper {
    overflow: hidden
}

.rg-main {
    padding: 20px 0
}

.rg-btnarea .rg-btn+.rg-btn {
    margin: 0 0 0 10px
}

.rg-header {
    background: #fff;
    z-index: 10;
    position: relative
}

.rg-topbar {
    border-bottom: 1px solid #eff2f5
}

.rg-langnotification {
    float: left;
    list-style: none
}

.rg-langnotification li {
    float: left;
    min-height: 55px;
    position: relative;
    list-style: none
}

.rg-langnotification>li {
    border-left: 1px solid #eff2f5
}

.rg-langnotification>li+li {
    border-right: 1px solid #eff2f5
}

.rg-langnotification li a {
    color: #324b6e;
    display: block;
    font: 500 14px/13px Poppins, Arial, Helvetica, sans-serif;
    position: relative;
    padding: 19px 21px
}

.rg-langnotification li:first-child a {
    padding: 18px 20px
}

.rg-langnotification li .rg-languagesbutton i {
    font-size: 14px;
    line-height: 14px
}

.rg-langnotification li a i {
    font-size: 20px;
    line-height: 20px
}

.rg-languagedropdown {
    left: -1px;
    z-index: 5;
    margin: 0;
    float: left;
    top: 100px;
    opacity: 0;
    width: 77px;
    font-size: 13px;
    background: #fff;
    line-height: 14px;
    position: absolute;
    visibility: hidden;
    text-transform: uppercase;
    border: 1px solid #eff2f5;
    border-radius: 0 0 5px 5px
}

.rg-langnotification>li:first-child:hover .rg-languagedropdown {
    top: 100%;
    opacity: 1;
    visibility: visible
}

.rg-languagedropdown li {
    width: 100%;
    float: left;
    background: #fff;
    text-align: center
}

.rg-languagedropdown li:hover {
    background: #fcfcfc
}

.rg-languagedropdown li a {
    display: block
}

.rg-notificationtag {
    top: 10px;
    right: 15px;
    width: 18px;
    color: #fff;
    height: 18px;
    padding: 0 3px;
    font: 10px/18px 'Open Sans', Arial, Helvetica, sans-serif;
    border-radius: 50%;
    text-align: center;
    background: #fc413c;
    position: absolute
}

.rg-userloginreg {
    float: right
}

.rg-loginreg,
.rg-userimg {
    display: inline-block;
    vertical-align: middle
}

.rg-userimg {
    margin: 0 0 0 10px
}

.rg-userimg img {
    width: 60px;
    height: 60px;
    display: block
}

.rg-loginreg {
    list-style: none;
    text-transform: capitalize;
    font: 400 14px/14px Poppins, Arial, Helvetica, sans-serif
}

.rg-loginreg li {
    float: left;
    padding: 0 7px;
    position: relative;
    line-height: inherit;
    list-style: none
}

.rg-loginreg li:last-child {
    padding-right: 0
}

.rg-loginreg li:before {
    top: 0;
    left: -3px;
    content: '/';
    color: #55acee;
    position: absolute;
    font: 500 14px/14px Poppins, Arial, Helvetica, sans-serif
}

.rg-usernav ul li a:before,
.rg-usernav:before {
    height: 0;
    content: ''
}

.rg-loginreg li:first-child:before {
    display: none
}

.rg-loginreg li a {
    color: #55acee;
    display: block;
    line-height: inherit
}

.rg-btnappdowld {
    width: 300px;
    margin: 0 auto;
    list-style: none;
    overflow: hidden;
    padding: 15px 0 14px
}

.rg-btnappdowld li {
    float: left;
    padding: 0 5px;
    list-style: none
}

.rg-btnappdowld li a {
    display: block
}

.rg-userlogedin,
body.rg-userlogin .rg-signinarea {
    display: none
}

.rg-signinarea,
body.rg-userlogin .rg-userlogedin {
    display: block
}

.rg-userlogedin {
    float: right;
    cursor: pointer;
    position: relative
}

.rg-userlogedin .rg-userimg {
    float: right;
    margin: 0 0 0 10px
}

.rg-username {
    padding: 13px 0;
    overflow: hidden;
    text-align: right
}

.rg-username h3 {
    margin: 0 0 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 13px
}

.rg-username span {
    color: #767676;
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px
}

.rg-usernav {
    right: 0;
    opacity: 0;
    top: 140px;
    z-index: 9;
    width: 220px;
    visibility: hidden;
    position: absolute;
    border-radius: 5px
}

.rg-userlogedin:hover .rg-usernav {
    top: 80px;
    opacity: 1;
    visibility: visible
}

.rg-usernav:before {
    width: 0;
    top: -5px;
    right: 30px;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 5px solid #324b6e
}

.rg-usernav ul {
    width: 100%;
    float: left;
    font-size: 12px;
    list-style: none;
    line-height: 13px;
    border-radius: 5px;
    background: #324b6e
}

.rg-usernav ul li,
.rg-usernav ul li a {
    line-height: inherit;
    position: relative
}

.rg-usernav ul li {
    width: 100%;
    float: left;
    list-style: none
}

.rg-usernav ul li:nth-child(2n) a {
    background: #2f4768
}

.rg-usernav ul li a {
    color: #ddd;
    display: block;
    padding: 13px 20px
}

.rg-usernav ul li a:before {
    top: 0;
    left: 0;
    width: 2px;
    position: absolute
}

.rg-usernav ul li a:hover:before {
    height: 100%
}

.rg-usernav ul li a i {
    float: left;
    width: 30px;
    color: #ddd;
    font-size: 15px;
    line-height: 15px
}

.rg-usernav ul li a span {
    line-height: inherit;
    display: inline-block;
    vertical-align: middle
}

.rg-usernav ul li .sub-menu {
    border: 0;
    left: 100%;
    border-radius: 0 5px 5px 0
}

.rg-usernav ul li:hover .sub-menu {
    top: 0;
    opacity: 1;
    visibility: visible
}

.rg-usernav ul li .sub-menu li a {
    border-radius: 0 5px 5px 0
}

.rg-usernav ul li .sub-menu li:hover {
    background: 0 0
}

.rg-usernav ul li:nth-child(2n):hover {
    background: #2f4768
}

.rg-usernav ul li.menu-item-has-children:after,
.rg-usernav ul li.page_item_has_children:after {
    top: 0;
    right: 20px;
    color: #fff;
    font: 12px/40px FontAwesome;
    content: '\f105';
    position: absolute
}

.couserlist li,
.top-menu ul li {
    line-height: 18px
}

.rg-navigationlogoarea {
    width: 100%;
    float: left
}

.top-menu.top-menu-new {
    position: relative
}

.top-menu {
    background: url(../images/top-had-img.png) repeat-x;
}

.top-menu.top-menu-new:after,
.top-menu.top-menu-new:before {
    position: absolute;
    background: #fff;
    content: ''
}


.top-menu.top-menu-new:before {
    width: 50%;
    height: 100%;
    top: 0;
    left: 0
}

.top-menu.top-menu-new:after {
    width: 60px;
    height: 50px;
    top: -10px;
    left: 48%;
    transform: rotate(-122deg)
}

.socialmediaicon {
    position: relative;
    width: 10%;
}

.socialmediaicon a:nth-child(0n+1) {
    margin-right: 11%;
    color: #62bbff;
}

.socialmediaicon a:nth-child(0n+2) {
    margin-right: 11%;
    color: #0089ff;
}

.socialmediaicon a:nth-child(0n+3) {
    margin-right: 11%;
    color: #ff1f19;
}

.socialmediaicon a:nth-child(0n+4) {
    margin-right: 11%;
    color: #00a5f7;
}

.couserlist,
.feebox,
.hilightaera li,
.learninghubtab,
.top-menu ul li i {
    position: relative
}

.top-menu ul {
    margin: 0;
    padding: 0
}

.top-menu ul li {
    display: inline-block;
    font-weight: 500;
    font-size: 13px;
    color: #111;
    padding: 2px 0 5px;
}

.top-menu ul li a {
    color: #fff
}

.top-menu ul li:first-child,
.top-menu ul li:nth-child(2) {
    padding-right: 2%
}

.top-menu ul li i {
    font-size: 15px;
    top: 2px
}

.top-menu ul li i.fa-whatsapp {
    color: #34be47;
    font-weight: 600;
}

.top-menu ul li span {
    font-weight: 500;
    font-size: 15px
}

.feetext,
.raningnum {
    font-weight: 600
}

.learningrating i {
    color: #ff9200;
    padding-right: 4px
}

.raningnum {
    font-size: 13px;
    padding: 0 4px
}

.feebox {
    padding: 10px 0
}

.feeoff {
    padding: 2px 10px;
    background: #276c1d;
    color: #fff;
    border-radius: 4px 10px;
    margin-right: 6px
}

.buynowbtn {
    margin-top: 20px
}

.buynowbtn a {
    color: #fff !important;
    background: #e81c28;
    color: #fff !important;
    padding: 6px 20px;
    border-radius: 4px;
    text-align: center;
    font-weight: 500
}

.buynowbtn a:hover {
    color: #fff;
    box-shadow: 0 2px 4px #ccc
}

.dropyourcv {
    color: #333 !important;
    display: flex;
    background: #fff;
    padding: 0 8px;
    font-weight: 500;
    border-radius: 30px
}

.dropyourcv i {
    padding-left: 4px
}

.certificatbtn {
    background: #f8f8f8;
    border-radius: 20px;
    padding: 4px 16px;
    border: 1px solid #ccc
}

.learninghubtab {
    padding-top: 30px
}

.learninghubtab ul {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ccc
}

.learninghubtab ul li {
    display: inline-block;
    line-height: 1.6
}

.learninghubtab ul li.active {
    border-bottom: 3px solid #e81c28;
    margin-bottom: -2px
}

.learninghubtab ul li a {
    margin-right: 25px;
    padding: 8px 13px;
    color: #5e5e5e;
    display: block;
    font-weight: 500
}

.hilightaera {
    background: #eee;
    padding: 24px;
    border-radius: 2px;
    margin-top: 20px
}

.hilightaera ul {
    margin: 0;
    padding: 0
}

.hilightaera li {
    display: block !important;
    font-size: 14px;
    line-height: 1.6;
    margin-left: 10px;
    padding-left: 16px
}

.Overviewarea ul li:before,
.hilightaera li:before {
    top: 50%;
    font: 10px FontAwesome;
    left: 0
}

.hilightaera li:before {
    margin: -4px 0 0;
    line-height: 8px;
    content: '\f00c';
    position: absolute
}

.Overviewarea {
    border-radius: 2px;
    background: #fbfbfb;
    padding: 20px;
    border: 1px solid #eee;
    margin-top: 20px
}

.Overviewarea ul {
    margin: 0;
    padding: 0;
    list-style: circle
}

.Overviewarea ul li {
    display: block !important;
    line-height: 1.6;
    margin-left: 10px;
    padding-left: 16px;
    position: relative
}

.Overviewarea ul li:before {
    margin: -4px 0 0;
    line-height: 8px;
    content: '\f10c';
    position: absolute
}

.course-curriculum {
    position: relative;
    margin-top: 20px
}

.course-curriculum ul {
    margin: 0;
    padding: 0
}

.course-curriculum ul li {
    list-style: none;
    background: #fbfbfb;
    margin-bottom: 6px;
    padding: 4px 14px;
    border-radius: 30px;
    border: 1px solid #eee;
    display: inline-block;
}

.course-provider {
    position: relative;
    margin-top: 40px;
    background: #fbfbfb;
    padding: 20px;
    border: 1px solid #eee
}

.course-provider ul.courselist {
    margin: 0;
    padding: 0
}

.course-provider ul.courselist li {
    display: inline-block
}

.course-provider ul.courselist li a {
    display: block;
    padding: 2px 12px;
    background: #fbfbfb;
    border: 1px solid #eee;
    margin-right: 6px;
    border-radius: 30px;
    color: #333
}

.learningfaqs {
    position: relative;
    margin-top: 40px
}

.rg-logo {
    float: left;
    padding: 12px 0
}

.rg-logo a {
    display: block
}

.rg-logo a img {
    max-width: 150px;
    height: auto;
    margin-top: -4px;
    display: block
}

.rg-rightarea {
    float: right
}

.rg-nav {
    width: 100%;
    float: left;
    font: 400 16px/16px "Roboto", Arial, Helvetica, sans-serif
}

.rg-storydetails em,
.rg-totalviews em {
    font-style: normal
}

.navi-menu-m {
    display: flex;
    align-items: flex-end;
}

.rg-navigation {
    padding: 0;
    width: 100%;
    float: left
}

.rg-navigation ul {
    list-style: none;
    line-height: inherit
}

.rg-navigation>ul {
    width: 100%;
    float: left;
    list-style: none;
    text-align: center;
    line-height: inherit
}

.rg-navigation ul li {
    text-align: left;
    line-height: inherit;
    list-style: none
}

.rg-navigation>ul>li {
    width: auto;
    float: left;
    padding: 30px 26px 16px;
}

.rg-navigation>ul>li+li {
    border-left: 0 solid #eff2f5
}

.rg-navigation>ul>li:hover:last-child {
    background: #fcfcfc
}

.rg-navigation ul li a {
    color: #222;
    display: block;
    font-weight: 400;
}

.rg-EmployeeHeaderHover ul li a:hover {
    color: #ea4a53 !important
}

.rg-navigation ul li a em {
    color: #767676;
    display: block;
    font-size: 13px;
    margin: 5px 0 0;
    line-height: 13px;
    font-style: normal
}

.children,
.mega-menu,
.sub-menu {
    left: 0px;
    top: 50px;
    opacity: 0;
    z-index: 999;
    text-align: left;
    background: #fff;
    visibility: hidden;
    position: absolute;
    border-radius: 6px
}

.children,
.sub-menu {
    margin: 0;
    width: 200px;
    border: 1px solid #eff2f5
}

.rg-navigation>ul>li.menu-item-has-children:last-child .sub-menu,
.rg-navigation>ul>li.page_item_has_children:last-child .sub-menu {
    left: 0;
    right: auto;
    border-radius: 6px;
}

.rg-navigation>ul>li.menu-item-has-children .sub-menu,
.rg-navigation>ul>li.page_item_has_children .sub-menu {
    border-radius: 6px;
}

.rg-navigation>ul>li.menu-item-has-children:last-child .sub-menu li .sub-menu,
.rg-navigation>ul>li.page_item_has_children:last-child .sub-menu li .sub-menu {
    left: auto;
    right: 100%;
    border-radius: 4px 0 4px 4px
}

.children .menu-item-has-children .children li,
.sub-menu .menu-item-has-children .sub-menu li {
    background: #fcfcfc
}

.children .menu-item-has-children .children li:first-child,
.sub-menu .menu-item-has-children .sub-menu li:first-child {
    border-radius: 0 4px 0 0
}

.children .menu-item-has-children .children li:last-child,
.sub-menu .menu-item-has-children .sub-menu li:last-child {
    border-radius: 0 0 4px 4px
}

.children .menu-item-has-children .children li:hover,
.sub-menu .menu-item-has-children .sub-menu li:hover {
    background: #fff
}

.rg-navigation ul li.menu-item-has-children .sub-menu a,
.rg-navigation ul li.page_item_has_children .children a,
.rg-navigation ul li.page_item_has_children .sub-menu a {
    padding: 10px 20px;
    border-bottom: 1px solid #eff2f5
}

.rg-navigation ul li.menu-item-has-children .sub-menu a:hover,
.rg-navigation ul li.page_item_has_children .children a:hover,
.rg-navigation ul li.page_item_has_children .sub-menu a:hover {
    color: #ea4a53
}

.rg-nav .rg-navigation ul li.menu-item-has-children {
    font-size: 14px;
    border-bottom: 4px solid transparent
}

.rg-nav .rg-navigation ul li.menu-item-has-children .sub-menu .menu-item-has-children {
    font-size: 14px;
    border-bottom: 0 solid #bebebe
}

.rg-navigation>ul>li.menu-item-has-children>a {
    font-size: 16px;
    font-weight: 400;
}

.rg-navigation>ul>li.menu-item-has-children.active {
    border-bottom: 4px solid #303233
}

.rg-navigation>ul>li.menu-item-has-children:hover {
    border-bottom: 4px solid #e81c28;
    border-radius: 4px
}

.rg-nav .rg-navigation ul li.menu-item-has-children a.rg-menubar {
    padding: 22px 20px
}

.rg-nav .rg-navigation ul li.menu-item-has-children a.rg-menubar-ez {
    color: #fff !important;
    background: #e81c28;
    padding: 6px 14px;
    margin-top: 21px;
    line-height: 22px;
    border-radius: 50px;
}

.my-rozgar.rg-web-dash-had .rg-menubar-ez {
    margin-top: 10px !important;
    background: transparent !important;
}

.my-rozgar.rg-web-dash-had {
    padding-top: 10px !important;
}

.my-rozgar.rg-web-dash-had .profileheader .fa-chevron-down {
    color: #222;
}

.my-rozgar.rg-web-dash-had .profileheader img {
    background: #fbc1c1;
    border: 1px solid #fbc1c1;
}

.rg-nav .rg-navigation ul li.menu-item-has-children a.rg-menubar-ez i {
    font-size: 12px;
    font-weight: 900
}

.rg-nav .rg-navigation ul li.menu-item-has-children a.rg-menubar span {
    display: none
}

.children li .rg-dropdowarrow i:before,
.sub-menu li .rg-dropdowarrow i:before {
    content: "\f107"
}

.children li.rg-open .rg-dropdowarrow i:before,
.sub-menu li.rg-open .rg-dropdowarrow i:before {
    content: "\f106"
}

.children li,
.sub-menu li {
    width: 100%;
    float: left;
    position: relative
}

.children li:last-child a,
.sub-menu li:last-child a {
    border-bottom: 0
}

.children li:hover,
.sub-menu li:hover {
    background: #fcfcfc
}

.children li a,
.sub-menu li a {
    color: #324b6e;
    display: block;
    padding: 13px 30px
}

.children li.menu-item-has-children a,
.children li.page_item_has_children a,
.sub-menu li.menu-item-has-children a,
.sub-menu li.page_item_has_children a {
    position: relative
}

.children li.menu-item-has-children>a:before,
.children li.page_item_has_children>a:before,
.sub-menu li.menu-item-has-children>a:before,
.sub-menu li.page_item_has_children>a:before {
    top: 0;
    right: 30px;
    content: '\f105';
    display: none;
    font: 14px/50px FontAwesome;
    position: absolute
}

.children li.menu-item-has-children .sub-menu,
.children li.page_item_has_children .sub-menu,
.sub-menu li.menu-item-has-children .sub-menu {
    left: 100%
}

.sub-menu li.page_item_has_children:hover .sub-menu {
    top: 0;
    opacity: 1;
    visibility: visible
}

.rg-navigation>ul>li:hover>.children,
.rg-navigation>ul>li:hover>.mega-menu,
.rg-navigation>ul>li:hover>.sub-menu {
    top: 107%;
    opacity: 1;
    visibility: visible
}

.mega-menu.c1 {
    width: 770px;
    left: -100px;

}

.mega-menu.c2 {
    width: 510px;
    margin-left: 0;
    left: -167px;
}

.mega-menu.Sc2 {
    width: 700px;
    margin-left: 0;
    left: -274px;
}

.mega-menu.c3 {
    width: 431px;
    margin-left: -174px;
    background: url(../images/myrozgar-bg.png) bottom right no-repeat #fff;
    object-fit: cover;
    background-size: 35%
}

.loginhome {
    display: flex
}

.loginhome i {
    font-weight: 900
}

.menu-item-has-children.loginpd {
    padding: 21px 8px 3px 0;
    margin: 0 14px 2px 30px;
    color: #fff
}

.menu-item-has-children.loginpd .loginhome a {
    color: #e81c28 !important;
    border-radius: 20px;
    margin: 0px 4px;
    padding: 9px 20px;
    font-weight: 600;
    background: transparent;
    border: 1px solid #e81c28;
}

.menu-item-has-children.loginpd .loginhome a:hover {
    color: #e81c28 !important;
    background: rgb(255 201 201 / 17%) !important;
}

.menu-item-has-children.loginpd .loginhome a:first-child {
    background: rgb(191 191 191 / 17%);
    color: #3e3e3e !important;
    border: 0px solid #736f6f;
}

.menu-item-has-children.loginpd:hover {
    border-bottom: 4px solid #fff !important;
}

.register {
    color: #fff !important;
    font-weight: 500 !important;
    padding: 8px 16px;
    border-radius: 50px;
    background: #3b5999
}

.menu-item-has-children.registerpd {
    padding: 12px 4px 12px 2px;
    border-left: 0 solid #eff2f5;
    margin-right: 40px
}

.menu-item-has-children.prologinpd {
    padding: 12px 20px
}

.mob-icon-btn {
    padding: 5px 10px !important;
}

.dropbtn-mobapp {
    position: relative;
}

.dropdownmobapp {
    position: relative;
    display: inline-block;
    float: right;
}

.dropdownmobapp-content {
    display: none;
    position: absolute;
    background-color: #fafafa;
    right: 0;
    min-width: 350px;
    box-shadow: 0 0px 8px 0 rgb(0 0 0 / 20%);
    padding: 20px;
    top: 100%;
    -webkit-box-shadow: 0 0px 8px 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid;
    border-color: #e81c28;
    border-radius: 5px;
}

.dropbtn-mobapp img {
    padding: 16px 0px;
}

.dropdownmobapp-content a {
    color: black;
    padding: 12px 6px;
    text-decoration: none;
    display: block;
}

.dropdownmobapp-content a:hover {
    background-color: #fafafa;
}

.dropdownmobapp:hover .dropdownmobapp-content {
    display: block;
}

.dropdownmobapp:hover .dropbtn-mobapp {
    background-color: transparent;
}

.dropdownmobapp-content p {
    font-size: 16px;
    margin-bottom: 10px;
}

.mobappp-g-pay {
    max-width: 100px;
}

.mobappp-store {
    max-width: 100px;
}

.mobapppic {
    position: relative;
}

.mobapppic img {
    max-width: 55px;
}

.employezone:hover {
    border: none !important;
}

.employezone a,
.my-rozgar a {
    color: #222 !important;
    font-size: 14px !important
}

.my-rozgar i {
    font-weight: 900;
    font-size: 12px
}

.rg-dropdownmenu {
    right: 0;
    left: auto;
    margin: 0;
    z-index: 9;
    opacity: 0;
    top: 100px;
    padding: 0;
    float: none;
    width: 315px;
    font-size: 13px;
    background: #fff;
    list-style: none;
    position: absolute;
    line-height: 20px;
    visibility: hidden;
    border-radius: 0 0 5px 5px;
    border: 1px solid #eff2f5
}

.rg-dropdownmenu li,
.rg-sliderholder {
    position: relative;
    width: 100%;
    float: left
}

.rg-langnotification>li:first-child:hover .rg-dropdownmenu {
    top: 100%;
    visibility: visible;
    opacity: 1
}

.rg-dropdownmenu li {
    line-height: inherit;
    list-style: none;
    padding: 2px 6px
}

.rg-dropdownmenu li+li {
    border-top: 1px solid #eaeaea;
    list-style: none
}

.rg-dropdownmenu li a {
    margin: 0;
    line-height: inherit
}

.rg-dropdownmenu li a em {
    color: #767676;
    font-size: 11px;
    line-height: inherit
}

.rg-slidercontentholder {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    /* position: absolute; */
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -webkit-box-orient: vertical;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

}

.rg-slidercontent {
    width: 100%;
    float: left
}

.rg-slidercontent h1 {
    color: #333333;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px
}

.rg-slidercontent .rg-description {
    padding: 0 130px 32px
}

.rg-slidercontent .rg-description p {
    margin: 0;
    color: #666;
    font-size: 18px;
    line-height: 12px;
}

.pr-rupees-del {
    color: #888888;
    font-size: 18px;
}

.pr-rupees {
    font-size: 16px;
}

.rg-searchoptions .form-group {
    margin: 0;
    position: relative
}

.rg-searchoptions .form-group:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    content: '';
    position: absolute
}

.rg-searchoptions .form-group .rg-radio {
    width: auto
}

.rg-searchoptions .form-group .rg-radio+.rg-radio {
    padding: 0 0 0 3px
}

.rg-searchoptions .form-group .rg-radio input[type=radio]+label {
    color: #fff;
    font-size: 14px;
    margin: 0 0 3px;
    line-height: 14px;
    padding: 11px 38px;
    border-radius: 5px
}

.rg-searchoptions .form-group .rg-radio input[type=radio]+label:before {
    display: none
}

.rg-searchoptions .form-group .rg-radio.rg-employers label {
    background: #324b6e
}

.rg-searchoptions .form-group .rg-radio.rg-candidates label {
    background: #fc413c
}

.rg-searchoptions .form-group .rg-radio input[type=radio]+label:hover,
.rg-searchoptions .form-group .rg-radio input[type=radio]:checked+label {
    margin: 0;
    line-height: 17px;
    border-radius: 5px 5px 0 0
}

.rg-formbannersearch .rg-searcharea {
    background: #fff;
    position: relative;
    border-radius: 4px;
    padding: 10px 110px 9px 10px;
    -webkit-box-shadow: 0 0 8px #eee, 1px 0 3px #0000001a, -1px 0 3px #0000001a;
    box-shadow: 0 0 8px #eee, 1px 0 3px #0000001a, -1px 0 3px #0000001a;
}

.rg-formbannersearch .rg-searcharea input {
    font-size: 15px;
}

.rg-formbannersearch .rg-searcharea select {
    font-size: 15px;
}

.rg-searchholder {
    width: 32%;
    float: left;
    padding: 8px 0;
    border-right: 1px solid #ddd
}

.rg-searchholder:last-child {
    border-right: none
}

.rg-searchholder.bl-0 {
    border-right: 0 solid #ddd;
    width: 34% !important
}

.rg-searchholder .form-group {
    margin: 0
}

.rg-searchholder label {
    width: 100%;
    float: left;
    color: #767676;
    font-size: 14px;
    margin: 0 0 5px;
    text-align: left;
    line-height: 14px
}

.rg-searchholder label em,
.rg-searchholder label span {
    line-height: inherit;
    display: inline-block;
    vertical-align: middle
}

.rg-searchholder label em {
    margin: 0 10px 0 0
}

.rg-searchholder .rg-inputwithicon i {
    top: .3em;
    left: auto;
    height: auto;
    color: #5c5c5c;
    font-size: 18px;
    line-height: normal
}

.rg-searchholder .form-control {
    border: 0;
    height: auto;
    color: #324b6e;
    font-size: 14px;
    line-height: 26px;
    padding: 0 0 0 30px;
    caret-color: #cecdcd
}

.rg-searchholder .form-control::-webkit-input-placeholder {
    color: #888
}

.rg-searchholder .form-control::-moz-placeholder {
    color: #888
}

.rg-searchholder .form-control:-ms-input-placeholder {
    color: #888
}

.rg-searchholder .form-control:-moz-placeholder {
    color: #888
}

.rg-searchholder+.rg-searchholder {
    width: 30%;
    float: left;
    padding: 6px 0 8px 25px
}

.rg-searchholder+.rg-searchholder label {
    margin: 0 0 13px
}

.rg-searchholder .rg-select .chosen-container {
    float: left;
    font-size: 14px;
    line-height: 26px;
    width: 100% !important
}

.rg-searchholder .rg-select:after {
    line-height: normal
}

.rg-searcharea .rg-searchholder .rg-select:after {
    font-weight: 600;
    top: 14px;
    z-index: 0
}

.rg-searchholder .rg-select .chosen-container-single .chosen-single {
    border: 0;
    padding: 0;
    z-index: 1;
    height: auto;
    color: #767676;
    box-shadow: none;
    background: 0 0;
    text-align: left;
    position: relative
}

.rg-searchholder .rg-select .chosen-container-single .chosen-drop {
    border: 0;
    margin: 0
}

.rg-searchholder .chosen-container-single .chosen-search {
    width: 100%;
    float: left;
    padding: 6px 10px
}

.rg-searchholder .chosen-container-single .chosen-search input[type=text] {
    width: 100%;
    height: 34px;
    border-radius: 2px;
    padding: 10px 20px;
    background-image: none !important
}

.rg-searchholder .chosen-container .chosen-results {
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    font-size: 14px;
    text-align: left;
    line-height: 34px
}

.rg-searchholder .chosen-container .chosen-results li {
    width: 100%;
    float: left;
    padding: 0 15px;
    line-height: inherit
}

.rg-searchholder .chosen-container .chosen-results li.result-selected {
    color: #fff
}

.rg-searchbtn {
    top: 50%;
    right: -5px;
    position: absolute;
    margin: -20px 10px 0px
}

.rg-searchbtn .rg-btn {
    border: 0;
    padding: 0;
    width: 108px;
    color: #fff;
    font-size: 20px;
    line-height: 40px;
    background: #e81c28;
    border-radius: 4px;
    margin-right: 6px;
}

.rg-haslayout.rg-sectionspace {
    width: 100%;
    float: initial;
    position: relative;
    display: flow-root;
    padding: 36px 0 20px;
}

.rg-searchbtn .rg-btn:hover {
    background: #d10914
}

.homemainSection {
    padding-top: 0px;
}

.rg-likebtnbox .rg-applied-btn:hover {
    color: #fff !important
}

.companytoplogo {
    float: left;
    width: 100%;
    background: #fff;
    padding: 40px 0px 0px 0px;
    position: relative;
    display: flow-root;
}

.comtop-pr-box {
    padding-top: 25px;
}

.searchjobview {
    position: relative;
    text-align: left;
    font-size: 15px;
    padding-top: 10px;
    color: #666;
}

.searchjobview span {
    color: #e81c28;
    font-weight: 600;
}

.rg-top-companies-hiring-viewall {
    max-width: 140px;
    height: 80px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    margin-right: 10px;
    width: 100%;
    display: flex;
    background: #f9f9f9;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.rg-top-companies-hiring-viewall:hover {
    background: #f4f4f4;
}

.rg-top-companies-hiring {
    width: 100%;
    float: left;
    padding: 0px 0 0;
}

.rg-top-companies-hiring span {
    color: #333;
    display: block;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 14px;
}

.rg-btn-top-companies-hiring {
    width: 100%;
    float: left;
    list-style: none;
    text-align: center;
}

.rg-btn-top-companies-hiring li {
    line-height: normal;
    display: inline-block;
    list-style: none;
    vertical-align: middle;
    margin-bottom: 10px;
    width: 12.5%;
}

.rg-btn-top-companies-hiring li img {
    max-width: 140px;
    height: 80px;
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    margin-right: 15px;
    width: 94%;
    object-fit: contain;
}

.rozgarmela-resume-box {
    margin-top: -70px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.rozgarmela-resume-box a.createalterjob-btn {
    background: #dbf2ff;
    padding: 10px 25px;
    border-radius: 40px;
    font-size: 17px;
    color: #222222;
    font-weight: 500;
    /*box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%); border: 1.26923px solid #e9e9e9;*/
}

.rozgarmela-resume-box a.createalterjob-btn img {
    max-width: 55px;
}

.rozgarmela-resume-box a.createalterjob-btn i {
    font-size: 16px;
    color: #5c5c5c;
    padding-right: 4px;
}

.rozgarmela-resume-box a.pmrozgar-btn {
    background: #dbf2ff;
    padding: 10px 30px;
    border-radius: 40px;
    font-size: 17px;
    color: #222222;
    font-weight: 500;
    margin: 0px 16px;
    display: flex;
    align-items: center;
    /*box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);border: 1.26923px solid #e9e9e9;*/
}

.rozgarmela-resume-box a.pmrozgar-btn img {
    max-width: 55px;
}

.rozgarmela-resume-box a.pmrozgar-btn i {
    font-size: 22px;
    color: #5c5c5c;
    padding-right: 4px;
}

.rozgarmela-resume-box a.caprozgar-btn {
    background: #dbf2ff;
    padding: 10px 30px;
    border-radius: 40px;
    font-size: 17px;
    color: #222222;
    font-weight: 500;
    margin-left: 0px;
    display: flex;
    align-items: center;
    /*box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);border: 1.26923px solid #e9e9e9;*/
}

.rozgarmela-resume-box a.caprozgar-btn i {
    font-size: 18px;
    color: #5c5c5c;
    padding-right: 4px;
}

.rozgarmela-resume-box a.createCV-btn {
    background: #dbf2ff;
    padding: 10px 30px;
    border-radius: 40px;
    font-size: 17px;
    color: #222222;
    font-weight: 500;
    margin-right: 16px;
    /*box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);border: 1.26923px solid #e9e9e9;*/
}

.rozgarmela-resume-box a.createCV-btn img {
    max-width: 55px;
}

.rozgarmela-resume-box a.createCV-btn i {
    font-size: 16px;
    color: #5c5c5c;
    padding-right: 4px;
}


.lpfeatures-jobbox {
    position: relative;
    background: #fff2f2;
    padding: 44px 20px;
    border-radius: 4px;
    border: 1px solid #ffe9e9;
    margin-top: -4px;
    min-height: 202px;
}

.lpfeatures-jobbox h2 {
    font-size: 21px;
    position: relative;
}

.lpfeatures-jobbox h2 span {
    font-weight: 600;
}

.lpfeatures-jobbox h2 span.latesttext {
    font-family: 'MonteCarlo', cursive;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 20px;
}

.lpfeatures-jobbox h2::before {
    content: '';
    position: absolute;
    height: 4px;
    background: #ff6969;
    width: 45px;
    bottom: -6px;
    border-radius: 20px;
}


.rg-btnsjobstags {
    width: 100%;
    float: left;
    margin-top: 50px;
}

.rg-btnsjobstags span {
    color: #333;
    display: block;
    font-size: 20px;
    margin: 0 0 19px;
    line-height: 16px;
    font-weight: 500;
}

.rg-btnjobtags.getprbox {
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    border: 1px solid #eeeeee;
    border-radius: 4px;
}

.rg-btnjobtags {
    width: 100%;
    float: left;
    list-style: none;
}

.rg-btnjobtags li {
    line-height: normal;
    display: inline-block;
    list-style: none;
    vertical-align: middle;
    width: 25%;
    padding: 6px 8px;
}

.rg-btnjobtag {
    color: #333333;
    float: left;
    font-size: 11px;
    padding: 0 14px;
    background: #333333;
    line-height: 20px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
}

.rg-btnjobtags li .rg-btnjobtag {
    font-size: 16px;
    line-height: 20px;
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
}

.rg-btnjobtag:focus,
.rg-btnjobtag:hover {
    color: #111
}

.rg-btnjobtag.rg-fulltimejob,
.rg-btnjobtag.rg-internship {
    background: #f9f9f9;
}

.rg-btnjobtag.rg-fulltimejob i {
    font-size: 20px;
    margin-right: 20px;
    width: 40px;
    height: 40px;
    background: #fff;
    text-align: center;
    border-radius: 50px;
    line-height: 40px;
    color: #878787;
}

.rg-btnjobtag.rg-fulltimejob i.pr-resume-bg {
    background: #ecfdff;
    color: #5a9eb1;
}

.rg-btnjobtag.rg-fulltimejob i.pr-jsearch-bg {
    background: #fffaeb;
    color: #ffb85b;
}

.rg-btnjobtag.rg-fulltimejob i.fa-linkedin-in {
    width: 68px;
    background: #f4efff;
    color: #866ac8;
}

.rg-btnjobtag.rg-fulltimejob i.pr-astro-bg {
    background: #fff1ed;
    color: #ff8262;
}


.rg-btnjobtag.rg-parttimejob {
    background: #9eded8
}

.rg-btnjobtag.rg-projectbasejob {
    background: #e0e0e0
}

.rg-btnjobtag.rg-homebasejob {
    background: #9eded8
}

.rg-btnjobtag.rg-fulltimejobreviews {
    background: #969696
}

.rg-btnjobtag.rg-fulltimejobreviews .star i {
    color: #ffecb2
}

.rg-btnjobtag.rg-homefulltimejobreviews {
    background: #d5d5d5;
    margin-top: 4px
}

.rg-btnjobtag.rg-homefulltimejobreviews .star i {
    color: #ff9200
}

.rg-btnjobtag.rg-sponsored-companies {
    background: 0 0;
    color: #222
}

.rg-btnjobtag.rg-sponsored-companies .star i {
    color: #ffecb2
}

.own-personal-recruiter-head {
    display: flex;
    justify-content: space-between;
}

.own-personal-recruiter-head span:nth-child(0n+2) {
    font-size: 21px;
    font-weight: 600;
}

.pr-resume-r {
    margin-right: 0.7rem !important;
}

.comprofile {
    margin: 0;
    padding: 0;
    list-style: none
}

.comprofile span {
    border: 1px solid #eee;
    border-radius: 20px;
    padding: 0 8px;
    display: inline-block;
    color: #666;
    font-size: 10px
}

.rg-homesliderone {
    width: 100%;
    float: left
}

.rg-sliderimg {
    margin: 0;
    width: 100%;
    float: left;
    position: relative
}

.rg-sliderimg img {
    width: 100%;
    height: auto;
    display: block
}

.rg-slidercontentholdervtwo:before,
.rg-sliderimg:before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: ''
}

.rg-sliderimg:before {
    position: absolute;
    background: -moz-linear-gradient(to right, rgb(248 248 248 / 95%) 0%, rgb(248 248 248 / 94%) 100%);
    background: -webkit-linear-gradient(to right, rgb(248 248 248 / 95%) 0%, rgb(248 248 248 / 94%) 100%);
    background: linear-gradient(to right, rgb(248 248 248 / 95%) 0%, rgb(248 248 248 / 94%) 100%);
}

.chosen-container-single .chosen-single div {
    display: none
}

.rg-slidercontentholdervtwo {
    display: block;
    position: relative;
    background: url(../images/slider/img-06.jpg) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.rg-slidercontentholdervtwo:before {
    position: absolute;
    background: -moz-linear-gradient(top, rgba(50, 75, 110, .7) 0, rgba(50, 75, 110, .7) 100%);
    background: -webkit-linear-gradient(top, rgba(50, 75, 110, .7) 0, rgba(50, 75, 110, .7) 100%);
    background: linear-gradient(to bottom, rgba(50, 75, 110, .7) 0, rgba(50, 75, 110, .7) 100%)
}

.rg-bannerslider,
.rg-successstory,
.rg-successstory .item {
    float: left;
    width: 100%
}

.rg-bannerslider {
    padding: 100px 0
}

.rg-story {
    float: left;
    width: 100%;
    padding: 0 270px
}

.rg-imgholder {
    float: left;
    width: auto;
    position: relative;
    margin: 0 30px 0 0
}

.rg-socialshareicon {
    top: -8px;
    left: -8px;
    z-index: 2;
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    border-radius: 50%;
    background: #cd201f
}

.rg-socialshareicon i {
    color: #fff;
    display: block;
    font-size: 14px;
    line-height: 50px;
    text-align: center
}

.rg-storyimg {
    margin: 0;
    width: auto;
    float: left;
    border-radius: 50%;
    border: 8px solid
}

.rg-storyimg img {
    width: 100%;
    display: block;
    border-radius: 50%
}

.rg-storycontent {
    padding: 15px 0;
    overflow: hidden;
    text-align: left
}

.rg-storycontent h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 400;
    margin: 0 0 10px;
    line-height: 65px
}

.rg-storydetails {
    float: left;
    width: 100%
}

.rg-storydetails h3 {
    color: #fff;
    margin: 0 0 2px;
    font-size: 26px;
    font-weight: 400;
    line-height: 26px
}

.rg-storydetails em,
.rg-storydetails span {
    color: #fff;
    display: block;
    padding: 5px 0;
    font-size: 16px;
    line-height: 16px
}

.autharname,
.blogheighbox {
    display: flex
}

.rg-bannersuccessstory .rg-next,
.rg-bannersuccessstory .rg-prev {
    top: 50%;
    color: #fff;
    opacity: 1;
    width: 50px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    margin: -25px 0 0;
    position: absolute;
    text-align: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, .1)
}

.rg-bannersuccessstory .rg-prev {
    left: 0
}

.rg-bannersuccessstory .rg-next {
    right: 0
}

.rg-bannersuccessstory .rg-next:hover,
.rg-bannersuccessstory .rg-prev:hover {
    background: #fff
}

.rg-bannersuccessstory .rg-next:hover span,
.rg-bannersuccessstory .rg-prev:hover span {
    color: #324b6e
}

.rg-slidercontentholdervtwo .rg-formbannersearch {
    background: #fff;
    border-radius: 10px
}

.rg-slidercontentholdervtwo .rg-formtheme.rg-formbannersearch:after,
.rg-slidercontentholdervtwo .rg-formtheme.rg-formbannersearch:before {
    content: '';
    bottom: 0;
    width: 20px;
    height: 20px;
    position: absolute
}

.rg-slidercontentholdervtwo .rg-formtheme.rg-formbannersearch:after {
    left: -4px;
    background: url(../images/contentimg-01.png)
}

.rg-slidercontentholdervtwo .rg-formtheme.rg-formbannersearch:before {
    right: -4px;
    background: url(../images/contentimg-02.png)
}

.rg-slidercontentholdervtwo .rg-searchoptions .form-group {
    padding: 0 30px
}

.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-radio input[type=radio]+label {
    margin: 0;
    color: #767676;
    border-radius: 0;
    font-size: 15px;
    line-height: 14px;
    padding: 16px 20px
}

.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-radio {
    border-right: 1px solid #eff2f5
}

.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-radio+.rg-radio {
    padding: 0
}

.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-radio.rg-candidates label,
.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-radio.rg-employers label,
.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-radio.rg-findjobs label {
    background: #fff
}

.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-radio input[type=radio]+label:hover,
.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-radio input[type=radio]:checked+label {
    border: 0;
    color: #fff;
    margin: -5px 0 0;
    line-height: 19px
}

.rg-slidercontentholdervtwo .rg-formbannersearch .rg-searcharea .rg-select:after,
.rg-slidercontentholdervtwo .rg-select:after {
    margin: -7px 0 0;
    top: 50%
}

.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-employers input[type=radio]+label:hover,
.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-employers input[type=radio]:checked+label {
    background: #324b6e !important
}

.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-candidates input[type=radio]+label:hover,
.rg-slidercontentholdervtwo .rg-searchoptions .form-group .rg-candidates input[type=radio]:checked+label {
    background: #fc413c !important
}

.rg-slidercontentholdervtwo .rg-searcharea {
    box-shadow: none;
    padding: 30px 20px
}

.rg-slidercontentholdervtwo .rg-searchholder {
    border: 0;
    padding: 0;
    width: 50%
}

.rg-slidercontentholdervtwo .rg-searchholder+.rg-searchholder {
    width: 30%;
    padding: 0 10px 0 0
}

.rg-slidercontentholdervtwo .rg-searchholder .form-control {
    color: #767676;
    height: auto;
    font-size: 16px;
    line-height: 48px;
    border: 1px solid #ddd;
    padding: 0 40px 0 50px;
    border-radius: 5px 0 0 5px
}

.rg-slidercontentholdervtwo .rg-searchholder .form-control::-webkit-input-placeholder {
    color: #767676
}

.rg-slidercontentholdervtwo .rg-searchholder .form-control::-moz-placeholder {
    color: #767676
}

.rg-slidercontentholdervtwo .rg-searchholder .form-control:-ms-input-placeholder {
    color: #767676
}

.rg-slidercontentholdervtwo .rg-searchholder .form-control:-moz-placeholder {
    color: #767676
}

.rg-slidercontentholdervtwo .rg-searchholder .rg-inputwithicon i {
    left: 0;
    width: 50px;
    line-height: 50px
}

.rg-slidercontentholdervtwo .rg-searchholder .rg-select .chosen-container {
    font-size: 16px;
    line-height: 16px
}

.rg-slidercontentholdervtwo .rg-searchholder .rg-select .chosen-container .chosen-single {
    color: #767676;
    font-size: 16px;
    line-height: 48px;
    border: 1px solid #ddd;
    border-left: 0;
    padding: 0 10px 0 50px;
    border-radius: 0 5px 5px 0
}

.rg-slidercontentholdervtwo .rg-searchholder .rg-select .chosen-container .chosen-single span {
    line-height: 48px
}

.rg-slidercontentholdervtwo .rg-formbannersearch .rg-searcharea .rg-searchholder.rg-inputwithicon i {
    color: #767676
}

.rg-slidercontentholdervtwo .rg-formbannersearch .rg-searcharea .rg-btn {
    padding: 0;
    border: 0;
    width: 20%;
    float: left;
    color: #fff;
    font-size: 14px;
    line-height: 50px;
    border-radius: 3px;
    background: #324b6e
}

.rg-slidercontentholdervtwo .rg-formbannersearch .rg-searcharea .rg-btn i {
    padding: 0 3px 0 0
}

.rg-slidercontentvthree .rg-btnsjobstags span,
.rg-slidercontentvthree .rg-description p,
.rg-slidercontentvthree h1 {
    color: #324b6e
}

.rg-homeslidervthree .rg-sliderimg:before {
    background: -moz-linear-gradient(to right, rgb(254 254 254) 0%, rgb(249 249 249 / 89%) 100%);
    background: -moz-linear-gradient(left, rgba(50, 75, 110, .8) 0, rgba(255, 255, 255, .8) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, .8) 0, rgba(255, 255, 255, .8) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, .8) 0, rgba(255, 255, 255, .8) 100%)
}

.headline {
    position: relative;
    margin-bottom: 40px !important
}

.headline:before {
    position: absolute;
    content: '';
    width: 80px;
    height: 2px;
    left: 0;
    bottom: -8px;
    background: #7e7f80
}

.blogheadline {
    position: relative;
    font-size: 22px
}

.blogheighbox .linefix {
    flex: 1;
    background: #fafafa;
    margin: 0 15px;
    position: relative
}

.rg-faqsourblog a,
.rg-topcompany a {
    -webkit-box-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.autharname {
    position: absolute;
    bottom: 0
}

.authimag a img {
    width: 36px;
    height: 36px;
    border-radius: 50px
}

.rg-faqsourblog,
.rg-faqsourblog a,
.rg-topcompanies,
.rg-topcompaniesholder {
    width: 100%;
    float: left
}

.blogtihead {
    display: inline-flex
}

.rg-topcompaniesholder {
    margin: 5px 0 0
}

.rg-faqsourblog {
    margin: 0;
    padding: 20px
}

.rg-faqsourblog h3 {
    font-size: 20px;
    margin: 10px 0 0
}

.rg-faqsourblog p {
    padding: 20px 0;
    color: #333;
    text-align: center
}

.rg-faqsourblog p span a {
    position: relative;
    display: contents
}

.rg-faqsourblog a {
    line-height: 25px;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column
}

.rg-faqsourblog a img {
    height: auto;
    display: block;
    width: 100% !important
}

.rg-topcompany {
    margin: 0;
    width: 100%;
    float: left
}

.rg-topcompany a {
    width: 100%;
    float: left;
    min-height: 90px;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column
}

.rg-topcompany a img {
    height: auto;
    display: block;
    width: auto !important
}

.rg-next,
.rg-prev {
    top: 50%;
    opacity: .3;
    font-size: 20px;
    color: #767676;
    cursor: pointer;
    margin: -10px 0 0;
    line-height: 20px;
    position: absolute
}

.rg-prev {
    left: -25px
}

.rg-next {
    right: -25px
}

.rg-next span,
.rg-prev span {
    display: block;
    line-height: inherit
}

.rg-next:hover span,
.rg-prev:hover span {
    color: #324b6e
}

.privacy-policy-box {
    position: relative;
    padding: 30px;
    margin: 0 0 10px;
    background: #fff;
    box-shadow: none;
    border: 1px solid #eff2f5;
    border-radius: 5px
}

.privacy-policy-box h1 {
    font-size: 22px
}

.privacy-policy-box h4 {
    font-size: 18px
}

.privacy-policy-box p {
    margin-bottom: 12px
}

.privacy-policy-box ul {
    width: 100%;
    list-style: none;
    font-size: 14px;
    line-height: 16px;
    padding: 0 0 22px
}

.privacy-policy-box ul li {
    position: relative;
    line-height: inherit;
    padding: 2px 0 8px 20px;
    list-style: none
}

.privacy-policy-box ul li:before {
    font: 12px FontAwesome;
    content: '\f00c';
    margin: 1px -16px 10px;
    position: absolute;
    color: #ce4850
}

.rg-featuredjobs {
    width: 100%;
    float: left;
    margin: -4px 5px -10px
}

.rg-featurejobholder {
    float: left;
    width: 33.33%;
    padding: 10px
}

.rg-feature-full-width,
.rg-featurejob {
    width: 100%;
    padding: 10px;
    float: left
}

.rg-featurejob {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%)
}

.rg-featurejob .rg-companyhead .rg-rightarea {
    padding-right: 10px
}

.rg-companyimg {
    width: 81px;
    float: left;
    min-height: 81px;
    margin: 0 10px 0 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    border: 1px solid #ebebeb;
    padding: 4px;
    border-radius: 4px
}

.rg-employergrids .rg-widget .rg-angrycreative .rg-companyimg {
    border: 0px solid #ebebeb;
}

.rg-hiringfeature,
.rg-tagfeature {
    float: left;
    text-align: center;
    border-radius: 50px;
    text-transform: capitalize
}

.rg-companyimg img {
    width: auto;
    height: auto;
    display: block
}

.rg-companyimg .logotext {
    color: #666;
    font-size: 100%
}

.rg-companycontent {
    padding: 7px 0;
    overflow: hidden
}

.rg-companyhead {
    width: 100%
}

.companyreviewbox {
    display: flex;
    justify-content: flex-start
}

.rg-hiringfeature {
    color: #e81c28;
    padding: 0 10px;
    font-size: 11px;
    line-height: 20px;
    font-weight: 500;
    border: 1px solid #e81c28
}

.rg-tagfeature,
.rg-tagfeaturetemp {
    padding: 0 8px;
    font-size: 10px;
    line-height: 20px
}

.rg-hiringfeature:focus,
.rg-hiringfeature:hover {
    color: #e81c28
}

.rg-tagfeature {
    color: #767676;
    border: 1px solid #ddd
}

.rg-savejobs,
.rg-tagfeaturetemp {
    float: left;
    text-align: center;
    border-radius: 50px
}

.rg-tagfeature:focus,
.rg-tagfeature:hover,
.rg-tagfeaturetemp {
    color: #767676
}

.rg-btnlike {
    color: #ddd;
    float: left;
    padding: 2px 0;
    font-size: 18px;
    line-height: 18px;
    margin: 0 0 0 10px
}

.rg-btnlike:focus {
    color: #ddd
}

.rg-btnlike.rg-btnliked i:before,
.rg-btnlike:hover i:before {
    color: #fc413c;
    content: '\f004'
}

.rg-savejobs {
    color: #217285;
    font-size: 10px;
    line-height: 33px;
    border: 1px solid #ddd;
    text-transform: capitalize;
    width: 28px;
    height: 28px
}

.rg-savejobs:focus,
.rg-savejobs:hover {
    color: #222
}

.numofsavejob {
    margin-bottom: -14px;
    background: #fff;
    padding: 16px 20px;
    border-radius: 4px;
    font-size: 18px
}

.recjobapply-box {
    text-align: right;
    float: right
}

.recjobapply {
    color: #fff !important;
    font-size: 18px;
    color: #fff !important;
    border-radius: 50px
}

.recjobapply:hover {
    background: #e81c28
}

.rg-companyname {
    width: 75%;
    float: left
}

.rg-companyname h3 {
    font-size: 14px;
    margin: 0 0 2px;
    font-weight: 600;
    line-height: auto;
    display: block;
    overflow: hidden
}

.rg-companyname span {
    color: #767676;
    display: block;
    font-size: 13px;
    line-height: 17px
}

.rg-companyname h6 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 2px
}

.rg-companyname span.reviewlink {
    color: #0077b5;
    font-size: 13px;
    font-weight: 500;
    padding-left: 6px
}

.rg-companyname span.reviewnumber {
    color: #767676;
    font-size: 13px;
    padding-left: 6px
}

.rg-companyname span.reviewnumber i {
    color: #f7a200
}

.rg-companyname.jobdetails {
    width: 100%;
    float: left
}

.rg-companyname.jobdetails h3 {
    font-size: 18px;
    margin: 0 0 5px;
    font-weight: 600;
    line-height: 16px;
    display: block;
    overflow: hidden
}

.rg-companyname.jobdetails span {
    color: #767676;
    display: block;
    font-size: 13px;
    line-height: 17px
}

.rg-companyname.jobdetails h6 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 2px
}

.rg-companyname.jobdetails span.reviewlink {
    color: #6a089d;
    font-size: 13px;
    font-weight: 600;
    padding-left: 6px
}

.rg-companyname.jobdetails span.reviewnumber {
    color: #767676;
    font-size: 13px;
    padding-left: 0px
}

.rg-companyname.jobdetails span.reviewnumber i {
    color: #f7a200
}

.rg-similarcompanyname {
    width: 67%;
    float: left
}

.rg-similarcompanyname h3 {
    font-size: 15px;
    margin: 0 0 2px;
    font-weight: 500;
    line-height: 20px;
    display: block;
    overflow: hidden
}

.rg-similarcompanyname span {
    color: #767676;
    display: block;
    font-size: 13px;
    line-height: 17px;
    overflow: hidden
}

.rg-similarcompanyname h6 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 1px
}

.rg-similarcompanyname span.reviewlink {
    color: #6a089d;
    font-size: 13px;
    font-weight: 500;
    padding-left: 6px
}

.rg-similarcompanyname span.reviewnumber {
    color: #767676;
    font-size: 13px;
    padding-left: 6px
}

.rg-similarcompanyname span.reviewnumber i {
    color: #f7a200
}

.jobcompanyreviewbox span,
.rg-companydetailsname span {
    color: #767676;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.rg-companydetailsname {
    width: 100%;
    float: left
}

.rg-companydetailsname h3 {
    font-size: 16px;
    margin: 0 0 5px;
    font-weight: 500;
    line-height: 16px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.rg-companydetailsname span {
    display: block;
    font-size: 13px;
    line-height: 17px
}

.rg-companydetailsname p {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0
}

.rg-detailopensjobs {
    width: 55%;
    float: left
}

.rg-detailopensjobs h3 {
    font-size: 16px;
    margin: 0 0 3px;
    font-weight: 500;
    line-height: 18px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.rg-detailopensjobs h6 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0
}

.jobcompanyreviewbox {
    display: flex;
    justify-content: flex-start
}

.jobcompanyreviewbox span {
    display: block;
    font-size: 13px;
    line-height: 17px
}

.jobcompanyreviewbox span i {
    color: #f7a200
}

.jobcompanyreviewbox span.reviewlink {
    color: #6a089d;
    font-size: 13px;
    font-weight: 500;
    padding-left: 6px
}

.jobcompanyhiringdetails {
    float: left;
    width: 100%;
    margin-top: 6px
}

.jobcompanyhiringdetails li {
    list-style: none;
    display: inline-block;
    padding-right: 14px
}

.jobcompanyhiringdetails li i {
    font-size: 14px
}

.roz-companydetaillogo {
    width: 65px;
    float: left;
    min-height: 60px;
    margin: 0 10px 0 0;
    background: #fafafa;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    border: 1px solid #ebebeb;
    padding: 4px;
    border-radius: 4px
}

.roz-companydetaillogo img {
    width: auto;
    height: auto;
    display: block
}

.rg-ourprofessional,
.rg-ourprofessionals,
.roz-companyjobtans,
section#campusrogar,
section#counter-stats {
    width: 100%;
    float: left
}

.read-more-less--more {
    display: inline-block !important
}

.rg-detailopensjobs p {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0
}

.roz-companyjobtans .ellipsis {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap
}

.rg-companydetailsname span.reviewlink {
    color: #6a089d;
    font-size: 13px;
    font-weight: 500;
    padding-left: 6px
}

.rg-companydetailsname span.reviewnumber {
    color: #767676;
    font-size: 13px;
    padding-left: 6px
}

.rg-companydetailsname span.reviewnumber i {
    color: #f7a200
}

section#campusrogar {
    background: 0 0;
    padding: 20px 0
}

section#counter-stats {
    background: 0 0;
    padding: 40px 0
}

.stats {
    text-align: center;
    font: 700 35px 'Open Sans', sans-serif
}

.stats .lnr {
    color: #e81c28;
    font-size: 40px;
    display: block;
    margin-bottom: 20px
}

.stats h5 {
    font-weight: 500;
    margin-top: 11px;
    color: #727272
}

.counting {
    color: #222;
    font-size: 22px;
    font-weight: 600;
}

.count-box {
    padding: 10px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%)
}

.worried-about-box {
    background: #e9f3fc;
    padding: 10px;
    margin-bottom: 40px;
    border-radius: 4px
}

.worried-about-box .nextinterview {
    font-size: 22px;
    font-weight: 500;
    display: flex;
    line-height: 30px;
    align-items: center;
    color: #222222;
    height: 16vh;
    justify-content: center;
    text-align: center;
}

.worried-about-box .nextinterview span {
    font-weight: 400;
    display: contents;
    color: #464646;
    font-size: 20px;
}

.worried-about-box .nextinterview span strong {
    display: contents;
}

.rozgarlarning {
    padding: 16px 20px;
    border-radius: 4px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 5px 2px rgb(0 0 0 / 2%);
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 2%)
}

.rozgarlarninghead {
    background: #f8f8f8;
    padding: 15px 20px;
    border-radius: 2px;
    position: relative
}

.rozgarlarninghead h2 {
    font-size: 18px
}

.rozgarlarninghead p {
    margin-bottom: 10px;
    margin-top: 16px
}

.rg-rozgarlarning p:nth-child(0n+2),
.rozgar-searchcontent .form-group {
    margin-bottom: 0
}

.rozgarlarning h4 {
    font-size: 16px;
    padding-bottom: 4px
}

.rg-rozgarlarning {
    position: relative
}

.rg-rozgarlarning ul {
    margin: 0;
    padding: 0
}

.rg-rozgarlarning ul li {
    display: inline-block;
    padding-right: 20px
}

.rg-rozgarlarning span:first-child {
    padding: 0 0 4px 20px
}

.rg-rozgarlarning span:first-child:before {
    margin: -8px 0 0
}

.rg-rozgarlarning span {
    display: block;
    font-size: 13px;
    line-height: 12px;
    position: relative;
    padding: 4px 0 4px 20px
}

.rg-rozgarlarning span:before {
    left: 4px;
    top: 50%;
    font: 10px/8px FontAwesome;
    margin: -4px 0 0;
    content: '\f192';
    position: absolute
}

.learnreadmore {
    padding: 2px 20px;
    margin-top: 10px;
    border-radius: 50px;
    background: #f9f9f9;
    border: 1px solid #ccc;
    color: #333;
    display: inline-block
}

.rg-ourprofessionals {
    margin: 0px 0 -15px;
}

.rg-ourprofessional {
    -webkit-box-shadow: 0 0 8px 0 rgba(0 0 0 / 6%);
    margin: 15px 0;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%);
    background: #fff
}

.rg-professionaldetail {
    width: 100%;
    float: left;
    padding: 10px;
    position: relative
}

.rg-professionalimg {
    float: left;
    width: auto;
    max-width: 19.2%;
    margin: 0 10px 0 0
}

.rg-professionalimg img {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 0 2px
}

.incombylogo {
    padding: 3px 2px 2px !important
}

.rg-interviewbyrole,
.rg-interviewbyskill {
    width: 100%;
    background: #f2f2f2;
    padding: 10px 5px;
    border-radius: 4px;
    float: left;
    text-align: center
}

.rg-professionalcontent {
    overflow: hidden;
    position: relative
}

.rg-professionalname {
    width: 100%;
    float: left
}

.rg-professionalname h3 {
    margin: 0 0 3px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px
}

.rg-professionalname h3 a {
    display: block
}

.rg-professionalname span {
    color: #767676;
    display: block;
    line-height: 17px
}

.rg-interviewbyrole h3,
.rg-interviewbyskill h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin: 0
}

.roz-jobbylocation {
    position: relative;
    float: left
}

.roz-jobbylocation ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.roz-jobbylocation ul li {
    display: inline-block;
    background: #fff;
    padding: 0px 12px;
    margin: 3px;
    border-radius: 50px;
    width: 16.1%;
    line-height: 24px;
    /* border: 1px solid #eeeeee; */
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 2%);
}

.roz-jobbylocation ul li:hover {
    border: 0px solid #e7e7e7;
}

.roz-jobbylocation ul li a {
    color: #666;
    font-size: 0.844rem;
    width: 92%;
    float: right;
}

.roz-jobbylocation ul li a:hover {
    color: #000
}

.roz-jobbylocation ul li i {
    font-size: 10px
}

.height100 {
    height: 80px;
    margin: 0 0 18px;
}

.rg-popular-caregoriescontent {
    overflow: hidden;
    position: relative;
    padding: 4px 6px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rg-popular-caregories-text {
    position: relative
}

.rg-popular-caregories-text h5 {
    font-size: 16px;
    font-weight: 500;
}

.rg-popular-caregories-job {
    position: relative;
    text-align: center;
    width: 19%;
    margin-left: 20px
}

.rg-popular-caregories-job p {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 6px
}

.rg-popular-caregories-job .btnnew,
.rg-popular-caregories-job .btnnew-blue,
.rg-popular-caregories-job .btnnew-red {
    font-size: 12px;
    padding: 0 14px;
    color: #fff;
    border-radius: 50px;
    display: inline-block;
    vertical-align: middle
}

.rg-popular-caregories-job .btnnew {
    background: #9575cd
}

.rg-popular-caregories-job .btnnew-blue {
    background: #1692b9
}

.rg-popular-caregories-job .btnnew-red {
    background: #e81c28
}

.rg-popular-caregories-job h3 {
    font-size: 22px
}

.job-popular-box {
    -webkit-box-shadow: 0 0 4px 0 rgb(0 0 0 / 6%);
    background: #fff;
    text-align: center;
    -webkit-box-shadow: 0 0 4px 0 rgb(0 0 0 / 3%);
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 3%);
    background: #fff;
    padding-bottom: 20px;
    border-radius: 2px
}

.btn-tpcategories {
    display: inline-block;
    text-align: center;
    background: 0 0;
    border: 2px solid #1e1e1e;
    padding: 4px 26px;
    border-radius: 50px;
    color: #1e1e1e;
    font-weight: 600;
    margin-top: -16px;
    margin-bottom: 30px;
}

.btn-tpcategories:hover {
    background: #1e1e1e;
    color: #fff
}

.rg-totalviews {
    width: 100%;
    float: left;
    display: block;
    color: #767676;
    font-size: 13px;
    margin: 2px 0 0;
    line-height: 18px
}

.rg-totalviews em,
.rg-totalviews i {
    display: inline-block;
    vertical-align: middle
}

.rg-totalviews em {
    margin: 0 0 0 5px
}

.rg-professionalcontent .rg-btn {
    top: 10px;
    z-index: 3;
    right: 10px;
    position: absolute
}

.accelerate-your-job .roz-section-by-rozgar,
.accelerate-your-job .roz-sectionimg {
    position: relative
}

.rg-professionalinfo {
    width: 100%;
    float: left;
    list-style: none;
    border-top: 1px solid #eff2f5
}

.rg-professionalinfo li {
    float: left;
    padding: 0 2em;
    line-height: 38px;
    text-align: center;
    list-style: none
}

.rg-professionalinfo li+li {
    border-left: 1px solid #eff2f5
}

.rg-professionalinfo li i,
.rg-professionalinfo li span,
.rg-professionalinfo li span a {
    color: #767676;
    display: inline-block;
    vertical-align: middle
}

.rg-professionalinfo li i {
    margin: 0 10px 0 0
}

.rg-professionalinfo li:hover span a {
    color: #55acee
}

.accelerate-your-job {
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    width: 100%;
    float: left;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-left: 15px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%)
}

.accelerate-your-job .roz-sectionimg img {
    max-width: 145px;
}

.accelerate-your-job .roz-sectiontext {
    position: relative;
    padding-top: 0;
    padding-top: 10px;
}

.accelerate-your-job .roz-sectiontext h2 {
    font-size: 20px;
}

.accelerate-your-job .roz-sectiontext p {
    font-size: 14px;
}

.accelerate-your-job .roz-section-by-rozgar .by-rozgar {
    position: absolute;
    top: auto;
    right: -25px;
    background: #ffe7e9;
    padding: 3px 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 20px;
    font-weight: 500;
    font-size: 14px;

}

.accelerate-your-job .roz-sectiontext ul {
    margin: 0 0 10px;
    padding: 0;
    list-style: none
}

.accelerate-your-job .roz-sectiontext ul li {
    display: inline-block;
    background: #fff7f7;
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    padding: 2px 20px;
    margin-right: 6px;
    line-height: 20px;
}

.accelerate-your-job .roz-sectiontext ul li i {
    font-size: 16px;
    color: #878787
}

.roz-btn-more {
    background: #e92732;
    border-radius: 50px;
    padding: 6px 18px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 12px;
    margin-top: 55px;
}

.roz-btn-more:focus,
.roz-btn-more:hover {
    color: #fff !important
}

.savejobs-aside {
    width: 100%;
    padding: 0;
    margin: 10px 0;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    border-radius: 5px
}

.savejobs-aside ul {
    padding: 10px 0
}

.savejobs-aside li {
    list-style: none;
    padding: 4px 20px;
    background: #fbfbfb;
    margin-bottom: 1px
}

.savejobs-aside li i {
    font-size: 17px;
    padding-right: 10px
}

.savejobs-aside li:first-child i,
.savejobs-aside li:nth-child(2) i {
    color: #e81c28;
    top: 2px;
    position: relative
}

.savejobs-aside li:nth-child(3) i {
    color: #ff8a00;
    top: 2px;
    position: relative
}

.savejobs-aside li:nth-child(4) i {
    color: #4a90e2;
    top: 2px;
    position: relative
}

.savejobs-aside li:nth-child(5) i {
    color: #ff8a00;
    top: 2px;
    position: relative
}

.savejobs-aside li:nth-child(6) i {
    color: #6ab31b;
    top: 2px;
    position: relative
}

.savejobs-aside li:nth-child(7) i {
    color: #333;
    top: 2px;
    position: relative
}

.rg-customerfeedbacks,
.rg-feebbackslider {
    float: left;
    width: 100%
}

.rg-feebbackslider .owl-stage-outer {
    margin: -8px 0 0 !important
}

.rg-employstory {
    float: left;
    width: 100%;
    padding: 8px
}

.rg-empoyimg {
    float: left;
    width: 160px;
    padding: 8px;
    background: #fff;
    margin: 0 30px 0 0;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2)
}

.rg-empoyimg img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 50%
}

.rg-empoyerinfo {
    width: auto;
    float: left;
    padding: 42px 0
}

.rg-empoyerinfo h3 {
    font-size: 26px;
    font-weight: 600;
    line-height: 26px
}

.rg-empoyerinfo span {
    clear: both;
    display: block;
    color: #767676;
    font-size: 16px;
    line-height: 16px
}

.rg-empoyerinfo span+span {
    margin: 10px 0 0
}

.rg-employstory .rg-description {
    width: 400px;
    float: right;
    padding: 40px 0
}

.rg-employstory .rg-description blockquote {
    margin: 0;
    color: #324b6e;
    font-size: 18px;
    line-height: 26px;
    font-style: italic
}

.rg-authorpicslider {
    width: 100%;
    float: left;
    overflow: hidden;
    margin: 22px 0 0;
    padding: 0 0 0 700px
}

.rg-authorpicslider.owl-carousel .owl-stage-outer {
    margin: 0 0 0 -2px
}

.rg-authorpicslider:before {
    top: 50%;
    z-index: 1;
    content: '';
    height: 1px;
    width: 100%;
    right: 250px;
    background: #ddd;
    position: absolute
}

.rg-authorpicslider .item {
    width: 100%;
    float: left;
    cursor: pointer
}

.rg-authorpicslider .item figure {
    margin: 0;
    width: 50px;
    float: left;
    height: 50px;
    overflow: hidden;
    border-radius: 50%
}

.rg-authorpicslider .item figure img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 50%
}

.rg-authorpicslider .item figure:before,
.rg-newsimg:before {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.rg-authorpicslider .item figure:before {
    opacity: 0;
    width: 100%;
    visibility: hidden;
    background: #324b6e
}

.rg-authorpicslider .owl-item.current .item figure:before,
.rg-authorpicslider .owl-item:hover .item figure:before {
    opacity: .5;
    visibility: visible
}

.rg-moreitems span {
    width: 100%;
    color: #fff;
    display: block;
    font-size: 14px;
    line-height: 54px;
    border-radius: 50%;
    text-align: center;
    background: #202121
}

.rg-sharestory {
    float: left;
    width: 100%
}

.rg-sharestory figure {
    float: left;
    width: auto;
    margin: 0 10px 0 0
}

.rg-sharestory figure img {
    width: 100%;
    display: block
}

.rg-sharestorycontent {
    overflow: hidden
}

.rg-sharestorycontent h3 {
    margin: 0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400
}

.rg-sharestorycontent a {
    color: #55acee;
    font: 13px/13px Poppins, Arial, Helvetica, sans-serif;
    display: inline-block;
    vertical-align: middle
}

.rg-blognews,
.rg-newsarticle {
    width: 100%;
    float: left
}

.rg-posts.rg-blognews {
    padding: 5px 0 0
}

.rg-newsarticle {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15)
}

.rg-newsimg {
    margin: 0;
    width: 100%;
    float: left;
    overflow: hidden
}

.rg-newsimg img,
.rg-postsgrid .rg-newsimg img {
    width: auto;
    max-width: none
}

.rg-newsimg img {
    display: block
}

.rg-newsimg:before {
    opacity: 0;
    width: 100%;
    visibility: hidden;
    background: -moz-linear-gradient(top, rgba(50, 75, 110, .7) 0, rgba(50, 75, 110, .7) 100%);
    background: -webkit-linear-gradient(top, rgba(50, 75, 110, .7) 0, rgba(50, 75, 110, .7) 100%);
    background: linear-gradient(to bottom, rgba(50, 75, 110, .7) 0, rgba(50, 75, 110, .7) 100%)
}

.rg-newsarticle:hover .rg-newsimg:before {
    opacity: 1;
    visibility: visible
}

.rg-posttag {
    z-index: 3;
    right: 10px;
    color: #fff;
    width: 40px;
    opacity: 0;
    height: 40px;
    bottom: 10px;
    display: block;
    line-height: 38px;
    position: absolute;
    text-align: center;
    border-radius: 50%;
    visibility: hidden;
    border: 2px solid #fff
}

.rg-newsarticle:hover .rg-posttag {
    opacity: 1;
    visibility: visible
}

.rg-posttag:focus {
    color: #fff
}

.rg-postauthorname {
    width: 100%;
    float: left;
    padding: 20px 0
}

.rg-postauthorpic {
    float: left;
    margin: 0 20px 0 0;
    border-radius: 50%
}

.rg-postauthorpic img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 50%
}

.rg-articlecontent {
    overflow: hidden;
    padding: 6px 0
}

.rg-articletitle {
    width: 100%;
    float: left
}

.rg-articletitle h3 {
    margin: 0 0 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px
}

.rg-articletitle h3 a {
    display: block;
    line-height: 23px
}

.rg-authorname {
    width: 100%;
    float: left;
    color: #767676;
    font-size: 14px;
    line-height: 17px;
    position: relative;
    padding: 0 0 0 30px;
}

.rg-authorname a {
    color: #767676
}

.rg-authorname a:focus,
.rg-newsarticle:hover .rg-authorname,
.rg-newsarticle:hover .rg-authorname a {
    color: #55acee
}

.rg-authorname:after {
    font: 13px/17px Linearicons-Free !important;
    color: #767676;
    content: '\e82a';
    position: absolute;
    font-family: "Linearicons-Free!important";
    left: 10px;
}

.rg-postarticlemeta {
    clear: both;
    margin: -10px;
    overflow: hidden;
    list-style: none;
    font-size: 13px;
    line-height: 13px;
    border-top: 1px solid #eff2f5
}

.rg-postarticlemeta li {
    width: 33%;
    float: left;
    padding: 12px 10px;
    text-align: center;
    line-height: inherit;
    list-style: none
}

.rg-postarticlemeta li+li {
    border-left: 1px solid #eff2f5
}

.rg-postarticlemeta li i,
.rg-postarticlemeta li span {
    color: #767676;
    display: inline-block;
    vertical-align: middle
}

.rg-postarticlemeta li:last-child span {
    color: #55acee
}

.rg-postarticlemeta li i {
    margin: 0 5px 0 0
}

.rg-footeraboutus {
    width: 100%;
    float: left;
    padding: 40px 0;
    text-align: center;
    background-color: #2a2a2a;
    border-top: 2px solid #f6f6f6;
}

.rg-footeraboutus .rg-logo {
    padding: 0;
    float: none;
    width: 160px;
    display: block;
    overflow: hidden;
    margin: 0 auto 6px
}

.rg-footeraboutus .rg-description p {
    margin: 0;
    font-size: 14px;
    line-height: 24px
}

.rg-footeraboutus .rg-description p a {
    color: #55acee;
    text-decoration: none
}

.rg-footeraboutus .rg-socialiconssimple {
    padding: 2px 0 0
}

.rg-footeraboutus .rg-socialiconssimple li {
    float: none;
    display: inline-block;
    vertical-align: middle
}

.mobileappbg {
    background: #fcefef;
    width: 100%;
    margin: 20px 0px;
    float: left;
    border-radius: 6px;
    padding: 8px 0;
    position: relative;
    text-align: center
}

.mobileappbg h3 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 18px;
}

.mobileappbg p {
    margin-bottom: 4px;
}

.mobileappbg .appshaperight {
    position: absolute;
    bottom: 0;
    right: 0
}

.mobileappbg .appshaperight img {
    max-width: 50%
}

.appdeveloper-vector {
    position: relative;
    z-index: 9;
}

.appdeveloper-vector img {
    max-width: 130px;
}

.appsearch {
    border: 1px solid #c4c4c4;
    width: 400px;
    background: #fff;
    overflow-x: hidden;
    display: flex;
    margin: 2px auto;
    border-radius: 50px;
    font-size: 18px;
    position: relative
}

.appsearch input {
    width: 310px;
    height: 36px;
    border: none;
    padding: 8px 20px;
    outline: 0;
    font-size: 16px
}

.button-src {
    padding: 2px 6px;
    display: inline-block
}

.button-src button {
    padding: 4px 8px;
    border-radius: 50px;
    border: none;
    margin: 2px;
    background-color: #ff7a00;
    color: #fff;
    font-size: 16px
}

.rg-fourcolumns {
    width: 100%;
    float: left;
    border-top: 0 solid #f2f2f2;
    background-color: #f2f2f2;
}

.rg-footercol {
    width: 20%;
    float: left;
    padding: 40px 15px
}

.rg-footercol+.rg-footercol {
    padding: 40px 15px 40px 30px;
    border-left: 0 solid #eff2f5
}

.rg-footeraboutus .roz-logo {
    max-width: 45%
}

.rg-footeraboutus .headh3 {
    font-size: 16px;
    font-weight: 600;
    color: #888;
    margin-bottom: 6px;
    margin-top: 8px
}

.rg-footeraboutus .headh4 {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
}

.rg-footeraboutus .addfooter {
    margin: 0;
    padding: 0;
    list-style: none
}

.rg-footeraboutus .addfooter li a:before,
.rg-widgetcontent ul li a:before {
    left: 0;
    margin: -5px 0 0;
    content: '\f0da';
    font-family: FontAwesome
}

.rg-footeraboutus .addfooter li {
    color: #fff;
    list-style: none;
    line-height: 1.6
}

.rg-footeraboutus .addfooter li a {
    transition: all .3s ease-in-out;
    display: block;
    font-size: 14px;
    color: #333333;
    position: relative
}

.rg-footeraboutus .addfooter li a:before {
    top: 50%;
    opacity: 0;
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    visibility: hidden
}

.rg-footeraboutus .addfooter li:hover a:before {
    opacity: 1;
    visibility: visible
}

.rg-footeraboutus .addfooter li:hover a {
    padding: 0 0 0 10px
}

.rg-footeraboutus .addfooter li a:hover {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.fivecolom {
    display: flex;
    width: 100%
}

.fivecolom .colom5 {
    width: 20%;
    padding: 20px 30px;
    text-align: left
}

.fixed-header {
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff
}

.visible-title {
    visibility: visible
}

.rg-widget,
.rg-widgetcontent {
    width: 100%;
    float: left
}

.rg-widgetcontent ul {
    width: 100%;
    float: left;
    font-size: 14px;
    list-style: none;
    font-weight: 400;
    line-height: 14px
}

.rg-widgetcontent ul li {
    width: 100%;
    float: left;
    padding: 6px 0;
    position: relative;
    line-height: inherit;
    list-style: none
}

.rg-widgetcontent ul li a {
    transition: all .3s ease-in-out;
    display: block;
    font-size: 14px;
    color: #333333;
    position: relative
}

.rg-widgetcontent ul li:hover a {
    padding: 0 0 0 6px;
}

.rg-widgetcontent ul li a:before {
    top: 50%;
    opacity: 0;
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    visibility: hidden
}

.rg-widgetcontent ul li:hover a:before {
    opacity: 1;
    visibility: visible
}

.rg-fwidgettitle {
    width: 100%;
    float: left;
    padding: 0 0 11px
}

.rg-fwidgettitle i {
    float: left;
    font-size: 16px;
    line-height: 16px;
    margin: -2px 7px 0 0
}

.rg-fwidgettitle h3 {
    margin: 0;
    float: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 16px;
    color: #333333;
}

.rg-footercol .rg-widgetcontent ul li:last-child {
    padding-bottom: 0
}

.rg-widgetcontent ul li.rg-viewmore a {
    color: #55acee
}

.rg-widgetcontent ul li.rg-viewmore:hover a {
    padding: 0
}

.rg-widgetcontent ul li.rg-viewmore a:before {
    display: none
}

.rg-widgetjobtype .rg-widgettiltshift,
.rg-widgetusfulllinks .rg-widgettiltshift {
    padding: 21px 0 11px
}

.rg-widgetusfulllinks .rg-btnappdowld {
    float: left;
    margin: 0;
    padding: 0;
    width: auto
}

.rg-widgetusfulllinks .rg-btnappdowld li:first-child {
    padding-left: 0
}

.rg-footerbottom {
    width: 100%;
    float: left;
    padding: 20px 0;
    position: relative;
    background: #1a1a1a;
}

.rg-btnscrolltop {
    top: -20px;
    right: 40px;
    width: 50px;
    height: 50px;
    display: block;
    color: #3a3a3a;
    font-size: 14px;
    background: #fff;
    position: absolute;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2)
}

.rg-btnscrolltop i {
    width: 42px;
    line-height: 42px;
    border-radius: 50%;
    border: 1px solid #dedede
}

.rg-copyrights {
    margin: 0;
    float: left;
    color: #fff;
    font-size: 13px;
    line-height: 20px
}

.rg-copyrights span {
    color: #ff0f1d;
}

.rg-addnav {
    float: right
}

.rg-addnav ul {
    width: 100%;
    float: left;
    font-size: 14px;
    line-height: 20px;
    list-style: none
}

.rg-addnav ul li {
    float: left;
    padding: 0 7px;
    line-height: inherit;
    list-style: none
}

.rg-addnav ul li:last-child {
    padding-right: 0
}

.rg-addnav ul li a {
    color: #fff;
    font-size: 13px;
    display: block
}

.rg-addnav ul li:hover a {
    color: #55acee
}

.rg-innersectionhead {
    width: 100%;
    float: left;
    padding: 0 0 12px
}

.rg-innersectionhead h2 {
    margin: 0;
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 0 4px
}

.rg-innersectionhead span {
    float: left;
    color: #767676;
    display: block;
    font-size: 14px;
    line-height: 16px
}

.rg-innersectionhead .sendme-jobs {
    width: 100%;
    text-align: right
}

.rg-innersectionhead .sendme-jobs button {
    color: #222;
    font-weight: 400;
    outline: 0;
    background: #ffe8e9;
    padding: 0 12px;
    border-radius: 4px 12px;
    font-size: 15px;
    line-height: 18px;
    height: 21px;
    display: inline-block
}

.rg-innersectionhead .sendme-jobs button:hover {
    color: #000
}

.rg-breadcrumbarea {
    float: left;
    width: 100%;
    padding: 10px 0 0
}

.rg-breadcrumb li:after {
    top: 0;
    left: -3px;
    content: '\f105';
    position: absolute;
    font-family: FontAwesome
}

.rg-breadcrumb li:first-child {
    padding-left: 0
}

.rg-breadcrumb li:first-child:after {
    display: none
}

.rg-breadcrumb li a {
    color: #55acee;
    display: block
}

.rozgar-faqssearch {
    float: left;
    width: 100%;
    background: #008ab2;
    padding: 40px 0
}

.rozgar-faqssearch h4 {
    color: #fff;
    font-size: 30px;
    text-align: center
}

.rozgar-faqssearchbox {
    padding: 15px;
    background: #ebebeb;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rozgar-faqssearchbox .rozgar-formbox {
    display: flex
}

.rozgar-searchcontent {
    width: 100%;
    position: relative
}

.rozgar-searchcontent .form-group i {
    position: absolute;
    left: 16px;
    top: 15px
}

.rozgar-searchbtn {
    position: relative
}

.rozgar-searchbtn a {
    color: #fff !important;
    background: #e81c28;
    padding: 6px 40px;
    color: #fff !important;
    border-radius: 4px;
    margin-left: 20px
}

.rozgar-searchbtn a i {
    top: 4px;
    font-size: 24px;
    position: relative
}

.rozgar-searchbtn a:hover {
    color: #fff !important
}

.headline1 {
    color: #008ab2
}

.rozgar-quciksolution {
    position: relative;
    float: left;
    width: 100%;
    padding: 30px 0
}

.rozgar-quciksolution .rozgar-quciksolutionbox {
    position: relative
}

.rozgar-quciksolution .rozgar-quciksolutionbox h2 {
    font-size: 22px;
    margin-bottom: 0px
}

.rozgar-quciksolution .rozgar-quciksolutionbox li span {
    float: left;
    width: 15px;
    vertical-align: top;
    align-items: start
}

.rozgar-quciksolution .rozgar-quciksolutionbox li {
    background: #eee;
    padding: 2px 10px;
    list-style: none;
    margin-bottom: 2px;
    border-radius: 2px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center
}

.rozgar-quciksolution .rozgar-quciksolutionbox li a {
    color: #333;
    line-height: 16px;
    width: 95%;
    float: left;
    margin-left: 10px
}

.rozgar-quciksolution .rozgar-quciksolutionbox .autoheight {
    overflow-y: scroll;
    height: 208px
}

#autoheightfix {
    height: 208px;
    overflow: hidden !important
}

#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, .3);
    background-color: #ccc
}

#style-4::-webkit-scrollbar {
    width: 6px;
    background-color: #ccc
}

#style-4::-webkit-scrollbar-thumb {
    background-color: #666
}

.chosen-select.locations {
    padding: 10px;
    border: none
}

.chosen-select.locations::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, .3);
    background-color: #ccc;
    border-radius: 4px
}

.chosen-select.locations::-webkit-scrollbar {
    width: 6px;
    border-radius: 4px;
    background-color: #ccc
}

.chosen-select.locations::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 4px
}

.rozgar-quciksolution .rozgar-browsebytopic {
    position: relative
}

.rozgar-quciksolution .rozgar-browsebytopic h2 {
    font-size: 22px;
    margin-bottom: 20px
}

.rozgar-quciksolution .rozgar-browsebytopic li {
    display: inline-block;
    background: #e4ebf5;
    margin-right: 2px;
    text-align: center;
    padding: 16px 10px;
    border-radius: 4px;
    width: 32.33%;
    margin-bottom: 8px;
    box-shadow: 0 4px 0 #9ea9b9
}

.profileside,
.rozgar-profilesearchbox {
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rozgar-quciksolution .rozgar-browsebytopic li a {
    font-size: 14px;
    color: #ff000f;
    display: block;
    font-weight: 500
}

.rozgar-quciksolution .rozgar-browsebytopic li i {
    font-size: 30px
}

.rozgar-quciksolution .rozgar-browsebytopic li:hover {
    background: #7fcfd6
}

.rozgar-quciksolution .rozgar-browsebytopic li p {
    color: #333
}

.rozgar-quciksolution .rozgar-createinnertopic {
    position: relative
}

.rozgar-quciksolution .rozgar-createinnertopic h2 {
    font-size: 22px;
    margin-bottom: 20px
}

.rozgar-quciksolution .rozgar-createinnertopic li {
    display: inline-block;
    background: #e4ebf5;
    margin-right: 2px;
    text-align: center;
    padding: 16px 10px 4px;
    border-radius: 4px;
    width: 49.33%;
    margin-bottom: 8px
}

.faqsquestions,
.rozgarfast-services {
    width: 100%;
    float: left
}

.rozgar-quciksolution .rozgar-createinnertopic li a {
    font-size: 14px;
    color: #ff000f;
    display: block;
    font-weight: 500
}

.rozgar-quciksolution .rozgar-createinnertopic li i {
    font-size: 30px
}

.rozgar-quciksolution .rozgar-createinnertopic li:hover {
    background: #7fcfd6
}

.rozgar-quciksolution .rozgar-createinnertopic li p {
    color: #333
}

.rozgarfast-services {
    background: #3e4450
}

.rozgarfast-services .rozgarfast-content {
    position: relative;
    padding: 20px
}

.rozgarfast-services .rozgarfast-content h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 20px
}

.rozgarfast-services .rozgarfast-content p {
    color: #fff;
    margin-bottom: 10px;
    font-size: 16px
}

.rozgarfastcentent-center {
    display: flex;
    align-items: center
}

.rozgarfast-services .problemform {
    position: relative;
    padding: 25px;
    float: left;
    background: #252a34
}

.rozgarfast-services .problemform h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 20px
}

.rozgarfast-services .problemform .form-control {
    padding: 0 10px
}

.rozgarfast-services .problemform input {
    height: 40px !important
}

.rozgarfast-services .problemform textarea {
    height: 60px
}

.rozgarfast-services .problemform i {
    font-size: 30px
}

.rozgar-content {
    width: 80%;
    margin: 0 auto;
    padding: 0 60px 0 0
}

.rozgar-content h3 {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 10px
}

.rozgar-centerplease {
    margin: 0 auto;
    max-width: 270px;
    font-size: 40px
}

.rozgar-question {
    color: #333;
    position: relative;
    background: #e8e8e8;
    margin-bottom: 1px;
    padding: 10px 10px 10px 50px;
    display: block;
    width: 100%;
    cursor: pointer;
    font-size: 16px
}

.rozgar-answers {
    font-weight: 300;
    background: #f2f2f2;
    padding: 0 15px;
    margin: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    position: relative;
    opacity: 0;
    -webkit-transition: .7s ease;
    -moz-transition: .7s ease;
    -o-transition: .7s ease;
    transition: .7s ease
}

.rozgar-answers p {
    font-size: 16px
}

.rozgar-questions:checked~.rozgar-answers {
    height: auto;
    opacity: 1;
    padding: 15px
}

.rozgar-plus {
    color: #333;
    position: absolute;
    margin-left: 10px;
    margin-top: 5px;
    z-index: 5;
    font-size: 2em;
    line-height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease
}

.rozgar-questions:checked~.rozgar-plus {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.rozgar-questions {
    display: none
}

.faqansheight {
    min-height: 215px
}

.accordion-item {
    border: 1px solid #eee;
    border-radius: 6px;
    margin-bottom: 6px;
    padding-bottom: 0
}

.accordion-item h2 {
    font-size: 16px !important
}

.namecontent h3,
.rozgar-profilesearchcontent .form-group {
    margin-bottom: 0
}

.accordion-header {
    background: #f0f0f0;
    padding: 10px
}

.accordion-header button {
    background: 0 0
}

.accordion-header button:focus {
    outline: 0
}

.accordion-collapse {
    padding: 6px 12px 4px
}

.accordion-body {
    line-height: 1.4
}

.accordion-body li {
    height: auto !important;
    background: 0 0 !important;
    line-height: 1.4;
    position: relative;
    padding-left: 18px !important
}

.accordion-body ul {
    margin-top: 10px
}

.accordion-body ul li::before {
    content: "\1F892";
    color: #e81c28;
    position: absolute;
    font-size: 25px;
    left: 6px;
    top: -8px
}

.rozgar-profilesearch {
    float: left;
    width: 100%;
    padding: 40px 0;
    background: url(../images/rozgar-profilesearch-bg.jpg)
}

.rozgar-profilesearch h4 {
    color: #03565e;
    font-size: 30px;
    text-align: center
}

.rozgar-profilesearchbox {
    padding: 15px;
    background: #ebebeb;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rozgar-profilesearchbox .rozgar-formbox {
    display: flex
}

.rozgar-profilesearchcontent {
    width: 100%;
    position: relative
}

.rozgar-profilesearchcontent .form-group i {
    position: absolute;
    left: 16px;
    top: 15px
}

.namecontent,
.namecontent .profileperformance,
.posrel,
.rozgar-profileimgarea .profilepic,
.rozgar-profilesearchbtn,
.rozgar-profilesearchbtn a i {
    position: relative
}

.rozgar-profilesearchbtn a {
    font-size: 20px;
    line-height: 40px;
    background: #e81c28;
    padding: 6px 40px;
    color: #fff;
    border-radius: 4px;
    margin-left: 20px
}

.rozgar-profilesearchbtn a i {
    top: 4px;
    font-size: 24px
}

.rozgar-profilesearchbtn a:hover {
    color: #fff !important
}

.rozgar-profileimgarea {
    float: left;
    width: 100%;
    background: #e4ebf5;
    padding: 45px 0 20px
}

.rozgar-profileimgarea .profilepic img {
    width: 180px;
    height: 180px;
    border-radius: 3px;
    margin-top: -80px;
    border: 4px solid #fff;
    box-shadow: 0 6px 8px #6969694f
}

.rozgar-profileimgarea .profilepic .proimgedit {
    position: relative;
    text-align: center;
    margin-top: -16px
}

.rozgar-profileimgarea .profilepic .proimgedit i {
    background: #fff;
    padding: 16px;
    font-size: 20px;
    border-radius: 100px;
    border: 6px solid #e4ebf5;
    box-shadow: 0 4px 8px #ccc;
    color: #303747
}

.namecontent {
    margin-top: -30px
}

.namecontent p {
    color: #005a9d;
    margin-bottom: 4px
}

.namecontent .profileperformance p {
    font-size: 14px;
    color: #333;
    font-weight: 400;
    margin-bottom: 4px
}

.namecontent .profileperformance ul {
    margin: 0
}

.namecontent .profileperformance ul li {
    background: #fbfcff;
    list-style: none;
    line-height: 1;
    display: inline-block;
    padding: 4px 10px;
    box-shadow: 0 1px 2px #c3cfe1;
    border-radius: 2px;
    margin-right: 10px;
    text-align: center
}

.namecontent .profileperformance ul li a {
    color: #005a9d
}

.namecontent .profileperformance ul li p {
    margin: 0;
    color: #005a9d
}

.progress,
.progress.profile {
    margin-bottom: 10px;
    border-radius: 20px
}

.namecontent .profileperformance ul li span {
    font-weight: 600
}

.profilepreview a,
.profileupdate a {
    color: #ffff !important;
    color: #ffff !important;
    display: inline-block;
    border-radius: 2px
}

.profileupdate {
    padding: 2px 4px
}

.profileupdate a {
    background: #e81c28;
    padding: 8px 33px 11px
}

.profilepreview {
    padding: 2px 4px
}

.profilepreview a {
    background: #287c3b;
    padding: 8px 32px 11px
}

.profilepreview a:hover {
    color: #fff !important;
    background: #57ab6a;
    transition: all
}

.profileupdate a:hover {
    color: #fff !important;
    background: #f33742;
    transition: all
}

.awardbtnbox {
    position: absolute;
    bottom: 0;
    background: #fffff3;
    padding: 2px 20px 10px;
    border-radius: 2px;
    font-weight: 600
}

.awardbtnbox i {
    font-size: 22px;
    color: #ffbc00;
    margin-right: 10px;
    position: relative;
    top: 8px;
    right: 10px
}

.awardbtnbox small {
    color: green;
    font-size: 14px;
    font-weight: 700
}

.awardbtnbox .use-reward {
    display: block;
    font-weight: 400;
    font-size: 10px;
    text-align: right;
    line-height: 6px
}

.awardbtnbox .nubtext {
    font-size: 14px
}

.profileside-head h3,
.profilesideinnner .texthead {
    font-size: 16px;
    font-weight: 500
}

.progress {
    height: 12px;
    width: 300px;
    background-color: #fff
}

.progress-done {
    height: 100%;
    width: 60%;
    opacity: 1;
    background: linear-gradient(to left, #57ab6a, #57ab6a);
    color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s ease
}

.progress.profile {
    background: transparent !important;
    background-color: transparent !important
}

.rozgar-profile-main-content {
    float: left;
    width: 100%;
    background: #fff;
    padding: 50px 0
}

.profileside {
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-bottom: 20px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.profileside-head {
    padding: 15px 15px 8px;
    border-bottom: 1px solid #eee
}

.profilesideinnner {
    padding: 8px 15px;
    border-bottom: 1px solid #eee
}

.profilesideinnner p {
    margin: 0
}

.calltext {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 15px 20px
}

.proknowmore {
    display: inline-block;
    border-radius: 50px;
    color: #e81c28;
    padding: 1px 10px;
    border: 1px solid #e81c28;
    font-weight: 500;
    margin: 10px 0;
    line-height: 16px;
    height: 20px
}

.proknowmore:hover {
    background: #e81c28;
    color: #fff
}

.profilerightside {
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.profilerightside .posttime {
    position: absolute;
    right: 20px;
    top: 10px;
    color: #999;
    font-size: 12px
}

.profilerightside-head {
    padding: 15px 15px 8px;
    border-bottom: 1px solid #eee
}

.profilerightside-head h3 {
    font-size: 16px
}

.projobtext {
    background: #f9f9f9;
    padding: 13px 15px;
    font-size: 26px;
    font-weight: 500
}

.profilerightsideinner {
    padding: 8px 15px;
    position: relative
}

.profilerightsideinner .pro-job-details {
    position: relative
}

.profilerightsideinner .pro-job-details a {
    color: #222
}

.profilerightside .com-total-job-box {
    position: relative;
    border-bottom: 1px solid #cccc;
    padding: 6px 4px
}

.profilerightside .com-total-job-box span {
    padding: 2px 6px;
    background: #fff1f2;
    font-size: 12px;
    font-weight: 600;
    border-radius: 100px;
    width: 45px;
    text-align: center;
    line-height: 14px;
    margin-left: 20px
}

.newrjobs .procomlogo,
.newrjobs .procomtext {
    padding-right: 10px;
    position: relative
}

.profilerightside .com-total-job-box a {
    color: #333
}

.profilerightside .com-total-job-box a:hover {
    color: #777
}

.newrjobs .procomlogo a,
.newrjobs .procomtext a {
    color: #222
}

.newrjobs {
    display: flex;
    margin-bottom: 15px
}

.newrjobs .procomlogo {
    padding-top: 6px
}

.newrjobs .procomlogo img {
    max-width: 35px
}

.newrjobs .procomtext .logtext {
    font-size: 16px;
    font-weight: 500
}

.newrjobs .procomtext .comtext,
.proap-text {
    font-size: 14px
}

.grid03 {
    padding: 2px 0;
    display: block
}

.grid03 .ti-pencil {
    color: #3795b1;
    cursor: pointer;
    border-radius: 2px;
    padding: 2px
}

.grid03 .ti-pencil:hover {
    color: #116e8a
}

.proap-text {
    padding: 15px;
    margin: 0
}

.bb-01 {
    border-bottom: 1px solid #eee
}

.pro-new-save {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 0
}

.jobsearchindiabutton,
.pro-new-save .pro-new-sec,
.pro-new-save .pro-save-sec {
    display: inline-block
}

.pro-new-save .pro-new-sec a {
    padding: 1px 6px;
    background: #888;
    font-size: 11px;
    color: #fff;
    border-radius: 2px
}

.pro-new-save .pro-save-sec a {
    background: 0 0;
    padding: 2px 10px;
    color: #bfbfbf;
    font-size: 18px;
    border-radius: 2px
}

.pro-new-save .pro-save-sec a:hover {
    color: #f33742
}

.rozgar-editprofileimgarea {
    float: left;
    width: 100%;
    padding: 50px 0 30px;
    background: url(../images/rozgar-profilesearch-bg01.jpg)
}

.rozgar-editprofileimgarea .profilepic {
    position: relative
}

.rozgar-editprofileimgarea .profilepic img {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    margin-top: -20px;
    border: 4px solid #fff;
    box-shadow: 0 6px 8px #6969694f
}

.profileheader {
    margin-top: -10px
}

.profileheader img {
    height: 40px;
    width: 40px;
    border-radius: 30px
}

.documentdownload {
    background: #dbafb2;
    padding: 6px 15px;
    border-radius: 30px;
    color: #101010
}

.jobsearchindiabutton {
    color: #fff !important;
    padding: 8px 14px;
    color: #fff !important;
    cursor: pointer;
    border-radius: 50px;
    margin-top: 4px
}

.profileli {
    padding: 18px 20px 2px 0 !important;
    margin-left: 52px;
    text-align: right !important;
    max-width: 270px;
    min-width: 256px;
}

#rg-nav-user .rg-navigation>ul>li {
    padding: 30px 26px 16px
}

.rozgar-editprofileimgarea .profilepic .proimgedit {
    position: relative;
    text-align: center;
    margin-top: -16px
}

.rozgar-editprofileimgarea .profilepic .proimgedit i {
    background: #fff;
    padding: 10px;
    font-size: 20px;
    border-radius: 100px;
    border: 4px solid #e4ebf5;
    box-shadow: 0 4px 8px #ccc;
    color: #303747
}

.proqaucontent {
    position: relative;
    margin-top: -30px
}

.proqaucontent h3 {
    margin-bottom: 0
}

.proqaucontent p {
    color: #005a9d;
    margin-bottom: 4px
}

.proqaucontent .profiledetails {
    position: relative
}

.proqaucontent .profiledetails p {
    font-size: 14px;
    color: #333;
    font-weight: 400;
    margin-bottom: 4px
}

.proqaucontent .btnverifid ul,
.proqaucontent .profiledetails ul {
    margin: 0
}

.proqaucontent .profiledetails ul li {
    list-style: none;
    line-height: 1;
    display: inline-block;
    padding: 0;
    width: 48%;
    border-radius: 2px;
    margin-right: 0;
    text-align: left
}

.proqaucontent .profiledetails ul li a {
    color: #005a9d
}

.proqaucontent .profiledetails ul li p {
    margin: 0;
    color: #005a9d
}

.proqaucontent .profiledetails ul li span.allicon {
    font-weight: 400;
    padding-right: 10px;
    font-size: 14px
}

.proqaucontent .profiledetails ul li span {
    font-weight: 400
}

.proqaucontent .btnverifid ul li {
    list-style: none;
    line-height: 1.8;
    display: block;
    padding: 0;
    border-radius: 2px;
    margin-right: 0;
    text-align: right
}

.epiconnotverified,
.epiconverified,
.proqaucontent .btnverifid ul li i {
    padding: 3px;
    margin-bottom: 8px;
    font-size: 12px;
    width: 16px;
    height: 16px;
    line-height: 10px
}

.proqaucontent .btnverifid ul li i {
    color: #005a9d;
    border: 1px solid #005a9d;
    border-radius: 100px
}

.epiconverified {
    color: #57ab6a !important;
    border: 1px solid #57ab6a !important
}

.epiconnotverified {
    color: #f33742 !important;
    border: 1px solid #f33742 !important
}

.totaltext,
.totaltext02 {
    line-height: 1.4;
    text-align: center
}

.proqaucontent .btnverifid ul li .btnverifyjob {
    background: #005a9d;
    color: #fff;
    border-radius: 50px;
    padding: 2px 10px
}

.proqaucontent .btnverifid ul li .btnepverifyjob {
    background: #57ab6a;
    color: #fff;
    border-radius: 50px;
    padding: 2px 10px
}

.proqaucontent .btnverifid ul li .btnepnotverifyjob {
    background: #f33742;
    color: #fff;
    border-radius: 50px;
    padding: 2px 10px
}

.totalapplied {
    position: relative;
    text-align: center
}

.totalapplied ul {
    margin: 0 0 30px;
    background: #f9f9f9;
    padding: 10px 0;
    border-radius: 4px
}

.totalapplied ul li {
    display: inline-block;
    border-right: 2px solid #ccc
}

.totalapplied ul li:last-child {
    border-right: 0;
    padding-left: 25px
}

.totalnum {
    font-size: 36px;
    font-weight: 300;
    padding-right: 16px;
    color: #666
}

.totaltext {
    color: #536777;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    padding-right: 32px
}

.totalapplied02 {
    position: relative;
    text-align: center
}

.totalapplied02 ul {
    margin: 0 0 30px;
    background: #fff;
    padding: 10px 0;
    border-radius: 4px
}

.totalapplied02 ul li {
    display: inline-block;
    border-right: 2px solid #ccc
}

.totalapplied02 ul li:last-child {
    border-right: 0;
    padding-left: 8px
}

.totalnum02 {
    font-size: 28px;
    font-weight: 300;
    padding-right: 2px;
    color: #666
}

.similarjobs li,
.totaltext02 {
    display: inline-block;
    padding-right: 10px
}

.totaltext02 {
    color: #536777;
    font-size: 12px;
    font-weight: 400
}

.edprojobtext,
.fw500,
dt span {
    font-weight: 500
}

.similarjobshead {
    position: relative
}

.similarjobshead h4 {
    font-size: 18px;
    font-weight: 500;
    padding-top: 10px;
    margin: 0
}

.similarjobs {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 20px
}

.borbt,
.similarjobs ul {
    margin: 0
}

.similarjobs li {
    list-style: none
}

.borbt {
    border: 1px solid #ccc;
    border-radius: 4px
}

.rg-application-status {
    float: left;
    width: 100%;
    border-radius: 4px
}

.pagesappstatus {
    border: 1px solid #eee;
    border-radius: 2px
}

.starter-template {
    padding: 3rem 1.5rem;
    text-align: center
}

.descrip {
    font-size: 12px;
    color: #999
}

dd {
    margin-bottom: 0
}

dt span {
    background: #d7f2e3;
    padding: 5px 10px;
    color: #333;
    border-radius: 2px
}

dt span i {
    background: #039d35;
    color: #ffff;
    border-radius: 20px;
    padding: 4px
}

.statusCont {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 0 1rem
}

.fs12,
.statebar li.status_awaiting div {
    white-space: normal
}

.statusCont::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    -webkit-appearance: none
}

.statusCont::-webkit-scrollbar,
.statusCont::-webkit-scrollbar-track {
    background-color: transparent
}

.statusCont::-webkit-scrollbar-thumb {
    background-color: #a8a8ab
}

.statebar {
    position: relative;
    font-size: 12px;
    display: inline;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    width: auto
}

.statebar li,
.statebar li em {
    display: inline-block;
    position: relative
}

.statebar li {
    width: 130px;
    vertical-align: top
}

.statebar li em {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #28ad35;
    z-index: 9
}

.statebar li em.rozgaricon-CircleTick {
    background: 0 0;
    color: #fff
}

.statebar li em::before {
    background: #28ad35;
    border-radius: 20px;
    width: 16px;
    display: block;
    height: 16px;
    text-align: center;
    line-height: 16px;
    z-index: 1
}

.statebar li div {
    margin-top: 0
}

.statebar li:before {
    content: "";
    position: absolute;
    top: 25px;
    left: -185px;
    width: 185px;
    height: 1px;
    border-top: 1px solid #28ad35;
    z-index: 0;
    margin-left: 5px
}

.statebar li:first-child:before {
    display: none
}

.statebar li.status_awaiting:before {
    left: -174px;
    width: 175px;
    border-color: #ebecf0
}

.statebar li.status_awaiting em {
    background: #ebecf0;
    border-color: #ebecf0
}

.statebar li.CircleTick div {
    color: #28ad35;
    margin-top: 5px
}

.statebar li.Exclamation:before {
    border-color: #c41f1f;
    width: 185px !important;
    left: -183px
}

.statebar li.Exclamation .icon {
    background: 0 0;
    font-size: 16px;
    height: 16px;
    width: 16px;
    position: relative;
    left: 0;
    top: -1px;
    color: #c41f1f;
    padding: 0
}

.statebar .active em {
    background: #d2d0d1
}

.statebar .active:before {
    border-top: 1px dashed #d2d0d1
}

.statebar .active div {
    color: #d2d0d1
}

.fs12 {
    font-size: 12px;
    display: block;
    line-height: 14px
}

.grey-text {
    color: #536777
}

.nesttabs a.nav-link {
    background-color: #fff;
    border: 1px solid #dedede;
    border-radius: 4px;
    color: #000;
    z-index: 1
}

.nesttabs a.nav-link.active {
    background-color: #ffefef;
    z-index: 1;
    box-shadow: 0 18px 12px -12px #e2e7ed;
    border-right: #fff
}

.nesttabs .container {
    background-color: #fff;
    padding: 12px
}

.nesttabs {
    padding: 12px;
    background: #f8f8f8
}

#offerletter tr>td {
    border: 0 solid #dbdbdb;
    text-align: left
}

.recruiterapply {
    position: relative;
    margin-bottom: 20px
}

.recruiterapply ul {
    margin: 0
}

.recruiterapply ul li {
    list-style: none;
    display: inline-block;
    margin-right: 10px
}

.recruiterapply ul li a {
    display: inline-block;
    padding: 2px 10px;
    border: 1px solid #ccc;
    border-radius: 50px;
    color: #333
}

.profileside aside .resumearea {
    background: #008ab2;
    display: flex;
    justify-content: flex-start;
    border-radius: 5px 5px 0 0
}

.get-your-own-box .head-box,
.get-your-text-box,
.grid03.row {
    justify-content: space-between
}

.profileside aside .resumearea img {
    max-width: 100px;
    padding-top: 20px
}

.profileside aside .resumearea .resumeuploadbox {
    position: relative;
    width: 100%;
    padding: 10px
}

.profileside aside .resumearea .resumeuploadbox h2,
.profileside aside .resumearea .resumeuploadbox h4 {
    font-size: 20px;
    color: #fff;
    margin: 0
}

.profileside aside .resumearea .resumeuploadbox p {
    font-size: 13px;
    color: #fff;
    margin: 0 0 10px
}

.profileside aside .resumearea .resumeuploadbox a.btnupload {
    background: #003c4e;
    color: #fff;
    padding: 2px 10px;
    border-radius: 100px;
    font-size: 16px;
    line-height: 19px;
    height: 26px;
    display: inline-block
}

.edprofilerightside {
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.edprofilerightside .posttime {
    position: absolute;
    right: 20px;
    top: 10px;
    color: #999;
    font-size: 12px
}

.edprofilerightside-head {
    padding: 15px 15px 8px;
    border-bottom: 1px solid #eee
}

.edprofilerightside-head h3 {
    font-size: 16px
}

.edprojobtext {
    background: #f9f9f9;
    padding: 13px 15px;
    font-size: 18px
}

.edprofilerightsideinner {
    padding: 8px 15px;
    position: relative
}

.edprofilerightsideinner .pro-job-details {
    position: relative
}

.edprofilerightsideinner .pro-job-details a {
    color: #222
}

.edprofilerightside .com-total-job-box {
    position: relative;
    border-bottom: 1px solid #cccc;
    padding: 6px 4px
}

.edprofilerightside .com-total-job-box span {
    padding: 2px 6px;
    background: #fff1f2;
    font-size: 12px;
    font-weight: 600;
    border-radius: 100px;
    width: 45px;
    text-align: center;
    line-height: 14px;
    margin-left: 20px
}

.edprofilerightside .com-total-job-box a {
    color: #333
}

.edprofilerightside .com-total-job-box a:hover {
    color: #777
}

.grid03.row {
    display: flex;
    padding: 2px 20px
}

.gridgap {
    margin: 20px
}

.cvtype {
    font-size: 14px
}

.cvtype span {
    font-weight: 600
}

.cvdeleted,
.cvdownload {
    font-weight: 700
}

.cvdownload {
    font-size: 20px
}

.cvdownload i {
    color: #008ab2
}

.cvdeleted a {
    color: #008ab2 !important
}

.updateresume {
    position: relative;
    width: 100%;
    background-color: #fcfcfc
}

.cvcard {
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(139 139 139 / 30%);
    background-color: #fff;
    padding: 10px 30px 20px
}

.cvcard h3 {
    font-size: 22px;
    font-weight: 600
}

.cvdrop_box {
    margin: 10px 0;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dotted #d7d7d7;
    border-radius: 5px
}

.btncv,
.btncv:hover {
    padding: 10px 20px;
    text-decoration: none
}

.cvdrop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e
}

.cvdrop_box p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #a3a3a3
}

.btncv {
    background-color: #008ab2;
    color: #fff;
    border: none;
    outline: 0;
    transition: .3s;
    border-radius: 1px
}

.btncv:hover {
    background-color: #fff;
    color: #008ab2;
    border: none;
    outline: 1px solid #010101
}

.formcv input {
    margin: 10px 0;
    width: 100%;
    background-color: #e2e2e2;
    border: none;
    outline: 0;
    padding: 12px 20px;
    border-radius: 4px
}

.grid03 table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 0 solid #ddd
}

.grid03 table th,
td {
    text-align: left;
    padding: 8px
}

.grid03 table tr:nth-child(2n) {
    background-color: #f9f9f9
}

.grid03 table h4 {
    margin: 0;
    font-size: 16px
}

.grid03 table h5 {
    margin: 0;
    font-size: 14px;
    color: #888
}

.grid03 table p {
    margin: 0
}

.profileside aside {
    height: auto;
    background: rgb(255 255 255)
}

.profileside aside nav {
    font-size: 1em;
    font-weight: 500;
    padding: 1em 0
}

.profileside aside nav ul {
    list-style: none
}

.profileside aside nav ul a li {
    color: #000;
    list-style: none;
    position: relative;
    padding: .2em 0 .2em 1em;
    display: flex;
    line-height: 22px;
    align-items: center;
    justify-content: flex-start
}

.profileside aside nav ul a li.active::after,
.profileside aside nav ul a li.active::before,
.profileside aside nav ul a li:hover::after,
.profileside aside nav ul a li:hover::before {
    content: '';
    position: absolute;
    right: 0
}

.profileside aside nav ul a li em,
.profileside aside nav ul a li i {
    padding-right: 10px
}

.profileside aside nav ul a li:hover {
    color: #e81c28;
    background: rgb(255 238 239);
    border-radius: 0
}

.profileside aside nav ul a li:hover::before {
    background: #ffa500;
    top: -1em;
    border-radius: 0 0 .3em
}

.profileside aside nav ul a li:hover::after {
    background: #ffa500;
    bottom: -1em;
    border-radius: 0 .3em .5em 0
}

.profileside aside nav ul a {
    color: inherit;
    text-decoration: none;
    font-size: 13.4px;
    cursor: pointer
}

.profileside aside nav ul a li.active {
    color: #e81c28;
    background: rgb(255 238 239);
    border-radius: 0
}

.profileside aside nav ul a li.active::before {
    background: #ffa500;
    top: -1em;
    border-radius: 0 0 .3em
}

.profileside aside nav ul a li.active::after {
    background: #ffa500;
    bottom: -1em;
    border-radius: 0 .3em .5em 0
}

.pofileupdatetext {
    font-size: 14px;
    font-weight: 500;
    color: #009bc8 !important;
    text-align: right;
    float: right;
}

.btn-keys-kills-sm,
.key-skills-box {
    display: inline-block;
    background: #f3f3f3;
    color: #333;
    margin-right: 10px;
    margin-bottom: 10px
}

.key-skills-box {
    padding: 4px 10px;
    border-radius: 2px;
    border: 1px solid #eee
}

.btn-keys-kills-sm {
    padding: 0 10px;
    border-radius: 2px;
    border: 1px solid #eee
}

.rozgar-jobbylocsearch {
    float: left;
    width: 100%;
    background: #3e4450;
    padding: 40px 0
}

.rozgar-jobbylocsearch h4 {
    color: #03565e;
    font-size: 30px;
    text-align: center
}

.rozgar-jobbylocsearchbox {
    padding: 15px;
    background: #ebebeb;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rozgar-jobbylocsearchbox .rozgar-formbox {
    display: flex
}

.rozgar-jobbylocsearchcontent {
    width: 100%;
    position: relative
}

.rozgar-jobbylocsearchcontent .form-group {
    margin-bottom: 0
}

.rozgar-jobbylocsearchcontent .form-group i {
    position: absolute;
    left: 16px;
    z-index: 1;
    top: 12px;
    font-size: 20px
}

.rozgar-jobbylocearchbtn {
    position: relative
}

.rozgar-jobbylocsearchbtn a {
    font-size: 20px;
    line-height: 40px;
    background: #e81c28;
    padding: 6px 40px;
    color: #fff;
    border-radius: 4px;
    margin-left: 20px
}

.rozgar-jobbylocsearchbtn a i {
    top: 4px;
    font-size: 24px;
    position: relative;
    color: #fff;
}

.rozgar-jobbylocsearchbtn a:hover {
    color: #fff !important
}

.jobsbylocation-top {
    position: relative;
    margin-top: 30px;
    margin-bottom: -38px
}

.jobsbylocation-top li {
    list-style: none;
    display: inline-block;
    margin: 0 1px
}

.jobsbylocation-top li a {
    background: #e4ebf5;
    padding: 8px 26.8px;
    color: #333
}

.jobsbylocation-top li a:first-child {
    border-top-left-radius: 2px
}

.jobsbylocation-top li a:last-child {
    border-top-right-radius: 2px
}

.jobsbylocation-top li a.active,
.jobsbylocation-top li a:hover {
    background: #fff
}

.jobsbylocationColumnBox {
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.jobsbylocationColumnBox .headtext {
    padding: 6px 20px;
    font-size: 15px
}

.jobsbylocationColumn {
    padding: 4px 20px
}

.jobsbylocationColumn.colCount_four {
    column-count: 4
}

.jobsbylocationColumn a {
    word-break: break-word;
    width: 100%;
    padding: 2px;
    margin-bottom: 4px;
    font-weight: 400;
    line-height: 16px;
    display: inline-block;
    color: #000;
    font-size: 12px
}

.jobsbylocation1ColumnBox {
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.jobsbylocation1ColumnBox .headtext {
    font-weight: 600;
    padding: 6px 20px;
    font-size: 15px
}

.jobsbylocation1Column {
    padding: 4px 20px
}

.jobsbylocation1Column.colCount_one {
    column-count: 1
}

.jobsbylocation1Column a {
    word-break: break-word;
    width: 100%;
    padding: 2px;
    font-weight: 300;
    display: inline-block;
    color: #333
}

.jobbycomsearch {
    padding: 10px 20px;
    background: #e2e2e2
}

.jobComsearch {
    width: 100%;
    position: relative;
    display: flex
}

.jobComsearchTerm {
    padding: 5px 10px 10px !important;
    border-right: 0;
    padding: 5px 10px !important;
    height: 37px !important;
    border-radius: 0;
    color: #9dbfaf;
    width: 100%;
}

.jobComsearchButton {
    width: 40px;
    height: 36px;
    border: 1px solid #3e4450;
    background: #3e4450;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px
}

.jobComwrap {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.multi-range-slider .thumb * {
    bottom: 16px
}

.multi-range-slider .bar-right {
    height: 4px;
    margin-top: -3px;
    box-shadow: none
}

.rg-sortandview,
.rightform {
    width: 100%;
    float: left;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rightform {
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-bottom: 20px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rightform h3 {
    font-size: 18px;
    padding: 10px 20px 4px;
    color: #3b5999
}

.rightform form {
    padding: 6px 20px
}

.rightform input {
    padding-left: 10px
}

.roz-signedcheck span {
    font-size: 13px;
    line-height: 16px;
    display: inline-block
}

.btnregister {
    background: #e81c28;
    padding: 0 20px 4px;
    display: block;
    color: #fff;
    font-size: 16px;
    border-radius: 2px
}

.popuraljobIncity {
    padding: 0
}

.popuraljobIncity li {
    position: relative;
    list-style: none;
    padding-left: 15px;
    border-bottom: 1px solid #f6f6f6
}

.popuraljobIncity li a {
    display: block;
    padding: 2px 10px;
    color: #333;
    font-size: 13px
}

.popuraljobIncity li a:hover {
    color: #e81c28
}

.rg-sortandview {
    background: #fff;

    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    border-radius: 5px;
    padding: 10px;

}

.rg-sortby {
    float: right
}

.rg-sortby .rg-select {
    width: 120px
}

.rg-sortby .rg-select+.rg-select {
    margin: 0 0 0 10px
}

.rg-sortby .rg-select select {
    height: 30px;
    color: #767676;
    font-size: 13px;
    border-radius: 0;
    line-height: 28px;
    padding: 0 0 0 10px
}

.rg-sortby .rg-select:after {
    right: 10px;
    font: 14px/30px FontAwesome;
    content: '\f107'
}

.rg-sortby span {
    float: left;
    color: #767676;
    font-size: 13px;
    line-height: 13px;
    padding: 8px 10px
}

.rg-views {
    float: left;
    width: auto
}

.rg-views ul {
    float: left;
    padding: 2px;
    background: #fff;
    list-style: none;
    border-radius: 20px;
    border: 1px solid #ddd
}

.rg-views ul li {
    float: left;
    list-style: none
}

.rg-views ul li a {
    width: 24px;
    display: block;
    font-size: 14px;
    overflow: hidden;
    line-height: 24px;
    text-align: center;
    border-radius: 16px
}

.rg-views ul li a:hover,
.rg-views ul li a:hover i,
.rg-views ul li.rg-active a,
.rg-views ul li.rg-active a i {
    color: #fff
}

.rg-views ul li a i {
    display: block;
    color: #ddd;
    line-height: inherit
}

.rg-views strong {
    color: #767676;
    font-size: 16px;
    line-height: 40px;
    padding: 0 15px 0 0
}

.rg-content,
.rg-sidebar,
.rg-twocolumns {
    float: left;
    width: 100%
}

.rg-posts.rg-postsgrid {
    float: left;
    width: 100%;
    margin: -5px 0
}

.rg-postsgrid .rg-newsarticle {
    margin: 15px 0
}

.rg-content .rg-pagination {
    margin: 15px 0 0
}

.rg-newsarticlevtwo {
    padding: 10px 0 0 10px
}

.rg-newsarticlevtwo .rg-newsimg {
    width: auto;
    cursor: pointer;
    margin: 0 0 10px
}

.rg-addcontent {
    overflow: hidden;
    min-height: 194px;
    position: relative
}

.rg-addcontent .rg-postarticlemeta {
    margin: 0;
    background: #f5f5f5;
    position: absolute;
    width: 100%;
    bottom: 3px;
    z-index: 0
}

.rg-addcontent .rg-postarticlemeta li {
    padding: 10px
}

.rg-addcontent .rg-postauthorname {
    padding: 20px
}

.rg-postauthorname .rg-description p {
    margin: 0;
    color: #324b6e;
    font-size: 14px;
    padding: 20px 0 0;
    line-height: 20px
}

.rg-nextprevposts {
    width: 100%;
    float: left;
    margin: 0 0 30px;
    padding: 0 0 20px;
    border-bottom: 1px solid #eff2f5
}

.rg-btnnextpost,
.rg-btnprevpost {
    width: auto;
    float: left
}

.rg-btnnextpost {
    float: right;
    text-align: right
}

.rg-btnnextpost a,
.rg-btnprevpost a {
    float: left;
    display: block
}

.rg-btnprevpost a figure {
    margin: 0;
    width: auto;
    position: relative;
    border-radius: 5px
}

.rg-btnnextpost a figure {
    margin: 0;
    width: auto;
    float: right
}

.rg-btnnextpost a figure img,
.rg-btnprevpost a figure img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px
}

.rg-btnprevpost a figure,
.rg-posttname {
    float: none;
    display: inline-block;
    vertical-align: middle
}

.rg-posttname {
    overflow: hidden
}

.rg-posttname h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px
}

.rg-btnprevpost .rg-posttname {
    padding: 0 0 0 10px
}

.rg-btnnextpost .rg-posttname {
    padding: 12px 10px 12px 0
}

.rg-posttname span {
    color: #a0a0a0;
    display: block;
    font-size: 14px;
    line-height: 14px
}

.rg-btnprevpost .rg-posttname span i {
    padding: 0 3px 0 0;
    display: inline-block;
    vertical-align: middle
}

.rg-btnnextpost .rg-posttname span i {
    padding: 0 0 0 3px;
    display: inline-block;
    vertical-align: middle
}

.rg-blogdetail .rg-innersectionhead,
.rg-blogdetailshort .rg-innersectionhead {
    padding: 0
}

.rg-postarticlemetavtwo li a {
    display: block;
    display: flex;
    color: #767676;
    font-size: 16px
}

.rg-blogdetail,
.rg-blogdetailsshort {
    float: left;
    width: 100%
}

.rg-blogdetailimg {
    margin: 0;
    width: auto;
    float: left
}

.rg-blogdetailimg img {
    width: 100%;
    display: block
}

.rg-blogdetailcontent {
    width: auto;
    overflow: hidden;
    padding: 0 0 0 20px
}

.rg-blogdetailcontent .rg-description p {
    font-size: 14px;
    line-height: 24px
}

.rg-readblog {
    float: left;
    width: 100%
}

.rg-readblog .rg-btn {
    border: 0;
    padding: 0 30px;
    font-size: 14px;
    line-height: 50px
}

.rg-readblog .rg-socialiconssimple {
    width: auto;
    float: right;
    padding: 15px 0
}

.rg-readblog .rg-socialiconssimple span {
    float: left;
    color: #767676;
    display: block;
    font-size: 12px;
    line-height: inherit
}

.rg-readblog .rg-socialiconssimple li a {
    font-size: 16px
}

.rg-widget {
    margin: 0 0 30px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rg-widgettitle {
    float: left;
    width: 100%;
    padding: 20px;
    background: #e3e3e3;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #eff2f5
}

.rg-widgettitle h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 14px
}

.rg-widgetsearch .rg-widgetcontent {
    padding: 19px
}

.rg-widgetsearch .rg-formtheme .form-group {
    margin: 0 0 10px
}

.rg-widgetsearch .rg-btn {
    padding: 0 42px
}

.rg-checkboxgroup {
    background: #fff;
    width: 100%;
    float: left;
    padding: 12px
}

.rg-checkboxgroup .rg-checkbox {
    padding: 0 0 6px
}

.rg-checkbox input[type=checkbox]+label,
.rg-radio input[type=radio]+label {
    color: #363b4d;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 0 0 0 24px;
    text-transform: capitalize
}

.rg-checkboxgroup .rg-checkbox input[type=checkbox]+label:before {
    top: 1px;
    color: #767676
}

.rg-checkboxgroup .rg-checkbox input[type=checkbox]+label span {
    float: left;
    color: #555;
    font-size: 13px
}

.rg-checkboxgroup .rg-checkbox input[type=checkbox]+label span.price {
    font-size: 14px;
    float: right
}

.rg-checkbox input[type=checkbox]:checked+label:before,
.rg-radio input[type=radio]:checked+label:before {
    content: ''
}

.rg-checkbox input[type=checkbox]+label:after {
    top: 0;
    left: 0;
    opacity: 0;
    width: 20px;
    height: 20px;
    font: 10px/20px FontAwesome;
    content: '\f00c';
    position: absolute;
    text-align: center;
    visibility: hidden
}

.rg-checkbox input[type=checkbox]:checked+label:after,
.rg-radio input[type=radio]:checked+label:after {
    opacity: 1;
    visibility: visible
}

.rg-checkboxgroup .rg-viewmore {
    float: left;
    color: #55acee;
    display: block;
    font-size: 13px;
    line-height: 13px;
    padding: 5px 0 0 30px
}

.rg-recentpost {
    float: left;
    width: 100%;
    padding: 0 20px;
    margin: 4px 0
}

.rg-recentpost:nth-child(odd) {
    background: #f9f9f9
}

.rg-recentpostdetails {
    float: left;
    width: 100%;
    margin: 15px 0
}

.rg-recentpostimg {
    margin: 0;
    width: auto;
    float: left;
    border-radius: 5px
}

.rg-recentpostimg img {
    width: 100%;
    display: block;
    border-radius: 5px
}

.rg-recentpostcontent {
    overflow: hidden;
    padding: 0 0 0 10px
}

.rg-recentpostcontent h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px
}

.rg-recentpostcontent span {
    color: #767676;
    display: block;
    font-size: 13px;
    padding: 2px 0 0;
    line-height: 13px
}

.rg-recentpostcontent span i {
    margin: 0 3px 0 0
}

.rg-widgettags {
    margin: 0
}

.rg-widgettags .rg-tag {
    margin: -5px 0;
    padding: 20px 10px
}

.rg-tag {
    float: left;
    width: 100%
}

.rg-tag a {
    color: #767676;
    float: left;
    margin: 2px;
    padding: 0 6px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    border-radius: 50px;
    border: 1px solid #ddd
}

.rg-tag a:hover {
    color: #fff;
    background: #666;
    border-color: #666
}

.rg-blogdetail .rg-description p {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 30px
}

.rg-blogdetailshort .rg-blogdetail .rg-description p {
    margin: 0 0 20px
}

.rg-blogdetail .rg-description .rg-clear {
    clear: both
}

.rg-blogdetailimgvtwo {
    float: left;
    width: 100%;
    margin: 0 0 25px;
    text-align: center
}

.rg-blogdetailimgvtwo img {
    display: block;
    max-width: 100%
}

.rg-blogdetail .rg-description blockquote {
    margin: 0;
    border: 0;
    width: 100%;
    float: left;
    quotes: none;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-style: italic;
    position: relative;
    padding: 0 15% 32px 20%
}

.rg-blogdetail .rg-description blockquote span {
    top: 0;
    left: 8%;
    width: 82px;
    height: 82px;
    padding: 6px;
    display: block;
    position: absolute;
    text-align: center;
    border-radius: 50%;
    background: rgba(0, 204, 103, .2)
}

.rg-blogdetail .rg-description blockquote span i {
    width: 70px;
    color: #fff;
    height: 70px;
    display: block;
    margin: 0 auto;
    font-size: 30px;
    line-height: 70px;
    text-align: center;
    border-radius: 50%
}

.rg-blogdetail .rg-description .rg-blockquotevone q {
    display: block;
    margin: 11px 0
}

.rg-blogdetailimgvtwo figcaption span {
    display: block;
    color: #324b6e;
    font-size: 13px;
    padding: 10px 0 0;
    line-height: 13px;
    text-align: center
}

.rg-blogdetail .rg-description ul {
    float: left;
    width: 100%;
    list-style: none;
    font-size: 14px;
    line-height: 16px;
    padding: 0 0 22px
}

.rg-blogdetail .rg-description .rg-blogliststyle {
    width: auto;
    float: none;
    overflow: hidden
}

.rg-blogdetail .rg-description ul li {
    position: relative;
    line-height: inherit;
    list-style: none;
    padding: 7px 0 7px 20px
}

.rg-blogdetail .rg-description ul li:first-child {
    padding: 2px 0 7px 20px
}

.rg-blogdetail .rg-description ul li:before {
    left: 0;
    top: 50%;
    font: 8px FontAwesome;
    content: '\f00c';
    margin: -7px 0 0;
    position: absolute
}

.rg-blogdetail .rg-description ul li span {
    font-size: inherit;
    line-height: inherit
}

.rg-blogdetail .rg-description .rg-blockquotevtwo {
    text-align: center;
    padding: 0 10% 32px
}

.rg-blogdetailimgvtwo.rg-alignleft {
    width: auto;
    margin: 0 30px 30px 0
}

.rg-blogdetailimgvtwo.rg-alignright {
    width: auto;
    float: right;
    margin: 0 0 30px 30px
}

.rg-video {
    float: left;
    width: 100%;
    margin: 0 0 30px
}

.rg-video figure {
    margin: 0;
    float: left;
    width: 100%
}

.rg-tagsshare .rg-tag {
    width: auto
}

.rg-tagsshare .rg-tag a {
    margin: 0 5px
}

.rg-tagsshare .rg-tag span {
    float: left;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    padding: 8px 5px 8px 0
}

.rg-tagsshare {
    float: left;
    width: 100%;
    padding: 40px 0;
    border-top: 1px solid #eff2f5
}

.rg-blogsocialicons {
    width: auto;
    float: right
}

.rg-tagsshare .rg-blogsocialicons {
    padding: 6px 0
}

.rg-socialiconssimple li:last-child {
    padding-right: 0
}

.rg-blogsocialicons span {
    float: left;
    color: #767676;
    font-size: 12px;
    display: block;
    line-height: inherit
}

.rg-authordetails {
    float: left;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}

.rg-author,
.rg-comments {
    border-bottom: 1px solid #dbdbdb
}

.rg-author figure {
    width: auto;
    float: left;
    margin: 0 20px 0 0;
    position: relative
}

.rg-author figure a {
    display: block;
    overflow: hidden;
    position: relative
}

.rg-author figure i {
    top: 0;
    left: 0;
    color: #fff;
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    background: #f91942
}

.rg-authorcontent {
    overflow: hidden;
    padding: 15px 0 0
}

.rg-author {
    width: 100%;
    float: left;
    margin: 0 0 40px
}

.rg-boxleft {
    float: left
}

.rg-boxright {
    float: right
}

.rg-authorhead,
.rg-comments,
.rg-comments ul,
.rg-comments>ul>li,
.rg-replaybox {
    width: 100%;
    float: left
}

.rg-boxright .rg-socialiconssimple li:last-child {
    padding-right: 0
}

.rg-authorhead h3 {
    margin: 0 0 7px;
    font-size: 16px;
    line-height: 14px;
    font-weight: 600
}

.rg-authorhead span {
    color: #767676;
    display: block;
    font-size: 13px;
    line-height: 13px
}

.rg-authorhead span a {
    display: block
}

.rg-authorcontent .rg-description {
    padding: 20px 0 0
}

.rg-authorcontent .rg-description p {
    margin: 0
}

.rg-comments {
    margin: 0 0 40px;
    padding: 0 0 40px
}

.rg-comments>h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 16px;
    line-height: 18px
}

.rg-comments ul {
    font-size: 16px;
    line-height: 18px;
    list-style: none
}

.rg-comments>ul>li {
    line-height: inherit;
    list-style: none
}

.rg-comments>ul>li+li {
    margin: 10px 0 0
}

.rg-comments>ul>li .rg-author {
    border: 0;
    margin: 0;
    padding: 0
}

.rg-child {
    margin: 0;
    list-style: none;
    padding: 10px 0 10px 95px
}

.rg-child li {
    list-style: none
}

.rg-child>li+li {
    margin: 20px 0 0
}

.rg-replaybox h2 {
    font-size: 20px;
    margin: 0 0 16px;
    font-weight: 600;
    line-height: 18px
}

.rg-formreply .rg-inputwithicon .form-control {
    padding: 10px 10px 10px 40px
}

.rg-formreply .rg-inputwithicon i {
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    text-align: center
}

.rg-btnreply {
    float: right;
    display: block;
    font-size: 16px;
    line-height: 14px
}

.rg-btnreply span {
    float: left;
    font-size: 12px;
    line-height: inherit;
    padding: 0 10px 0 0
}

.rg-commentsbtn {
    float: left;
    margin: 30px 0 0;
    width: 100%;
    text-align: center
}

.rg-commentsbtn .rg-btn {
    border: 0;
    padding: 0 30px;
    font-size: 14px;
    line-height: 50px
}

.rg-formleavecomment fieldset {
    width: auto;
    float: none;
    clear: both;
    margin: -5px;
    overflow: hidden
}

.rg-formleavecomment fieldset .form-group {
    margin: 0;
    width: 50%;
    padding: 5px
}

.rg-formleavecomment fieldset .form-group i {
    left: 4px;
    color: #767676;
    font-size: 14px
}

.rg-formleavecomment .form-group:nth-child(5) {
    width: 100%
}

.rg-formleavecomment fieldset .form-group input.form-control::placeholder {
    color: #767676
}

.rg-formleavecomment .form-group textarea.form-control {
    height: 130px;
    padding: 14px 20px 10px 44px
}

.rg-formleavecomment .form-group .rg-btn {
    border: 0;
    padding: 0 40px;
    font-size: 14px;
    line-height: 50px
}

.rg-successstorysbanner {
    float: left;
    width: 100%
}

.rg-formbannersearchinner {
    padding: 15px;
    margin: 0 0 30px;
    background: #ebebeb;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rg-formbannersearchinner .rg-searcharea {
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 6px 100px 6px 8px
}

.rg-formbannersearchinner .rg-searcharea .rg-searchholder .rg-select select {
    padding: 2px 10px;
    border: 0;
    height: 27px;
    font-size: 14px;
    line-height: 27px
}

.rg-formbannersearchinner .rg-searcharea .rg-searchholder {
    padding: 4px 0 5px;
    position: relative
}

.rg-formbannersearchinner .rg-searcharea .rg-searchholder+.rg-searchholder {
    padding: 4px 0 5px 25px
}

.rg-formbannersearchinner .rg-searcharea .rg-searchholder label,
.rg-formbannersearchinner .rg-searcharea .rg-searchholder+.rg-searchholder label {
    margin: 0 0 2px
}

.rg-formbannersearchinner .rg-searcharea .rg-searchholder .lnr-calendar-full {
    top: -5px;
    left: 20px;
    z-index: 2;
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    text-align: center;
    font-size: 18px
}

.rg-adds {
    float: left;
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0 0 10px
}

.rg-adds a {
    display: block
}

.rg-adds figure {
    margin: 0;
    width: 100%
}

.rg-adds figure img {
    width: auto;
    border-radius: 5px;
    padding: 5px;
    margin: 0 auto;
    display: block;
    background: #fff;
    border: 1px solid #eff2f5
}

.rg-adds span {
    color: #eee;
    display: block;
    font-size: 11px;
    margin: 4px 0 0;
    line-height: 11px;
    position: absolute;
    top: 3px;
    right: 7px;
    background: #373737;
    padding: 2px 4px
}

.rg-successstorys {
    padding: 0;
    float: left;
    width: 100%;
    margin: -5px 0 0
}

.rg-successstoryvtwo {
    float: left;
    width: 100%;
    padding: 20px;
    margin: 15px 0;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}

.rg-successstoryvtwo figure {
    margin: 0;
    width: auto;
    float: left
}

.rg-successstoryvtwo figure img {
    width: 100%;
    display: block
}

.rg-successcontent {
    overflow: hidden;
    padding: 10px 0 0 10px
}

.rg-successname {
    float: left;
    width: 100%;
    padding: 0 0 22px
}

.rg-successcontent h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px
}

.rg-successcontent span,
.rg-successcontent time {
    display: block;
    color: #767676;
    font-size: 13px;
    line-height: 13px
}

.rg-successcontent span {
    padding: 6px 0 7px
}

.rg-successcontent .rg-description p {
    margin: 0;
    font-size: 14px;
    line-height: 24px
}

.rg-successcontent .rg-description p+p {
    padding: 25px 0 0
}

.rg-successstorys .rg-pagination {
    margin: 10px 0 0
}

.rg-successstorysgrid .rg-successstoryvtwo {
    text-align: center;
    padding: 30px 20px
}

.rg-successstorysgrid .rg-successstoryvtwo figure {
    width: 81px;
    float: none;
    margin: 0 auto
}

.rg-successstorysgrid .rg-successcontent {
    padding: 18px 0 0
}

.rg-threecolumns {
    float: left;
    width: 100%
}

.rg-jobtitle {
    margin: 0;
    border-radius: 0
}

.rg-widgetjobalert .rg-widgetcontent {
    padding: 20px
}

.rg-sidebarcol .rg-widget.rg-widgetsearch {
    margin: 0 0 30px;
    border: 1px solid #eff2f5
}

.rg-sidebarcol .rg-widget.rg-widgetjobalert .rg-widgettitle {
    padding: 20px;
    background: #fafafa
}

.rg-widgetjobalert .rg-widgetcontent span {
    display: block;
    font-size: 14px;
    padding: 0 0 7px;
    line-height: 16px
}

.rg-widgetjobalert .rg-widgetcontent a {
    color: #55acee;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px
}

.rg-widgetjobalert .rg-formtheme .form-group {
    margin: 13px 0 10px
}

.rg-sidebarcol .rg-widget {
    margin: 0;
    border: 0
}

.rg-narrowsearchcollapse {
    float: left;
    width: 100%;
    border: 1px solid #eff2f5;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rg-jobdetails,
.rg-narrowsearchcollapse22 {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}

.rg-narrowsearchcollapse22 {
    float: left;
    width: 100%;
    border: 1px solid #eff2f5;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}

.rg-sidebarcol .rg-themecollapsetitle {
    box-shadow: none;
    position: relative;
    background: #fafafa;
    padding: 12px 13px 10px
}

.rg-sidebarcol .rg-widget a {
    display: inline-block;
    vertical-align: middle;
    width: 100%
}

.rg-sidebarcol .rg-widget+.rg-widget {
    border: 0;
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid #eff2f5
}

.rg-sidebarcol .rg-widget .rg-widgettitle {
    border: 0;
    padding: 0;
    border-radius: 0;
    background: 0 0
}

.rg-sidebarcol .rg-widget .rg-widgettitle h3 {
    float: left
}

.rg-sidebarcol .rg-widget .rg-widgettitle span {
    right: 20px;
    color: #767676;
    font-size: 22px;
    line-height: 10px;
    position: absolute
}

.rg-sidebarcol .rg-widget.open .rg-widgettitle span:before {
    content: '\f107'
}

.rg-Jobssearch {
    background: #fafafa
}

.rg-Jobssearch a {
    display: block !important
}

.rg-Jobssearchbtn {
    float: left;
    width: 100%;
    padding: 20px
}

.rg-Jobssearchbtn .rg-btn {
    border: 0;
    padding: 0;
    width: 160px;
    display: block;
    margin: 0 auto
}

.rg-btnreset {
    width: 100%;
    color: #767676;
    display: block;
    font-size: 12px;
    margin: 19px 0 0;
    line-height: 12px;
    text-align: center
}

.rg-addsvtwo {
    margin: 0 0 30px
}

.rg-featuredjobsvtwo {
    margin: 0
}

.rg-featuredjobsvtwo .rg-featurejobholder {
    width: 100%;
    padding: 0;
    margin: 10px 0;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    border-radius: 5px
}

.rg-featuredjobsvtwo .rg-adds.rg-featurejobholder {
    border: 0;
    box-shadow: none
}

.rg-featuredjobsvtwo .rg-featurejobholder .rg-featurejob {
    border: 0;
    box-shadow: none;
    padding: 10px
}

.roz-dep-hiring-content-box,
.sendenquirybox {
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%)
}

.rg-featuredjobsvtwo .rg-featurejobholder .rg-featurejob .rg-companycontent {
    padding: 0
}

.rg-featuredjobsvtwo .rg-featurejobholder .rg-featurejob .rg-companycontent .ti-crown,
.ti-crown {
    color: #ff8a00;
    font-weight: bolder
}

.rg-featuredjobsvtwo .rg-companycontent .rg-description p {
    font-size: 13px;
    line-height: 14px;
    margin: 6px 0 0
}

.rg-featuredjobsvtwo .rg-pagination {
    margin: 14px 0 0
}

.rg-recentsearches {
    float: left;
    width: 100%;
    padding: 20px;
    font-size: 13px;
    list-style: none;
    line-height: 12px
}

.rg-clearsearches span,
.rg-recentsearches li,
.rg-recentsearches li a {
    font-size: inherit;
    line-height: inherit
}

.rg-recentsearches li {
    float: left;
    width: 100%;
    padding: 0 0 17px;
    list-style: none
}

.rg-recentsearches li:last-child {
    padding: 0
}

.rg-recentsearches li a {
    display: block;
    color: #55acee
}

.rg-clearsearches {
    color: #fc413c !important
}

.rg-clearsearches span {
    padding: 0 6px 0 0
}

.rg-employerweek {
    padding: 20px;
    text-align: center
}

.rg-employerweek .rg-widgettitle {
    border: 0;
    padding: 0 0 18px;
    background: 0 0
}

.rg-employerdetails {
    float: left;
    width: 100%
}

.rg-angrycreative {
    width: 130px;
    height: 63px;
    padding: 5px;
    margin: 0 auto 10px;
    display: inline-block;
    border: 1px solid #eff2f5
}

.rg-employerdetails .empo-text {
    margin: 0;
    color: #222;
    padding: 4px 0
}

.rg-angrycreative span {
    color: #fff;
    width: 113px;
    font-size: 70px;
    line-height: 113px;
    background: #ff5050
}

.rg-employerjobs,
.rg-pages,
.rg-pagesdetails {
    width: 100%;
    float: left
}

.rg-employerdetails h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.rg-employerdetails h4 {
    margin: 0;
    color: #767676;
    font: 400 13px/15px 'Open Sans', Arial, Helvetica, sans-serif
}

.rg-employerdetails h4.reviewbox {
    background: #e8e8e8;
    padding: 2px 0 4px;
    line-height: 19px;
    border-radius: 3px;
    margin-top: 4px
}

.rg-employerdetails h4 span {
    display: block;
    padding: 5px 0 0;
    font-size: 16px
}

.rg-employerdetails h4 span small.reviewtext {
    padding: 5px 0 0;
    color: red;
    font-size: 13px
}

.rg-employerdetails h4 span i {
    padding: 0 3px 0 0;
    color: #f7a200
}

.rg-employerjobs {
    color: #55acee;
    font-size: 13px;
    list-style: none;
    margin: 8px 0 0;
    line-height: 13px
}

.rg-employerjobs li {
    font-size: inherit;
    line-height: inherit;
    display: inline-block;
    padding: 0 4px 6px;
    list-style: none;
    vertical-align: middle
}

.rg-employerjobs li:first-child {
    padding-left: 0
}

.rg-employerjobs li:last-child {
    border: 0;
    padding-right: 0
}

.rg-employerjobs li a {
    display: block;
    color: #303233;
    padding: 2px 4px;
    border-radius: 3px;
    background: #ebebeb
}

.filterbox,
.filterbox-com {
    background: #fff;
    background: #fff;
    border: 0 solid #eff2f5;
    display: flex;
}

.rg-employerdetails .rg-btn {
    margin: 10px 0 0;
    padding: 0 20px;
    font-size: 13px;
    height: 30px;
    line-height: 26px
}

.rg-jobsearchadd {
    margin: 0
}

.filterbox {
    justify-content: flex-start;
    padding: 10px
}

.filterbox .appliesno {
    font-weight: 500;
    color: #003891;
    font-size: 15px
}

.filterbox .filtername {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding-left: 3px;
    flex: 1 1 auto !important
}

.filterbox-com {
    justify-content: flex-start;
    padding: 10px
}

.rg-pages {
    padding: 0 30px;
    margin: 20px 0 15px
}

.rg-pagesdetails {
    list-style: none;
    line-height: 14px;
    border-bottom: 1px solid #eff2f5
}

.rg-pagesdetails li {
    width: auto;
    float: left;
    position: relative;
    line-height: inherit;
    list-style: none;
    text-transform: capitalize
}

.rg-pagesdetails li:after {
    left: 0;
    width: 0;
    bottom: -2px;
    content: '';
    height: 2px;
    position: absolute
}

.rg-pagesdetails li.rg-active:after,
.rg-pagesdetails li:hover:after {
    width: 100%
}

.rg-pagesdetails li.rg-active a {
    color: #324b6e
}

.rg-pagesdetails li a {
    padding: 9px;
    display: block;
    color: #767676;
    font-size: 14px;
    line-height: inherit
}

.rg-pagesdetails li:hover a {
    color: #324b6e
}

.rg-employergrids {
    width: 100%;
    float: left;
    margin: 0 0 10px
}

.rg-employergrids .rg-widget {
    background: #fff;
    padding: 20px;
    text-align: center;
    margin: 15px 0
}

.rg-employergrids .rg-widget .rg-angrycreative {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -webkit-box-orient: vertical;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column
}

.rg-addlike a+a,
.rg-employerlist .rg-widget .rg-employerdetails h4 {
    display: inline-block
}

.rg-employergrids .rg-widget .rg-angrycreative figure {
    margin: 0;
    width: auto
}

.rg-employergrids .rg-widget .rg-angrycreative figure h1 {
    font-size: 2em
}

.rg-employergrids .rg-widget .rg-angrycreative figure img {
    max-width: 90%;
    max-height: 60px
}

.rg-employergrids .rg-employerjobs {
    margin: 4px 0 0
}

.rg-addlike {
    width: 100%;
    float: left;
    margin: 18px 0 0
}

.rg-addlike .rg-btnlike {
    float: none;
    text-align: center;
    margin: 0 4px 0 0
}

.rg-addlike a+a {
    color: #55acee;
    font-size: 13px;
    line-height: 13px
}

.rg-employerlist .rg-widget {
    padding: 0;
    text-align: left
}

.rg-angrycreativelist {
    width: 100%;
    float: left;
    padding: 9px
}

.rg-employerlist .rg-widget .rg-angrycreative {
    width: 80px;
    float: left;
    height: 80px;
    margin: 0 10px 0 0
}

.rg-employerlist .rg-widget .rg-employerdetails {
    float: none;
    width: auto;
    padding: 9px 0;
    overflow: hidden
}

.rg-employerlist .rg-widget .rg-employerdetails .rg-addlike {
    margin: 0 6px 0 0;
    width: auto;
    float: right
}

.rg-employerlist .rg-widget .rg-professionalinfo li a {
    color: #55acee;
    display: inline-block;
    vertical-align: middle
}

.roz-dep-hiring {
    width: 100%;
    float: left;
    margin: 20px 0;
    background: #eee;
    padding: 1px 2px;
    border-radius: 4px
}

.roz-dep-hiring h4 {
    padding: 6px 0 0 10px;
    font-size: 16px
}

.roz-dep-hiring-content-box,
.roz-out-dep-hiring-box {
    width: 100%;
    padding: 10px;
    float: left
}

.roz-dep-hiring-content-box {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.roz-dep-hiringcontent {
    padding: 7px 0;
    overflow: hidden
}

.roz-dep-hiring-companyname {
    width: 95%;
    float: left
}

.roz-dep-hiring-companyname h3 {
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0
}

.roz-more-informaiton {
    display: flex;
    justify-content: flex-start;
    align-items: start
}

.roz-more-informaiton span:first-child {
    font-size: 15px;
    font-weight: 500;
    width: 45%
}

.roz-more-informaiton span {
    font-size: 15px
}

.roz-more-informaiton span a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #039bff
}

.jobintrested-box {
    float: left;
    width: 100%;
    padding: 20px
}

.jobintrested-box .boxshow {
    background: #f9f9f9;
    padding: 10px 20px;
    border-radius: 4px
}

.roz-jobintrestedtext {
    float: left;
    width: 100%;
    border: 1px solid #eff2f5;
    padding: 12px;
    background: #fff
}

.roz-jobintrestedtext ul li {
    list-style: none;
    margin: 0;
    padding-left: 20px;
    width: 48%;
    display: inline-block
}

.roz-jobintrestedtext ul li a {
    color: #666;
    position: relative;
    font-size: 13px
}

.roz-jobintrestedtext ul li a:hover {
    color: #333
}

.roz-jobintrestedtext ul li a:before {
    left: -12px;
    top: -4px;
    content: '\f058';
    font: 12px FontAwesome;
    position: absolute;
    color: #048189
}

.postjobdays,
.postjobnum,
.postjobsend,
.rg-postarticlemetavone li span+span {
    position: relative
}

.postjobdays span,
.postjobnum span {
    color: #333;
    font-weight: 600
}

.rg-jobapplycenter {
    float: left;
    width: 100%;
    padding: 20px 20px 10px 20px;
    margin: 0 0 20px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    background: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .15)
}

.rg-jobapplycenter .rg-companyimg {
    width: 90px;
    min-height: 80px
}

.rg-jobapplycenter .rg-companyimg h1 {
    font-size: 30px
}

.rg-jobapplycenter .rg-companycontent {
    padding: 0;
    position: relative
}

.postedjobapply {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-top: 1px solid #eee;
    padding-top: 8px;
    margin-top: 10px
}

.postjobsend a {
    font-size: 16px;
    border: 1px solid #eee;
    background: #f9f9f9;
    border-radius: 50px;
    padding: 2px 20px 4px;
    display: inline-block
}

.rg-jobapplydetails {
    width: 58%;
    float: left
}

.rg-jobapplycenter .rg-companycontent .rg-btnjobtag {
    line-height: 30px;
    font-size: 12px
}

.rg-jobapplycenter .rg-companycontent .rg-tagfeature {
    font-size: 12px;
    line-height: 28px;
    margin: 0 0 0 6px;
    padding: 0 30px
}

.rg-jobapplycenter .rg-companycontent .rg-socialiconssimple span {
    float: left
}

.rg-jobapplycentervtwo {
    margin: 0 0 10px
}

.rg-jobapplycentervtwo .rg-jobapplydetails .rg-companyname {
    padding: 12px 0
}

.rg-jobapplycentervtwo .rg-companyimg {
    margin: 0 20px 0 0
}

.rg-jobapplycenter .rg-companyname h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px
}

.rg-postarticlemetavone {
    width: 100%;
    float: left;
    font-size: 13px;
    list-style: none;
    margin: 6px 0 0;
    line-height: 13px
}

.rg-postarticlemetavone li {
    width: auto;
    float: left;
    padding: 0 6px;
    text-align: left;
    line-height: inherit;
    list-style: none
}

.rg-postarticlemetavone li:first-child {
    padding-left: 0
}

.rg-postarticlemetavone li .job_exp,
.rg-postarticlemetavone li a {
    display: block;
    display: flex
}

.rg-postarticlemetavone li i,
.rg-postarticlemetavone li span {
    font-size: 13px !important;
    vertical-align: middle;
    font-size: 13px !important
}

.rg-postarticlemetavone li span+span {
    padding: 0 0 0 5px
}

.rg-postarticlemetavone li span+span:before {
    top: 50%;
    left: 0;
    content: ',';
    font-size: 13px;
    line-height: 14px;
    margin: -7px 0 0;
    position: absolute
}

.rg-postarticlemetavone li i,
.rg-postarticlemetavone li:hover span {
    margin: 0 3px 0 0
}

.rg-postarticlemetavone li i {
    margin-left: -2px;
}

.rg-postarticlemetavtwo {
    width: 100%;
    float: left;
    font-size: 13px;
    list-style: none;
    margin: 6px 0 0;
    line-height: 13px
}

.rg-postarticlemetavtwo li {
    width: auto;
    float: left;
    padding: 0 6px;
    text-align: center;
    line-height: inherit;
    list-style: none
}

.rg-postarticlemetavtwo li:first-child {
    padding-left: 0
}

.rg-postarticlemetavtwo li i,
.rg-postarticlemetavtwo li span {
    font-size: 13px !important;
    vertical-align: middle;
    font-size: 13px !important
}

.rg-postarticlemetavtwo li span+span {
    position: relative;
    padding: 0 0 0 5px
}

.rg-postarticlemetavtwo li span+span:before {
    top: 50%;
    left: 0;
    content: ',';
    font-size: 13px;
    line-height: 14px;
    margin: -7px 0 0;
    position: absolute
}

.rg-postarticlemetavtwo li:hover span,
.rg-postarticlemetavtwo li:last-child span {
    color: #767676;
    margin-top: -2px
}

.rg-postarticlemetavtwo li i {
    margin: -1px 5px 4px;
    color: #f7a200
}

.rg-postarticlemetavthree {
    width: 100%;
    float: left;
    font-size: 13px;
    list-style: none;
    margin: 6px 0 0;
    line-height: 13px
}

.rg-postarticlemetavthree li {
    width: auto;
    float: left;
    padding: 0 6px;
    text-align: center;
    line-height: inherit;
    list-style: none
}

.rg-postarticlemetavthree li:first-child {
    padding-left: 0
}

.rg-postarticlemetavthree li .job_exp,
.rg-postarticlemetavthree li a {
    display: block;
    display: flex
}

.rg-postarticlemetavthree li i,
.rg-postarticlemetavthree li span {
    font-size: 13px !important;
    vertical-align: middle;
    font-size: 13px !important
}

.rg-postarticlemetavthree li span+span {
    position: relative;
    padding: 0 0 0 5px
}

.rg-postarticlemetavthree li span+span:before {
    top: 50%;
    left: 0;
    content: ',';
    font-size: 13px;
    line-height: 14px;
    margin: -7px 0 0;
    position: absolute
}

.rg-postarticlemetavthree li:hover span,
.rg-postarticlemetavthree li:last-child span {
    color: #767676
}

.rg-postarticlemetavthree li i {
    margin: 2px 3px 0 0
}

.rg-jobapplybtnlike {
    padding: 0;
    width: auto;
    float: right;
    text-align: right
}

.rg-likebtnbox {
    overflow: hidden;
    line-height: 40px
}

.rg-likebtnbox img {
    max-width: 100px;
    height: 30px;
    margin-left: 22px;
    border: 1px solid #eee;
    padding: 4px;
    border-radius: 4px
}

.rg-jobapplybtnlike .rg-btnlike {
    padding: 0;
    width: 44px;
    float: right;
    height: 44px;
    line-height: 44px;
    margin: 0 4px 0 0;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #dfdfdf
}

.rg-jobapplybtnlike .rg-btn {
    border: 0;
    float: right;
    padding: 0 26px;
    font-size: 16px;
    line-height: 36px
}

.rg-jobapplycenter .rg-companycontent .rg-socialiconssimple {
    width: auto;
    clear: both;
    float: right;
    margin: 20px 0 0
}

.rg-jobapplycenter .rg-companyname span {
    font-size: 14px;
    line-height: 19px
}

.rg-jobdetails {
    float: left;
    width: 100%;
    border: 1px solid #eff2f5;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}

.rg-jobdetaildescription {
    width: 100%;
    float: left;
    padding: 20px
}

.rg-jobdetails .rg-title {
    width: 100%;
    float: left
}

.rg-jobdetails .rg-title h2 {
    color: #303233;
    margin: 0 0 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px
}

.rg-jobdescription {
    width: 100%;
    float: left
}

.rg-jobdescription p:nth-child(0n+2) {
    margin-bottom: 0
}

.rg-jobdescription span:first-child {
    padding: 0 0 7px 20px
}

.rg-jobdescription span:first-child:before {
    margin: -8px 0 0
}

.rg-jobdescription span {
    display: block;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    padding: 7px 0 7px 20px
}

.rg-jobdescription span:before {
    left: 0;
    top: 50%;
    font: 10px/8px FontAwesome;
    margin: -4px 0 0;
    content: '\f00c';
    position: absolute
}

.rg-tagvtwo a:before,
.rg-tagvtwo span:before {
    left: 5px;
    top: 0;
    content: '\f005';
    font-family: FontAwesome
}

.rg-jobdescription .rg-description {
    margin: 22px 0 0
}

.rg-jobdescription .rg-description p {
    margin: 0;
    font-size: 14px;
    line-height: 25px
}

.rg-jobdetailinfo {
    float: left;
    width: 100%
}

.rg-jobdetailinfo .rg-title {
    padding: 10px 20px 0
}

.rg-jobdetailinfo ul {
    float: left;
    width: 100%;
    margin: 0 0 20px;
    line-height: 14px;
    list-style: none
}

.rg-jobdetailinfo ul li {
    width: 100%;
    float: left;
    line-height: inherit;
    list-style: none;
    padding: 6px 0 6px 20px
}

.rg-jobdetailinfo ul li:nth-child(2n) {
    background: #fafafa
}

.rg-jobdetailinfo ul li em,
.rg-jobdetailinfo ul li span {
    font-size: 13px;
    line-height: inherit;
    display: inline-block;
    vertical-align: middle
}

.rg-jobdetailinfo ul li span {
    width: 285px;
    font-weight: 500
}

.rg-jobdetailinfo ul li em {
    color: #767676;
    font-style: normal
}

.rg-jobrequirment {
    width: 100%;
    float: left;
    padding: 20px
}

.rg-jobrequirmentvtwo {
    padding: 12px 19px
}

.rg-jobbenefits {
    float: left;
    width: 100%;
    padding: 20px
}

.rg-tagvtwo a,
.rg-tagvtwo span {
    line-height: 24px;
    float: left;
    text-align: center;
    padding: 0 10px 0 10px;
}

.rg-tagvtwo {
    float: left;
    width: 100%
}

.rg-tagvtwo a {
    color: #767676;
    margin: 2px;
    font-size: 12px;
    position: relative;
    border-radius: 50px;
    border: 1px solid #ddd
}

.rg-tagvtwo a:first-child {
    margin-left: 0
}

.rg-tagvtwo a:before {
    color: #919191 !important;
    color: #919191 !important
}

.rg-tagvtwo span {
    color: #767676;
    margin: 2px;
    font-size: 12px;
    position: relative;
    border-radius: 50px;
    border: 1px solid #ddd
}

.rg-tagvtwo span:first-child {
    margin-left: 0
}

.rg-tagvtwo span:before {
    color: #919191 !important;

    margin-right: 4px;
}

.adbox:after,
.rg-direction li+li:before,
.rg-themenavtabs li a:after,
.roz-themenavtabs li a:after {
    content: ''
}

.rg-jobapply {
    width: 100%;
    float: left;
    padding: 20px;
    border-top: 1px solid #eff2f5
}

.rg-jobapplynowbtn {
    width: auto;
    float: left
}

.rg-jobapplynowbtn .rg-btn {
    padding: 0 35px;
    line-height: 40px
}

.rg-jobapplynowbtnvtwo .rg-btn {
    padding: 0 28px
}

.rg-jobapply .rg-socialiconssimple {
    width: auto;
    float: right;
    padding: 15px 0
}

.rg-jobapply .rg-socialiconssimple span {
    float: left;
    color: #767676;
    font-size: 12px;
    line-height: 20px
}

.rg-similarjobs {
    float: left;
    width: 100%;
    padding: 8px 0 0
}

.rg-similarjobs .rg-sectionhead {
    padding: 0 0 15px
}

.rg-similarjobs .rg-featuredjobs {
    width: 100%;
    margin: -10px
}

.rg-similarjobs .rg-featuredjobs .rg-featurejobholder {
    width: 50%;
    float: none;
    margin: 0 -1px;
    vertical-align: top;
    display: inline-block
}

.rg-aboutcompany {
    float: left;
    width: 100%;
    padding: 20px 0
}

.rg-aboutcompany .rg-sectionhead {
    padding: 0 0 15px
}

.rg-aboutcompanybox {
    float: left;
    width: 100%;
    padding: 20px;
    border: 1px solid #eff2f5;
    background: #fff;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}

.rg-aboutimg {
    margin: 0;
    width: 125px;
    height: 95px;
    padding: 5px;
    float: left;
    border: 1px solid #eff2f5;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: center
}

.rg-innerbanner,
.rg-innerbannercity,
.rg-innerbannercontent,
.rozgorcitycreatejob,
.sendenquirybox {
    float: left;
    width: 100%
}

.rg-aboutdetails {
    overflow: hidden;
    position: relative;
    padding: 7px 170px 7px 20px
}

.rg-aboutdetailsfeature {
    float: left;
    width: 100%;
    margin: 0 0 8px
}

.rg-aboutdetails h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px
}

.rg-aboutdetails h4 {
    margin: 0;
    color: #767676;
    font: 400 13px/13px 'Open Sans', Arial, Helvetica, sans-serif
}

.rg-aboutdetails h4 span {
    display: block;
    font-weight: 500;
    padding: 5px 0 0
}

.rg-aboutdetails h4 span i {
    padding: 0 5px 0 0;
    color: #f7a200
}

.rg-aboutdetails .rg-btn {
    top: 50%;
    right: 0;
    padding: 0 30px;
    margin-top: -20px;
    position: absolute;
    border-radius: 5px;
}

.cityjob-aboutimg,
.rg-comaboutdetails,
.rg-innerbanner,
.rg-innerbannercity,
.roz-search,
.roz-themenavtabs li a,
.roz-wrap,
.rozgar-cityjobaboutbox,
.rozgar-cityjobslocaiton,
.rozgorcitycreatejob {
    position: relative
}

.rg-aboutdetails h4 span.comname {
    font-size: 18px;
    padding: 10px 0 5px
}

.rg-comaboutdetails {
    overflow: hidden;
    padding: 7px 10px 7px 0
}

.rg-comaboutdetails h4 {
    margin-bottom: 2px;
    margin-top: 15px
}

.rg-aboutcompany .rg-description {
    margin: 25px 0 0
}

.rg-aboutcompany .rg-description p {
    margin: 0;
    font-size: 14px;
    line-height: 24px
}

.rg-aboutcompany .rg-description p a {
    color: #55acee;
    text-decoration: none
}

.rg-innerbanner {
    min-height: 200px;
    background: url(../images/innerbanner/img-01.jpg) no-repeat
}

.rg-innerbannercontent {
    padding: 55px 0
}

.rg-innerbannercontent h2 {
    font: 500 36px/36px "Open Sans", Arial, Helvetica, "sans-serif " !important
}

.rg-innerbannercontent h2 span {
    color: #324b6e;
    display: block;
    font-size: 32px;
    line-height: 40px;
    font-weight: 400
}

.rg-innerbannercity {
    min-height: 200px;
    background: url(../images/innerbanner/img-03.jpg) no-repeat
}

.rg-innerbannercontent h3 {
    font: 500 16px/16px "Open Sans", Arial, Helvetica, "sans-serif " !important;
    text-transform: none;
    font: 500 16px/16px 'Open Sans', Arial, Helvetica, sans-serif !important
}

.rg-innerbannercontent h3 span {
    color: #324b6e;
    display: block;
    font-size: 22px;
    line-height: 40px;
    font-weight: 400
}

.rg-innerbannercontent .applyforjobs {
    background: #272727;
    padding: 10px;
    border-radius: 3px;
    color: #fff;
    margin-top: 4%;
    display: inline-block;
    font-size: 18px
}

.rg-innerbannercontent .applyforjobs:hover {
    background: #111
}

.rozgorcitycreatejob h2 {
    font-size: 24px
}

.rozgorcitycreatejob h2 span {
    color: #e81c28
}

.rozgorcitycreatejob p {
    font-size: 16px
}

.rozgorcitycreatejob .createyourcv {
    background: #e81c28;
    color: #fff;
    padding: 10px 20px;
    border-radius: 3px;
    margin-bottom: 20px;
    display: inline-block;
    font-size: 18px
}

.rozgorcitycreatejob .createyourcv:hover {
    background: #fa0110;
    color: #fff
}

.sendenquirybox {
    margin: 0 0 30px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rozgar-cityjobabout,
.rozgar-cityjobslocaitonbox {
    width: 100%;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    float: left
}

.sendenquiryhead {
    background: #fafafa;
    border-bottom: 1px solid #eff2f5;
    padding: 10px
}

.sendenquiryhead h2 {
    font-size: 18px
}

.sendenquirform {
    padding: 10px
}

.rozgar-cityjobabout {
    padding: 20px;
    background: #fff;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rozgar-cityjobaboutbox {
    display: flex
}

.cityjob-aboutcontent {
    position: relative;
    padding-right: 6%
}

.cityjob-aboutcontent h2 {
    font-size: 22px
}

.cityjob-aboutcontent h4 {
    font-size: 18px;
    margin-bottom: 20px
}

.cityjob-aboutcontent p {
    font-size: 16px;
    line-height: 1.6
}

.cityjob-aboutimg img {
    max-width: 300px
}

.cityjobcompanybox {
    padding: 30px
}

.rozgar-cityjobslocaitonbox {
    padding: 20px;
    background: #fff;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rozgar-cityjobslocaiton h3 {
    font-size: 22px
}

.rozgar-cityjobslocaiton ul {
    margin: 0;
    padding: 0
}

.rozgar-cityjobslocaiton ul li {
    display: inline-block;
    width: 23%
}

.rozgar-cityjobslocaiton ul li a {
    color: #767676
}

.rozgar-cityjobslocaiton ul li a:hover {
    color: #333
}

.citytredsjobbox {
    width: 100%;
    float: left;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.citytredsjobbox h2 {
    line-height: .6;
    margin-bottom: 25px;
    font-size: 22px
}

.roz-search {
    width: 100%;
    display: flex
}

.roz-searchTerm {
    padding: 5px 5px !important;
    border-right: 0;
    padding: 5px !important;
    height: 36px !important;
    border-radius: 0;
    font-size: 12px !important
}

.roz-searchTerm:focus {
    color: #747474 !important;
    border-color: #747474 !important
}

.roz-searchButton {
    border-radius: 0;
    border: 1px solid #747474;
    background: #747474;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0 !important;
    cursor: pointer
}

.roz-jobschedules,
.roz-themenavtabs {
    width: 100%;
    float: left
}

.roz-themenavtabs {
    display: block;
    list-style: none;
    line-height: 14px;
    background: #f9f9f9;
    border-top: 1px solid #ffe5e5
}

.roz-themenavtabs li {
    float: left;
    width: auto;
    text-align: center;
    line-height: inherit;
    list-style: none;
    border-left: 1px solid #ffe5e5
}

.roz-themenavtabs li a {
    padding: 8px 5px;
    color: #303233;
    display: block;
    font-size: 16px;
    line-height: inherit
}

.roz-themenavtabs li:hover a {
    color: #324b6e
}

.roz-themenavtabs li a:after {
    width: 0;
    left: 0;
    bottom: -1px;
    height: 4px;
    border-radius: 10px;
    position: absolute
}

.roz-themenavtabs li a.active:after,
.roz-themenavtabs li:hover a:after {
    width: 100%
}

.roz-themenavtabs li a.active {
    color: #222;
    background: #fff3f3
}

.roz-themenavtabs li .rating-star {
    display: block
}

.roz-themenavtabs li .rating-star i {
    font-size: 10px;
    color: #f7a200
}

.roz-abouttab .roz-jobdetaildescription {
    padding: 14px 14px 10px;
    float: left;
    width: 100%;
    border-bottom: 1px solid #e2e2e2cc;
    background: #fff
}

.roz-tabcontent .roz-jobdetails .roz-jobdescription {
    margin: 6px 0 22px
}

.roz-abouttab.roz-jobdetails .roz-title h2 {
    margin: 0 0 9px
}

.roz-tabcontent .roz-jobdetails .roz-description p {
    font-size: 14px;
    margin: 0 0 27px;
    line-height: 24px
}

.roz-tabcontent .roz-jobdetails .roz-description p:last-child {
    margin: 0
}

.roz-near-locality-box {
    display: flex;
    float: left;
    width: 100%;
    justify-content: flex-start;
    padding: 8px 0 0
}

.roz-near-locality-box .locimgbox {
    position: relative
}

.roz-near-locality-box .locimgbox img {
    max-width: 60px;
    height: 80px;
    border-radius: 1px;
    outline-offset: 4px;
    outline: 1px solid #ccc
}

.roz-near-locality-box .loccontentbox {
    position: relative;
    padding-left: 0
}

.roz-near-locality-box .loccontentbox h4 {
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 52%;
    white-space: nowrap;
    margin-left: 10%
}

.roz-near-locality-box .loccontentbox p {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 6px;
    margin-top: 8px;
    width: 64%
}

.roz-locrating-star {
    display: block
}

.roz-locrating-star i {
    font-size: 10px;
    color: #f7a200
}

.roz-callphone {
    font-size: 12px;
    font-weight: 500
}

.roz-callphone a {
    font-size: 13px;
    color: #333
}

.roz-callcontect-details {
    float: left;
    width: 100%;
    background: #f9f9f9;
    padding: 5px;
    border: 1px solid #f2e9e9;
    border-radius: 4px;
    margin-top: 10px
}

.adbox,
.adbox:after {
    background: #ffe3e5;
    position: absolute
}

.roz-callcontect-details li {
    display: inline-block;
    list-style: none;
    padding-right: 10px;
    text-align: center;
    line-height: 1
}

.roz-callcontect-details li a {
    font-size: 14px;
    color: #333;
    font-weight: 500
}

.roz-callcontect-details li a i {
    font-size: 16px;
    padding-right: 1px;
    color: #242524;
    display: block
}

.roz-callcontect-details li a:hover,
.roz-callcontect-details li a:hover i {
    color: red
}

.adbox {
    display: inline-block;
    padding: 0 4px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
    left: 0;
    z-index: 0;
    font-weight: 700
}

.callwebtime li,
.ratingreviewpop li,
.savephoneshare li {
    line-height: 1;
    list-style: none
}

.adbox:after {
    width: 8px;
    height: 8px;
    right: -4px;
    z-index: -1;
    transform: rotate(45deg);
    top: 6px
}

.btn-twitter {
    background: #00acee;
    color: #fff
}

.btn-twitter:link,
.btn-twitter:visited {
    color: #fff
}

.btn-twitter:active,
.btn-twitter:hover {
    background: #0087bd;
    color: #fff
}

.btn-instagram {
    color: #fff;
    background-color: #3f729b;
    border-color: rgba(0, 0, 0, .2)
}

.btn-instagram.focus,
.btn-instagram:focus,
.btn-instagram:hover {
    color: #fff;
    background-color: #305777;
    border-color: rgba(0, 0, 0, .2)
}

.btn-github {
    color: #fff;
    background-color: #444;
    border-color: rgba(0, 0, 0, .2)
}

.btn-github.focus,
.btn-github:focus,
.btn-github:hover {
    color: #fff;
    background-color: #2b2b2b;
    border-color: rgba(0, 0, 0, .2)
}

.modal.fade:not(.in).left .modal-dialog {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.modal.fade:not(.in).bottom .modal-dialog {
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0)
}

.modal.left .modal-dialog {
    position: absolute;
    top: 10px;
    left: 2px;
    margin: 0
}

.modal.left .modal-dialog.modal-sm {
    max-width: 500px
}

.modal.left .modal-content {
    min-height: 100vh;
    border: 0
}

#sidebar-left .modal-header {
    display: flow-root !important;
    background: #bb1f28
}

#sidebar-left .modal-header h4 {
    font-size: 14px;
    color: #fff
}

#sidebar-left .modal-body img {
    width: 100%;
    height: 185px
}

.slideleftnearlocality {
    position: relative;
    font-size: 13px;
    color: #555;
    background: #ffe6e8;
    padding: 12px
}

.slideleftnearlocality i {
    color: #000;
    font-size: 14px
}

.savephoneshare {
    position: relative;
    background: #f6f6f6
}

.callwebtime,
.ratingreviewpop {
    background: #fff;
    position: relative
}

.savephoneshare li {
    display: inline-block;
    width: 32.653%;
    text-align: center;
    padding: 10px 6px
}

.rg-jobschedules,
.rg-themenavtabs {
    width: 100%;
    float: left
}

.savephoneshare li a {
    color: #333;
    font-weight: 500
}

.savephoneshare li a:hover {
    color: #3f82cf
}

.savephoneshare li i {
    display: block;
    color: #3f82cf
}

.callwebtime {
    padding: 12px 0
}

.callwebtime li {
    padding: 2px 16px
}

.callwebtime li a {
    color: #333;
    font-weight: 500
}

.callwebtime li a:hover,
.callwebtime li i,
.ratingreviewpop li a:hover {
    color: #3f82cf
}

.ratingreviewpop {
    padding: 12px 0
}

.ratingreviewpop li {
    padding: 2px 16px
}

.ratingreviewpop li a {
    color: #3f82cf;
    font-weight: 500
}

.ratingreviewpop .roz-locrating-star-color i,
.review-experience-box .imageandcontent .reviewrateandpoint .reviewstartshow i {
    color: #f7a200
}

.ratingreviewpop .roz-locrating-star-color {
    font-size: 14px
}

.review-experience-box {
    position: relative
}

.review-experience-box h3 {
    font-size: 16px;
    margin-bottom: 20px
}

.review-experience-box .imageandcontent {
    display: flex;
    justify-content: flex-start
}

.review-experience-box .imageandcontent .imground {
    position: relative;
    padding-right: 20px
}

.review-experience-box .imageandcontent .imground .imgrateperson {
    max-width: 100px;
    height: 100px !important;
    border-radius: 100px
}

.review-experience-box .imageandcontent .reviewrateandpoint {
    position: relative
}

.review-experience-box .imageandcontent .reviewrateandpoint h4 {
    font-size: 16px;
    padding-bottom: 12px
}

.review-experience-box .imageandcontent .reviewrateandpoint .reviewstartshow,
.review-experience-box .imageandcontent .reviewrateandpoint p {
    font-size: 14px
}

.rg-themenavtabs {
    display: block;
    list-style: none;
    line-height: 14px
}

.rg-themenavtabs li {
    float: left;
    width: auto;
    line-height: inherit;
    list-style: none
}

.rg-themenavtabs li a {
    padding: 20px;
    color: #303233;
    display: block;
    font-size: 16px;
    position: relative;
    line-height: inherit
}

.rg-themenavtabs li a.active,
.rg-themenavtabs li:hover a {
    color: #324b6e
}

.rg-themenavtabs li a:after {
    width: 0;
    left: 0;
    bottom: -1px;
    height: 4px;
    border-radius: 10px;
    position: absolute
}

.rg-themenavtabs li a.active:after,
.rg-themenavtabs li:hover a:after {
    width: 100%
}

.rg-abouttab .rg-jobdetaildescription {
    padding: 20px
}

.rg-tabcontent .rg-jobdetails .rg-jobdescription {
    margin: 6px 0 22px
}

.rg-abouttab.rg-jobdetails .rg-title h2 {
    margin: 0 0 9px
}

.rg-tabcontent .rg-jobdetails .rg-description p {
    font-size: 14px;
    margin: 0 0 27px;
    line-height: 20px
}

.rg-tabcontent .rg-jobdetails .rg-description p:last-child {
    margin: 0
}

.rg-employerdetailsimg {
    float: left;
    width: 100%;
    margin: 6px 0 9px
}

.rg-employerdetailsimg img {
    width: 100%;
    display: block
}

.roz-employerdetailsimg {
    float: left;
    width: 50%;
    margin: 5px 20px 0 0
}

.roz-employerdetailsimg img {
    width: 100%;
    display: block
}

.roz-jobcityimg {
    float: right;
    width: 37%;
    margin: 5px 20px 0 0
}

.roz-jobcityimg img {
    width: 100%;
    display: block
}

.rg-videovtwo {
    margin: 4px 0 24px
}

.rg-videovtwo figure a img,
.rg-videovtwo figure img,
.rg-videovtwo figure>a {
    display: block
}

.rg-videovtwo figure img {
    width: 100%
}

.rg-featurejobs {
    width: auto;
    float: left;
    margin: -10px
}

.rg-featurejobs .rg-featurejob {
    width: 50%;
    border: 0;
    padding: 10px;
    border-radius: 0;
    box-shadow: none;
    background: 0 0
}

.rg-featurejobs .rg-companycontent {
    float: left;
    width: 100%;
    background: #fff;
    padding: 14px 20px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15)
}

.rozgar-featurejobs {
    width: 100%;
    float: left;
    margin: 0
}

.rozgar-featurejobs .rozgar-featurejob {
    width: 100%;
    float: left;
    border: 0;
    padding: 10px;
    border-radius: 0;
    box-shadow: none;
    background: 0 0
}

.rozgar-featurejobs .rozgar-companycontent {
    float: left;
    width: 100%;
    background: #fff;
    padding: 14px 20px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15)
}

.rg-tabgallery {
    float: left;
    width: 100%
}

.rg-tabgalleryimgs {
    width: auto;
    margin: -3px;
    float: left;
    list-style: none
}

.rg-tabgalleryimgs li {
    width: 20%;
    float: left;
    padding: 3px;
    list-style: none
}

.rg-tabgalleryimgs li figure {
    margin: 0;
    width: 100%;
    float: left
}

.rg-tabgalleryimgs li figure img {
    width: 100%;
    display: block
}

.rg-tabgallery .rg-pagination {
    line-height: 13px;
    padding: 25px 0 8px
}

.pagination {
    margin-top: 20px
}

.pagination li.page-item {
    list-style: none none
}

.rg-qapolicy {
    width: 100%;
    float: left;
    margin: 0 0 30px;
    border-radius: 5px;
    background: #e9f4ff;
    border: 1px solid #d9d9e2
}

.rg-qapolicy span {
    display: block;
    color: #324b6e;
    font-size: 13px;
    line-height: 14px;
    padding: 10px 0 10px 19px
}

.rg-qapolicy span a {
    color: #55acee;
    text-decoration: underline
}

.rg-askjobform {
    padding: 10px;
    border-radius: 5px;
    background: #fafafa
}

.rg-askjobform .form-group {
    margin: 0 0 10px
}

.rg-askjobform .form-group:nth-last-child(1) {
    margin: 0
}

.rg-askjobform .form-group:first-child input {
    padding: 10px 274px 10px 44px
}

.rg-askjobform .form-group textarea.form-control {
    line-height: 30px;
    height: 130px
}

.rg-askjobform .form-group .form-control:focus {
    border-color: #ddd
}

.rg-selectholder {
    right: 0;
    width: 255px;
    position: absolute
}

.rg-askjobform .rg-inputwithicon i {
    font-size: 14px
}

.rg-selectholder.rg-inputwithicon i {
    width: 55px
}

.rg-selectholder .rg-select select {
    padding: 0 0 0 55px;
    border-radius: 0 5px 5px 0;
    cursor: pointer
}

.rg-selectholder .rg-select:after {
    right: 20px;
    font-size: 10px;
    line-height: 50px
}

.rg-askjobform .form-group .rg-btn {
    border: 0;
    padding: 0 40px;
    font-size: 14px;
    line-height: 50px
}

.rg-questions {
    margin: 40px 0 0
}

.rg-questions .rg-title {
    margin: 0 0 7px
}

.rg-questions .rg-title h2 {
    float: left
}

.rg-questionslect {
    float: right;
    width: auto
}

.rg-btns,
.rg-interviewprocess,
.rg-questions {
    float: left;
    width: 100%
}

.rg-questionslect span:first-child {
    float: left;
    color: #767676;
    font-size: 13px;
    line-height: 13px;
    padding: 8px 10px 8px 0
}

.rg-questionslect .rg-select {
    width: 115px
}

.rg-questionslect .rg-select select {
    height: 30px;
    font-size: 13px;
    padding: 0 15px;
    border-radius: 0
}

.rg-questionslect .rg-select:after {
    line-height: 30px;
    font-size: 8px
}

.rg-interviewprocess {
    padding: 20px 0 0;
    border-top: 1px solid #eff2f5
}

.rg-interviewprocess+.rg-interviewprocess {
    margin: 20px 0 0
}

.rg-interviewprocess .rg-title h2,
.rg-interviewprocess .rg-title h3 {
    width: 100%;
    margin: 0 0 5px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400
}

.rg-interviewprocess .rg-title {
    margin: 0
}

.rg-interviewprocess .rg-title span {
    color: #767676;
    display: block;
    font-size: 13px;
    line-height: 13px
}

.rg-interviewprocess .rg-title h2 a:hover,
.rg-qapolicyvtwo a,
.rg-reportbar a {
    color: #55acee
}

.rg-interviewprocess .rg-title h2 a {
    display: inline-block;
    text-transform: initial
}

.comingsoon h3,
.free-text,
.rg-customnoti a em,
.tg-qrcodedetails {
    text-transform: uppercase
}

.rg-tabcontent .rg-jobdetails.rg-qapolicys .rg-description p {
    margin: 22px 0 0
}

.rg-interviewprocess .rg-btns {
    margin: 13px 0 0
}

.rg-interviewprocess .rg-btns a+a {
    color: #55acee;
    margin: 0 0 0 16px;
    font-size: 12px;
    line-height: 12px;
    display: inline-block
}

.rg-reportbar {
    width: auto;
    float: right;
    padding: 8px 0
}

.rg-reportbar i {
    margin: 0 5px 0 0
}

.rg-interviewprocess .rg-pagination {
    margin: 5px 0
}

.rg-qapolicyvtwo {
    width: 100%;
    float: left;
    margin: 0 0 25px
}

.rg-qapolicyvtwo a {
    display: block;
    font: 400 16px/16px Poppins, Arial, Helvetica, sans-serif
}

.rg-qapolicyvtwo a i {
    margin: 0 8px 0 0
}

.rg-qapolicysvtwo .rg-askjobform .form-group textarea.form-control {
    height: 50px;
    overflow: hidden
}

.rg-qapolicysvtwo .rg-askjobform .form-group .rg-btn {
    outline: 0;
    padding: 0 27px
}

.rg-askjobform .form-group .rg-checkbox {
    float: none;
    width: auto;
    padding: 0 0 0 25px;
    display: inline-block;
    vertical-align: middle
}

.rg-checkbox input[type=checkbox]+label {
    color: #767676;
    font-size: 13px;
    line-height: 20px
}

.rg-qapolicysvtwo .rg-interviewprocess .rg-title {
    width: auto;
    padding: 5px 0 5px 10px
}

.rg-interviewprocess figure {
    margin: 0;
    width: auto;
    float: left;
    border-radius: 50%
}

.rg-interviewprocess figure img {
    width: auto;
    display: block;
    border-radius: 50%
}

.rg-qapolicysvtwo .rg-btns .rg-btndislike,
.rg-qapolicysvtwo .rg-btns .rg-btnlikevtwo {
    border: 0;
    padding: 0;
    width: 95px;
    color: #fff;
    line-height: 40px;
    font-size: 12px;
    border-radius: 5px
}

.rg-direction,
.rg-locationcontant,
.rg-locationdetails,
.rg-ourlocations,
.rg-thememap {
    width: 100%;
    float: left
}

.rg-qapolicysvtwo .rg-btns a i {
    margin: 0 5px 0 0
}

.rg-qapolicysvtwo .rg-btns .rg-btnlikevtwo {
    background: #55acee
}

.rg-qapolicysvtwo .rg-btns .rg-btndislike {
    background: #fc413c
}

.rg-ourlocations {
    margin: 0 0 19px
}

.rg-thememap {
    height: 140px
}

.rg-locationcontant {
    padding: 18px 0
}

.rg-tabcontent .rg-locationcontant .rg-title h2 {
    margin: 0;
    font-size: 16px;
    line-height: 17px
}

.rg-tabcontent .rg-locationcontant .rg-title span {
    font-size: 13px;
    line-height: 20px;
    display: block
}

.rg-direction {
    margin: 10px 0 0;
    list-style: none;
    line-height: 13px
}

.rg-direction li {
    width: auto;
    float: left;
    padding: 0 10px;
    position: relative;
    line-height: inherit;
    list-style: none
}

.rg-direction li:first-child {
    padding-left: 0
}

.rg-direction li+li:before {
    left: 0;
    width: 1px;
    height: 100%;
    position: absolute;
    background: #a7a7a7
}

.rg-direction li a {
    display: block;
    font-size: 13px;
    color: #55acee;
    line-height: inherit
}

.rg-contactformarea {
    width: 100%;
    float: left
}

.rg-formcontactus fieldset {
    width: auto;
    float: none;
    margin: -5px;
    overflow: hidden
}

.rg-formcontactus .form-group {
    margin: 0;
    width: 50%;
    float: left;
    padding: 5px
}

.rg-formcontactus .form-group:nth-last-child(1),
.rg-formcontactus .form-group:nth-last-child(2) {
    width: 100%
}

.rg-formcontactus .form-group textarea.form-control {
    height: 130px
}

.rg-formcontactus .form-group .form-control {
    line-height: 28px
}

.rg-formcontactus .form-group i {
    top: 5px;
    left: 5px;
    font-size: 14px;
    line-height: 50px
}

.rg-btn:after,
.rg-btn:before,
.rg-candidatescover:before {
    left: 0;
    height: 100%;
    content: ''
}

.rg-formcontactus .form-group .rg-btn {
    border: 0;
    outline: 0;
    padding: 0 40px;
    font-size: 14px;
    line-height: 50px
}

.rg-btn:after,
.rg-btn:before {
    top: 0;
    z-index: -1;
    width: 100%;
    position: absolute;
    -webkit-border-radius: 4px;
    border-radius: 4px
}

.rg-btn:after {
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, .1)
}

.rg-btn:hover:after {
    opacity: 1;
    visibility: visible
}

.rg-candidatessearchs {
    float: left;
    width: 100%
}

.rg-candidatessearch {
    float: left;
    width: 100%;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .15)
}

.rg-candidatessearch .rg-angrycreativelist {
    position: relative
}

.rg-candidatessearch .rg-angrycreativelist figure {
    margin: 0;
    float: left
}

.rg-candidatessearch .rg-employerdetails {
    width: auto;
    float: none;
    overflow: hidden;
    padding: 11px 160px 11px 10px
}

.rg-candidatessearch .rg-employerdetails .rg-btn {
    top: 30px;
    margin: 0;
    right: 20px;
    padding: 0 20px;
    position: absolute
}

.rg-candidatessearch:hover .rg-employerdetails .rg-btn {
    color: #fff
}

.rg-professionalinfo li span a {
    color: #55acee
}

.rg-searchholder .chosen-container-single .chosen-single span {
    line-height: 32px
}

.rg-candidatessearchsvtwo {
    float: none;
    width: auto;
    margin: -10px
}

.rg-candidatessearcgrid {
    width: 50%;
    float: left;
    padding: 10px 9px
}

.rg-candidatessearchsvtwo .rg-employerdetails {
    float: left;
    width: 100%;
    padding: 20px
}

.rg-candidatessearchsvtwo .rg-candidatessearch {
    margin: 0
}

.rg-candidatessearchsvtwo .rg-candidatessearch figure {
    margin: 0;
    border-radius: 5px 5px 0 0
}

.rg-candidatessearchsvtwo .rg-candidatescover img {
    width: 100%;
    display: block;
    border-radius: 5px 5px 0 0
}

.rg-candidatescover:before {
    top: 0;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, .5);
    color: rgba(0, 0, 0, .5);
    border-radius: 5px 5px 0 0
}

.comingsoon,
.opning-jobs-swiggy,
.rg-enquiryrvtworep,
.rg-innerbannervtwo,
.rg-innerbannervtwoabout,
.rg-innerbannervtworep,
.rg-swiggy-life-bgbanner,
.rg-swiggybgbanner,
.small-swiggy-logo,
.swiggy-about-box,
.swiggy-apply-jobbox,
.swiggy-job-opning,
.swiggy-tech-stack,
.swiggy-yearlocation,
.swiggynavbg,
.swiggyon-web,
.swiggyon-web-icons,
.view-all-openings {
    position: relative
}

.rg-candidatessearchsvtwo .rg-candidatessearch figure+figure {
    width: 100%;
    margin: -46px 0 0
}

.rg-candidatessearchsvtwo .rg-candidatessearch figure+figure img {
    padding: 5px;
    display: block;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #eff2f5
}

.rg-verticaltop,
.swiggynavbg ul.swiggynav li {
    display: inline-block
}

.rg-candidatessearchsvtwo .rg-employerdetails h3 {
    margin: 0;
    line-height: 23px
}

.rg-candidatessearchsvtwo .rg-employerdetails h4+h4 {
    padding: 15px 0 0
}

.rg-candidatessearchsvtwo .rg-employerdetails h4 span {
    padding: 0;
    line-height: 16px
}

.rg-candidatessearchsvtwo .rg-employerdetails h4 span+span {
    padding: 4px 0 0
}

.rg-candidatessearchsvtwo .rg-employerdetails h4 span a {
    color: #55acee
}

.rg-verticaltop {
    float: none !important;
    vertical-align: top
}

.rg-candidatesdetails,
.rg-candidatesprofile,
.rg-enquiryrvtworep,
.rg-innerbannervtwo,
.rg-innerbannervtworep,
.rg-swiggy-life-bgbanner,
.rg-swiggybgbanner {
    float: left;
    width: 100%
}

.rg-candidatessearchsvtwo .rg-adds {
    margin: 0
}

.rg-candidatessearchsvtwo .rg-pagination {
    margin: 15px 0 0
}

.comingsoon {
    padding-top: 2%
}

.rg-enquiryrvtworep {
    min-height: 300px;
    background: url(../images/innerbanner/img-044.jpg) no-repeat;
    background-size: contain
}

.rg-innerbannervtworep {
    min-height: 300px;
    background: url(../images/innerbanner/img-022.jpg) no-repeat;
    background-size: contain
}

.rg-innerbannervtwo {
    min-height: 300px;
    background: url(../images/innerbanner/img-02.jpg) no-repeat;
    background-size: cover
}

.rg-innerbannervtwoabout {
    float: left;
    width: 100%;
    min-height: 300px;
    background: url(../images/innerbanner/img-about01.jpg) no-repeat;
    background-size: cover
}

.rg-swiggybgbanner {
    min-height: 300px;
    background: url(../images/innerbanner/swiggybg.jpg) no-repeat;
    background-size: contain
}
.rg-greenlam {
    min-height: 300px;
    background: url(../images/innerbanner/greenlamindustriesbg.jpg) no-repeat;
    background-size: contain;
    float: left;
    width: 100%;
}

.rg-swiggy-life-bgbanner {
    min-height: 300px;
    background: url(../images/innerbanner/swiggy-life-bg.jpg) no-repeat;
    background-size: contain
}
.rg-greenlam-life-bgbanner {
    min-height: 300px;
    background: url(../images/innerbanner/greenlamindustries-life-bg.jpg) no-repeat;
    background-size: contain;
    float: left;
    width: 100%;
}
.rg-candidatesdetails {
    margin: -150px 0 0
}

.rg-jobapplycentervthree {
    padding: 20px;
    margin: 0 0 10px;
    background: #fff;
    box-shadow: none;
    border: 1px solid #eff2f5
}

.rg-jobapplycentervthree .rg-companyimg {
    margin: 0 20px 0 0
}

.rg-jobapplycentervthree .rg-companyimg img {
    width: 100%
}

.rg-jobapplycentervthree .rg-companycontent {
    padding: 4px 0 0
}

.rg-jobapplycentervthree .rg-jobapplybtnlike .rg-btn {
    float: none;
    padding: 0 30px
}

.swiggynavbg {
    float: left;
    width: 100%;
    margin-top: -60px;
    background: #fff6f6
}

.swiggynavbg ul.swiggynav {
    list-style: none
}

.swiggynavbg ul.swiggynav li a {
    display: block;
    padding: 15px 30px;
    font-size: 18px;
    color: #3d3d3d;
    border-bottom: 3.5px solid #d3d3d3;
    border-radius: 4px;
    margin-right: 18px
}

.swiggynavbg ul.swiggynav li a.active {
    border-bottom: 3.5px solid #4c4c4c;
    border-radius: 4px
}

.swiggy-apply-jobbox {
    width: 100%;
    float: left;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-top: 0;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    background: url(../images/innerbanner/swiggybgjob.jpg) no-repeat;
    background-size: contain
}

.swiggy-apply-jobbox .swiggy-text-logo {
    text-align: center;
    padding: 20px
}

.swiggy-apply-jobbox .swiggy-text-logo h3 {
    font-size: 16px;
    padding-bottom: 10px
}

.swiggy-apply-jobbox .swiggy-text-logo img {
    max-width: 30%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;

}

.swiggyjobapply {
    position: relative;
    padding: 10px;
    text-align: center
}

.swiggyjobapply a {
    color: #fff !important;
    font-weight: 500;
    background: #e81c28;
    font-size: 16px;
    color: #fff !important;
    border-radius: 50px;
    margin-bottom: 20px;
    padding: 3px 10px;
}

.swiggyjobapply a:hover {
    background: #f83440
}

.swiggy-about-box {
    float: left;
    width: 100%
}

.swiggy-about-box h2 {
    font-size: 20px;
    color: #464646
}

.swiggy-about-box p {
    font-size: 14px
}

.small-swiggy-logo {
    padding: 20px
}

.small-swiggy-logo img {
    padding: 4px 10px;
    width: 60px;
    height: 60px;
    border: 1px solid #eaf1f5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    background: #fff;
    object-fit: contain;
}

.swiggy-hiring-now {
    display: inline-block;
    background: #e3e3e3;
    text-align: center;
    padding: 0 10px;
    border-radius: 20px;
    font-weight: 400
}

.opning-jobs-swiggy {
    padding: 3px 6px
}

.opning-jobs-swiggy ul {
    margin: 0;
    padding: 0
}

.opning-jobs-swiggy ul li {
    list-style: none;
    margin-bottom: 6px;
    background: #fff8f8;
    padding-left: 6px;
    border-radius: 4px
}

.opning-jobs-swiggy ul li h4 {
    font-size: 13px;
    margin: 0
}

.swiggy-yearlocation span {
    color: #888;
    font-size: 12px;
    padding-right: 10px
}

.view-all-openings {
    text-align: center;
    padding: 30px 0;
    width: 100%;
    display: flow-root
}

.view-all-openings a {
    display: inline-block;
    padding: 4px 20px;
    border: 1px solid #e81c28;
    border-radius: 50px;
    font-weight: 500
}

.view-all-openings a:hover {
    background: #e81c28;
    color: #fff
}

.swiggy-com-info {
    float: left;
    width: 100%;
    background: #fff9f9;
    padding: 20px;
    border-radius: 4px
}

.jobs-at-swiggy-box {
    float: left;
    width: 100%
}

.jobs-at-swiggy-box .swiggy-boxshow {
    background: #fafafa;
    padding: 20px 35px;
    border-radius: 4px
}

.rg-detailopensjobs.swiggy-job-width {
    width: 75%
}

.headcom-text {
    font-size: 15px !important;
    margin-bottom: 10px !important
}

.bycomtext {
    color: #999;
    font-size: 13px
}

.rg-featurejob.swiggy-jobbox-link a {
    color: #222
}

.rg-companycontent.swiggy-jobbox-shadow {
    box-shadow: none;
    border: 1px solid #f0f0f0
}

.rg-companycontent.swiggy-jobbox-shadow:hover {
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.rg-candidatebg,
.rg-logindetails {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}

.atswiggy-text {
    color: #999;
    font-size: 13px
}

.swiggy-benefits-head {
    padding: 6px 24px 0
}

.swiggy-benefits-box {
    background: #f9f9f9;
    float: left;
    width: 100%;
    border-radius: 4px
}

.swiggy-facility-icon {
    display: inline-block !important
}

.swiggy-facility-size {
    font-size: 16px;
    font-weight: 600
}

.roz-more-informaiton.swiggy-more-info-text span {
    font-weight: 400 !important;
    padding-bottom: 10px
}

.swiggy-follow-box {
    display: flex;
    align-items: center
}

.swiggy-follow-box span {
    font-size: 14px;
    color: #888
}

.swiggy-follow-btn {
    color: #fff !important;
    color: #fff !important;
    padding: 8px 24px;
    border-radius: 50px;
    font-weight: 600;
    margin-left: 20px;
    background: #35a22f;
}

.swiggy-follow-btn:hover {
    color: #f9f9f9;
    background: #666
}

.swiggyon-web {
    float: left;
    width: 100%;
    padding-top: 20px
}

.swiggyon-web-head {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px
}

.swiggyon-web-icons ul {
    list-style: none
}

.swiggyon-web-icons ul li {
    display: inline-block;
    background: #fff6f6;
    margin-right: 10px;
    padding: 0 10px;
    border-radius: 20px 10px
}

.swiggyon-web-icons ul li a {
    color: #888;
    font-size: 14px
}

.swiggyon-web-icons ul li a .fa-linkedin-square {
    color: #0077b5;
    font-size: 18px
}

.swiggyon-web-icons ul li a .fa-twitter-square {
    color: #1da1f2;
    font-size: 18px
}

.swiggyon-web-icons ul li a .fa-instagram {
    color: #5642d7;
    font-size: 18px
}

.swiggyon-web-icons ul li a .fa-android {
    color: #a4c639;
    font-size: 18px
}

.swiggyon-web-icons ul li a .fa-apple {
    color: #0077b5;
    font-size: 18px
}

.swiggyon-web-icons ul li a:hover {
    color: #444
}

.swiggyon-web-icons ul li a:hover i {
    color: #444;
    opacity: .8
}

.swiggy-tech-stack {
    float: left;
    width: 100%;
    padding-top: 50px
}

.swiggy-tech-stack-head {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px
}

.swiggy-tech-point {
    list-style: none
}

.swiggy-tech-point li {
    display: inline-block;
    padding: 0 10px;
    background: #f9f9f9;
    border-radius: 50px;
    color: #222;
    font-size: 14px;
    margin-right: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px
}

.swiggy-services-box {
    position: absolute;
    top: -32px;
    text-align: center;
    left: 14%
}

.swiggy-mission,
.worklife-swiggy,
.worklife-swiggy-box {
    position: relative
}

.swiggy-services-box ul {
    padding: 0;
    margin: 0
}

.swiggy-services-box ul li {
    list-style: none;
    display: inline-block;
    background: 0 0;
    margin-right: 4px;
    padding: 0 10px;
    border-radius: 20px 10px;
    color: #000;
    border: 1px solid #757575;
    line-height: 22px
}

.swiggy-video-bg {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 4px
}

.swiggy-mission {
    background: #f68620;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 20px 10px
}

.swiggy-mission .mission-text {
    font-size: 24px;
    line-height: 30px;
    color: #fff
}

.worklife-swiggy-head {
    font-size: 25px;
    margin-bottom: 10px
}

.worklife-swiggy-pix-box {
    background: #f9f9f9;
    border-radius: 4px
}

.worklife-swiggy-pix-box img {
    border-radius: 4px;
    height: 200px;
    object-fit: cover;
    width: 100%;
}

.worklife-swiggy-pix-box h3 {
    font-size: 18px;
    padding: 10px 16px
}

.worklife-swiggy-pix-box p {
    padding: 0 16px 16px;
    font-size: 14px
}

.swiggy-our-benefits-box {
    position: relative;
    padding: 50px 0
}

.swiggy-ben-ser-head {
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 10px
}

.swiggy-ben-ser-text {
    font-size: 14px
}

.swiggy-benefites-number {
    margin: 0;
    padding-top: 20px
}

.swiggy-benefites-number li {
    display: inline-block;
    width: 31%;
    vertical-align: top;
    padding-bottom: 20px;
    margin-right: 18px
}

.sb-icons-box {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center
}

.sb-icons-box.bgco {
    box-sizing: border-box;
    background: #fbfbfb;
    padding: 20px 10px;
    border-radius: 4px;
    position: sticky;
    border: 1px solid #eee;
    height: 160px
}

.sb-icons-box .sb-icons {
    font-size: 30px;
    color: #3198a9;
    background: #ffe3e5;
    position: relative;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50% 20%;
    margin-bottom: 15px
}

.sb-icons-box .sb-icons::before {
    content: '';
    position: absolute;
    background: #fff0f1;
    width: 55px;
    height: 55px;
    left: 0;
    z-index: -1;
    border-radius: 50% 20%
}

.sb-icons-box p {
    font-size: 18px
}

.rg-shortlist {
    color: #55acee;
    font-size: 14px;
    line-height: 14px;
    margin: 0 10px 0 25px;
    display: inline-block;
    vertical-align: middle
}

.rg-addsvthree {
    margin: 0;
    padding: 10px 0
}

.rg-experience {
    padding: 20px 20px 13px
}

.rg-candidatebg {
    float: left;
    width: 100%;
    padding: 20px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}

.rg-candidatebg .rg-title h2 {
    margin: 0 0 16px;
    font-size: 20px;
    line-height: 18px;
    font-weight: 500
}

.rg-candidatebg .rg-title i {
    float: left;
    font-size: 20px;
    line-height: 18px;
    margin: -3px 10px 0 0
}

.rg-introduction .rg-description p {
    margin: 0;
    font-size: 14px;
    line-height: 24px
}

.rg-introduction .rg-description p+p {
    margin: 25px 0 0
}

.rg-candidateinfo {
    padding: 0
}

.rg-candidateinfo .rg-jobdetailinfo ul li span {
    width: 265px
}

.rg-addproject,
.rg-experiencecontent {
    width: 100%;
    float: left
}

.rg-experiencecontent figure {
    margin: 0;
    float: left
}

.rg-experiencecontent .rg-successcontent {
    padding: 5px 0 0 10px
}

.rg-experiencecontent .rg-successname {
    padding: 0 0 17px
}

.rg-successname a {
    display: block;
    margin: 5px 0 0;
    color: #55acee;
    font-size: 13px;
    line-height: 14px
}

.rg-successname span {
    padding: 7px 0
}

.rg-projects {
    width: auto;
    float: left;
    padding: 0 0 19px;
    margin: 23px 0 20px 90px;
    border-bottom: 1px solid #ddd
}

.rg-projects .rg-title h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 17px;
    line-height: 14px
}

.rg-experiencepro {
    float: left;
    width: auto;
    margin: -10px
}

.rg-addprojectpart {
    width: 50%;
    padding: 10px;
    float: left
}

.rg-addproject {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15)
}

.rg-projectimg,
.rg-projectimg img {
    border-radius: 5px 5px 0 0;
    width: 100%
}

.rg-projectimg {
    margin: 0;
    float: left
}

.rg-projectimg img {
    height: auto;
    display: block
}

.rg-projectcontent {
    width: 100%;
    float: left;
    min-height: 105px;
    padding: 20px 10px;
    position: relative;
    border: 1px solid #eff2f5;
    border-radius: 0 0 5px 5px
}

.rg-projectname {
    width: 100%;
    float: left
}

.rg-projectname h4 {
    margin: 0 0 2px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px
}

.rg-projectname a {
    clear: both;
    color: #55acee;
    display: block;
    font-size: 13px;
    line-height: 15px
}

.rg-education .rg-experiencecontent+.rg-experiencecontent {
    margin: 8px 0 0
}

.rg-education .rg-experiencecontent+.rg-experiencecontent .rg-successname {
    padding: 25px 0 17px;
    border-top: 1px solid #ddd
}

.rg-education .rg-experiencecontent+.rg-experiencecontent figure {
    margin: 25px 0 0
}

.rg-ourskill {
    width: auto;
    float: left;
    margin: -10px
}

.rg-skill {
    width: 50%;
    float: left;
    padding: 10px
}

.rg-skill:hover {
    cursor: pointer
}

.rg-skillname .rg-skillbar span {
    position: absolute;
    top: 0;
    right: 0
}

.rg-skillholder {
    float: left;
    width: 100%;
    background: #f7f7f7
}

.rg-strenghtcomplete {
    width: 100%;
    float: right;
    color: #767676;
    text-align: right;
    font-size: 12px;
    padding: 0 0 7px;
    line-height: 15px
}

.rg-skillbar {
    height: 6px;
    float: left;
    transition: none;
    position: relative;
    border-radius: 3px;
    background: #ffd3a5;
    background: -moz-linear-gradient(left, #ffd3a5 0, #fd6585 100%);
    background: -webkit-linear-gradient(left, #ffd3a5 0, #fd6585 100%);
    background: linear-gradient(to right, #ffd3a5 0, #fd6585 100%)
}

.rg-skill h3 {
    font-size: 16px;
    line-height: 16px;
    margin: 37px 0 0;
    font-weight: 400
}

.rg-candidate .rg-title h2 {
    margin: 0 0 15px
}

.rg-employerlistvtwo {
    width: auto;
    margin: -10px
}

.rg-employedetails {
    width: 50%;
    float: left;
    padding: 10px
}

.rg-employerlistvtwo .rg-widget {
    margin: 0;
    width: 100%
}

.rg-followedcom {
    margin: 10px 0 0
}

.rg-formcontactusvtwo .form-group {
    width: 100%
}

.rg-formcontactusvtwo .form-group .form-control::placeholder {
    color: #767676
}

.rg-smartscan figure {
    margin: 0;
    width: auto;
    float: left;
    padding: 4px;
    background: #f7f7f7;
    border: 1px solid #eff2f5
}

.rg-smartscan figure img {
    display: block;
    width: 100%;
    padding: 10px;
    background: #fff
}

.rg-smartscancontent {
    float: left;
    width: auto;
    padding: 0 0 0 10px
}

.rg-smartscancontent span {
    display: block
}

.rg-smartscancontent span:first-child {
    font-size: 60px;
    line-height: 44px
}

.rg-smartscancontent span:first-child i {
    line-height: inherit
}

.rg-smartscancontent span:last-child {
    font-size: 20px;
    line-height: 25px
}

.rg-smartscancontent span em {
    display: block;
    font-style: normal
}

.rg-signincontent,
.rg-smartscan figure figcaption {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.rg-jobsearchaddvtwo {
    margin: 20px 0 0
}

.rg-jobsearchaddvtwo figure img {
    width: 255px
}

.rg-logoareavtwo,
.rg-signincontent {
    float: left;
    width: 100%
}

body.rg-loginuser {
    background: url(../images/signin-img.jpg) no-repeat;
    background-size: cover
}

body.rg-signupuser {
    /* background: url(../images/signup-img.jpg) no-repeat; */
    background: url(../../assets/images/logo/signup-img.jpg) no-repeat;
    background-size: cover
}

body.rg-loginuser .rg-main {
    padding: 0
}

.rg-signincontent {
    height: auto;
    min-height: 880px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column
}

.rg-logoareavtwo .rg-logo {
    padding: 0;
    float: none;
    width: 220px;
    display: block;
    margin: 0 auto
}

.rg-logoareavtwo .rg-logo a img {
    width: auto
}

.rg-logindetails {
    float: left;
    width: 100%;
    padding: 30px;
    background: #fff;
    margin: 40px 0 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
    border: 1px solid #eff2f5
}

.rg-titlelogin {
    float: left;
    width: 100%;
    margin: 0 0 16px
}

.rg-titlelogin h2 {
    margin: 0;
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    position: relative;
    padding: 0 0 0 30px
}

.rg-titlelogin h2:before {
    left: 0;
    top: -3px;
    content: '\e80f';
    position: absolute;
    font-family: Linearicons-Free
}

.rg-formlogin {
    margin: 0 0 30px
}

.rg-formlogin fieldset .form-group {
    margin: 0 0 10px
}

.rg-formlogin fieldset .form-group:last-child {
    margin: 10px 0 0
}

.rg-formlogin fieldset .form-group.rg-inputwithicon i {
    height: 50px;
    font-size: 14px;
    line-height: 50px
}

.rg-formlogin fieldset .form-group input {
    height: 50px
}

.rg-formlogin fieldset .form-control:disabled {
    background: #f7f7f7
}

.rg-formlogin fieldset .form-group input::placeholder {
    color: #767676
}

.rg-formlogin fieldset .rg-signedcheck {
    margin: 10px 0
}

.rg-signedcheck span {
    color: #767676;
    width: auto;
    font-size: 13px
}

.rg-formlogin fieldset .form-group .rg-btn {
    padding: 0;
    border: 0;
    float: left;
    width: 130px;
    font-size: 14px;
    line-height: 50px
}

.rg-forgotpassword {
    float: left;
    color: #767676;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin: 17px 0 17px 10px
}

.rg-socialiconssignin {
    float: left;
    width: 100%;
    position: relative;
    padding: 33px 0 30px;
    border-top: 1px solid #ddd
}

.rg-signinnext {
    top: 0;
    left: 50%;
    width: 30px;
    color: #767676;
    font-size: 14px;
    background: #fff;
    line-height: 14px;
    text-align: center;
    position: absolute;
    margin: -7px 0 0 -15px
}

.rg-signinicons {
    float: left;
    width: 100%;
    padding: 0 65px
}

.rg-signinicons a {
    color: #fff;
    float: left;
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    line-height: 50px
}

.rg-signinicons a+a {
    margin: 10px 0 0
}

.rg-signinicons a.rg-signinfacebook {
    background: #3b5999
}

.rg-signinicons a.rg-signingoogle {
    background: #dd4b39
}

.rg-signinicons a:focus,
.rg-signinicons a:hover {
    color: #fff
}

.rg-signinicons a span {
    display: block;
    line-height: inherit
}

.rg-signinicons a i {
    float: left;
    width: 50px;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    margin: 0 20px 0 0;
    background: rgba(0, 0, 0, .1)
}

.rg-loginfooter {
    float: left;
    width: 100%;
    width: auto;
    margin: 0 -30px -6px;
    padding: 23px 40px 0;
    border-top: 1px solid #ddd
}

.rg-loginfooter .rg-description p {
    margin: 0;
    font-size: 13px;
    line-height: 22px;
    text-align: center
}

.rg-loginfooter .rg-description p a {
    color: #55acee;
    text-decoration: none
}

.rg-signupbottombar {
    float: left;
    width: 100%;
    margin: 10px 0 0;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}

.rg-signupbottombar ul {
    float: left;
    width: 100%;
    list-style: none;
    line-height: 60px
}

.rg-signupbottombar ul li {
    width: 50%;
    float: left;
    text-align: center;
    line-height: inherit;
    list-style: none
}

.rg-btnopenclose,
.rg-btnopenclose i {
    background: #fff;
    width: 50px;
    height: 50px
}

.rg-signupbottombar ul li+li {
    border-left: 1px solid #eff2f5
}

.rg-signupbottombar ul li span {
    display: block;
    font-size: 14px;
    line-height: inherit
}

.rg-signupbottombar ul li span a {
    color: #55acee
}

.rg-footersignin .rg-footerbottom {
    background: #fff;
    padding: 19px 0
}

.rg-footersignin .rg-footerbottom .rg-addnav ul li a,
.rg-footersignin .rg-footerbottom .rg-copyrights {
    color: #324b6e
}

.rg-footersignin .rg-footerbottom .rg-addnav ul li a:hover {
    color: #55acee
}

.rg-socialiconssimple li.rg-sharejob {
    padding: 0
}

.rg-btnopenclose {
    left: 0;
    top: 50%;
    display: none;
    font-size: 20px;
    position: fixed;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    z-index: 999
}

.rg-sidenavshow .rg-btnopenclose {
    -moz-transform: translateX(295px);
    -webkit-transform: translateX(295px);
    -o-transform: translateX(295px);
    -ms-transform: translateX(295px);
    transform: translateX(295px)
}

.rg-btnopenclose i {
    border: 2px solid #dbdbdb;
    line-height: 46px;
    border-radius: 50%;
    animation: lds-dual-ring 3s linear infinite
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.spread-overlay .rg-sidenavholder {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    position: fixed;
    background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .6) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .6) 100%);
    background: linear-gradient(135deg, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .6) 100%)
}

.rg-sidenav {
    top: 0;
    left: 0;
    width: 320px;
    height: 100%;
    z-index: 100;
    position: fixed;
    background: #f7f7f7;
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%)
}

.rg-sidebarscrollbar {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    position: relative
}

.rg-sidebarscrollbar::-webkit-scrollbar {
    display: none
}

.rg-sidenavshow .rg-sidenav {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0)
}

.rg-close {
    top: 300px;
    width: 36px;
    height: 36px;
    z-index: 99;
    right: -18px;
    display: block;
    font-size: 18px;
    background: #fff;
    line-height: 34px;
    position: absolute;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #dbdbdb
}

.rg-sidebarscrollbar .rg-widget {
    margin: 0;
    border: 0;
    box-shadow: none
}

.rg-smartscan figure figcaption {
    top: 50%;
    left: 50%;
    width: 108px;
    height: 108px;
    background: rgba(255, 255, 255, .8);
    margin: -54px 0 0 -54px;
    position: absolute;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center
}

.tg-qrcodedetails {
    float: left;
    width: 97px;
    height: 97px;
    text-align: center;
    border-radius: 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center
}

.tg-qrcodedetails span {
    color: #fff;
    padding: 10px;
    display: block;
    font-size: 12px;
    line-height: 16px
}

.tg-qrcodedetails span i {
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 10px
}

.rg-commingsoonbg {
    min-height: 938px;
    background: #324b6e
}

.rg-commingsoonlogo {
    width: auto;
    float: left
}

.rg-commingsoonlogo figure {
    margin: 0;
    width: 100%;
    float: left
}

.rg-commingsoonlogo figure a img {
    width: 100%;
    display: block;
    height: auto
}

.rg-commingrightbox .rg-socialiconssimple {
    width: 100%;
    margin: 4px 0;
    line-height: 22px
}

.rg-commingrightbox .rg-socialiconssimple li a i {
    color: #fff;
    font-size: 22px
}

.rg-commingrightbox {
    width: auto;
    float: right
}

.rg-commingsoonheader {
    width: 100%;
    float: left;
    padding: 50px
}

.rg-commingdetails {
    width: 100%;
    float: left;
    text-align: center
}

.rg-commingdetails .rg-title h3 {
    color: #fff;
    font-size: 50px;
    margin: 0 0 20px;
    line-height: 65px
}

.rg-commingdetails .rg-description p {
    color: #fff;
    font-size: 16px;
    line-height: 29px;
    margin: 0 0 3px
}

.rg-commingdetails figure {
    margin: 50px 0;
    width: 100%;
    float: left
}

.rg-commingdetails figure img {
    width: auto;
    display: block;
    height: auto;
    margin: 0 auto
}

.rg-commingsfooter {
    width: 100%;
    float: left;
    padding: 20px 0
}

.rg-commingsfooter .rg-description p,
.rg-footerlink span {
    margin: 0;
    color: #ddd;
    font-size: 14px;
    line-height: 22px;
    text-align: center
}

.rg-footerlink span i {
    font-size: 14px;
    margin: 0 8px 0 0
}

.rg-commingsfooter .rg-description {
    width: auto;
    float: left
}

.rg-commingsfooter .rg-description p a {
    color: #55acee;
    text-decoration: none
}

.rg-commingsfooter .rg-footerlink {
    width: auto;
    float: right;
    text-align: center
}

.rg-errorslink,
.rg-notfound,
.rg-notfoundtitl {
    width: 100%;
    float: left
}

.rg-commingsoonfooter {
    bottom: 0;
    position: fixed;
    background: #324b6e;
    border-top: 1px solid #8894a6
}

.rg-notfound {
    padding: 0 50px;
    text-align: center
}

.rg-notfoundtitle h3 {
    color: #f7f7f7;
    font-size: 120px;
    line-height: 75px;
    margin: 0 0 -43px
}

.rg-notfoundtitle h4 {
    margin: 0 0 23px;
    font: 300 46px/46px "Open Sans", Arial, Helvetica, sans-serif
}

.rg-notfound .rg-description p {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 46px
}

.rg-errorslink span {
    font-size: 14px;
    display: block;
    line-height: 14px
}

.rg-errorslink span a {
    color: #55acee;
    font-size: 14px;
    line-height: inherit
}

.rg-questsearch {
    width: auto;
    float: right
}

.rg-dashboardboxtitle .rg-questionsearch {
    width: auto;
    float: left
}

.rg-dashboardboxtitle .rg-questsearch {
    width: auto;
    float: right
}

.rg-dashboardboxtitle .rg-questsearch .rg-formtheme fieldset .form-group {
    margin: 0
}

.rg-dashboardboxtitle .rg-questsearch .rg-formtheme fieldset .rg-inputwithicon .form-control {
    height: 40px;
    color: #767676;
    font-size: 13px;
    width: 225px;
    margin: 0 45px 0 0;
    padding: 10px 30px
}

.rg-dashboardboxtitle .rg-questsearch .rg-formtheme fieldset .rg-inputwithicon i {
    top: 1px;
    left: 0;
    width: 40px;
    height: 40px;
    line-height: 40px
}

.rg-askquestiontitle,
.rg-astquestion,
.rg-dashboardtitles {
    width: 100%;
    float: left
}

.rg-dashboardtitles {
    padding: 20px 0;
    border-bottom: 1px solid #eff2f5
}

.rg-askquestiontitle {
    position: relative;
    padding: 0 13px
}

.rg-askquestiontitle:before {
    content: '';
    top: 3px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #b4b4b4;
    position: absolute
}

.rg-questionbtns {
    width: 100%;
    float: left
}

.rg-questionbtns .rg-btnreset {
    width: auto;
    margin: 0;
    color: #55acee;
    display: inline-block;
    padding: 12px 15px
}

.rg-questionbtns a {
    width: auto;
    float: left;
    padding: 0 12px;
    display: inline-block
}

.rg-questionbtns a:last-child {
    width: auto;
    float: right;
    color: #55acee;
    padding: 10px
}

.rg-questionbtns a:last-child span {
    color: #767676;
    font-size: 13px;
    padding: 0 7px 0 0;
    display: inline-block
}

.rg-askquestiontitle h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 5px
}

.rg-askquestiontitle span {
    display: block;
    font-size: 13px;
    line-height: 13px;
    color: #767676;
    margin: 0 0 22px
}

.rg-astquestion .rg-description p {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    margin: 0 0 15px
}

.rg-dashboardtitles:hover .rg-astquestion .rg-askquestiontitle h4 {
    color: #55acee
}

.rg-dashboardtitles:hover .rg-askquestiontitle:before {
    background-color: #55acee
}

.rg-questionsbox {
    width: 100%;
    float: left;
    padding: 20px;
    height: 700px
}

.rg-dashboardbox .rg-questionsbox .rg-dashboardtitles:first-child {
    padding-top: 0
}

.rg-countersoon {
    float: none;
    width: auto;
    overflow: hidden;
    margin: 0 -15px 30px
}

.rg-countersoon li {
    width: 25%;
    float: left;
    padding: 0 15px;
    text-align: center;
    list-style: none
}

.rg-countdowncontent {
    float: left;
    width: 100%;
    padding: 30px 0;
    background: #5b6f8b;
    border-radius: 5px;
    -webkit-border-radius: 5px
}

.rg-countersoon li span {
    color: #fff;
    display: block;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 3px
}

.rg-countersoon li span samp {
    display: block;
    font: 20px/20px Poppins, Arial, Helvetica, sans-serif;
    margin: 10px 0 0
}

.rg-countersoon li p {
    margin: 0;
    color: #fff;
    font-size: 20px;
    line-height: 24px
}

.rg-customnoti a em {
    color: #fc413c;
    font-size: 10px;
    font-weight: 700;
    padding: 0 0 0 5px;
    font-style: normal;
    display: inline-block;
    text-shadow: 0 0 2px #fff
}

.rg-addmargin {
    margin-top: 30px
}

.rg-rightarea .rg-nav .navbar-toggler {
    color: #fff;
    margin: 20px 0;
    padding: 8px 9px
}

.roz-home-nav {
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    transition: height .3s ease;
    height: 475px;
    position: relative;
    border: 1px solid #eff2f5
}

.jobscitypage,
.roz-search-job {
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.roz-home-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative
}

.roz-home-nav ul li {
    list-style: none
}

.roz-home-nav ul li a {
    display: block;
    background: #fff;
    padding: 8px 15px;
    color: #333;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    font-size: 15px;
    text-decoration: none;
    font-weight: 400;
    -webkit-transition: .2s linear;
    -moz-transition: .2s linear;
    -ms-transition: .2s linear;
    -o-transition: .2s linear;
    transition: .2s linear
}

.roz-home-nav ul li a:hover {
    background: #5e5e5e;
    color: #fff !important
}

.roz-home-nav ul li a.activetab:first-child {
    background: #5e5e5e;
    color: #fff;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px
}

.roz-home-nav ul li a .fa {
    width: 16px;
    padding-top: 8px;
    text-align: center;
    margin-right: 5px;
    float: right
}

.roz-home-nav ul li a i {
    float: initial !important;
    top: 2px;
    position: relative;
    float: initial !important
}

.roz-home-nav ul ul {
    background-color: #ebebeb
}

.roz-home-nav ul li ul li a {
    background: #f5f5f5;
    padding: 4.3px 20px;
    font-size: 14px
}

.roz-home-nav ul li ul li a:hover {
    background: #4c4c4c;
    color: #f9f9f9
}

.get-your-own-box,
.roz-search-job {
    width: 100%;
    background: #fff;
    position: relative;
    float: left
}

.roz-search-job {
    border-radius: 5px;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    margin-bottom: 20px
}

.roz-search-job .search-head {
    width: 100%;
    float: left;
    padding: 10px 20px;
    position: relative;
    border-bottom: 1px solid #eaedef
}

.roz-search-job .search-head h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0
}

.roz-search-job .search-head h1 span {
    color: #e81c28
}

.roz-search-job .search-head p {
    margin: 0;
    font-size: 16px
}

.roz-search-job .search-form-area {
    width: 100%;
    float: left;
    padding: 15px 20px;
    position: relative
}

.get-your-own-box {
    -webkit-box-shadow: 0 0 4px 0 rgb(0 0 0 / 3%);
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 4px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 6%)
}

.get-your-own-box .head-box {
    position: relative;
    padding: 10px 10px 18px;
    display: flex
}

.get-your-own-box .head-box .head-text h2 {
    font-size: 16px;
    color: #222;
    font-weight: 500
}

.get-your-own-box .head-box .ownprice {
    font-size: 15px;
    font-weight: 500
}

.get-your-own-box .head-box .ownprice del {
    color: #e81c28
}

.get-your-own-box .head-box .ownprice i {
    font-size: 12px
}

.get-your-own-box .head-box .ownprice a {
    color: #e81c28;
    border: 1px solid #e81c28;
    border-radius: 50px;
    padding: 2px 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
    height: 20px;
    display: block
}

.get-your-own-box .head-box .ownprice a:hover {
    color: #fff !important
}

.get-your-own-box a {
    color: #444 !important
}

.get-your-text-box {
    position: relative;
    display: flex;
    margin: 0 4px
}

.get-your-text-box .resume-box {
    background: #f9f9f9;
    margin: 0 6px;
    padding: 6px;
    border-bottom: 3px solid #008ab2;
    text-align: center;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 31.333%
}

.get-your-text-box .resume-box span {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ddf6f9;
    border-radius: 50px;
    margin: -18px auto 4px
}

.get-your-text-box .resume-box span i {
    color: #008ab2;
    font-size: 18px
}

.get-your-text-box .resume-box p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 8px 0
}

.get-your-text-box .per-box {
    background: #f9f9f9;
    margin: 0 6px;
    padding: 6px;
    border-bottom: 3px solid #ff9e1d;
    text-align: center;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 31.333%
}

.get-your-text-box .per-box span {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #fef6df;
    border-radius: 50px;
    margin: -18px auto 4px
}

.get-your-text-box .per-box span i {
    color: #ff9e1d;
    font-size: 18px
}

.get-your-text-box .per-box p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 8px 0
}

.get-your-text-box .linkdin-box {
    background: #f9f9f9;
    margin: 0 6px;
    padding: 6px;
    border-bottom: 3px solid #593e96;
    text-align: center;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 37.333%
}

.get-your-text-box .linkdin-box span {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #e2d4ff;
    border-radius: 50px;
    margin: -18px auto 4px
}

.get-your-text-box .linkdin-box span i {
    color: #593e96;
    font-size: 18px
}

.get-your-text-box .linkdin-box p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 8px 0
}

.get-your-text-box .astro-box {
    background: #f9f9f9;
    margin: 0 6px;
    padding: 6px;
    border-bottom: 3px solid #ee532c;
    text-align: center;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 32.333%
}

.get-your-text-box .astro-box span {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ffe6e0;
    border-radius: 50px;
    margin: -18px auto 4px
}

.get-your-text-box .astro-box span i {
    color: #ee532c;
    font-size: 18px
}

.get-your-text-box .astro-box p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 8px 0
}

.boxatoz {
    background: #fff1f2;
    position: relative;
    border-top: 1px solid #fff
}

.boxatozlist {
    position: relative
}

.boxatozlist ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between
}

.boxatozlist ul li {
    display: inline-block;
    border-right: 1px solid #fff
}

.boxatozlist ul li:last-child {
    border-right: 0 solid #fff
}

.boxatozlist ul li a {
    display: inline-block;
    padding: 2px 18px;
    font-size: 13px;
    font-weight: 500;
    color: #444;
    border-bottom: 3px solid #d7d7d7;
    border-radius: 0 0 4px 4px
}

.boxatozlist ul li a.active,
.boxatozlist ul li a:hover {
    background: #ffd8d8;
    border-bottom: 3px solid #ff5959
}

.slick-dots li button:before {
    font-size: 13px !important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #4c4c4c !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-dots li.slick-active button:before {
    color: #b1b1b1 !important
}

.slick-prev {
    left: -10px !important;
    z-index: 9;
}

.slick-next {
    right: 8px !important;
    z-index: 9;
}

.slick-next:before,
.slick-prev:before {
    color: #858585 !important;
    font-size: 24px;
}

.jobscitypage {
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.jobscitypage ul {
    margin: 0;
    padding: 0
}

.jobscitypage ul li {
    list-style: none;
    display: block
}

.jobscitypage ul li i {
    font-size: 18px;
    top: 2px;
    position: relative;
    right: 6px
}

.jobscitypage ul li a {
    background: #f9f9f9;
    width: 100%;
    display: block;
    color: #333;
    margin-bottom: 4px;
    padding: 4px 20px;
    border-radius: 2px;
    font-size: 14px
}

.jobscitypage ul li a:hover {
    background: #f3f3f3;
    color: #222
}

.roz-create-cv {
    width: 100%;
    float: left;
    position: relative;
    border-radius: 5px
}

.roz-create-cv .imgfree {
    text-align: center;
    position: relative
}

.roz-create-cv .imgfree img {
    max-width: 85px
}

.roz-create-cv .create-cv-bg {
    display: flex;
    margin-top: 2px;
    justify-content: space-between;
    background: #fff;
    padding: 6px 16px;
    align-items: flex-end;
    align-items: center;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    border: 1px solid #eff2f5
}

.roz-create-cv .create-cv-bg h4 {
    font-size: 20px;
    font-weight: 500;
    color: #222;
    margin-bottom: 0
}

.create-cv-bg .btn-cv i {
    margin-bottom: -25px;
    font-size: 30px;
    color: #000
}

.free-text {
    background: #eee;
    display: inline-block;
    border-radius: 3px;
    padding: 0 8px;
    font-weight: 600
}

.urgent-hiring-area {
    background: #fff;
    padding: 4px 0px 1px;
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    margin-bottom: 10px
}

.urgent-hiring-area .hiring-img {
    position: relative;
    width: 100%;
    padding: 8px;
    text-align: center
}

.urgent-hiring-area .hiring-img img {
    max-width: 100%
}

.urgent-hiring-area .immediate-joiners {
    position: relative;
    width: 100%;
    margin-top: -4px
}

.urgent-hiring-area .immediate-joiners::after,
.urgent-hiring-area .immediate-joiners::before {
    content: "";
    background: #c6c6c6;
    position: absolute;
    width: 28px;
    height: 28px;
    z-index: 0;
    transform: rotate(74deg);
    border-radius: 2px
}

.urgent-hiring-area .immediate-joiners h1 {
    color: #fff;
    font-size: 17px;
    font-weight: 700
}

.urgent-hiring-area .immediate-joiners::after {
    right: 110px;
    bottom: -4px
}

.urgent-hiring-area .immediate-joiners::before {
    left: 4px;
    bottom: 3px
}

.urgent-hiring-area .immediate-joiners a {
    display: inline-block;
    color: #fff;
    background: #e81c28;
    position: relative;
    padding: 2px 10px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    z-index: 2;
    animation: blinker 1s step-end infinite
}

@keyframes blinker {
    50% {
        opacity: 1;
        background: #000;
        color: #fff
    }
}

.roz-company-hiring {
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-top: 20px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%)
}

.roz-company-hiring .company-hiring {
    display: flex;
    justify-content: space-between;
    align-items: flex-start
}

.company-hiring-text {
    background: transparent;
    padding: 4px 20px 6px 10px;
    border-top-right-radius: 25px;
    border-top-left-radius: 4px
}

.company-hiring-text h3 {
    color: #222;
    font-size: 16px;
    font-weight: 500 !important;
    margin: 0
}

.company-hiring-view {
    background: #fff;
    padding: 4px 8px;
    color: #222;
    margin-top: 3px
}

.company-hiring-view a {
    line-height: 16px;
    height: 18px;
    display: block;
    border: 1px solid #e81c28;
    border-radius: 50px;
    padding: 0 6px;
    font-weight: 500;
    color: #e81c28
}

.company-hiring-view a:hover {
    background: #e81c28;
    color: #fff
}

.roz-company-hiring .company-hiring-logo {
    padding: 6px 10px 2px
}

.roz-company-hiring .company-hiring-logo a {
    border: 1px solid #eee;
    padding: 4px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 7px
}

.roz-company-hiring .company-hiring-logo img {
    width: 48.6px;
    height: 47px
}

.create-free-job-alert {
    padding: 20px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: snow;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.create-free-job-alert .create-free-job-box {
    position: relative;
    text-align: center;
    padding: 0 30px
}

.create-free-job-alert .create-free-job-box h3 {
    line-height: 18px;
    font-size: 17px;
    margin-bottom: 6px
}

.create-free-job-alert .create-free-job-box p {
    margin-bottom: 2px;
    line-height: 16px;
    font-size: 13px;
    color: #444
}

.no-reg-r {
    font-size: 12px;
    color: #999
}

.create-job-alert-btn {
    position: relative;
    text-align: center
}

.create-job-alert-btn a {
    color: #fff !important;
    background: #e81c28;
    border: 1px solid #e81c28;
    color: #fff !important;
    padding: 2px 8px;
    border-radius: 50px;
    margin: 4px 0 6px;
    box-shadow: inset 0 0 0 0 #e81c28;
    -webkit-transition: ease-out .4s;
    -moz-transition: ease-out .4s;
    transition: ease-out .4s
}

.create-job-alert-btn a:hover {
    background: #e81c28;
    color: #fff;
    box-shadow: inset 0 100px 0 0 #e81c28;
    display: inline-block
}

.create-job-alert-head {
    position: relative;
    padding-bottom: 10px
}

.create-job-alert-head h2 {
    font-size: 24px;
    font-weight: 400
}

.create-job-alert-left-box {
    position: relative;
    background: #f6f6f6;
    padding: 15px 20px;
    border-radius: 4px
}

.create-job-alert-point {
    padding: 0;
    margin: 0
}

.create-job-alert-point li.alerttabs {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #222;
    background: #ffeeef;
    padding-bottom: 3px;
    margin-bottom: 10px;
    border-radius: 4px
}

.create-job-alert-point li {
    display: block;
    padding-left: 35px;
    position: relative;
    line-height: 20px;
    padding-bottom: 20px;
    font-size: 14px;
    color: #666
}

.create-job-alert-point li i {
    font-size: 20px;
    width: 20px;
    left: 4px;
    top: 4px;
    position: absolute
}

.new-to-rozgar,
.re-freejob-alert,
.rfj-cv-line,
.why-rozgar-head {
    position: relative
}

.font-b-30 {
    font-size: 30px !important
}

.font-b-28 {
    font-size: 26px !important
}

.why-rozgar-job-alert {
    background: #fff;
    padding: 14px;
    border-radius: 4px 20px;
    margin-top: 20px
}

.why-rozgar-head {
    font-size: 20px
}

.why-rozgar-head i {
    font-size: 22px;
    padding-right: 5px
}

.why-rozgar-text {
    font-size: 14px;
    padding-top: 16px;
    color: #606060
}

.new-to-rozgar {
    border-radius: 2px;
    margin-top: 10px;
    padding: 20px;
    background: #ffedee;
    text-align: center
}

.re-freejob-alert a {
    color: #fff !important;
    color: #fff !important;
    display: inline-block;
    padding: 6px 20px;
    border-radius: 2px
}

.rfj-cv-line {
    background: #fff8f8;
    height: 2px;
    margin: 30px 0
}

.rfj-cv-line span {
    background: #fff;
    display: inline-block;
    padding: 2px 15px;
    left: 40%;
    top: -14px;
    position: absolute;
    font-weight: 600;
    border-radius: 40px
}

.cfja-blog-content,
.cfja-blog-content .faqhead,
.create-job-alert-form,
.freejob-alert-resume {
    position: relative
}

.freejob-alert-resume {
    margin-top: 20px
}

.freejob-alert-resume a {
    color: #fff !important;
    color: #fff !important;
    padding: 20px 50px;
    border-radius: 2px
}

.freejob-alert-resume a:hover {
    background: #323232
}

.freejob-alert-resume .head-resume {
    font-size: 20px
}

.create-alert-form-head {
    font-size: 17px;
    font-weight: 400;
    padding-bottom: 20px
}

.rg-create-job-alert-form {
    width: 100%;
    float: left;
    background: snow;
    padding: 20px;
    border-radius: 4px
}

.rg-create-job-alert-form .towform {
    display: flex
}

.rg-create-job-alert-form .towform .form-group:first-child {
    margin-right: 20px
}

.rg-create-job-alert-form .towform .form-group:last-child {
    margin-right: 0
}

.rg-create-job-alert-form .towform input,
.rg-create-job-alert-form .towform select {
    padding: 2px 8px
}

.create-job-alert-label {
    margin-bottom: 2px;
    font-size: 14px;
    color: #494949
}

.label-required {
    color: red;
    font-size: 12px
}

.create-free-jobs-alert-btn {
    text-align: right
}

.create-free-jobs-alert-btn a {
    color: #fff !important;
    background: #e81c28;
    padding: 10px 20px;
    color: #fff !important;
    border-radius: 2px;
    font-size: 16px
}

.create-free-jobs-alert-btn a:hover {
    color: #fff;
    background: #cc030f
}

.create-free-jobs-alert-content {
    position: relative;
    float: left;
    width: 100%
}

.create-free-jobs-alert-content .cfja-blog {
    width: 100%;
    padding: 20px 0
}

.create-free-jobs-alert-content .cfja-blog h2 {
    font-size: 18px;
    color: #444
}

.create-free-jobs-alert-content .cfja-blog p {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666
}

.cfja-blog-content .faqhead {
    font-size: 17px;
    color: #5f5f5f;
    font-weight: 500;
    background: #eee;
    padding: 10px 35px;
    border-radius: 50px;
    margin-top: 20px;
    margin-bottom: 6px
}

.cfja-blog-content .faqhead:before {
    left: 8px;
    top: 10px;
    color: #00cc67;
    content: '\f058';
    font: 22px FontAwesome;
    position: absolute
}

.cfja-blog-para-box {
    background: #f9f9f9;
    padding: 10px;
    margin-top: -5px;
    border-radius: 15px 15px 4px 4px
}

.cfja-blog-content p {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
    padding-left: 12px
}

.hire-company {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    position: absolute;
    bottom: -2%;
    left: 0;
    z-index: 0
}

.hire-company .hire-com-logo {
    position: relative
}

.hire-company .hire-com-logo img {
    max-width: 260px;
    padding: 10px 4px;
    margin-right: 8px;
    border-radius: 2px
}

.hire-company .hire-com-text {
    margin-top: -4px
}

.hire-company .hire-com-text h2 {
    font-size: 11px;
    margin-bottom: 2px
}

.hire-company .hire-com-text p {
    font-size: 11px;
    line-height: 1;
    margin-bottom: 6px
}

.hire-company .hire-com-text a {
    border: 1px solid #e81c28;
    border-radius: 50px;
    padding: 1px 6px;
    line-height: 1.1;
    display: inline-block;
    font-size: 12px
}

.roz-aside-jobs-by-location {
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-top: 20px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.roz-aside-jobs-by-location-box {
    padding: 12px 10px
}

.roz-aside-jobs-by-location-box h3 {
    font-size: 16px;
    padding-left: 3px
}

.roz-aside-jobs-by-location-box ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: auto;
    max-height: 800px
}

.roz-aside-jobs-by-location-box ul li {
    display: block;
    width: 100%
}

.roz-aside-jobs-by-location-box ul li a {
    color: #333;
    font-size: 13px;
    display: block;
    background: #f9f9f9;
    margin-bottom: 2px;
    padding-left: 10px
}

.roz-aside-jobs-by-location-box ul li a:hover {
    background: #f4f4f4
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #f9f9f9
}

.roz-aside-awards,
.roz-aside-review {
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    position: relative
}

#style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #f9f9f9
}

#style-3::-webkit-scrollbar-thumb {
    background-color: #eee
}

.roz-aside-review {
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-top: 20px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.roz-aside-review-box {
    padding: 12px 10px 0
}

.roz-aside-review-box h3 {
    font-size: 18px;
    padding-left: 3px
}

.roz-aside-reviewpoints-box {
    padding: 4px 10px 12px
}

.roz-aside-reviewpoints-box li {
    list-style: none;
    line-height: 1.3;
    padding-left: 3px
}

.roz-aside-reviewpoints-box li a {
    color: #333;
    font-size: 13px
}

.roz-aside-reviewpoints-box li a i {
    color: #f7a200;
    padding-right: 2px
}

.roz-aside-reviewpoints-box li span.pno {
    font-weight: 500;
    font-size: 14px;
    padding-right: 4px
}

.seoKeyword-section {
    display: flow-root;
    width: 100%
}

.roz-aside-reviewpoints-box li span {
    color: #777
}

.roz-aside-reviewpoints-box .viewall {
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    float: right;
    margin: 14px;
    background: 0 0;
    display: inline-block;
    border-radius: 50px;
    border: 2px solid #e81c28;
    padding: 0 10px 4px;
    line-height: 1
}

.roz-aside-reviewpoints-box .viewall:hover {
    background: #e81c28;
    color: #fff
}

.roz-aside-awards {
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-top: 20px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.roz-aside-awards-box {
    padding: 12px 10px 0
}

.roz-aside-awards-box h3 {
    font-size: 18px;
    padding-left: 3px
}

.roz-aside-awardspoints-box .awardsyears-list {
    padding: 12px 10px;
    display: flex;
    justify-content: flex-start
}

.roz-aside-awardspoints-box .awardsyears-list .awardsyears {
    width: 20%;
    font-weight: 500
}

.roz-aside-awardspoints-box .awardsyears-list .item {
    position: relative;
    z-index: 9
}

.roz-aside-awardspoints-box .awardsyears-list .item:before {
    color: #ffc3c7;
    content: "\25CF";
    font-size: 20px
}

.roz-aside-awardspoints-box .awardsyears-list .item:first-of-type:before {
    color: #6191ff
}

.roz-aside-awardspoints-box .awardsyears-list .item:last-of-type:after {
    display: none
}

.roz-aside-awardspoints-box .awardsyears-list .item:last-of-type .title {
    margin-bottom: 0
}

.roz-aside-awardspoints-box .awardsyears-list .desc {
    margin-left: 20px;
    margin-top: -25px;
    display: inline-block;
    vertical-align: text-top;
    width: calc(100% - 30px)
}

.roz-aside-awardspoints-box .awardsyears-list .year {
    margin-bottom: 6px;
    font-weight: 600;
    line-height: 18px
}

.roz-aside-awardspoints-box .awardsyears-list .title {
    margin-bottom: 10px;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.6
}

.roz-near-facilities-box {
    float: left;
    width: 100%;
    border: 1px solid #eff2f5;
    border-radius: 5px;
    margin-top: 20px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.roz-near-facilities-heading {
    box-shadow: none;
    position: relative;
    background: #fafafa;
    padding: 12px 13px 10px
}

.roz-postfreejob a,
.roz-postfreejob a:hover {
    color: #fff !important
}

.roz-near-facilities-heading h4 {
    font-size: 16px
}

.roz-near-facilities-body {
    border: 0;
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid #eff2f5
}

.roz-near-facilities-body .contentbox {
    width: 100%;
    float: left;
    padding: 12px
}

.roz-postfreejob {
    position: relative
}

.roz-postfreejob a {
    display: inline-block;
    padding: 10px 20px;
    width: 97%;
    font-size: 20px;
    border-radius: 3px;
    margin: 4px
}

.fxt-template-layout4 {
    min-height: 100vh
}

.fxt-template-layout4.savepoupop {
    min-height: 70vh
}

.fxt-template-layout4.savepoupop::before {
    background: 0 0 !important
}

.fxt-template-layout4:before {
    position: absolute;
    z-index: 0;
    background: url(../images/loginbg.jpg);
    content: "";
    min-height: 100%;
    width: 100vw;
    background-size: 20%;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .1
}

.fxt-template-layout4 .fxt-bg-wrap-reg {
    padding: 3vh 0 3vh 16vw;
    min-height: 100vh;
    position: relative;
    z-index: 1
}

.fxt-template-layout4 .fxt-bg-wrap {
    padding: 10vh 0 10vh 16vw;
    min-height: 100vh;
    position: relative;
    z-index: 1
}

@media only screen and (max-width:1199px) {
    .fxt-template-layout4 .fxt-bg-wrap {
        padding: 8vh 0 8vh 10vw
    }
}

@media only screen and (max-width:991px) {
    .fxt-template-layout4 .fxt-bg-wrap {
        padding: 6vh 0 6vh 6vw
    }
}

@media only screen and (max-width:767px) {
    .fxt-template-layout4 .fxt-bg-wrap {
        padding: 5vh 4vw
    }
}

.fxt-template-layout4 .fxt-checkbox-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.fxt-template-layout4 .fxt-bg-color.pupupbox {
    min-height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0
}

.fxt-template-layout4 .fxt-bg-color {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0
}

@media only screen and (max-width:991px) {
    .fxt-template-layout4 .fxt-bg-color {
        padding: 15px
    }
}

@media only screen and (max-width:767px) {
    .fxt-template-layout4 .fxt-bg-color {
        min-height: 100%;
        padding: 20px 30px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.fxt-template-layout4 .fxt-bg-img {
    padding: 40px 50px;
    min-height: 100%;
    background: no-repeat center center;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    z-index: 1;
    border-radius: 6px
}

.fxt-template-layout4 .fxt-bg-img:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgb(218 218 218 / 33%);
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    border-radius: 6px
}

.fxt-template-layout4 .fxt-bg-img:after {
    content: "";
    right: 0;
    top: 20%;
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 20px solid #fff;
    border-bottom: 15px solid transparent
}

.fxt-template-layout4 .fxt-header {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center
}

.fxt-template-layout4 .fxt-header .fxt-logo {
    display: block;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 12vw
}

.fxt-template-layout4 .fxt-header h1 {
    color: #222;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
    text-transform: none
}

.fxt-template-layout4 .fxt-content,
.fxt-template-layout4 .fxt-content-reg {
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    margin-right: -5px;
    margin-bottom: 10px;
    width: 100%
}

@media only screen and (max-width:991px) {
    .fxt-template-layout4 .fxt-bg-img {
        padding: 30px
    }

    .fxt-template-layout4 .fxt-header h1 {
        font-size: 30px
    }
}

@media only screen and (max-width:767px) {
    .fxt-template-layout4 .fxt-header h1 {
        font-size: 28px
    }
}

.fxt-template-layout4 .fxt-header p {
    color: #222;
    font-size: 25px;
    line-height: 1.2
}

.fxt-template-layout4 .fxt-content-reg {
    padding: 20px 30px 20px 40px;
    max-width: 580px
}

.fxt-template-layout4 .fxt-content-reg h4 {
    font-size: 22px;
    margin-left: -18px
}

.fxt-template-layout4 .fxt-content {
    padding: 20px 30px;
    max-width: 520px;
    margin-left: -5px
}

.fxt-template-layout4 .fxt-content h4 {
    font-size: 22px
}

@media only screen and (max-width:1199px) {

    .fxt-template-layout4 .fxt-content,
    .fxt-template-layout4 .fxt-content-reg {
        padding: 0
    }
}

.fxt-template-layout4 .fxt-form {
    margin-top: 20px
}

.fxt-template-layout4 .fxt-form .fxt-otp-logo {
    margin-bottom: 20px;
    display: block
}

.fxt-template-layout4 .fxt-form h2 {
    font-weight: 700;
    margin-bottom: 25px
}

.fxt-template-layout4 .fxt-form p {
    margin-bottom: 30px;
    font-size: 17px
}

.fxt-template-layout4 .fxt-form p span {
    display: block
}

.fxt-template-layout4 .fxt-form .fxt-otp-label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px
}

.fxt-template-layout4 .fxt-form .form-group {
    margin-bottom: 20px;
    position: inherit !important
}

.react-tel-input .country-list .country {
    padding: 0 9px;
    list-style: none
}

.fxt-template-layout4 .fxt-form .form-group.mb-12 {
    margin-bottom: 6px
}

.fxt-template-layout4 .fxt-form .form-group .input-label {
    margin-bottom: -2px;
    color: #2f2f2f;
    font-size: 13px;
    font-weight: 400
}

.fxt-template-layout4 .fxt-form .form-group .eyeicon,
.fxt-template-layout4 .fxt-form .form-group .field-icon {
    position: absolute;
    right: 24px;
    bottom: 14px;
    padding: 0 5px;
    color: #c5c5c5;
    font-size: 14px;
    z-index: 1
}

.fxt-template-layout4 .fxt-form .form-group .eyeicon {
    top: 50px
}

.fxt-template-layout4 .fxt-form .form-group .eyeicon:before,
.fxt-template-layout4 .fxt-form .form-group .field-icon:before {
    padding: 17px 10px
}

.fxt-template-layout4 .fxt-form .form-control {
    min-height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
    padding: 8px 30px 8px 10px;
    background: #f4f4f4;
    color: #111
}

.fxt-template-layout4 .fxt-form input::-webkit-input-placeholder {
    color: #a1a1a1;
    font-size: 13px;
    font-weight: 300
}

.fxt-template-layout4 .fxt-form input::-moz-placeholder {
    color: #a1a1a1;
    font-size: 13px;
    font-weight: 300
}

.fxt-template-layout4 .fxt-form input:-moz-placeholder {
    color: #a1a1a1;
    font-size: 13px;
    font-weight: 300
}

.fxt-template-layout4 .fxt-form input:-ms-input-placeholder {
    color: #a1a1a1;
    font-size: 13px;
    font-weight: 300
}

.fxt-template-layout4 .fxt-form .fxt-form-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px
}

.fxt-template-layout4 .fxt-form .fxt-form-row .fxt-form-col {
    margin-right: 10px;
    flex-basis: 0;
    flex-grow: 1
}

.fxt-template-layout4 .fxt-form .fxt-form-row .fxt-form-col:last-child {
    margin-right: 0
}

.fxt-template-layout4 .fxt-form .fxt-form-btn {
    margin-bottom: 20px
}

.fxt-template-layout4 .fxt-btn-fill {
    font: 500 20px Roboto, sans-serif;
    cursor: pointer;
    display: inline-block;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    border: 0;
    color: #fff;
    border-radius: 3px;
    background-color: #e81c28;
    padding: 8px 36px;
    margin-bottom: 10px;
    width: 100%;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.fxt-template-layout4 .fxt-btn-fill:hover {
    background-color: #de0b18;
    border-color: #de0b18
}

.fxt-template-layout4 .fxt-btn-fill:focus {
    outline: 0
}

.allfloatleft {
    width: 100%;
    float: left
}

.fxt-template-layout4 .fxt-style-line {
    text-align: center
}

.fxt-template-layout4 .fxt-style-line h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 30px;
    font-size: 14px;
    color: #a4a4a4;
    display: inline-block;
    position: relative;
    padding: 0 25px;
    z-index: 1
}

.fxt-template-layout4 .fxt-style-line h3:before {
    display: inline-block;
    content: "";
    height: 1px;
    width: 65%;
    background-color: #ebebeb;
    left: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    z-index: 1
}

.fxt-template-layout4 .fxt-style-line h3:after,
.fxt-template-layout4 .fxt-style-line h4:before {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    display: inline-block;
    height: 1px;
    width: 65%;
    background-color: #ebebeb;
    top: 50%;
    z-index: 1;
    content: ""
}

.fxt-template-layout4 .fxt-style-line h3:after {
    right: 100%;
    transform: translateY(-50%);
    position: absolute
}

.fxt-template-layout4 .fxt-style-line h4 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 16px;
    color: #a4a4a4;
    display: inline-block;
    position: relative;
    padding: 0 25px;
    z-index: 1
}

.fxt-template-layout4 .fxt-style-line h4:before {
    left: 100%;
    transform: translateY(-50%);
    position: absolute
}

.fxt-template-layout4 .fxt-style-line h4:after {
    display: inline-block;
    content: "";
    height: 1px;
    width: 65%;
    background-color: #ebebeb;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    z-index: 1
}

.fxt-template-layout4 ul.fxt-socials {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 10px
}

.fxt-template-layout4 ul.fxt-socials li {
    max-width: 100%;
    list-style: none;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0%;
    flex: 0 0 0%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px
}

@media only screen and (max-width:575px) {
    .fxt-template-layout4 ul.fxt-socials li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%
    }
}

@media only screen and (max-width:350px) {
    .fxt-template-layout4 ul.fxt-socials li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%
    }
}

.fxt-template-layout4 ul.fxt-socials li a {
    border-radius: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    height: 35px;
    color: #fff;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.fxt-template-layout4 ul.fxt-socials li a i {
    border-radius: 2px 0 0 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 45px;
    height: 35px
}

.fxt-template-layout4 ul.fxt-socials li a span {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.fxt-template-layout4 ul.fxt-socials li.fxt-facebook a {
    background-color: #3b5998
}

.employer-login-link {
    position: absolute;
    top: 6px;
    right: 1px
}

.employer-login-link a {
    background: #fff3f3;
    padding: 2px 10px;
    border-radius: 20px 0px 0px 20px;
    color: #e81c28
}

.regfacebook {
    color: #0008 !important;
    background: 0 0 !important;
    padding: 1px 4px;
    color: #0008 !important;
    height: 40px !important
}

.regfacebook:hover {
    border: 1px solid #d2e3fc !important;
    background: rgb(66 133 244 / 3%) !important
}

.fxt-template-layout4 ul.fxt-socials li.fxt-facebook a i {
    background-color: #4867aa
}

.fxt-template-layout4 ul.fxt-socials li.fxt-facebook a:hover {
    background-color: #5676bb
}

.fxt-template-layout4 ul.fxt-socials li.fxt-twitter a {
    background-color: #00acee
}

.fxt-template-layout4 ul.fxt-socials li.fxt-twitter a i {
    background-color: #3cf
}

.fxt-template-layout4 ul.fxt-socials li.fxt-twitter a:hover {
    background-color: #3dc5f3
}

.fxt-template-layout4 ul.fxt-socials li.fxt-google a {
    background-color: #c33
}

.fxt-template-layout4 ul.fxt-socials li.fxt-google a i {
    background-color: #db4437
}

.fxt-template-layout4 ul.fxt-socials li.fxt-google a:hover {
    background-color: #e75042
}

.fxt-template-layout4 .switcher-text {
    color: #004c87;
    font-size: 15px;
    margin-bottom: 5px;
    display: block;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.fxt-template-layout4 .switcher-text:hover {
    color: #000
}

.fxt-template-layout4 .switcher-text2 {
    color: #004c87;
    font-size: 16px;
    display: inline-block;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.fxt-template-layout4 .switcher-text2.inline-text {
    margin-left: 3px
}

.fxt-template-layout4 .switcher-text2:hover {
    color: #000
}

.fxt-template-layout4 .checkbox {
    padding-left: 5px;
    margin-right: 30px;
    margin-bottom: 5px
}

.fxt-template-layout4 .checkbox label {
    padding-left: 20px;
    color: #616161;
    margin-bottom: 0;
    font-size: 15px;
    position: relative
}

.fxt-template-layout4 .checkbox label:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 0;
    margin-left: -5px;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border .15s ease-in-out, color .15s ease-in-out;
    -o-transition: border .15s ease-in-out, color .15s ease-in-out;
    transition: border .15s ease-in-out, color .15s ease-in-out
}

.fxt-template-layout4 .checkbox label:after {
    position: absolute;
    margin-left: -20px;
    padding-left: 3px;
    font-size: 10px;
    color: #555
}

.fxt-template-layout4 .checkbox input[type=checkbox] {
    display: none
}

.fxt-template-layout4 .checkbox input[type=checkbox]:checked+label::after {
    font-family: 'FontAwesome';
    content: "\f00c";
    font-weight: 600;
    color: #fff;
    left: 15px;
    top: 4px
}

.fxt-template-layout4 .checkbox input[type=checkbox]:checked+label::before {
    background-color: #a29696;
    border-color: #a29696
}

.fxt-template-layout4 .fxt-footer {
    text-align: center
}

.fxt-template-layout4 .fxt-footer p {
    color: #999898;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center
}

.fxt-template-layout4 .fxt-footer .fxt-btn-resend {
    margin-left: 3px;
    box-shadow: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    color: #63bbff;
    text-decoration: underline;
    transition: all .3s ease-in-out
}

.fxt-template-layout4 .fxt-footer .fxt-btn-resend:focus {
    outline: 0
}

.fxt-template-layout4 .fxt-footer .fxt-btn-resend:hover {
    color: #000
}

.fxt-template-layout4 .fxt-footer .text-or {
    margin-left: 3px;
    color: #000
}

.otpimg {
    background: #ccc;
    display: inline-block;
    padding: 18px;
    border-radius: 200px
}

.otpimg img {
    max-width: 60px
}

.opthead {
    text-align: center;
    justify-content: space-around
}

.opthead h5 {
    font-size: 26px
}

.toppoup {
    max-width: 500px;
    text-align: center
}

.form-group .otpnum {
    height: 34px;
    width: 37px;
    margin-right: 6px
}

select::-webkit-input-placeholder {
    color: #767676
}

select:-moz-placeholder {
    color: #767676
}

select::-moz-placeholder {
    color: #767676
}

select:-ms-input-placeholder {
    color: #767676
}

select::-ms-input-placeholder {
    color: #767676
}

.registerList {
    list-style: none;
    max-width: 360px;
    margin: 4px auto 0;
    box-shadow: 0 0 6px #eee;
    border-radius: 4px;
    background: #fff;
    padding: 16px 10px
}

.registerList li {
    color: #333;
    list-style: none;
    position: relative;
    padding-left: 25px;
    line-height: 1.2;
    font-size: 13px;
    text-align: left;
    margin-bottom: 12px
}

.registerList li:before {
    font: 10px/16px FontAwesome;
    position: absolute;
    left: 0;
    top: 1px;
    color: #fff;
    background: #3898a0;
    border-radius: 50px;
    width: 16px;
    height: 16px;
    text-align: center
}

.registerList li.rlist:before {
    content: "\f00c"
}

.registerList li:hover:before {
    color: #f9f9f9
}

.blogcategoryscroll {
    padding: 10px;
    max-height: 270px;
    overflow: auto;
    color: #f9f9f9;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto
}

.blogcategoryscroll::-webkit-scrollbar {
    width: 8px
}

.rg-inputtyfile,
.rg-inputtyfile label,
.rg-inputtypefile {
    width: 100%;
    float: left
}

.blogcategoryscroll::-webkit-scrollbar-track {
    background-color: rgba(128, 137, 150, .1);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px
}

.blogcategoryscroll::-webkit-scrollbar-thumb {
    background-color: rgba(128, 137, 150, .2);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px
}

.blogcategoryscroll::-webkit-scrollbar-thumb:hover {
    background-color: rgba(128, 137, 150, .4)
}

.rg-inputtypefile .rg-inputtyfile {
    padding: 20px;
    background: #fcfcfc
}

.rg-inputtypefile .rg-inputtyfile label {
    margin: 0
}

.rg-tabimgpopupcontent {
    float: left;
    width: 100%;
    margin: 8px 0 0
}

.rg-tabimgpopupcontent span {
    margin: 8px 0;
    color: #324b6e;
    font-size: 16px;
    line-height: 16px
}

.rg-basicformholder .rg-inputtypefile .rg-inputtyfile {
    border-radius: 0 0 4px 4px
}

.rg-basicformholder .rg-inputtypefile {
    border-top: 1px solid #eff2f5
}

.rg-reportissue {
    margin: 0 0 30px
}

.rg-inputtyfile label input[type=file] {
    display: block;
    padding-left: 14%
}

.rg-inputtyfile label {
    cursor: pointer;
    padding: 10px 40px;
    min-height: 120px;
    text-align: center;
    border-radius: 14px;
    border: 2px dashed #ddd;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center
}

.rg-inputtyfile label i {
    color: #b4b4b4;
    font-size: 30px;
    margin: 0 0 6px;
    line-height: 30px
}

.rg-inputtyfile label span {
    color: #767676;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 4px;
    line-height: 16px
}

.rg-inputtyfile label span a {
    color: #55acee
}

.rg-inputtyfile label em {
    margin: 0;
    color: #767676;
    line-height: 20px;
    font-style: normal
}

.repostproblembox {
    background: #f2f2f2;
    padding: 20px;
    border-radius: 2px
}

.reporproblem {
    position: relative;
    padding: 40px 20px;
    background: #fff8f8;
    border-radius: 4px;
    margin-bottom: 20px
}

.reporproblem h3 span {
    color: red;
    font-size: 26px
}

.rsc-cs {
    padding: 7px 7px !important;
    border-radius: 25px !important;
    background-color: #fafafa !important
}

.rsc-header {
    background-color: #e81c28 !important
}

.rsc-header-title {
    color: #efefef !important
}

.rsc-os-option-element {
    border: 1px solid #e81c28 !important;
    color: #e81c28 !important;
    padding: 1px 10px 10px !important;
    background-color: #fff !important;
    font-weight: 700 !important
}

.rsc-container {
    box-shadow: 0 14px 26px 0 #888 !important;
    height: 380px !important
}

.rsc-ts-bubble {
    padding: 5px 10px 10px !important;
    line-height: 18px !important
}

.chatboxfooter {
    position: fixed;
    right: 10px;
    bottom: 10%;
    z-index: 9
}

.chatboxmsg {
    position: fixed;
    right: 78px;
    z-index: 999;
    bottom: 12%
}

.bot {
    bottom: 0;
    right: 0;
    margin: 10px;
    position: fixed;
    width: 350px;
    z-index: 99
}

.discalaimerbox {
    position: relative;
    width: 100%;
    float: left;
    background-color: #383838;
    border-top: 0 solid #383838;
}

.discalaimerbox p {
    font-size: 11px;
    color: #b6b6b6;
    margin: 0;
    padding: 10px 0
}

.discalaimerbox p span {
    font-weight: 500;
}

.discalaimerbox p a {
    color: #b6b6b6;
    text-decoration: underline;
}

.discalaimerbox p a:hover {
    color: #e81c28;
    text-decoration: underline;
}

.btn {
    background: #4c4c4c !important;
    height: 60px;
    border-radius: 50px;
    background-color: #4c4c4c !important;
    position: relative
}

.rbt.form-control {
    z-index: auto !important
}

.rbt-input-multi.form-control {
    padding: 0;
    border: none;
    height: auto;
    padding-right: 0px;
    min-width: 126px;
}

.rbt-input-multi.form-control input {
    height: auto !important
}

.rbt .dropdown-menu {
    z-index: 9
}

.rbt-input-wrapper {
    display: -webkit-inline-box;
    align-items: center
}

.rbt-input-wrapper .rbt-token {
    display: flex !important;
    align-items: center;
    outline: 0;
    line-height: 17px;


}

.rbt-input-wrapper .close {
    position: relative;
    right: 0;
    top: auto;
    line-height: 2px
}

.rbt-input-wrapper button::after,
.rbt-input-wrapper button::before {
    display: none
}

#keyword {
    width: 90% !important;
    margin-left: 0 !important;
    top: 6px !important
}

.rbt .rbt-menu.dropdown-menu.show {
    width: 100% !important;
    margin-left: 0 !important;
    top: 6px !important
}

#ITSkills {
    padding: 0 0 !important;
    top: -2px !important
}

#ITSkills .dropdown-item {
    display: block;
    width: 100%;
    padding: 0 1.5rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    font-size: 13px
}

#container1 .rbt-menu.dropdown-menu {
    width: 100% !important;
    margin-left: -43px !important;
    top: 6px !important
}

.dropdown-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, .3);
    background-color: #ccc;
    border-radius: 8px
}

.dropdown-menu::-webkit-scrollbar {
    width: 6px;
    border-radius: 8px;
    background-color: #ccc
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 8px
}

.rg-searchholder .chosen-container .chosen-results::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, .3);
    background-color: #ccc;
    border-radius: 8px
}

.rg-searchholder .chosen-container .chosen-results::-webkit-scrollbar {
    width: 6px;
    border-radius: 8px;
    background-color: #ccc
}

.rg-searchholder .chosen-container .chosen-results::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 8px
}

.rozgar-section-title {
    padding-bottom: 20px;
    margin-bottom: 30px
}

.rozgar-section-title h1 {
    font-size: 38px;
    color: #fff;
    font-weight: 520;
    margin-bottom: 0
}

.rozgar-section-title h2 {
    font-size: 38px;
    color: #333;
    font-weight: 520;
    margin-bottom: 0
}

.rozgar-section-title p {
    color: #06122c;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    margin: 0
}

.rozgar-recruiter-aboutus {
    background: #1f98ff;
    padding: 60px 0 0;
    float: left;
    width: 100%
}

.rozgar-recruiter-aboutus .section-title {
    text-align: left;
    margin-bottom: 40px
}

.rozgar-recruiter-aboutus-entry p {
    color: #fff;
    font-size: 18px;
    line-height: 1.5em;
    font-weight: 300;
    margin-bottom: 1.5em
}

.rozgar-recruiter-aboutus-image {
    padding-top: 0;
    margin-top: -40px;
    text-align: right
}

.related-product-info .btn-booknow,
.rozgar-astrology-profile-info .btn-booknow,
.rozgar-linkdin-profile-info .btn-booknow {
    margin-top: 10px
}

.successmailbox {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    position: relative
}

.related-product::before,
.rozgar-linkdin-profile::before {
    position: absolute;
    height: 100%;
    content: ''
}

.emialboxcontent {
    border-radius: 4px;
    width: 40%;
    background: #fff;
    padding: 60px 20px;
    text-align: center;
    box-shadow: 0 6px 30px #ccc
}

.related-product-image img,
.rozgar-linkdin-profile-image img {
    border-radius: 2px 30px 4px 20px
}

.SimilarCompanies-Box,
.ctas-btn-medium {
    box-shadow: 0 2px 6px -2px rgb(0 106 194 / 20%)
}

.emialboxcontent img {
    margin-bottom: 20px
}

.emialboxcontent h2 {
    text-align: center;
    font-size: 25px
}

.related-product {
    padding: 0;
    float: left;
    width: 100%;
    background: #fff;
    margin-bottom: 0
}

.related-product::before {
    width: 100%;
    background: rgb(255 255 255 / 70%)
}

.related-single {
    padding: 40px 0 60px
}

.related-product-info {
    padding-top: 60px
}

.related-product-info h3 {
    font-size: 38px;
    color: #06122c;
    font-weight: 520;
    margin-bottom: .4em
}

.related-product-info p {
    font-size: 18px;
    color: #000;
    font-weight: 300;
    margin-bottom: 1.5em;
    line-height: 1.4
}

.related-product-image {
    max-width: 100%
}

.section-title {
    padding-bottom: 20px;
    margin: -6px auto 0;
    max-width: 820px;
    text-align: center
}

.rozgar-linkdin-profile {
    padding: 0;
    float: left;
    width: 100%;
    background: #f9f9f9
}

.rozgar-linkdin-profile::before {
    width: 100%;
    background: 0 0
}

.rozgar-linkdin-single {
    margin-bottom: 80px
}

.rozgar-linkdin-profile-info {
    padding-top: 50px
}

.rozgar-linkdin-profile-info h3 {
    font-size: 38px;
    color: #fff;
    font-weight: 520;
    margin-bottom: .5em
}

.rozgar-linkdin-profile-info p {
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 1.5em;
    line-height: 1.4
}

.rozgar-linkdin-profile-image {
    max-width: 80%;
    padding-top: 3%
}

.rozgar-linkdin-profile-title {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 60px
}

.rozgar-astrology-profile {
    padding: 0;
    float: left;
    width: 100%;
    background: #f9f9f9;
    position: relative
}

.rozgar-astrology-profile::before {
    height: 100%;
    width: 100%;
    background: 0 0;
    content: '';
    position: absolute
}

.rozgar-astrology-single {
    margin-bottom: 80px
}

.rozgar-astrology-profile-info {
    padding-top: 30px
}

.rozgar-astrology-profile-info h3 {
    font-size: 38px;
    color: #fff;
    font-weight: 520;
    margin-bottom: .5em
}

.rozgar-astrology-profile-info h4 {
    font-size: 30px;
    color: #fff;
    font-weight: 520;
    margin-bottom: 1em
}

.rozgar-astrology-profile-info p {
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 1.5em;
    line-height: 1.4
}

.rozgar-astrology-profile-image {
    max-width: 70%;
    padding-top: 5%
}

.rozgar-astrology-profile-title {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 60px
}

.btn-astrology,
.btn-booknow,
.btn-booknowblack,
.btn-booknowred {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px 8px;
    margin-top: 20px;
    transition: all .3s ease-in-out
}

.btn-booknow {
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 40px
}

.btn-booknow:hover {
    color: #fff;
    background: #0a5da5
}

.btn-booknowred {
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 40px
}

.btn-booknowred:hover {
    color: #fff;
    background: rgb(0 0 0 / 48%)
}

.btn-booknowblack {
    background: 0 0;
    color: #4c4c4c;
    border: 1px solid #4c4c4c;
    border-radius: 40px
}

.btn-booknowblack:hover {
    color: #fff;
    background: #4c4c4c
}

.btn-astrology {
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 40px
}

.btn-astrology:hover {
    color: #fff;
    background: #e81c28;
    border: 1px solid #e81c28
}

.interview-questions-company {
    display: flow-root;
    float: left;
    width: 100%
}

.company-logo-box {
    width: 90px;
    height: 90px;
    padding: 5px;
    margin: 0 auto 10px;
    border: 1px solid #eff2f5;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    -ms-flex-pack: center;
    float: left
}

.companyReviews {
    display: inline-flex;
    align-items: center;
    line-height: 1.33;
    white-space: nowrap;
    vertical-align: text-bottom;
    margin: 0 0 .625rem
}

.star-reviews-bx {
    margin-right: .5rem;
    padding: .21875rem .5rem;
    border-radius: .25rem;
    box-sizing: border-box;
    background: #f0ad4e;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0
}

.companytotalReviews {
    color: #767676;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 0;
    font-size: 12px
}

.aboutCompanytotal {
    font-weight: 500;
    margin-bottom: .5625rem;
    color: #767676
}

.company-info-box {
    width: 89%;
    float: left;
    padding: 0 20px
}

.company-services-provider ul {
    list-style: none
}

.company-services-provider ul li {
    display: inline-block;
    margin-right: 5px;
    font-style: normal;
    font-weight: 500;
    color: #303233;
    padding: 0 10px;
    border-radius: 20px;
    background: #ebebeb
}

.company-services-provider ul li:last-child {
    background: 0 0
}

.company-services-provider ul li a {
    font-weight: 600
}

.headquarters-header span {
    font-weight: 600;
    color: #767676
}

.aboutCom-Main {
    width: 80%;
    float: left
}

.follow-button-bx .follow-btn {
    color: #e81c28 !important;
    font-weight: 600 !important;
    padding: 8px 16px;
    border-radius: 50px;
    text-decoration: none;
    width: 100%;
    display: block;
    text-align: center
}

.right-section-interview-company {
    width: 20%;
    float: left;
    display: flow-root;
    padding-top: 40px
}

.follow-button-bx {
    display: flow-root;
    width: 100%;
    float: left
}

.Share-interviews-button-bx {
    display: flow-root;
    width: 100%;
    float: left;
    margin-top: 10px
}

.ctas-btn-medium {
    padding: 7px 14px;
    min-width: 120px;
    cursor: pointer;
    background-color: #e81c28;
    border: 1px solid #e81c28;
    color: #fff;
    border-radius: 30px;
    outline: 0;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 600
}

.companyNameBox h3 {
    font-weight: 700;
    letter-spacing: normal;
    color: #1e223c;
    text-transform: uppercase
}

.company-header-main {
    display: flow-root;
    margin-top: 2.5rem;
    float: left;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 15%)
}

.interviw-tab-company ul li {
    list-style: none;
    display: inline-block
}

.interviw-tab-company ul li a {
    padding: 0 15px;
    color: #303233;
    display: block;
    font-size: 14px;
    position: relative;
    line-height: inherit
}

.interviw-tab-company ul li a.active {
    box-shadow: inset 0 -.15rem 0 0 #e81c28
}

.headquarters-header {
    margin-top: 15px;
    margin-bottom: 0
}

.interviw-tab-company ul {
    border-bottom: 1px solid #eff2f5;
    background: #f6f6f6;
    margin-top: 2px
}

.PopularJoblistBox ul li {
    list-style: none;
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 5px
}

.PopularJoblistBox ul li a {
    padding: .45rem .75rem;
    border-radius: 14px;
    background: #fff;
    border: 1px solid #e4e6ee;
    color: #303233
}

.SectionDetailsBox,
.company-interview-section {
    display: flow-root;
    width: 100%;
    float: left
}

.PopularJoblistBox {
    width: 67%;
    float: left;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 15%)
}

.simple-header {
    margin-top: 20px;
    margin-bottom: 20px
}

.PopularJoblistBox h4 {
    margin-bottom: 20px;
    font-weight: 600
}

.filter-box-popular .menu-item-has-children,
.userNamepara {
    margin-bottom: 0
}

.PopularJoblistBox ul li a:hover {
    background: #ffeaec;
    border-color: #ffeaec
}

.search-designation-box .rozgar-jobbylocsearchcontent input {
    border-radius: 30px;
    height: 50px
}

.search-designation-box .rozgar-jobbylocsearchcontent .form-group i {
    font-size: 16px;
    margin-top: 3px
}

.search-designation-box .rozgar-jobbylocsearchcontent {
    margin-top: 30px
}

.search-designation-box {
    display: flow-root;
    width: 100%;
    float: left
}

.totalcountinterviews {
    display: flow-root;
    width: 100%;
    float: left;
    margin-top: 25px
}

.cont-filter-box .total-count-interview {
    font-weight: 600;
    font-size: 14px
}

.cont-filter-box .employezone:hover .sub-menu {
    top: 108%;
    opacity: 1;
    visibility: visible
}

.filter-box-popular {
    float: right
}

.filter-box-popular .rg-popular-filter {
    padding: 4px 15px;
    border-radius: 14px;
    background: #fff;
    border: 1px solid #e4e6ee;
    font-weight: 500
}

.filter-box-popular .rg-popular-filter i {
    margin-left: 5px
}

.filter-box-popular .rg-popular-filter .sub-menu {
    padding: 15px
}

.filter-box-popular .menu-item-has-children a {
    padding: 5px 15px;
    border: none;
    border-radius: 0;
    font-weight: 500
}

.rg-popular-filter {
    border: 1px solid #e4e6ee !important;
    border-radius: 15px !important
}

.posted-date-box {
    display: inline-block;
    float: left;
    width: 100%;
    margin-top: 15px
}

.posted-date-box p {
    font-size: .775rem;
    color: #767676;
    line-height: 1.43;
    font-weight: 600
}

.userNamepara {
    position: relative;
    padding-left: 35px;
    padding-top: 3px;
    font-weight: 600;
    color: #767676
}

.userNamepara:after {
    font: 11px/21px Linearicons-Free !important;
    color: #e81c28;
    content: '\e82a';
    position: absolute;
    font-family: "Linearicons-Free!important";
    background: #d5d5d5;
    padding: 2px;
    border-radius: 30px;
    height: 25px;
    width: 25px;
    text-align: center
}

.interview-content-wrap,
.interviewProcessBox {
    display: block;
    width: 100%;
    float: left
}

.interviewProcessBox {
    margin-top: 30px
}

.interviewProcessBox .main-had-qa {
    background: #f5f5f5;
    padding: 10px 5px 10px 15px;
    margin-left: -20px;
    width: 90%;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    font-size: 14px;
    border-left: 3px solid #e81c28
}

.list-interview-box li {
    cursor: default;
    background: #f5f5f5;
    border: none;
    border-radius: .875rem;
    font-size: .875rem;
    color: #303233;
    padding: .25rem .75rem;
    line-height: 1.25rem;
    height: 1.75rem;
    box-sizing: border-box;
    font-weight: 400;
    align-items: center;
    justify-content: center
}

.list-interview-box {
    margin-top: 20px
}

.QuestionsTechnicalRound .round-item {
    display: flex;
    margin-top: 1.5rem
}

.QuestionsTechnicalRound .round-item .round-box {
    border: 1px solid #f6f6f6;
    border-radius: .1875rem;
    min-width: 2.875rem;
    height: 2.875rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #97a0be;
    font-size: .625rem;
    line-height: .9375rem;
    margin-right: .75rem;
    background: #f6f6f6
}

.QuestionsTechnicalRound .round-item .round-box .count {
    font-size: 1.1875rem;
    margin-bottom: 4px
}

.QuestionnuberBox .body-medium h5 {
    font-size: .875rem;
    line-height: 1.43;
    margin-bottom: 0
}

.QuestionnuberBox .answer-body-medium h6 {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    margin-bottom: 0;
    font-size: .775rem
}

.QuestionnuberBox .answer-body-medium h6 span i {
    color: #e81c28;
    font-weight: 600;
    margin-left: 5px;
    padding-top: 4px;
    line-height: 26px
}

.round-item .Questions-box {
    font-size: .875rem;
    line-height: 20px;
    margin-bottom: 10px
}

.Questions-box .QuestionnuberBox {
    margin-bottom: 15px
}

.Questions-box h4 {
    font-size: .875rem;
    line-height: 20px;
    margin-bottom: 5px
}

.interviewPreparationList li {
    display: block !important;
    margin-bottom: 0 !important;
    position: relative;
    padding-left: 15px;
    line-height: 21px;
    font-size: 12px
}

.interviewPreparationList {
    padding-left: 15px;
    margin-bottom: 5px
}

.interviewPreparationList li:before {
    position: absolute;
    content: '';
    background: red;
    width: 5px;
    height: 5px;
    top: 8px;
    left: 0
}

.interviewPreparationBox {
    display: block;
    float: left
}

.interviewPreparation-had {
    margin-top: 10px;
    font-size: .875rem;
    line-height: 20px
}

.company-interview-right-section {
    margin-left: 30px;
    float: left;
    width: 30%
}

.SimilarCompanies-Box {
    border-radius: 6px;
    position: relative;
    border: 1px solid #ebf0f6;
    background-color: #fff;
    box-sizing: border-box;
    padding: 1.5rem 1.25rem;
    display: flow-root;
    margin-bottom: 20px
}

.SimilarCompanies-Box h5 {
    font-size: 1rem;
    font-weight: 600
}

.SimilarCompanies-item .SimilarCompanies-img {
    float: left;
    width: 3rem;
    height: 3rem;
    border-radius: 3px;
    -o-object-fit: contain;
    object-fit: contain;
    border: .8px solid #f0f0f0;
    text-align: center;
    line-height: 3rem;
    margin-right: 10px
}

.SimilarCompanies-item {
    display: block;
    width: 100%;
    float: left;
    margin: 5px 0
}

.SimilarCompanies-item .SimilarCompaniesContent h4 {
    font-size: .875rem;
    line-height: 1.43;
    margin-bottom: 4px;
    font-weight: 500;
    color: #1e223c
}

.SimilarCompanies-item .SimilarCompaniesContent .star-reviews-bx {
    margin-right: .5rem;
    padding: .11875rem .5rem;
    border-radius: .25rem;
    box-sizing: border-box;
    background: #f0ad4e;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0
}

.SimilarCompanies-item .SimilarCompaniesContent .companytotalReviews {
    font-size: .75rem;
    line-height: 1.33;
    font-weight: 500;
    color: #767676
}

.viewallbox a {
    padding: 5px 25px;
    color: #e81c28;
    font-weight: 600;
    background-color: #f5f5f5;
    display: inline-block;
    border-radius: 27px
}

.SimilarCompaniesContent .companytotaladdress {
    margin-bottom: 0
}

.SimilarCompaniesContent .infojobSimilarCompanies ul li {
    list-style: none;
    margin-right: 20px;
    color: #5e6b92
}

.SimilarCompaniesContent .infojobSimilarCompanies ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between
}

.SimilarCompaniesContent.jobCompaniesContent {
    float: left
}

.SimilarCompaniesContent.jobCompaniesContent .companyReviews {
    margin: 0
}

.SimilarCompanies-Box.JobsatCompanies-Box .SimilarCompanies-item {
    border-bottom: 1px solid #f1f1f1;
    padding: 10px 0
}

.SimilarCompanies-Box .viewallbox,
.SimilarCompanies-Box.JobsatCompanies-Box .viewallbox {
    display: block;
    width: 100%;
    float: left;
    margin: 20px 0 5px
}

.simple-header.company-header h3 {
    font-size: 1.3rem;
    font-weight: 600
}

.interview-content-wrap .interview-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 13px;
    border-bottom: 1px solid #222;
    display: inline-block
}

.companyNameBox h3 img {
    width: 26px;
    margin-top: -10px;
    margin-left: 5px
}

.campus-placements-img {
    font-size: 20px;
    background: #f0f0f0;
    color: #737373
}

.own-personal-recruiter-price span {
    color: #007114;
    font-weight: 500;
    clip-path: ellipse(65% 100% at 70% 50%);
    font-size: 32px;
    display: inline-block;
    background: #fff;
    padding: 2px 20px;
    border-radius: 0
}

.rozgar-interviewShare {
    position: relative
}

.inter-process-round-head h5 {
    font-weight: 500;
    font-size: 18px;
    background: #ffeff7;
    padding: 2px 20px;
    border-radius: 53px;
    display: inline-block
}

.interview-round-chips-list input,
.interviewShare-form-box .field input {
    padding: 10px 20px
}

.interviewsformbox,
.interviewsformbox-left-box {
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 30px;
    border: 1px solid #f4f4f4
}

.interview-round-chips-list select {
    padding: 10px 20px;
    width: 45%;
    margin-bottom: 10px;
    background: 0 0;
    font-weight: 500
}

.addanshr {
    position: absolute;
    top: 45px;
    right: 28px
}

.addanshr a {
    color: #2899cc
}

.tob-Bling-box h5 img {
    width: 40px;
    padding: 6px 0
}

.own-personal-recruiter p {
    margin-bottom: 8px;
    line-height: 18px;
    text-align: justify
}

.own-personal-recruiter p.packageoff {
    color: #07ad00;
    font-weight: 400;
    font-size: 14px;
    background: #fff;
    display: inline-block;
    padding: 2px 2px 2px 6px;
    border-radius: 2px
}

.own-personal-recruiter p.packageoff span {
    margin-left: 20px;
    padding: 1px 6px;
    border-radius: 50px;
    font-weight: 500;
    background: #000;
    color: #3aff00
}

.own-personal-recruiter-price {
    height: 85%;
    background: #fff;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between
}

.own-personal-recruiter-price span del {
    font-size: 26px;
    font-weight: 300;
    color: #e81c28
}

.own-personal-recruiter-price span del i {
    font-size: 14px
}

.rozgar-interviewShare:before {
    position: absolute;
    z-index: 0;
    background: url(../images/loginbg.jpg);
    content: '';
    min-height: 100%;
    width: 100vw;
    background-size: 20%;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .1
}

.featured-icon-box.style4.benefits-box.height-box,
.rg-enquiryrvtworep.rg-hed-section {
    min-height: 240px
}

.interviewShare-form-box .field input[type=text] {
    width: 100%;
    height: 45px;
    background: 0 0
}

.interviewShare-form-box .field textarea {
    width: 100%;
    padding-left: 10px;
    height: 120px;
    background: 0 0
}

.interview-round-chips-list .ab_chip {
    border-radius: 20px;
    background: 0 0;
    border: 1px solid #e4e6ee;
    display: inline-flex;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: .25rem 1.25rem;
    height: auto;
    font-size: .875rem
}

.tob-Bling-box {
    padding: 0 0 0 20px;
    background: #fdf5f5;
    margin-bottom: 2.0625rem;
    margin-top: -30px;
    margin-left: -32px;
    width: 108.5%;
    border-radius: 10px 10px 0 0
}

.tob-Bling-box h5 {
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.5rem;
    color: #404040
}

.interviewShare-form-box .field label {
    margin-bottom: 1px;
    color: #2f2f2f;
    font-size: 13px;
    font-weight: 400
}

.tob-Bling-box h5 span {
    color: #000;
    font-weight: 600
}

.tob-Bling-box h5 span i {
    background: #ff9298;
    padding: 10px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 10px;
    color: #fff;
    margin-right: 5px
}

.inter-process-heading {
    margin-top: 2.0625rem
}

.inter-process-round-head {
    margin-top: 1.0625rem;
    margin-bottom: 2.0625rem
}

.field-textarea {
    margin-top: 2.0625rem;
    margin-bottom: 2.0625rem
}

.interviewsformbox {
    background: #fff
}

.interviewsformbox-left-box {
    background: #f4f4f4;
    height: 650px;
    position: relative
}

.interviewsformbox-left-box .interview-titles-bx {
    font-size: 28px;
    text-align: center;
    margin-top: 30px;
    z-index: 9;
    position: relative
}

.sharintervtext {
    font-size: 25px;
    background: #ffe5e5;
    padding: 0 10px;
    border-radius: 50px
}

.other-input-bx input {
    border: none;
    height: 25px;
    width: 90px;
    text-align: center;
    background: 0 0
}

.other-input-bx input::placeholder {
    color: #000
}

.lablecheckbox {
    display: flex;
    align-items: center
}

.lablecheckbox input {
    margin-right: 5px
}

.interview-Submit-btn {
    padding: 10px 14px;
    min-width: 120px;
    cursor: pointer;
    box-shadow: 0 2px 6px -2px rgb(222 11 24);
    background-color: #de0b18;
    border: 1px solid #de0b18;
    color: #fff;
    border-radius: 24px;
    font-size: 16px;
    margin-top: 30px;
    width: 50%
}

.add-Answer-container,
.questions-top-designations-Box {
    box-shadow: 0 2px 6px -2px rgb(0 106 194 / 20%);
    box-sizing: border-box
}

.field-para-interview {
    margin-top: 30px
}

.inter-process-heading h5 img {
    width: 20px;
    margin-bottom: 7px;
    margin-left: 2px
}

.job-seekers-lable label {
    font-size: 16px !important;
    font-weight: 500 !important
}

.job-seekers-lable label img {
    width: 20px;
    margin-bottom: 5px
}

.interviewsformbox-left-box::before {
    content: "";
    position: absolute;
    width: 310px;
    height: 290px;
    left: 0;
    top: 0 !important;
    transform: translateX(0);
    right: initial;
    transition: 2s ease-in-out;
    background: #feeff1;
    border-radius: 0 50% 50% 0;
    z-index: 6
}

.delete-btn {
    float: right;
    color: #aaa
}

.checkbox-final-round {
    margin-top: 6px;
    margin-bottom: 10px
}

.list-box.list-box-Companies {
    display: block;
    float: left;
    width: 100%;
    position: relative;
    margin-top: 20px
}

.list-box.list-box-Companies h4 {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px
}

.list-box.list-box-Companies li a {
    min-width: 80px;
    display: block;
    text-align: center;
    border-radius: 30px;
    height: 40px;
    line-height: 25px
}

.rg-company-name-box {
    float: left;
    display: flex;
    width: 100%;
    align-items: center
}

.rg-company-name-box .company-icon-box {
    border-radius: .3125rem;
    margin-right: .75rem;
    border: 1px solid #f0f0f0;
    -o-object-fit: contain;
    object-fit: contain;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center
}

.rg-company-name-box .company-text-box a {
    font-size: 1rem;
    color: #1e223c;
    line-height: normal;
    margin-bottom: .0625rem;
    cursor: pointer
}

.rg-company-name-box .company-text-box a span {
    font-weight: 500
}

.card-company-interviews {
    display: block;
    float: left;
    width: 100%;
    border: 1px solid #ebf0f6;
    margin-top: 30px;
    padding: 20px;
    border-radius: 3px
}

.more-item-box,
.pagination-box #pagination {
    margin-top: 20px
}

.answer-link-view,
.detail-links-box a {
    border: none !important;
    padding: 0 0 !important;
    background: 0 0 !important
}

.detail-links-box a {
    color: #e81c28 !important
}

.detail-links-box a i {
    font-weight: 600
}

.answer-link-view {
    color: #3b5999 !important
}

.data-wrapper ul li p {
    margin-bottom: 0;
    font-size: .875rem;
    line-height: 1.43
}

.pagination-box {
    display: block;
    width: 100%;
    float: left;
    text-align: center
}

.pagination-box #pagination li a {
    border-radius: 5px
}

.pagination-box #pagination li .active {
    background-color: #e81c28;
    color: #fff;
    border: none
}

.questions-top-designations-Box {
    border-radius: 6px;
    position: relative;
    border: 1px solid #ebf0f6;
    background-color: #fff;
    padding: 1.5rem 1.25rem;
    display: flow-root;
    margin-bottom: 20px
}

.questions-top-designations-Box ul li {
    list-style: none;
    margin-bottom: 15px;
    display: inline-block;
    margin-right: 5px
}

.questions-top-designations-Box ul li a {
    padding: .45rem .75rem;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #e4e6ee;
    color: #303233
}

.questions-top-designations-Box h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 15px
}

.more-item-box a {
    padding: 5px 25px;
    color: #e81c28;
    font-weight: 600;
    background-color: #f5f5f5;
    display: inline-block;
    border-radius: 27px
}

.breadcrumb2 ul li .arrow,
.breadcrumb2 ul li a,
.hastags .hastag-item {
    color: #9e9e9e;
    font-weight: 600
}

.company-info-box.skillsinfo-box {
    padding-left: 0
}

.company-info-box.skillsinfo-box .companyNameBox h3 {
    text-transform: inherit
}

.breadcrumb2 ul li {
    list-style: none;
    display: inline-block
}

.add-Answer-container {
    margin-top: 40px;
    background: #fff;
    border: 1px solid #ebf0f6;
    border-radius: .25rem;
    padding: 1rem
}

.breadcrumb2 ul li .arrow {
    margin-left: 5px;
    margin-right: 5px
}

.question-info-box {
    display: block;
    margin-top: 15px
}

.question-info-box p {
    margin-bottom: 0;
    font-size: .875rem;
    line-height: 1.43
}

.hastags .hastag-item {
    font-size: .75rem;
    line-height: 1.5;
    margin-right: 5px
}

.hastags {
    margin-top: .875rem
}

.review_quality-box {
    margin-top: 30px;
    display: flex;
    justify-content: space-between
}

.review_quality-box .want-answer-btn {
    border: 1px solid #e81c28;
    color: #e81c28;
    background-color: #fff;
    padding: .4375rem 1.25rem;
    border-radius: .2rem;
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.43
}

.review_misc-answer {
    display: flex;
    align-items: center
}

.review_misc-answer .review_views {
    font-weight: 500;
    font-size: .75rem;
    line-height: 15px;
    letter-spacing: .6px;
    color: #666;
    margin-right: 40px
}

.report-interview-bx a {
    font-size: 20px;
    font-weight: 600
}

.report-interview-bx {
    margin-right: 40px
}

.answer-text {
    font-size: .8888rem;
    line-height: 18px;
    color: #222;
    font-weight: 700;
    margin-top: 2.25rem
}

.hr_margin {
    border: 0;
    border-top: 1px solid #dfe0e3;
    margin: 1rem 0 1.5rem;
    box-shadow: none;
    width: 100%
}

.add-question-box textarea {
    height: 100px;
    width: 100%;
    background: #f7f7f7
}

.add-question-box {
    margin-top: 30px
}

.add-question-box .submit-wrap button {
    outline: 0;
    padding: .55rem 2.75rem;
    font-size: 1rem;
    cursor: pointer;
    background-color: #e81c28;
    color: #fff;
    box-shadow: 0 2px 6px -2px rgb(0 106 194 / 20%);
    border: 1px solid #ebf0f6;
    border-radius: .2rem;
    font-weight: 500;
    margin-right: .75rem
}

.submit-wrap .d-inline-flex {
    margin-left: 15px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center
}

.submit-wrap .d-inline-flex label {
    margin-bottom: 0;
    margin-left: 5px;
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: 500;
    font-size: .875rem;
    line-height: 17px;
    color: #ababab;
    display: inline-flex;
    align-items: center
}

.add-question-box label {
    font-size: .855rem;
    font-weight: 600;
    color: #222;
    line-height: 1.43
}

.answers .answer .img img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%
}

.answers .answer {
    display: flex
}

.answers .answer .contant {
    margin-left: .875rem;
    width: 100%
}

.answers .answer .contant .header .bio .intro-box {
    line-height: 18px;
    display: flex;
    align-items: center;
    font-size: .875rem;
    font-weight: 600;
    color: #222
}

.actions ul li,
.all-jobs-tabbed ul li {
    display: inline-block;
    list-style: none
}

.answers .answer .contant .header .bio .meta-posted span {
    font-size: .75rem;
    color: #ababab;
    font-weight: 600;
    line-height: 13px;
    margin-top: .25rem;
    margin-bottom: .25rem
}

.actions ul li a {
    cursor: pointer;
    box-shadow: none;
    background: 0 0;
    padding: 0;
    margin-left: 1.25rem;
    font-size: .875rem;
    color: #5e6b92
}

.actions ul li:first-child a {
    margin-left: 0
}

.sliced-answer-text p {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0
}

.salary_calculator_wrapper {
    padding: 24px 16px;
    border-radius: 6px;
    box-shadow: 0 2px 6px -2px rgb(0 106 194 / 20%);
    border: 1px solid #ebf0f6;
    background-color: #fff;
    height: 220px
}

.right-aside-calculator_wrapper {
    margin-top: 40px
}

.salary_calculator-form input {
    width: 100%;
    padding-left: 15px;
    border-radius: 1.5rem
}

.salary_calculator-form {
    position: relative
}

.salary_calculator-form .sal-continue-btn {
    position: absolute;
    right: 2px;
    height: 36px;
    background-color: #e81c28;
    border: 1px solid #e81c28;
    border-radius: 1.5rem;
    outline: 0;
    padding: 7px 14px;
    box-sizing: border-box;
    min-width: 88px;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    font-weight: 700;
    line-height: 1.43;
    font-size: .875rem;
    top: 2px
}

.brows-by-locations-bx,
.brows-jobs-companies {
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    margin-top: 10px
}

.salary_calculator_wrapper h5 {
    font-size: 1rem;
    font-weight: 700;
    line-height: .94;
    letter-spacing: normal;
    color: #1e223c;
    margin-bottom: 20px
}

.all-jobs-tabbed ul li {
    border-right: 1px solid #e7e7e7
}

.all-jobs-tabbed ul li a {
    padding: 10px 15px;
    display: block;
    background: #f7f7f7;
    color: #000;
    position: relative
}

.all-jobs-tabbed ul li:last-child {
    border-right: 0
}

.all-jobs-tabbed ul {
    text-align: center
}

.all-jobs-tabbed ul li.active a {
    background: #fff
}

.all-jobs-tabbed ul li:first-child {
    border-right: 0;
    font-weight: 600;
    margin-right: 10px
}

.hoz-location-bx ul li {
    list-style: none;
    width: 15%;
    text-align: center;
    float: left;
    background: #fff;
    margin-right: 15px;
    padding: 5px 20px;
    border-radius: 5px;
    position: relative
}

.hoz-location-bx ul li:last-child {
    margin-right: 0
}

.hoz-location-bx ul li a img {
    width: 100%;
    margin-bottom: 15px
}

.hoz-location-bx ul li h5 {
    font-size: 14px;
    font-weight: 400;
    color: #666
}

.hoz-location-bx {
    display: flow-root;
    position: relative
}

.brows-by-locations-bx {
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-bottom: 10px
}

.by-locations-head {
    padding: 15px 20px;
    font-weight: 300;
    border-bottom: 1px solid #f3f3f3;
    z-index: 9;
    position: relative;
    margin-bottom: 0
}

.by-locations-head h2 {
    font-size: 14px;
    color: #333;
    margin-bottom: 0
}

.hoz-location-bx ul {
    display: flow-root;
    padding: 20px 15px 25px
}

.hoz-location-bx ul li a {
    position: relative
}

.rozgar-browseJobs {
    display: flow-root;
    width: 100%;
    float: left;
    position: relative
}

.jobsbylocation-top.jobsbylocation-list li:first-child {
    color: #fff;
    margin-right: 7px;
    font-size: 14px;
    font-weight: 600
}

.images-box-location {
    border-radius: 50%;
    width: 130px;
    height: 130px;
    background: #ebebeb;
    padding: 7px;
    margin-bottom: 10px;
    position: relative
}

.images-box-location img {
    border-radius: 50%
}

.functional-area-list li {
    list-style: none;
    display: inline-block;
    width: 25%
}

.brows-jobs-companies,
.job-maincontaine-row .brows-by-locations-bx {
    width: 100%;
    float: left
}

.functional-area-list li a {
    color: #666;
    position: relative;
    padding-left: 15px
}

.functional-area-list {
    padding: 10px 20px;
    margin-bottom: 15px
}

.functional-area-list li a:before {
    color: #048189;
    content: "\f105";
    font: 600 12px FontAwesome;
    left: 0;
    position: absolute;
    top: 2px
}

.view-all-box {
    text-align: right;
    padding: 0 20px 10px;
    margin-bottom: 5px
}

.view-all-box a {
    color: #e81c28 !important;
    padding: 5px 20px;
    background: 0 0;
    margin-bottom: 5px;
    border-radius: 30px;
    border: 1px solid #e81c28
}

.job-maincontaine-row .brows-by-locations-bx:first-child {
    margin-right: 15px
}

.brows-jobs-companies {
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-bottom: 20px;
    display: flow-root
}

.companies-brows-list ul li {
    list-style: none;
    width: 17%;
    float: left;
    margin: 8px 18px;
    text-align: center
}

.companies-brows-list ul {
    padding: 10px;
    margin-bottom: 18px;
    display: flow-root
}

.companies-brows-list ul li a img {
    border: 1px solid #ededed;
    border-radius: 3px;
    padding: 5px 15px
}

.companies-brows-list ul li:last-child {
    margin-right: 0
}

.companies-brows-list {
    display: flow-root;
    padding-bottom: 0
}

.companies-brows-list ul li a h6 {
    margin-bottom: 0;
    margin-top: 10px;
    color: #666
}

.companies-brows-list ul li a {
    display: flow-root
}

.resume-writing-box {
    text-align: center;
    margin-top: 30px;
}

.create-your-job-section {
    padding: 60px 0;
    display: flow-root;
    float: left;
    width: 100%;
    background: #f5f5f5
}

.create-your-job-section .resume-img-box {
    background: #1f98ff;
    border-radius: 5px 5px 0 0;
    height: 290px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px
}

.create-your-job-section .resume-text-box {
    padding: 10px 10px 16px;
    border: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
    background: #fff
}

.create-your-job-section .resume-text-box h5 {
    margin-bottom: 0;
    font-size: 20px
}

.own-personal-recruiter h4,
.rozgar-recruiter-blog-section .rozgar-section-blog-title h4 {
    font-size: 22px;
    margin-bottom: 0;
    font-weight: 500
}

.resume-text-box .btn-booknow {
    color: #1f98ff !important;
    border: 1px solid #1f98ff;
    margin-top: 10px;
    padding: 5px 30px 6px
}

.resume-writing-box.personal-job-search-bx .resume-text-box .btn-booknowred {
    color: #ff3569 !important;
    border: 1px solid #ff3569;
    color: #ff3569 !important
}

.resume-writing-box.personal-job-search-bx .resume-img-box {
    background: #fc88a6
}

.resume-writing-box.linkdin-profile .resume-img-box {
    background: #ffe7c5
}

.resume-writing-box.linkdin-profile .btn-booknowblack {
    margin-top: 10px;
    padding: 5px 30px 6px;
    color: #ff9400;
    border: 1px solid #ff9400
}

.resume-writing-box.about-astrology-box .resume-img-box {
    background: #e57c52
}

.resume-writing-box.about-astrology-box .btn-astrology {
    color: #872a88 !important;
    color: #872a88 !important;
    border: 1px solid #872a88
}

.resume-writing-box.about-astrology-box .btn-astrology:hover {
    color: #fff !important;
    background: #872a88
}

.resume-writing-box.linkdin-profile .btn-booknowblack:hover {
    background: #ff9400;
    color: #fff
}

.resume-writing-box.personal-job-search-bx .resume-text-box .btn-booknowred:hover {
    color: #fff !important
}

.resume-text-box .btn-booknow:hover {
    color: #fff !important
}

.own-personal-recruiter {
    text-align: left;
    padding-bottom: 30px
}

.rozgar-recruiter-blog-section {
    display: flow-root;
    float: left;
    width: 100%;
    background: #f5f5f5;
    padding: 50px 0
}

.own-personal-recruiter h4:before {
    position: absolute;
    content: '';
    width: 80px;
    height: 1px;
    left: 1.5%;
    bottom: 24px;
    background: #7e7f80
}

.dez-media,
.dez-post-media,
.rozgar-recruiter-blog-section .blog-post,
.rozgar-recruiter-blog-section .dez-box,
.rozgar-recruiter-blog-section .dez-info,
.rozgar-recruiter-blog-section .dez-tilte,
.rozgar-recruiter-blog-section .dez-tilte-inner,
.rozgar-recruiter-blog-section.dez-media {
    position: relative
}

.own-personal-recruiter-price li {
    list-style: none
}

.own-personal-recruiter-price span i {
    font-size: 16px
}

.rozgar-recruiter-blog-section .radius-sm {
    border-radius: 4px;
    -webkit-border-radius: 4px
}

.rozgar-recruiter-blog-section .dez-img-effect {
    position: relative;
    overflow: hidden;
    display: block;
    vertical-align: middle
}

.dez-post-media img,
.rozgar-recruiter-blog-section .dez-media img {
    width: 100%;
    height: auto
}

.rozgar-recruiter-blog-section .dez-img-effect img {
    display: block;
    margin: 0;
    width: 100%;
    box-shadow: 0 0 0 rgb(0 0 0 / 0);
    -webkit-box-shadow: 0 0 0 rgb(0 0 0 / 0);
    -moz-box-shadow: 0 0 0 transparent;
    transition: all .25s;
    -moz-transition: all .25s;
    -webkit-transition: all .25s;
    -o-transition: all .25s;
    height: 300px;
    object-fit: cover
}

.rozgar-recruiter-blog-section .blog-style-1 .dez-post-meta {
    margin-bottom: 10px;
    padding: 25px 5px 0
}

.rozgar-recruiter-blog-section .dez-post-meta ul {
    margin: 0 -4px;
    list-style: none;
    text-transform: capitalize
}

.rg-hed-section:before {
    background: linear-gradient(110deg, #000a11 50%, #000a11 50%);
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(110deg, rgb(0 0 0 / 63%) 50%, rgb(0 0 0 / 63%) 50%)
}

.rozgar-recruiter-blog-section .dez-post-meta ul li {
    padding: 0;
    display: inline-block;
    color: #707070;
    font-weight: 500;
    font-size: 13px
}

.rozgar-recruiter-blog-section .dez-post-meta i {
    color: #707070;
    margin: 0 5px 0 0;
    font-size: 15px;
    font-weight: 500
}

.rozgar-recruiter-blog-section .dez-post-title {
    margin-bottom: 5px
}

.rozgar-recruiter-blog-section .blog-style-1 .dez-post-title .post-title {
    margin-top: 0
}

.rozgar-recruiter-blog-section .dez-post-title .post-title {
    margin-top: 5px;
    margin-bottom: 10px;
    line-height: 25px
}

.rozgar-recruiter-blog-section .dez-post-text {
    margin-bottom: 10px
}

.rozgar-recruiter-blog-section .dez-post-text p:last-child {
    margin: 0
}

.rozgar-recruiter-blog-section .dez-info>div:last-child,
.rozgar-recruiter-blog-section .dez-info>p:last-child {
    margin-bottom: 0
}

.rozgar-recruiter-blog-section .blog-share {
    position: relative
}

.rozgar-recruiter-blog-section .site-button-link {
    background: rgba(0, 0, 0, 0);
    border: 0;
    display: inline-block;
    font-weight: 400
}

.rozgar-recruiter-blog-section .fw6 {
    font-weight: 600
}

.rozgar-recruiter-blog-section .blog-style-recuiter {
    background: #fff
}

.blogheadline:before,
.rozgar-section-blog-title h4:before {
    width: 80px;
    bottom: -14px;
    background: #7e7f80;
    content: '';
    left: 0
}

.rozgar-recruiter-blog-section .blog-style-recuiter .dez-info {
    padding: 10px 15px 20px
}

.blogheadline:before {
    position: absolute;
    height: 2px
}

.rozgar-section-blog-title {
    padding-bottom: 24px;
    margin-bottom: 20px
}

.rozgar-section-blog-title h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    margin-bottom: 20px
}

.rozgar-section-blog-title h4:before {
    position: absolute;
    height: 1px
}

.rozgar-recruiter-faq-section {
    display: flow-root;
    float: left;
    width: 100%;
    padding: 50px 0
}

.dez-post-meta .post-date img {
    width: 30px;
    background: #ddd;
    padding: 0;
    border-radius: 30px
}

.dez-post-meta .post-date {
    font-size: 16px !important
}

.dez-info .dez-post-meta {
    padding-top: 10px;
    padding-bottom: 10px
}

.rozgar-recruiter-faq-section .rozgar-section-blog-title h4 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0
}

.jobsbylocation-top.jobsbylocation-list {
    margin-bottom: -39px
}

.page-header-content h4 {
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    line-height: 40px;
    margin: 0 0 10px
}

.page-header-content p {
    font-size: 16px;
    color: #fff
}

.page-header-content {
    text-align: left;
    padding-top: 60px
}

.main-head-box {
    margin-bottom: 25px;
    text-align: center
}

.main-head-box .feature-line {
    width: 80px;
    height: 2px;
    position: relative;
    background: #f60;
    margin: 5px 0;
    display: inline-block
}

.feature-line .animate-bar {
    height: 100%;
    width: 10px;
    left: -5px;
    top: 0;
    background: #fff;
    position: absolute;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: moveLeftRight;
    animation-name: moveLeftRight
}

.usp-main-item {
    display: flex
}

.usp-item-box.color-one {
    border-top: 10px solid #ce041a;
    border-bottom: 10px solid #ce041a
}

.usp-main-item .usp-item-box {
    background: #fff;
    padding: 35px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 10px 50px rgb(0 0 0 / 7%);
    box-shadow: 0 10px 50px rgb(0 0 0 / 7%);
    margin-left: 15px;
    margin-right: 15px;
    width: 33.3%;
    border-radius: 10px 10px;
    text-align: center
}

.usp-item-box .icon-img {
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    color: #f60;
    font-size: 30px;
    padding-top: 3px;
    width: 100px;
    height: 100px;
    text-align: center
}

.tt-topic-list .tt-item,
.tt-topic-list .tt-list-header {
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal
}

.usp-item-box .icon-img img {
    width: 100%
}

.usp-item-box h4 {
    color: #000;
    font-weight: 800;
    margin-bottom: 0;
    line-height: 23px
}

.usp-item-box p {
    line-height: 25px;
    font-size: 16px
}

.usp-item-box.color-two {
    border-top: 10px solid #d8d8d8;
    border-bottom: 10px solid #d8d8d8
}

.usp-item-box.color-three {
    border-top: 10px solid #000;
    border-bottom: 10px solid #000
}

.rozgar-distinguishing-expertise {
    padding: 50px 0
}

.img-radius20 {
    border-radius: 20px;
}

.lheight24 {
    line-height: 24px;
}

.feature-line {
    width: 80px;
    height: 2px;
    position: relative;
    background: #f60;
    margin: 10px 0;
    display: inline-block
}

.color-two .feature-line {
    background: #2f97f7
}

.color-three .feature-line {
    background: #12b571
}

@keyframes moveLeftRight {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px)
    }
}

@-webkit-keyframes zoomInOut {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.secrtion-bg-gray {
    background: #f5f5f5
}

.pddding-top-btm {
    padding-top: 50px;
    padding-bottom: 50px
}

.one-side-image-bx img {
    max-width: 100%;
    border-radius: 6px;
}

.main-head-box h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 30px;
    color: #000;
    font-weight: 600
}

.content-page-box p {
    line-height: 25px;
    font-size: 16px;
    margin-bottom: 5px
}

.content-page-box ul.list-offer li {
    line-height: 25px;
    font-size: 16px;
    position: relative;
    display: flex;
    margin-bottom: 10px
}

.content-page-box ul.list-offer li:before {
    font: 700 20px FontAwesome;
    content: "\f058";
    margin-right: 20px;
    color: red;
    margin-top: 2px;
}

.cmt-row {
    padding: 50px 0
}

.section-title.style2,
.section-title.style3 {
    margin-bottom: 10px
}

.section-title.style2 .title-desc,
.section-title.style2 .title-header,
.section-title.style3 .title-desc,
.section-title.style3 .title-header {
    vertical-align: middle
}

.featured-icon-box.style4.benefits-box {
    border: 1px solid rgba(102, 102, 102, .24);
    padding: 20px 30px;
    transition: all .5s ease 0s;
    border-radius: 5px;
    min-height: 195px;
    background: #fff;
    box-shadow: 0 6px 25px rgb(0 0 0 / 7%)
}

.faq .card .card-header,
.featured-content .cmt-icon {
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s
}

.featured-content .cmt-icon {
    position: absolute;
    z-index: 1;
    top: -1px;
    right: -1px;
    font-size: 17px;
    font-weight: 700;
    width: 50px;
    height: 50px;
    border: 0;
    border-radius: 0 5px 0 50px;
    -ms-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    background-color: #e81c28;
    text-align: center;
    line-height: 44px;
    color: #fff;
    padding-left: 0
}

.featured-icon-box.icon-align-before-content.style4 .featured-title h3 {
    margin-bottom: 5px;
    margin-top: 0;
    color: #ce041a;
}

.featured-icon-box.icon-align-before-content.style4 .featured-title h3 a {
    color: #ce041a
}

.featured-icon-box.icon-align-before-content .featured-content .featured-desc p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 22px
}

.featured-icon-box {
    position: relative;
    margin: 15px 0
}

.featured-icon-box.style4.benefits-box .featured-content .number {
    position: absolute;
    content: "";
    top: 7px;
    right: 12px;
    color: #f3f3f3;
    z-index: -1;
    font-size: 25px;
    line-height: 1;
    font-weight: 700
}

.benefits-box .featured-content .number.number-one:after {
    content: '1'
}

.benefits-box .featured-content .number.number-two:after {
    content: '2'
}

.benefits-box .featured-content .number.number-three:after {
    content: '3'
}

.benefits-box .featured-content .number.number-four:after {
    content: '4'
}

.benefits-box .featured-content .number.number-five:after {
    content: '5'
}

.benefits-box .featured-content .number.number-six:after {
    content: '6'
}

.container .number:after {
    counter-increment: container;
    content: counter(container, decimal-leading-zero);
    font-family: var(--base-bodyfont);
    font-style: normal
}

.section-title.style2 h4.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 0;
    margin-top: 0
}

.section-title.style2 {
    text-align: left
}

.featured-icon-box.style4.staffing-height {
    height: 250px
}

.featured-icon-box.style4 {
    border: 1px solid rgba(102, 102, 102, .24);
    padding: 30px;
    transition: all .5s ease 0s;
    height: 170px;
    background: #fff;
    box-shadow: 0 6px 25px rgb(0 0 0 / 7%);
    border-radius: 10px;
}

.content-page-box .main-head-box {
    text-align: left;
    margin-bottom: 10px
}

#eyepassicon {
    position: relative !important
}

.answers,
.question {
    position: relative;
    background: #f5f5f5
}

.centerplease {
    margin: 0 auto;
    max-width: 270px;
    font-size: 40px
}

.question {
    color: #000;
    margin: 0 0 3px;
    padding: 15px 10px 15px 50px;
    display: block;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500
}

.answers {
    font-weight: 300;
    padding: 0 15px;
    margin: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    opacity: 0;
    -webkit-transition: .7s ease;
    -moz-transition: .7s ease;
    -o-transition: .7s ease;
    transition: .7s ease
}

.questions:checked~.answers {
    height: auto;
    opacity: 1;
    padding: 15px
}

.plus {
    color: red;
    position: absolute;
    margin-left: 10px;
    margin-top: 15px;
    z-index: 5;
    font-size: 2em;
    line-height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease
}

.questions:checked~.plus {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.questions {
    display: none
}

.fill-time-hr-section .featured-icon-box.style4 {
    height: 170px
}

.fill-time-hr-section .featured-icon-box.style4.benefits-box.height-box {
    min-height: 190px
}

.hrms-content-bx h5 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 5px
}

.bg-gray {
    background: #f5f5f5
}

.title-header.title-header-two {
    text-align: center;
    display: block !important
}

.title-header.title-header-two h5 {
    font-size: 24px
}

.innerServiceEnquiry-box .rg-companycontent {
    text-align: center;
    padding-bottom: 20px !important
}

.innerServiceEnquiry-box .rg-candidatesdetails {
    margin: auto
}

.innerServiceEnquiry-box {
    background: #f5f5f5
}

.innerServiceEnquiry-box .rg-main .rg-jobapplycentervthree .rg-companycontent h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 30px;
    color: #000;
    font-weight: 600
}

.dsplay-b {
    display: block;
    width: 100%;
    float: left
}

.discussions-title h5 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 15px
}

.discussions-title {
    text-align: center
}

.discussion-Form-section {
    display: flow-root;
    float: left;
    width: 100%;
    position: relative;
    padding-top: 50px
}

.tt-topic-list .tt-list-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    color: #303344;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .01em
}

.tt-topic-list .tt-list-header>[class^=tt-col] {
    padding: 20px 8px 22px
}

.tt-topic-list .tt-list-header .tt-col-topic {
    width: 50%;
    -webkit-box-flex: 2;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto
}

.tt-topic-list .tt-list-header .tt-col-category {
    width: 16.66667%
}

.tt-topic-list .tt-list-header .tt-col-value {
    width: 11.4%
}

.tt-topic-list .tt-topic-alert.tt-alert-default {
    background-color: #d1e4f9;
    border-left: 2px solid #e81c28
}

.tt-topic-list .tt-topic-alert {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: .01em;
    padding: 6px 6px 7px 123px;
    width: 100%
}

.tt-topic-list .tt-item.tt-itemselect {
    background-color: #fff9fa;
    border-bottom: 1px solid #e2e7ea
}

.tt-topic-list .tt-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    min-height: 101px;
    padding: 15px
}

.tt-topic-list .tt-item .tt-col-avatar {
    width: 8.33333%
}

.tt-topic-list .tt-item .tt-col-avatar .tt-icon {
    width: 40px;
    height: 40px;
    background: #4c4c4c;
    text-align: center;
    line-height: 40px;
    color: #fff;
    border-radius: 50%
}

.tt-topic-list .tt-item .tt-col-avatar .tt-icon h5 {
    margin-bottom: 0;
    line-height: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: 600
}

.tt-topic-list .tt-item .tt-col-description>:first-child {
    margin-top: 0
}

.tt-topic-list .tt-item .tt-col-description .tt-title {
    color: #303344;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    letter-spacing: .01em
}

.tt-topic-list .tt-item .tt-col-description .tt-title .tt-icon {
    width: 18px;
    height: 18px;
    fill: #666f74;
    margin-right: 8px;
    position: relative;
    top: -1px
}

.tt-topic-list .tt-item .tt-col-description>:not(:first-child) {
    margin-top: 6px
}

.tt-topic-list .tt-item .tt-col-description>:last-child {
    padding-bottom: 0
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.tt-topic-list .tt-list-badge {
    margin-top: -8px
}

.tt-list-badge {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    margin: -8px 0 0 -8px
}

.tt-topic-list .hide-desktope,
.tt-topic-list .show-mobile {
    display: none
}

.tt-list-badge li {
    margin-left: 8px;
    margin-top: 8px;
    list-style: none
}

.tt-badge:not([class^=tt-color]) {
    background-color: #d7dadc;
    color: #182730
}

.tt-badge {
    font-size: 14px;
    padding: 1px 7px 2px;
    line-height: 1;
    font-weight: 500;
    height: 25px;
    border: none;
    outline: 0;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 3px;
    color: #fff
}

.tt-topic-list .tt-item .tt-col-category:not(.tt-col-value-large) {
    width: 16.66667%
}

.tt-badge.tt-color01 {
    background-color: #2e3192
}

.tt-topic-list .tt-item .tt-col-value {
    width: 11.2%
}

.tt-topic-list .tt-item .tt-col-value1 {
    width: 14.2%
}

.tt-topic-list .tt-item .tt-col-value2 {
    width: 17.3%
}

.tt-topic-list .tt-item .tt-col-value3 {
    width: 12.2%
}

.tt-topic-list .tt-item>[class^=tt-col] {
    padding-left: 15px;
    padding-right: 15px
}

.tt-topic-list .tt-color-select {
    color: #182730;
    font-weight: 500
}

.tt-topic-list .tt-item .tt-col-description {
    width: 57.66667%
}

.tt-badge.tt-color02 {
    background-color: #4436f8
}

.tt-list-header {
    background-color: #fff3f4;
    border-bottom: 1px solid rgba(26, 54, 126, .125);
    border-left: 2px solid #e81c28
}

.by-forum-update {
    font-weight: 500
}

.by-forum-update span {
    font-weight: 600
}

.answer-action {
    border-radius: 6px;
    background: #fff;
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px
}

.answer-action .action-content,
.onecolldetailbox {
    display: flex;
    align-items: center
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.answer-action .action-content .image-wrap {
    margin-right: 30px
}

.answer-action .action-content .ans-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px
}

.answer-action .action-content p {
    margin: 0
}

.action-button-container {
    display: flex;
    justify-content: flex-end
}

.answer-action .btn-ans {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 25px;
    box-shadow: none;
    border: 1px;
    transition: all .3s ease-in-out;
    background: #e81c28
}

.action_btn {
    color: #fff;
    border-radius: 4px
}

.active-topics-box,
.topics-discussio-forum {
    border-radius: 2px;
    box-shadow: 0 1px 2px #c9cccd;
    margin-bottom: 20px
}

.answer-action .btn-ans:hover {
    background: 0 0;
    color: #e81c28
}

.right-topic-box {
    justify-content: space-between
}

.active-topics-box {
    background-color: #fff;
    padding: 15px
}

.byCourseSection form textarea {
    height: 150px;
    padding-left: 15px
}

.active-topics-box .active-topics-title {
    background: #fff3f4;
    padding: 10px;
    font-size: 18px;
    margin-left: -16px;
    margin-right: -15px;
    margin-top: -15px
}

.topics-discussio-forum {
    background-color: #fff;
    padding: 15px
}

.topics-discussio-forum .active-topics-title {
    background: #fff3f4;
    padding: 10px;
    font-size: 18px;
    margin-left: -16px;
    margin-right: -15px;
    margin-top: -15px
}

.popular-topics-list ul li {
    list-style: none;
    position: relative
}

.popular-topics-list ul li a {
    list-style: none;
    color: #222;
    margin: 0 0 8px;
    font-weight: 500;
    line-height: 1.4;
    font-style: normal;
    font-size: 14px
}

.popular-topics-list ul li:hover a {
    color: #e81c28
}

.popular-topics-list ul li:before {
    color: #e81c28;
    content: "\f105";
    font: 700 14px FontAwesome;
    margin-right: 20px
}

.byCourseSection {
    padding: 25px 30px;
    background: #f8f8f8
}

.buy-now-course-title h5 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    position: relative;
    padding-bottom: 10px
}

.byCourseSection form input {
    padding: 10px 20px 10px 15px;
    height: 45px
}

.buy-now-course-title h5:before {
    position: absolute;
    content: '';
    width: 80px;
    height: 2px;
    left: 0;
    bottom: 0;
    background: #e81c28
}

.course-title-bx {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px !important
}

.thankyou-page ._header {
    padding: 50px 30px 100px;
    text-align: center;
    background: #efefef
}

.thankyou-page ._header .logo {
    max-width: 200px;
    margin: 0 auto 5px
}

.thankyou-page ._header .logo img {
    width: 49px;
    margin-bottom: 0
}

.thankyou-page ._header h1 {
    font-size: 40px;
    font-weight: 800;
    color: #4ab74a;
    margin: 0
}

.thankyou-page ._body {
    margin: -70px 0 30px
}

.thankyou-page ._body ._box {
    text-align: center;
    margin: auto;
    max-width: 80%;
    padding: 50px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 35px rgba(10, 10, 10, .12);
    -moz-box-shadow: 0 0 35px rgba(10, 10, 10, .12);
    -webkit-box-shadow: 0 0 35px rgba(10, 10, 10, .12)
}

.thankyou-page ._body ._box h2 {
    font-size: 26px;
    font-weight: 600;
    color: #4ab74a
}

.thankyou-page ._footer {
    text-align: center;
    padding: 20px 30px
}

.thankyou-page ._footer .btn {
    background: #e81c28;
    color: #fff;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .8px;
    padding: 15px 3px;
    text-transform: uppercase;
    width: 200px;
    border-radius: 4px;
    line-height: 20px;
    height: 50px;
    box-shadow: none
}

.thankyou-page ._body ._box p {
    font-size: 16px
}

.studetsexplorer.-type-5 {
    position: relative;
    width: 100%;
    background: #f5f5f5
}

.studetsexplorer {
    background-position: bottom right !important;
    background-size: cover !important
}

.studetsexplorer::before {
    background: linear-gradient(0deg, #000, transparent);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%
}

.studetsexplorer .contentarea {
    padding: 38px 0 8px
}

.studetsexplorer .contentarea h1 {
    font-size: 33px;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 2px
}

.studetsexplorer .contentarea h2 {
    font-size: 20px;
    color: #fff;
    line-height: 28px;
    padding-bottom: 10px;
    font-weight: 400
}

.studetsexplorer .contentarea p {
    font-size: 18px;
    line-height: 26px;
    color: #fff
}

.img-responsiveadd {
    max-width: 70%;
    padding-bottom: 30px
}

.studetsexplorer .contentlistarea {
    padding: 100px 0 30px
}

.studetsexplorer .contentlistarea h2 {
    color: #fff;
    padding-bottom: 0;
    margin-bottom: 0
}

.studetsexplorer .contentlistarea p {
    color: #fff
}

.collegecontact {
    position: relative;
    text-align: right
}

.collegecontact a {
    display: inline-block;
    padding: 4px 10px;
    border: 1px solid #fff;
    border-radius: 50px;
    color: #fff;
    margin: 0 4px
}

.collegecontact a:hover {
    color: #fff !important;
    border: 1px solid #e81c28
}

.claim-college {
    position: relative;
    text-align: center
}

.claim-college a {
    color: #fff !important;
    color: #fff !important;
    margin: 20px 0 0
}

.claim-college a:hover {
    border-bottom: 1px solid #fff;
    color: #fff;
    padding-bottom: 0
}

.breadteam {
    margin: 0;
    padding: 0
}

.breadteam li {
    display: inline-block;
    padding-right: 10px;
    color: #fff
}

.breadsitemap {
    margin: 0;
    padding: 0
}

.breadsitemap li {
    display: inline-block;
    padding-right: 20px;
    color: #363636;
    position: relative
}

.breadsitemap li:first-child::after {
    content: none
}

.breadsitemap li:after {
    top: 0;
    left: -12px;
    content: '\f105';
    position: absolute;
    font-family: FontAwesome
}

.aboutuniversityhead,
.aboutuniversityvideo,
.aboutunivesitycon,
.allcoldateevent,
.college-btn-apply,
.college-tow-btn-applybox,
.college-tow-btn-dbbox,
.univesitypic {
    position: relative
}

.breadsitemap li a {
    color: #888
}

.aboutuniversityhead {
    padding: 20px 0 0;
    text-align: left
}

.aboutuniversityhead h1 {
    font-size: 32px
}

.college-btn-apply {
    margin-top: -80px
}

.college-tow-btn-applybox a.apply-btn-mail {
    background: #e81c28;
    display: block;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    box-shadow: rgb(207 207 207 / 50%) 0 0 4px 0;
    margin-bottom: 15px;
    font-size: 20px
}

.college-tow-btn-applybox a.apply-btn-mail span {
    padding: 10px 20px;
    display: inline-block
}

.college-tow-btn-applybox a.apply-btn-mail i {
    font-size: 28px;
    background: linear-gradient(45deg, #9c0000, #d81818);
    padding: 3px 20px 12px 120px;
    float: right;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 100%;
    box-shadow: -18px 0 0 0 #ff8080
}

.college-tow-btn-applybox a.apply-btn-mail:hover {
    background: #d4040a
}

.college-tow-btn-dbbox a.download-btn-bro {
    border-radius: 4px;
    box-shadow: rgb(207 207 207 / 50%) 0 0 4px 0;
    display: block;
    text-align: center;
    background: #1692b9;
    color: #fff;
    font-size: 17px
}

.college-tow-btn-dbbox a.download-btn-bro span {
    padding: 10px 20px;
    display: inline-block
}

.college-tow-btn-dbbox a.download-btn-bro i {
    background: linear-gradient(45deg, #077fa5, #077fa5);
    padding: 9px 30px 14px 63px;
    font-size: 20px;
    float: right;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 100%;
    box-shadow: -18px 0 0 0 #6dd6f8
}

.college-tow-btn-dbbox a.download-btn-bro:hover {
    background: #077fa5
}

.btn-askaquestion-box {
    border-radius: 4px;
    box-shadow: rgb(207 207 207 / 50%) 0 0 4px 0;
    margin: 15px 0;
    display: block;
    text-align: center;
    padding: 12px 20px;
    background: #fff;
    color: #fff;
    font-size: 17px
}

.btn-askaquestion-box .coll-inter-text {
    color: #464646;
    padding: 12px 0;
    text-transform: uppercase
}

.btn-askaquestion-box a.btn-askaquestion {
    background: #e81c28;
    color: #fff;
    padding: 6px 30px;
    border-radius: 4px;
    display: inline-block;
    font-size: 18px
}

.btn-askaquestion-box a.btn-askaquestion i {
    font-size: 20px;
    padding-left: 20px
}

.univesitypic img {
    max-width: 100%;
    height: auto;
    border-radius: 8px
}

.aboutunivesitycon {
    padding-bottom: 30px
}

.aboutunivesitycon p {
    font-size: 15px;
    text-align: justify
}

.aboutuniversityvideo {
    padding: 30px 0
}

.aboutuniversityvideo iframe {
    border-radius: 6px;
    margin-bottom: 20px
}

.facilityarea {
    padding: 50px 0
}

.facilityarea p.font-16 {
    font-size: 16px
}

.ptf-advantage-box {
    margin-bottom: 12px
}

.ptf-advantage-box__content {
    padding: .4rem 1rem;
    border-radius: 9.375rem;
    background-color: #fff;
    text-align: center;
    border: 1px solid #ddd;
    display: flex;
    align-items: center
}

.ptf-advantage-box__image {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: all .3s ease-in-out;
    transition: all var(--ptf-transition-duration)var(--ptf-transition-easing)
}

.ptf-advantage-box__image img {
    width: auto;
    height: var(--ptf-image-height);
    max-width: 30px
}

.ptf-advantage-box__value {
    display: inline-flex;
    margin-top: .2rem;
    color: var(--ptf-color-#000);
    font: 1.2rem var(--ptf-secondary-font);
    padding-left: 10px
}

.ptf-advantage-box__title {
    margin-top: 1.5625rem;
    color: var(--ptf-color-#000);
    font-size: 1.125rem;
    font-weight: 400
}

.ptf-advantage-box:hover .ptf-advantage-box__image {
    -webkit-animation: ptf_bounce calc(var(--ptf-transition-duration) *3) var(--ptf-transition-easing)forwards;
    animation: ptf_bounce calc(var(--ptf-transition-duration) *3) var(--ptf-transition-easing)forwards;
    -webkit-filter: none;
    filter: none
}

.ptf-approach-box {
    --ptf-subtitle-color: var(--ptf-color-#000);
    --ptf-title-color: var(--ptf-color-#000);
    --ptf-content-color: var(--ptf-color-#000);
    padding-top: 3.75rem;
    border-top: 2px solid
}

.allcoldateevent {
    padding: 60px 0 30px
}

.eventresult {
    font-size: 18px;
    display: inline-block;
    padding-top: 10px
}

.fdatetext,
.imptext {
    font-size: 17px
}

.eventresult span {
    font-weight: 600
}

.eventhead li,
.fdatetext,
.ftimetext,
.imptext {
    font-weight: 500
}

.form-control.coursedate {
    padding-left: 10px;
    height: calc(2.25rem + 5px) !important;
    margin-bottom: 30px
}

.datehead,
.dateheadtwo {
    padding: 10px 20px;
    height: 65px;
    line-height: 65px;
    display: flex;
    vertical-align: middle
}

.coursedatetimebox,
.coursedatetimetwo {
    box-shadow: 0 4px 8px #ccc;
    margin-bottom: 25px
}

.coursedatetimebox {
    background: #fff;
    border: 2px solid #6949b1;
    border-radius: 4px;
    border-top: 0
}

.datehead {
    background: #6949b1;
    align-items: center
}

.coursedatetimetwo {
    background: #fff;
    border: 2px solid #1692b9;
    border-radius: 4px;
    border-top: 0
}

.dateheadtwo {
    background: #1692b9;
    align-items: center
}

.datehead h4,
.dateheadtwo h4 {
    color: #fff;
    font-size: 17px
}

.cou-datetime {
    padding: 20px;
    background: #fffbfb
}

.cou-datetime p {
    margin-bottom: 8px
}

.fdatetext {
    color: #e81c28
}

.ftimetext {
    font-size: 16px
}

.eventhead {
    background: #1692b9;
    display: flex;
    border-radius: 4px
}

.eventhead li {
    display: inline-block;
    padding: 12px 20px;
    width: 30%;
    color: #fff;
    font-size: 18px
}

.eventhead li:first-child {
    border-radius: 4px 0 0
}

.eventhead li:last-child {
    border-radius: 0 4px 0 0
}

.eventheadthree li,
.eventheadtwo li {
    padding: 12px 20px;
    width: 30%;
    font-weight: 500;
    border: 1px solid #f5f5f5
}

.eventheadtwo {
    display: flex
}

.eventheadtwo li {
    display: inline-block
}

.eventheadthree {
    display: flex;
    background: #f9f9f9
}

.eventheadthree li {
    display: inline-block
}

#applycolnow {
    padding: 4px 10px
}

#applycolnow h1 {
    font-size: 20px;
    margin-bottom: 20px
}

#applycolnow .form-group {
    margin-bottom: 18px
}

#applycolnow .applyboxform {
    max-width: 712px
}

#applycolnow .contentapplybox {
    border-radius: 4px;
    max-width: 470px;
    background: url(../images/careerExplorerupimg.png) bottom right -6px no-repeat #f9f9f9;
    padding: 10px 30px;
    background-size: 60%
}

.Unlogo {
    padding-right: 10px
}

.untext {
    font-size: 16px
}

.untext span {
    font-size: 12px;
    display: block;
    line-height: 10px
}

.colregisternow {
    font-size: 22px;
    color: #e81c28;
    padding-bottom: 26px
}

#applycolnow .contentapplybox ul {
    margin: 0;
    padding: 0
}

#applycolnow .contentapplybox ul li {
    padding: 0 0 2px 20px;
    margin: 0;
    position: relative;
    list-style: none;
    font-size: 14px
}

#applycolnow .contentapplybox ul li:before {
    content: '\f00c';
    position: absolute;
    left: 0;
    font: 12px FontAwesome;
    color: #0a8899cc
}

.logotextbg {
    background: #fff9f9;
    padding: 10px;
    border-radius: 4px
}

.conapplynow {
    color: #fff !important;
    background: #e81c28;
    color: #fff !important;
    border-radius: 2px;
    margin: 20px 0;
    padding: 10px;
}

.conapplynow:hover {
    background: #db101c;
    box-shadow: 0 2px 6px #ccc
}

.subcoursesarea {
    padding: 0 0 40px
}

.submainhead {
    font-size: 32px;
    color: #464646;
    margin: 0
}

.annualfee,
.elitext,
.feerupees,
.submaintitle {
    font-size: 16px
}

.submaintitle {
    padding-bottom: 20px
}

.subcoursebox {
    position: relative;
    border-radius: 4px;
    background: #fff;
    filter: drop-shadow(4px 2px 16px #ccc);
    margin-bottom: 30px;
    transition: all .3s
}

.subhead {
    background: url(/public/assets/images/colheadbg.jpg);
    padding: 16px 20px;
    border-radius: 4px 4px 0 0;
    background-size: cover
}

.subhead h4 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 0
}

.subname {
    padding: 10px 20px;
    border: 1px solid #6646b0;
    border-radius: 0 0 4px 4px
}

.annualfee {
    font-weight: 500;
    color: #ff8d00;
    padding-bottom: 0
}

.feerupees {
    color: #6646b0;
    font-weight: 600;
    padding-bottom: 10px
}

.col-apply,
.dugreetext,
.elitext {
    font-weight: 500
}

.elitext {
    color: #ff8d00;
    padding-bottom: 0
}

.dugreetext {
    font-size: 14px;
    padding-bottom: 4px
}

.studentshead {
    position: relative;
    padding: 25px 0 12px;
    text-align: center
}

.studentshead h1 {
    font-size: 32px;
    color: #464646
}

.col-banerimg {
    max-width: 40%;
    padding-top: 10px
}

.header-sec-link:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA3NiAzMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzYgMzE7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjx0aXRsZT5iZ19zb3VyY2VfYmFkZ2VfMTwvdGl0bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTcuNywxMS40Yy0xLjQtMS40LTIuNy0yLjktNC4xLTQuNGMtMC4yLTAuMy0wLjUtMC41LTAuOC0wLjhjLTAuMi0wLjItMC4zLTAuMy0wLjUtMC41bDAsMA0KCUM0OC42LDIuMiw0My41LDAsMzgsMFMyNy40LDIuMiwyMy42LDUuN2wwLDBjLTAuMiwwLjItMC4zLDAuMy0wLjUsMC41Yy0wLjMsMC4zLTAuNSwwLjUtMC44LDAuOGMtMS40LDEuNS0yLjcsMy00LjEsNC40DQoJYy01LDUuMS0xMS43LDYuMS0xOC4zLDYuM1YzMWg5LjRoOC45aDM5LjRoNC45SDc2VjE3LjZDNjkuNCwxNy40LDYyLjcsMTYuNSw1Ny43LDExLjR6Ii8+DQo8L3N2Zz4NCg==) no-repeat center bottom;
    background-size: 400px 58px;
    top: 25px;
    z-index: 0
}

.colstu-searcharea {
    padding: 40px 0
}

.colstu-searcharea .searchcol {
    width: 100%;
    position: relative;
    display: flex
}

.colleges-countary-box .Marquee-countary,
.d-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.colstu-searcharea .searchTerm {
    width: 100%;
    border: 2px solid silver;
    border-right: 0;
    padding: 5px 10px;
    height: 50px;
    border-radius: 3px 0 0 3px;
    outline: 0;
    color: #444
}

.colstu-searcharea .searchTerm:focus {
    color: #666
}

.colstu-searcharea .searchButton {
    width: 100px;
    height: 50px;
    background: #444;
    text-align: center;
    color: #fff;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    font-size: 20px;
    border: 1px solid solid solid none #444
}

.colstu-searcharea .wrap-search {
    width: 60%;
    margin: 0 auto
}

.imgarea,
.imgmain {
    width: 180px;
    overflow: hidden
}

.college-area {
    position: relative;
    box-shadow: 0 0 20px #d0d0d0;
    padding: .75rem;
    border-radius: 3px;
    margin-bottom: 30px
}

.d-flex {
    display: flex
}

.h-100 {
    height: 100%
}

.imgarea {
    min-height: 200px;
    padding: 10px;
    border-radius: 4px;
    margin-right: 1rem;
    position: relative
}

.img-overlay,
.imgmain {
    position: absolute;
    top: 0;
    left: 0
}

.imgmain {
    z-index: 0;
    height: 200px;
    max-height: 200px;
    max-width: 180px
}

.img-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 1
}

.clg-name-address,
.jsx-college .list-unstyled,
.locationarea,
.masthead.-type-5,
.onecollogo {
    position: relative
}

.justify-content-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.data-icon {
    color: #fff !important;
    -webkit-text-decoration: none;
    text-decoration: none;
    z-index: 1;
    color: #fff !important;
    line-height: 1;
    margin-right: 15px;
}

.data-icon .fa-play {
    border: 1px solid #fff;
    padding: 0 3px;
    border-radius: 2px;
    font-size: 13px
}

.flex-fill {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.jsx-college {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.clg-logo {
    width: 50px;
    height: 50px
}

img.col-logo {
    height: 40px;
    width: 40px;
    max-height: 40px;
    max-width: 40px;
    overflow: hidden
}

.onecollogo {
    padding-right: 20px
}

img.col-logo-md {
    height: 60px;
    width: 60px;
    border-radius: 2px;
    max-height: 60px;
    max-width: 60px;
    overflow: hidden
}

.clg-name-address {
    line-height: 16px
}

.clg-name-address a {
    font-size: 12px;
    color: #444;
    font-weight: 500
}

.location {
    font-size: 9px
}

.jsx-college .list-unstyled li {
    list-style: none;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 2px solid #edeff0
}

.jsx-college .list-unstyled li:last-child {
    border-right: 0 solid #edeff0
}

.jsx-college .list-unstyled li a {
    line-height: 20px
}

.col-text-size {
    font-size: 10px;
    color: #999
}

.coladdfee a {
    color: #999;
    margin-right: 10px;
    padding: 2px 10px;
    font-size: 12px;
    border: 1px solid #999;
    border-radius: 18px;
    text-transform: capitalize;
    text-decoration: none
}

.coladdfee a:hover {
    border: 1px solid #e81c28
}

.col-apply {
    color: #fff !important;
    color: #fff !important;
    border-radius: 2px;
    padding: 4px 16px;
    font-size: 14px;
    white-space: nowrap
}

.col-apply:hover {
    box-shadow: 0 0 10px #d0d0d0
}

.pricecolor {
    color: #e81c28
}

.media-rank.jsx-2158881826 {
    height: 40px
}

.media-rank.jsx-2158881826 .facilities-svg {
    width: 20px;
    height: 20px;
    margin: 10px 10px 0 0;
    background: url(../images/listing_spirit_2.svg) no-repeat;
    background-size: auto 100%
}

.media-rank.jsx-2158881826 .Comp {
    background-position: -200px 0
}

.media-rank.jsx-2158881826 .Sports {
    background-position: -125px 0
}

.media-rank.jsx-2158881826 .Gym {
    background-position: -225px 0
}

.media-rank.jsx-2158881826 .Medical {
    background-position: -250px 0
}

.media-rank.jsx-2158881826 .Cafeteria {
    background-position: -275px 0
}

.media-rank.jsx-2158881826 .Library {
    background-position: -50px 0
}

.media-rank.jsx-2158881826 .Auditorium {
    background-position: -150px 0
}

.media-rank.jsx-2158881826 .Hostel {
    background-position: -550px 0
}

.icon {
    display: inline-block;
    line-height: initial;
    height: 15px;
    width: 15px
}

.masthead.-type-5 {
    z-index: 0;
    padding-top: 150px;
    padding-bottom: 140px;
    background: #f5f5f5
}

.masthead.-type-5 .masthead__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.masthead.-type-5 .masthead__container {
    padding-left: 140px;
    padding-right: 60px
}

.y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px
}

.fw-500 {
    font-weight: 500 !important
}

.mb-10 {
    margin-bottom: 10px !important
}

.masthead.-type-5 .masthead__title {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 30px;
    line-height: 60px;
    margin-bottom: 30px
}

.masthead.-type-5 p {
    margin-bottom: 30px;
    font-size: 16px
}

.pt-20 {
    padding-top: 20px !important
}

[data-anim*=delay-4],
[data-anim-child*=delay-4] {
    transition-delay: .4s;
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.button.-orange-1 {
    background-color: #e81c28;
    border: 2px solid #e81c28
}

.button.-md {
    padding: 12px 55px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    height: 50px;
}

.text-orange-1 {
    color: #ffa500
}

.lh-15 {
    line-height: 1.5 !important
}

.x-gap-20 {
    margin-left: -10px;
    margin-right: -10px
}

.y-gap-20 {
    margin-top: -10px;
    margin-bottom: -10px
}

.mr-10 {
    margin-right: 10px !important
}

.pt-60 {
    padding-top: 60px !important
}

.button.-outline-light-5 {
    border: 2px solid #ededed
}

.text-dark-1 {
    color: #222
}

.masthead .button {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    border-radius: 4px;
    line-height: 1;
    font-weight: 600;
    transition: all .3s cubic-bezier(.165, .84, .44, 1);
    font-size: 20px
}

.x-gap-20>* {
    padding-left: 10px;
    padding-right: 10px
}

.hero-img {
    position: relative;
    z-index: 1
}

.hero-img .hero-bg-shape {
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.hero-img .hero-bg-shape .bg-img-shape2 {
    display: none !important
}

.hero-img .hero-bg-shape img {
    max-width: 582px
}

.hero-img img {
    max-width: 550px
}

.hero-slider-area {
    background-color: #f5f5f5
}

.masthead .section-shape.shape-2 {
    top: -30%;
    right: 0;
    width: 400px;
    opacity: .3;
    position: absolute
}

.masthead .section-shape.shape-1 {
    top: -2%;
    left: -5%;
    width: 400px;
    position: absolute
}

.layout-pb-lg {
    padding-bottom: 80px
}

.layout-pt-lg {
    padding-top: 80px
}

[data-anim-wrap].animated {
    pointer-events: auto
}

[data-anim-child^=slide-].is-in-view,
[data-anim^=slide-].is-in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto
}

.y-gap-30>* {
    padding-top: 15px;
    padding-bottom: 15px
}

.y-gap-30 {
    margin-top: -15px;
    margin-bottom: -15px
}

.featureIcon.-type-1 {
    display: flex
}

.featureIcon.-type-1 .featureIcon__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    border-radius: 100%
}

.bg-light-7 {
    background-color: #e5f0fd
}

.text-purple-1 {
    color: #e81c28
}

.composition.-type-5 {
    position: relative;
    padding-bottom: 170px;
    display: flex;
    justify-content: flex-end
}

.composition.-type-5 .-el-2 {
    position: absolute;
    bottom: 120px;
    left: -40px
}

.composition.-type-5 .-el-1 img {
    border-radius: 125px 0 130px 15px;
    border: 14px solid #f3f0f0
}

.composition.-type-5 .-el-2 img {
    width: 330px;
    border-radius: 90px 10px;
    border: 14px solid #f3f0f0
}

.composition.-type-5 .-el-3 {
    position: absolute;
    top: 20px;
    left: 120px
}

.composition.-type-5 .-el-4 {
    position: absolute;
    bottom: 300px;
    left: -40px
}

.composition.-type-5 .-el-5 {
    position: absolute;
    bottom: 101px;
    left: 250px
}

.about-abroad-section p {
    font-size: 15px;
    margin-bottom: 12px;
}

.about-abroad-section {
    padding-right: 25px
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px
}

.section-title h2 {
    font-size: 30px;
    margin-bottom: 15px;
    position: relative
}

.section-title span {
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    color: #ffb607
}

.single-categories {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    border-radius: 3px;
    height: 200px
}

.single-categories img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 3px
}

.single-categories .categories-content-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    display: flex;
    background-color: rgb(0 0 0 / 48%)
}

.single-categories .categories-content-wrap .categories-content a {
    display: block
}

.single-categories .categories-content-wrap .categories-content h3 {
    color: #fff;
    margin-bottom: 0;
    font-size: 24px;
    -webkit-transition: var(--transition);
    transition: var(--transition)
}

.single-categories .categories-content-wrap .categories-content span {
    color: #fff;
    font-size: 15px
}

.what-we-offer-section {
    background-color: #f5f5f5
}

.categories-area {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px
}

.education-img-wrap .education-img-2 {
    text-align: right
}

.education-img-wrap .education-img-3 {
    position: absolute;
    top: 100px;
    left: 70px
}

.education-img-wrap .education-img-4 {
    position: absolute;
    left: 150px;
    top: 200px;
    z-index: -1
}

.education-img-wrap .education-shape-1 {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: -1
}

.education-img-wrap .education-shape-2 {
    position: absolute;
    top: 401px;
    right: -30px;
    z-index: -2
}

.education-area-two {
    position: relative
}

.education-area {
    overflow: hidden
}

.ebeef5-bg-color {
    background-color: #ebeef5
}

.education-img {
    background: no-repeat center center;
    background-size: cover;
    height: 100%
}

.education-area-why-us {
    position: relative;
    padding-top: 70px;
    padding-bottom: 70px
}

.education-area-why-us:after,
.education-area-why-us:before {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    content: ''
}

.education-area-why-us:before {
    z-index: 0;
    background: url(../images/why-us-bg.jpg);
    background-size: 100%;
    right: 0;
    bottom: 0
}

.education-area-why-us:after {
    left: 0;
    background-color: rgb(0 0 0 / 77%)
}

.education-img img {
    display: none
}

.education-content {
    z-index: 9;
    position: relative;
    margin-top: -5px;
    margin-bottom: -3px
}

.education-content .top-title {
    color: #ffb607;
    font-size: 16px;
    margin-bottom: 5px;
    display: block
}

.education-content h2 {
    font-size: 30px;
    margin-bottom: 20px;
    color: #fff
}

.education-content h2 span {
    color: #e81c28;
    text-transform: uppercase
}

.education-content ul {
    margin-top: 20px
}

.education-content ul li {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    padding-left: 35px;
    margin-bottom: 20px;
    color: #fff;
    list-style: none
}

.education-content ul li i {
    background-color: #fff;
    border-radius: 50%;
    color: #e81c28;
    font-size: 13px !important;
    height: 20px;
    left: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 6px;
    width: 20px;
}

.education-content .default-btn {
    margin-top: 47px
}

.default-btn {
    font-size: 16px;
    color: var(--white-color);
    padding: 15px 40px;
    line-height: 1;
    -webkit-transition: var(--transition);
    transition: 4px;
    text-align: center;
    background-color: #ffb607;
    border-radius: var(--border-radius);
    border: 1px solid #e81c28;
    margin-bottom: -4px;
    text-transform: capitalize
}

.education-enquery-form {
    position: relative;
    z-index: 9
}

.enroll-wrap {
    text-align: center;
    background-color: #fff;
    padding: 30px;
    border-radius: 3px
}

.enroll-wrap .text-danger {
    display: block;
    text-align: left
}

.enroll-wrap .courses-form {
    border: 1px solid #f0f0f0;
    padding: 30px 10px
}

.enroll-wrap h3 {
    font-size: 26px;
    margin-bottom: 5px;
    color: #000
}

.enroll-wrap .courses-form .form-group .form-control {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    height: 50px;
    color: #000
}

.enroll-wrap .courses-form .form-group select {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    height: 50px;
    color: #848484
}

.enroll-wrap .courses-form .default-btn {
    background-color: #e81c28;
    color: #fff;
    margin-bottom: 0;
    font-weight: 600
}

.enroll-wrap .courses-form .default-btn:focus,
.enroll-wrap .courses-form .default-btn:hover {
    background-color: transparent;
    color: #e81c28;
    border-color: #e81c28
}

.enroll-wrap .courses-form .form-group textarea {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    height: 110px;
    color: #848484
}

.testimonials-section,
.what-we-offer-section {
    padding-top: 70px;
    padding-bottom: 70px
}

.title-shape {
    background-color: #e81c28;
    border-radius: 30px;
    height: 3px;
    position: relative;
    width: 150px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px
}

.testimonials-title-box {
    text-align: center
}

.testimonials-title-box h5 {
    font-size: 36px;
    position: relative;
    text-align: center;
    margin-bottom: 30px
}

.tophead-banner {
    z-index: 0;
    background: url(../images/abroad-bg.jpg) !important;
    content: '';
    height: 100%;
    width: 100%;
    background-size: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    position: relative
}

.about-abroad-section h2 {
    font-size: 30px
}

.we-offer-item {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    margin-bottom: 20px;
    border-radius: 6px;
    transition: all .45s ease-in-out;
    box-shadow: 0 5px 25px 0 rgb(0 0 0 / 6%)
}

.we-offer-item .we-offer-images {
    width: 30%;
    float: left
}

.we-offer-item .we-offer-content {
    width: 70%;
    float: left;
    padding: 10px;
    text-align: center
}

.we-offer-item .we-offer-content a {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    padding-left: 5px
}

.readmore-about-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    border-radius: 5px;
    line-height: 1;
    font-weight: 500;
    transition: all .3s cubic-bezier(.165, .84, .44, 1);
    font-size: 14px;
    background-color: #e81c28;
    border: 2px solid #e81c28;
    color: #fff;
    height: 40px;
    width: 140px
}

.masthead {
    background: url(../images/main-banner.jpg) bottom right !important
}

.student-hero-img img {
    max-width: 282px;
    position: absolute;
    right: 100px;
    top: 20px
}

.fw-600 {
    font-weight: 600
}

.we-offer-item .we-offer-content h4 {
    font-weight: 500
}

.header_search_keyword {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 500
}

.header_search_keyword .header-search-form__keywords-label {
    color: #fff;
    font-weight: 400
}

.header_search_keyword span {
    font-size: 14px;
    color: #fff;
    margin-right: 10px
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.header_search_keyword ul {
    margin-bottom: 0
}

.header_search_keyword ul li {
    margin-right: 3px;
    margin-left: 3px;
    list-style: none;
    color: #fff;
    display: inline-block
}

.header_search_keyword ul li a {
    color: #fff;
    font-weight: 600;
    border-bottom: 2px dotted
}

.header_search_keyword ul li a:hover {
    border-bottom: 2px dotted rgb(244 244 244)
}

.navigation .pagination .page-item .page-link {
    border-radius: 5px;
    background-color: #f8f8f8;
    color: #4c4c4c;
    border: none
}

.navigation .pagination .page-item {
    margin-right: 10px
}

.navigation .pagination .page-item.active .page-link {
    background-color: #0275d8 !important;
    color: #fff !important
}

.breadcrumb-banner-area {
    background: rgba(0, 0, 0, 0) url(../images/breadcrumb-banner.jpg) no-repeat rgba(0, 0, 0, 0) no-repeat center center;
    color: #fff;
    padding: 50px 0;
    position: relative;
    background-size: cover
}

.breadcrumb-text {
    z-index: 9;
    position: relative
}

.breadcrumb-text h1 {
    line-height: 25px;
    text-transform: uppercase;
    position: relative;
    font-size: 36px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 0
}

.breadcrumb-banner-area:after {
    background: rgb(0 0 0 / 42%) none;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1
}

.main-section-box {
    padding: 50px 0
}

.right-aside-study-abroad .enroll-wrap {
    padding: 20px 15px;
    background-color: #f0f0f0
}

.right-aside-study-abroad .enroll-wrap h3 {
    font-size: 20px
}

.right-aside-study-abroad .enroll-wrap .courses-form {
    border: 1px solid #fff;
    padding: 15px 10px
}

.right-aside-study-abroad .enroll-wrap .courses-form .form-group .form-control {
    background-color: #fff;
    border-color: #f0f0f0;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    height: 40px;
    color: #000
}

.right-aside-study-abroad .enroll-wrap .courses-form .form-group select {
    background-color: #fff;
    border-color: #f0f0f0;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    height: 40px;
    color: #848484
}

.right-aside-study-abroad .enroll-wrap .courses-form .form-group textarea {
    background-color: #fff;
    border-color: #f0f0f0;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    height: 100px;
    color: #848484
}

.right-aside-study-abroad .enroll-wrap .courses-form .default-btn {
    width: 100%
}

.right-aside-study-abroad .form-group {
    margin: 0 0 10px
}

.content-main-box h4 {
    padding: 10px 0;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 20px
}

.study-list-bx li {
    list-style: none;
    position: relative;
    padding-left: 15px;
    font-size: 14px
}

.study-list-bx li::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #e81c28
}

.content-main-box p {
    font-size: 14px
}

.what-we-offer {
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    margin-top: 20px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    padding: 20px
}

.list-sidebar-right li {
    list-style: none
}

.list-sidebar-right li a {
    color: #333;
    font-size: 14px;
    display: block;
    background: #f9f9f9;
    margin-bottom: 2px;
    padding: 6px 10px;
    border-left: 3px solid #e81c28
}

.list-sidebar-right li a:hover {
    background: #f4f4f4
}

.what-we-offer h3 {
    padding: 10px;
    background-color: #f0f0f0;
    font-size: 20px;
    font-weight: 500;
    color: #222;
    margin: -21px -20px 10px
}

.studyabrodbg {
    background: url(../images/studyabrod-bg.jpg) no-repeat transparent;
    background-position: 50%;
    background-size: cover;
    color: #fff;
    padding: 90px 0;
    position: relative;
}

.orderlist-box li {
    font-size: 14px
}

.australia-bg {
    background: url(../images/australia-bg.jpg) no-repeat rgba(0, 0, 0, 0)
}

.canada-bg {
    background: url(../images/canada-bg.jpg) no-repeat rgba(0, 0, 0, 0)
}

.uk-bg {
    background: url(../images/uk-bg.jpg) no-repeat rgba(0, 0, 0, 0)
}

.usa-bg {
    background: url(../images/usa-bg.jpg) no-repeat rgba(0, 0, 0, 0)
}

.italy-bg {
    background: url(../images/italy-bg.jpg) no-repeat rgba(0, 0, 0, 0)
}

.ireland-bg {
    background: url(../images/ireland-bg.jpg) no-repeat rgba(0, 0, 0, 0)
}

.new-zealand-bg {
    background: url(../images/new-zealand-bg.jpg) no-repeat rgba(0, 0, 0, 0)
}

.singapore-bg {
    background: url(../images/singapore-bg.jpg) no-repeat rgba(0, 0, 0, 0)
}

.top-colleges-countary-section {
    background: #fff;
    padding: 20px 0
}

.colleges-countary-box h4 {
    font-size: 24px;
    text-align: center;
    position: relative;
    display: inline-block;
    margin-bottom: 20px
}

.colleges-countary-box {
    text-align: center
}

.colleges-countary-box .Marquee-countary {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 1em;
    color: #fff;
    font-weight: 200;
    display: -moz-box;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden
}

.colleges-countary-box .Marquee-content {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-animation: marquee 30s linear infinite running;
    -moz-animation: marquee 30s linear infinite running;
    -o-animation: marquee 30s linear infinite running;
    -ms-animation: marquee 50s linear infinite running;
    animation: marquee 30s linear infinite running
}

.as_contact_expert .as_icon:before,
.right-astrology-bx img {
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

.colleges-countary-box .Marquee-content:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    -ms-animation-play-state: paused;
    animation-play-state: paused
}

.colleges-countary-box .Marquee-tag {
    width: 250px;
    margin: 0 .5em;
    padding: .5em;
    background: rgb(237 237 237);
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease;
    border-radius: 5px
}

.service-box,
.service-box:before {
    -moz-transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s
}

.colleges-countary-box .Marquee-tag:hover {
    background: rgba(255, 255, 255, .5);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer
}

@-moz-keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%)
    }
}

@-webkit-keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%)
    }
}

@-o-keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%)
    }
}

@keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%)
    }
}

.breadcrumb-list-bx {
    list-style: none;
    padding-left: 0;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0
}

.breadcrumb-list-bx li {
    list-style: none;
    display: inline;
    margin-left: 6px;
    font-size: 14px
}

.breadcrumb-list-bx li a {
    color: #fff;
    padding-left: 5px
}

.breadcrumb-list-bx li:first-child {
    margin-left: 0
}

.breadcrumb-list-bx>li+li::before {
    content: "/";
    font-size: 14px
}

.breadcrumb-list-bx li:last-child a {
    color: #ffbf4d;
    font-weight: 500
}

.readmore-about-btn:focus,
.readmore-about-btn:hover {
    color: #fff
}

.what-we-offer-section.abroad-city-section {
    padding-top: 30px;
    padding-bottom: 30px
}

.what-we-offer-section.abroad-city-section .section-title h2 {
    font-size: 24px
}

.static-hero-s3 {
    background: url(../images/international-work-visas2.jpg) no-repeat rgba(0, 0, 0, 0);
    height: 450px;
    display: flex;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-position: center top 0px;
    background-size: cover;
}

.static-hero-s3 .hero-container,
.static-hero-s3 .hero-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0
}

.static-hero-s3 .hero-inner {
    z-index: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left
}

.call-confirm-bx,
.service-box {
    text-align: center
}

.static-hero .hero-inner .container,
.static-hero-s2 .hero-inner .container,
.static-hero-s3 .hero-inner .container {
    position: relative
}

.static-hero-s3 .hero-content {
    padding-top: 0;
    padding-left: 0
}

.static-hero-s3 .hero-content .slide-title-sub h5 {
    text-transform: capitalize;
    margin-bottom: 20px;
    color: #fff;
    font-size: 18px;
    border-left: 5px solid red;
    padding-left: 10px
}

.static-hero-s3 .slide-title h2 {
    color: #fff;
    font-size: 42px;
    font-weight: 900;
    line-height: 42px;
    margin: 10px 0 15px
}

.static-hero-s3 .slide-text,
.wpo-hero-slider-s2 .slide-text {
    max-width: 680px
}

.static-hero-s3 .slide-text p {
    font-size: 20px;
    color: #fff;
    line-height: 35px;
    max-width: 680px;
    margin-bottom: 40px
}

.static-hero-s3 .theme-btn,
.static-hero-s3 .view-cart-btn {
    background: #e81c28;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    padding: 12px 20px;
    border: 0;
    border-radius: 30px;
    text-transform: capitalize;
    -webkit-backface-visibility: hidden;
    z-index: 1;
    position: relative;
    font-size: 1.0666666667rem
}

.static-hero-s2 .consult-pic,
.static-hero-s3 .consult-pic {
    right: 200px;
    top: 7px;
    bottom: 0;
    transform: unset;
    border-radius: 0;
    position: absolute;
    z-index: 9999
}

.static-hero .hero-line-shape-1,
.static-hero-s2 .hero-line-shape-1,
.static-hero-s3 .hero-line-shape-1 {
    position: absolute;
    left: -30px;
    top: -30px;
    z-index: -1
}

.static-hero .hero-line-shape-2,
.static-hero-s2 .hero-line-shape-2,
.static-hero-s3 .hero-line-shape-2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1
}

.consult-pic .enroll-wrap {
    width: 500px;
    background-color: #fff;
    padding: 20px 0 30px
}

.consult-pic .enroll-wrap .fxt-checkbox-area .checkbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center
}

.as_contact_expert,
.top-main-banner-bx {
    align-items: center
}

.consult-pic .enroll-wrap .fxt-checkbox-area .checkbox label {
    margin-bottom: 0;
    margin-left: 8px
}

.international-list-bx li {
    font-size: 15px;
    list-style: none
}

.consult-pic .enroll-wrap .courses-form {
    border: 1px solid #f0f0f0;
    padding: 30px 15px;
    background-color: #fff;
    border-radius: 3px
}

.consult-pic .enroll-wrap .courses-form .form-group.col-md-6 {
    padding: 0 5px
}

.consult-pic .courses-form .form-group input {
    font-size: 13px !important
}

.consult-pic .courses-form .form-group select {
    font-size: 13px;
    font-weight: 500
}

.categories-area-top .single-categories {
    height: 150px
}

.categories-area-top .single-categories img {
    height: 100%
}

.categories-area-top .single-categories .categories-content-wrap .categories-content h3 {
    font-size: 18px
}

.categories-area.categories-area-top {
    background-color: #efefef
}

.international-list-bx li span {
    color: #e81c28;
    font-weight: 600;
    font-size: 14px
}

.service-box {
    background: #fff;
    padding: 30px 20px;
    margin-bottom: 30px;
    box-shadow: 0 14px 30px rgb(0 0 0 / 7%);
    position: relative;
    overflow: hidden;
    transition: all .3s ease-out 0s
}

.service-box:after,
.service-box:before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    opacity: 0;
    left: 0;
    top: 0
}

.service-box:before {
    background: rgba(24, 49, 95, .8);
    z-index: 1;
    transition: all .3s ease-out 0s
}

.service-box:after {
    background: url(../images/uk-bg.jpg) center center no-repeat;
    background-size: cover;
    -moz-transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.service-box:hover {
    -moz-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
}

.service-box .service-content .title h3 {
    font-size: 20px;
    margin-bottom: 15px
}

.service-box:hover .service-content .title h3 {
    color: #fff;
    position: relative;
    z-index: 2
}

.service-box:hover .service-content .para p {
    position: relative;
    z-index: 2;
    color: #fff
}

.service-box:hover:after,
.service-box:hover:before {
    opacity: 1
}

.bg-silver-light {
    background: #f8f8f8
}

.service-box .service-icon img {
    width: 50px;
    position: relative;
    z-index: 2
}

.service-box .service-icon {
    margin-bottom: 30px
}

.service-box:hover .service-icon .icon-white-hov {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.service-box .service-icon .icon-white-hov,
.service-box:hover .service-icon .icon-black-img {
    display: none
}

.international-serivce-section {
    padding: 50px 0
}

.section-left-sub-title .sub-title {
    font-weight: 600;
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 13px;
    letter-spacing: 1px;
    margin-bottom: 5px
}

.section-left-sub-title .text-primary-color {
    color: #e81c28
}

.section-title-left-part .title span {
    display: initial;
    font-size: 30px;
    margin-bottom: 15px;
    position: relative;
    font-weight: 600;
    color: #e81c28
}

.destination-top-categories .single-categories,
.destination-top-categories .single-categories img {
    height: 150px
}

.destination-top-categories .single-categories .categories-content-wrap .categories-content h3 {
    font-size: 18px
}

.static-hero-s3:before {
    background: linear-gradient(45deg, black, transparent);
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%
}

.call-section-bx {
    background: url(../images/call-back-footer-bg.png) no-repeat rgba(0, 0, 0, 0);
    padding: 70px 0;
    position: relative
}

.call-section-bx:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: ""
}

.call-confirm-bx h4 {
    text-align: center;
    color: #fff;
    font-size: 30px
}

.call-confirm-bx p {
    color: #fff;
    font-size: 20px;
    margin-top: 20px
}

.why-work-abroad-box p {
    color: #fff
}

.why-work-abroad-section:before {
    background: url(../images/why-work-abroad.jpg) right top;
    content: '';
    opacity: .5
}

.why-work-abroad-section:after {
    background-color: initial
}

.education-content.why-work-abroad-box h2 {
    color: #060606;
    font-size: 22px
}

.education-content.why-work-abroad-box p {
    color: #222;
    font-size: 14px
}

.education-content.why-work-abroad-box ul li {
    background: 44%;
    background: rgb(255 255 255 / 44%);
    padding: 7px 15px;
    border-radius: 2px;
    margin-bottom: 10px;
    color: #222;
}

.education-content.why-work-abroad-box ul li:before {
    position: absolute;
    content: '';
    width: 2px;
    height: 42px;
    left: 0;
    top: 0;
    background: #e81c28
}

.header-top-astrology {
    background: url(../images/astrology-bg.jpg) no-repeat;
    position: relative;
    padding: 30px 0
}

.hero-astrology-content .slide-title h2 {
    background: hsla(0, 0%, 99%, .44);
    border-left: 4px solid #e81c28;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    margin: 15px 0 30px;
    padding: 10px;
}

.hero-astrology-content .slide-title-sub h5 {
    color: #fff;
    font-size: 20px
}

.hero-astrology-content .slide-text {
    color: #fff;
    font-size: 16px
}

.header-top-astrology:before {
    background: rgba(0, 0, 0, .68);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.as_contact_expert {
    display: flex;
    background-color: #eee;
    border-radius: 10px;
    padding: 25px;
    margin-top: 25px;
    max-width: 470px
}

.as_contact_expert .as_icon {
    width: 65px;
    height: 65px;
    background-color: #e81c28;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    position: relative
}

.as_contact_expert .as_icon:before {
    position: absolute;
    left: -7px;
    top: -7px;
    bottom: -7px;
    right: -7px;
    border: 1px dashed #e81c28;
    content: '';
    border-radius: 100%;
    animation-name: spin
}

.as_aboutimg::before,
.as_heading:after,
.as_service_box .as_subheading:after {
    bottom: 0;
    content: ''
}

.as_contact_expert h5 {
    font-size: 18px;
    margin: 0 0 7px
}

.as_contact_expert h1 {
    font-size: 26px;
    font-weight: 700;
    margin: 0
}

.as_orange {
    color: #e81c28
}

.as_black {
    color: #000
}

.as_contact_expert span i {
    font-size: 26px;
    color: #fff
}

.as_aboutimg::before {
    position: absolute;
    right: -19px;
    border-right: 2px solid #e81c28;
    border-bottom: 2px solid #e81c28;
    width: 90px;
    height: 90px
}

.as_aboutimg::after {
    position: absolute;
    content: '';
    top: 100px;
    left: 95px;
    background-color: #eee;
    width: 420px;
    height: 450px;
    z-index: -1
}

.as_aboutimg {
    position: relative;
    z-index: 1;
    padding: 0 0 40px;
    text-align: right
}

.as_padderBottom80 {
    padding-bottom: 80px
}

.as_padderTop80 {
    padding-top: 80px
}

.as_service_box {
    background-color: #fff;
    padding: 0 0 40px;
    margin-top: 30px
}

.as_service_box .as_icon {
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    transition: all .3s linear
}

.as_service_box .as_subheading {
    margin: 10px 0;
    padding-bottom: 16px;
    position: relative;
    color: #000
}

.as_subheading {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600
}

.as_link,
.as_service_box p {
    font-size: 14px;
    line-height: 24px
}

.as_service_box .as_subheading:after {
    width: 70px;
    height: 2px;
    background-color: #e81c28;
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
}

.as_service_box p {
    color: #000
}

.as_link {
    text-transform: uppercase;
    border-bottom: 1px solid #e81c28;
    color: #e81c28;
    letter-spacing: .6px
}

.as_heading.as_heading_center:after {
    left: 50%;
    transform: translateX(-50%)
}

@keyframes spin {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}

.right-astrology-bx {
    text-align: center;
    margin-bottom: 40px;
}

.right-astrology-bx img {
    animation-name: spin;
    height: 360px
}

.as_service_wrapper {
    background: url(../images/services-asto-bg.jpg) center 0 no-repeat;
    position: relative;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px
}

.education-img-wrap .education-img-2 img {
    border-radius: 5px 60px 0
}

section.as_whychoose_wrapper.as_padderTop80 {
    padding-top: 80px
}

.as_padderBottom50 {
    padding-bottom: 50px
}

.as_verticle_center {
    display: flex;
    align-items: center
}

.as_heading {
    font-size: 36px;
    text-transform: capitalize;
    padding-bottom: 23px;
    position: relative;
    margin: -8px 0 20px
}

.as_heading:after {
    position: absolute;
    width: 100px;
    border-bottom: 3px solid #e81c28;
    left: 0
}

.as_font14 {
    font-size: 14px;
    line-height: 24px
}

.as_margin0 {
    margin: 0
}

.as_number {
    display: block;
    position: relative;
    width: 130px;
    margin: 0 auto
}

.as_whychoose_box .as_number>span {
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff
}

.as_whychoose_box h4 {
    font-size: 18px;
    margin: 25px 0 35px;
    text-align: center;
    transition: all .2s linear;
    color: #000
}

.as_whychoose_wrapper {
    background: #fff
}

.as_whychoose_wrapper .as_font14 {
    color: #000;
    margin-bottom: 50px
}

.as_whychoose_wrapper .as_heading {
    color: #000
}

.details-dis-form {
    background-color: #fff9fa;
    border-bottom: 1px solid #e2e7ea
}

.details-dis-form .discussion-para {
    padding: 0 30px
}

.comments-and-reply {
    margin-top: 30px
}

.reaply-box-user {
    padding-left: 100px;
    padding-right: 10px
}

.dis-rply-section-bx .tt-item {
    padding-bottom: 0;
    min-height: 60px
}

.reaply-box-user p {
    margin-bottom: 0
}

.reply-text-btn {
    margin-bottom: 10px;
    margin-top: 5px
}

.reply-text-btn a {
    font-weight: 600
}

.reply-by-reply-bx {
    margin-left: 60px
}

.add-reply-section-bx {
    margin-top: 15px;
    background: #f4f4f4;
    padding: 25px 15px;
    border-radius: 2px
}

.add-reply-section-bx form button {
    font-size: 18px;
    line-height: 30px;
    background: #e81c28;
    padding: 6px 40px;
    color: #fff;
    border-radius: 4px
}

.dis-hed-section.dis-rply-section-bx .tt-item .tt-col-description {
    width: 48.66667%
}

.dis-rply-section-bx.reply-by-reply-bx .tt-item .tt-col-value {
    width: 11.33333%
}

.call-Back-btn,
.quick-contact-btn {
    height: 50px;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-flex;

    width: auto;
    font-weight: 600;
    position: relative;
    transition: .2s linear;
    border-radius: 30px;
}

.quick-contact-btn {
    border: none !important;
    align-items: center;
    padding: 0 23px;
    color: #fff;
    border: none !important;
    outline: 0;
    margin-right: 0px;
    background-color: #e81c28;
}

.call-Back-btn {
    color: #e81c28 !important;
    padding: 0 50px;
    color: #e81c28 !important;
    outline: 0;
    border-radius: 30px;
    margin-right: 15px;
    border: 2px solid #e81c28
}

.as_verticle_center .as_heading:after {
    transform: translateX(-50%);
    left: 50%
}

.quick-contact-btn:hover {
    color: #fff
}

.time-input-form {
    margin-top: 25px
}

.quik-call-back-bx .form-group select {
    width: 100%
}

.quik-call-back-bx {
    padding: 0 10px;
    border-radius: 3px
}

.quik-call-back-bx h4 {
    font-size: 20px;
    margin-bottom: 15px;
    margin-left: 17px
}

.quik-call-back-bx form,
.quik-right-contact-bx .form-group {
    margin-bottom: 10px
}

.quik-call-back-bx form button {
    height: 43px;
    background-color: #e81c28;
    padding: 6px 30px;
    border-radius: 30px;
    color: #fff;
    font-size: 20px
}

.quik-right-contact-bx .form-group input,
.quik-right-contact-bx .form-group select {
    padding: 10px 5px 10px 10px;
    font-size: 13px
}

.quik-call-back-bx form input,
.quik-call-back-bx form select,
.quik-call-back-bx form textarea {
    background: #efefef
}

.quick-contact-btn:focus {
    color: #fff
}

.header-inner-astrology {
    background: url(../images/astrologer-inner-bg.jpg) no-repeat;
    position: relative
}

.breadcrumb-banner-area.header-inner-astrology:after {
    background: rgb(0 0 0 / 77%) none
}

.quik-right-contact-bx .form-group select {
    width: 100%
}

.quik-right-contact-bx .form-group textarea {
    width: 100%;
    height: 80px;
    padding-left: 10px;
    font-size: 13px
}

.quik-right-contact-bx .form-group button {
    height: 38px;
    background-color: #e81c28;
    padding: 6px 30px;
    border-radius: 30px;
    color: #fff;
    font-size: 16px
}

.quik-right-contact-bx {
    background: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    border-top: 5px solid #e81c28
}

.pd-rt5 {
    padding-right: 5px
}

.pd-lft5 {
    padding-left: 5px
}

.breadcrumb-banner-area.header-inner-astrology .breadcrumb-list-bx,
.work-country .breadcrumb-list-bx {
    padding: 7px 20px;
    border-radius: 40px;
    display: inline-block;
    position: relative;
    text-align: center;
    list-style: none
}

.quik-right-contact-bx h4 {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 8px
}

.breadcrumb-banner-area.header-inner-astrology .breadcrumb-text {
    text-align: center
}

.breadcrumb-banner-area.header-inner-astrology .breadcrumb-list-bx {
    background: 0 0;
    margin: 23px auto 0
}

.asto-images-bx {
    margin-bottom: 30px
}

.australia-work-bg {
    background: url(../images/australia-bg.jpg) no-repeat rgba(0, 0, 0, 0)
}

.canada-work-bg {
    background: url(../images/work-in-canada.jpg) no-repeat rgba(0, 0, 0, 0)
}

.work-country {
    text-align: center
}

.work-country .breadcrumb-list-bx {
    background: 0 0;
    margin: 23px auto 0
}

.new-main-banner-wrap-area {
    background: #f7f8ff;
    padding-bottom: 80px;
    overflow: hidden;
    position: relative;
    z-index: 1
}

.new-main-banner-wrap-content {
    padding-left: 0px
}

.new-main-banner-wrap-content .sub-title {
    color: #e81c28;
    font-size: 20px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px
}

.new-main-banner-wrap-content h1 {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: 900
}

.breadcrumbF ul {
    display: inline-block
}

.breadcrumbF ul li {
    list-style: none;
    display: inline-block;
    margin-right: 10px
}

.breadcrumbF ul li a {
    color: #8d8d8d;
    padding-left: 10px
}

.breadcrumbF ul>li+li::before {
    content: "/";
    font-size: 14px
}

.breadcrumb-section {
    display: flow-root;
    width: 100%;
    float: left;
    background: #f9f9f9
}

.breadcrumbF ul li.active a {
    color: red;
    font-weight: 500
}

.tt-item.details-tt-item {
    min-height: 45px;
    padding: 0 15px
}

.tt-item.details-tt-item .tt-col-description .by-forum-update {
    margin-bottom: 0
}

.fourm-cat-box span {
    font-weight: 500
}

.crate-topuc-button {
    font-size: 15px;
    line-height: 30px;
    background: #e81c28;
    padding: 5px 40px;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 600
}

.crate-topuc-button:hover {
    color: #fff
}

.new-main-banner-wrap-content h1 span {
    color: #e81c28
}

.new-main-banner-wrap-content p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 28px
}

.new-main-banner-wrap-content .btn-box {
    margin-top: 30px
}

.default-btn-with-radius {
    background-color: #e81c28;
    border: none;
    border-radius: 30px;
    color: #fff !important;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 35px;
    position: relative;
    text-align: center;
    transition: var(--transition);
    z-index: 1;
}

.new-main-banner-wrap-content .btn-box .phone-btn {
    position: relative;
    margin-left: 15px;
    padding-left: 55px;
    font-size: 20px;
    font-weight: 600;
    color: var(--blackColor)
}

.new-main-banner-wrap-content .btn-box .phone-btn i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    font-weight: 400;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: -webkit-gradient(linear, right top, left top, color-stop(-12.37%, #04c8eb), to(#5945d2));
    background: linear-gradient(270deg, #04c8eb -12.37%, #5945d2 100%);
    color: var(--whiteColor);
    font-size: 20px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    border-radius: 50%
}

.new-main-banner-wrap-image {
    margin-top: -95px;
    margin-right: -110px;
    position: relative;
    z-index: 1
}

.new-main-banner-wrap-image .wrap-circle-shape {
    position: absolute;
    right: 30px;
    bottom: -20px;
    z-index: -1
}

.education-lons-main-hd {
    background: url(../images/education-lons2.jpg) no-repeat transparent;
    background-color: #f4f4f4;
    background-position: right top 0;
    height: 500px;
    padding-top: 100px
}

.type-study-loans-section {
    padding: 30px 0 60px
}

.loans-box {
    text-align: center;
    background: #fff;
    box-shadow: 0 2px 7px 0 rgb(12 0 46 / 10%);
    border-radius: 10px
}

.loans-box-text {
    padding: 20px 0
}

.loans-box-text h5 {
    font-size: 18px;
    color: #000;
    margin-top: 0;
    margin-bottom: 0
}

.title-loans-section h3 {
    font-size: 26px;
    text-align: center;
    margin-bottom: 20px
}

.title-loans-section {
    padding: 0 0 20px
}

.best-marketing {
    position: relative;
    padding: 60px 0;
    background: #f4f4f4
}

.pt-section-title-1 {
    margin-bottom: 30px
}

.pt-section-title-1 .pt-section-title {
    font-size: 34px;
    line-height: 60px;
    z-index: 9;
    position: relative;
    margin-bottom: 5px;
    font-weight: 600
}

.pt-section-title-1 .pt-section-title span {
    color: #e81c28
}

.pt-section-title-1 .pt-section-description {
    z-index: 9;
    position: relative
}

.pt-best-marketing {
    display: flex
}

.pt-best-marketing .pt-icon-box-icon .pt-icon i {
    fill: #FF4B34;
    color: #ff4b34;
    border-color: #ff4b34;
    font-size: 22px
}

.pt-best-marketing .pt-icon-box-content .pt-icon-box-title span {
    font-size: 20px;
    font-style: normal;
    line-height: 28px;
    color: #09114a;
    font-weight: 500
}

.pt-best-marketing1 .pt-icon-box-icon .pt-icon i {
    fill: #434EFF;
    color: #434eff;
    border-color: #434eff;
    font-size: 22px
}

.talk-us h4,
.why-loans-choose-section .pt-best-marketing .pt-icon-box-content .pt-icon-box-title span,
.why-loans-choose-section .pt-section-title-1 .pt-section-title {
    color: #fff
}

.pt-section-title-1 p {
    font-size: 15px
}

.pt-section-title-1 h4 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px
}

.why-loans-choose-section {
    background: url(../images/why-choose-edu-lone.png) no-repeat rgba(0, 0, 0, 0);
    position: relative;
    background-size: 100%
}

.why-loans-choose-section:before {
    background: rgba(8, 14, 50, .86);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.why-loans-choose-section p {
    color: #fff;
    font-size: 15px
}

.service-loans-bx {
    background: #fff;
    padding: 60px 0
}

.pt-icon-box-style-06 {
    background: #f4f4f4;
    padding: 30px;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    height: 320px;
    margin-bottom: 20px
}

.pt-icon-box-style-06 .pt-icon-media {
    margin-bottom: 10px
}

.pt-icon-box-style-06 .pt-iconbox-info .pt-icon-box-title {
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 32px
}

.pt-iconbox-description {
    font-size: 15px;
    margin-top: 15px;
    line-height: 25px
}

.pt-icon-box-style-06 .pt-icon-media img {
    width: 70px
}

.talk-us {
    background-color: #080e32db;
    padding: 53px 0;
    position: relative
}

.talk-us h4 {
    display: inline-block;
    margin-top: 10px;
    font-size: 28px;
    padding-left: 100px
}

.btn-custom {
    border-radius: 50px;
    text-transform: capitalize;
    line-height: 1;
    font-weight: 600;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    overflow: hidden;
    position: relative;
    z-index: 0
}

.talk-us .btn-custom {
    background: #fff !important;
    background-color: #fff !important;
    color: #e81c28;
    float: right;
    padding: 13px 3px 10px;
    width: 200px;
    line-height: 22px;
    height: 50px
}

.talk-us::after {
    position: absolute;
    content: "\e830";
    left: 10%;
    font: 100px Linearicons-Free;
    color: #fff;
    top: 50%;
    transform: translateY(-45%);
    opacity: .3
}

.factors-section-bx {
    background: #fff;
    padding: 60px 0
}

.Factors-text-box p {
    font-size: 15px
}

.Factors-text-box h4 {
    font-size: 18px
}

.eligible-lone-list li {
    list-style: none;
    font-size: 15px;
    position: relative;
    padding-left: 16px
}

.eligible-lone-list li:before {
    position: absolute;
    content: '';
    width: 5px;
    height: 15px;
    left: 0;
    top: 10px;
    background: red;
    border-radius: 30px
}

.eligible-lone-list {
    margin-bottom: 20px
}

.chat-headtext {
    font-size: 18px;
    color: #fff;
    margin-bottom: 6px
}

.profile-performance-head {
    position: relative
}

#bgcoltran {
    border: none !important
}

.pptoparea,
.pptopareaone {
    background: #fff;
    padding: 20px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 9px 2px rgb(98 98 98 / 25%)
}

.ppheadtext {
    font-size: 22px;
    color: #fff;
    margin: 0
}

.ppheadpara {
    color: #fff;
    font-size: 12px
}

.pptopminushead {
    width: 100%;
    float: left;
    margin-top: -20px
}

.pptoparea {
    box-shadow: 0 1px 9px 2px rgb(98 98 98 / 25%)
}

.pptopareaone {
    margin-top: 20px;
    box-shadow: 0 1px 9px 2px rgb(98 98 98 / 25%)
}

.pptskillsflow,
.progressmidium {
    border-radius: 4px;
    position: relative
}

.pptopareatwo,
.recruiterrgservice {
    -webkit-box-shadow: 0 1px 9px 2px rgb(98 98 98 / 25%)
}

.pptopareaonehead {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px
}

.pptopareaflow {
    position: relative
}

.pptskillsflow {
    background: #fff2f2;
    padding: 10px;
    margin-bottom: 10px
}

.pptskillsflow h4 {
    font-size: 14px;
    font-weight: 300
}

.ppskills {
    position: relative
}

.ppskills li {
    list-style: none;
    padding-right: 30px
}

.ppskills li>div {
    font-weight: 600;
    color: #444;
    font-size: 12px
}

.progressmidium {
    background: snow;
    padding: 8px 8px 20px;
    margin-top: 4px
}

.progressmidium .midiutext {
    font-size: 17px;
    font-weight: 400;
    color: #5caee5;
    padding-bottom: 4px
}

.progressmidium>p {
    font-size: 14px;
    color: #777;
    font-weight: 500;
    margin: 0
}

.tipsimprovebox {
    position: relative;
    padding: 20px 0
}

.tipsimprovetext {
    font-size: 12px;
    padding-bottom: 10px
}

.recruiterpoints {
    padding: 0;
    margin: 0
}

.recruiterpoints li {
    list-style: none;
    position: relative;
    padding-left: 18px;
    margin-bottom: 16px
}

.recruiterpoints li:before {
    left: 2px;
    color: #00cc67;
    top: 0;
    content: '\f058';
    font: 14px FontAwesome;
    position: absolute
}

.reccheckbox,
.recproupdate {
    position: relative;
    display: block;
    line-height: 16px
}

.pptopnum-text {
    font-size: 24px;
    font-weight: 500
}

.pptopnum-text span {
    font-size: 13px
}

.pptopareatwohead,
.recruiter-name {
    font-size: 14px;
    font-weight: 600
}

.pptopplusonearea {
    width: 100%;
    float: left;
    margin-top: 20px
}

.pptopareatwo {
    background: #fff;
    padding: 20px;
    border-radius: 3px;
    margin-top: 20px;
    box-shadow: 0 1px 9px 2px rgb(98 98 98 / 25%)
}

.pptopareatwohead {
    margin-bottom: 10px
}

.pptrecuiternum {
    position: relative
}

.pptrecuiternumli {
    list-style: none
}

.pptrecuiternumli li {
    border-radius: 40px;
    display: inline-block;
    list-style: none;
    background: #ffdadc;
    margin: 4px;
    padding: 2px 12px
}

.concomdetail {
    background: #fbfbfb;
    position: relative;
    margin-top: 20px;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #d7d7d7
}

.recruiter-img {
    margin-right: 10px
}

.recruiter-img img {
    border-radius: 50px;
    max-width: 60px;
    height: 60px;
    border: 1px solid #eee;
    box-shadow: 0 2px 2px #ccc
}

.recruiter-name span {
    display: block;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px
}

.recruiter-locaton {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 15px
}

.recruiter-locaton span {
    display: block;
    font-weight: 400;
    font-size: 12px
}

.recruiter-by-email {
    font-size: 14px;
    color: #1d8233;
    font-weight: 500
}

.recruiter-by-date {
    font-size: 12px
}

.recruiterrgservice {
    background: #fff;
    padding: 20px;
    border-radius: 3px;
    margin-top: 20px;
    box-shadow: 0 1px 9px 2px rgb(98 98 98 / 25%)
}

.recruiterrghead {
    font-size: 16px;
    font-weight: 500
}

.recruiterrgtext {
    padding: 16px 20px;
    background: #fff6f6;
    border-radius: 4px;
    margin: 16px 0
}

.recruiter-messagebox {
    background: #fff;
    border-radius: 4px;
    margin-top: -20px;
    -webkit-box-shadow: 0 1px 9px 2px rgb(98 98 98 / 25%);
    box-shadow: 0 1px 9px 2px rgb(98 98 98 / 25%)
}

.recruiter-messagebox-head {
    padding: 20px;
    background: #ffdcdc;
    border-radius: 4px 4px 0 0;
    font-weight: 500;
    font-size: 20px
}

.recruiter-messagebox-tabs {
    padding: 0;
    margin: 0;
    position: relative;
    height: calc(100vh - 184px);
    overflow: auto
}

.recruiter-messagebox-tabs::-webkit-scrollbar {
    width: 6px
}

.recruiter-messagebox-tabs::-webkit-scrollbar-track {
    background-color: #d3d3d3
}

.recruiter-messagebox-tabs::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
}

.recruiter-messagebox-tabs li {
    list-style: none;
    position: relative;
    margin: 0;
    border-top: 1px solid #f9f9f9;
    border-bottom: 1px solid #f9f9f9
}

.recruiter-messagebox-tabs li a.active {
    background: #f9f9f9;
    border-left: 3px solid #222
}

.recruiter-messagebox-tabs li a {
    display: block;
    color: #444;
    border-left: 3px solid #f9f9f9
}

.recruiter-messagebox-tabs li a:hover {
    background: #f9f9f9;
    border-left: 3px solid #222
}

.persionimg {
    position: relative;
    margin: 0 14px 0 4px
}

.persionimg img {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    border: 1px solid #ccc
}

.chatpersion-name {
    position: relative
}

.chatpersion-name h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0
}

.chatpersion-name p {
    font-size: 13px;
    margin-bottom: 2px
}

.chat-message-text {
    color: #999
}

.recruiter-message-chat {
    position: relative;
    padding: 10px;
    border-radius: 4px 4px 0 0;
    background: #eee
}

.persionchatimg {
    position: relative;
    padding-right: 10px
}

.persionchatimg img {
    max-width: 70px;
    height: 70px;
    border-radius: 50px;
    border: 4px solid #ccc
}

.persionchatmsg {
    position: relative;
    padding: 4px 0 0
}

.persionchatmsg h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0
}

.persionchatmsg p {
    font-weight: 400;
    font-size: 16px
}

.recruiter-message-write {
    padding: 20px;
    height: calc(89vh - 184px);
    overflow: auto
}

.recruiter-message-write::-webkit-scrollbar {
    width: 10px
}

.recruiter-message-write::-webkit-scrollbar-track {
    background-color: #a9a9a9
}

.recruiter-message-write::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
}

.chat-date-year {
    margin-bottom: 5px;
    font-weight: 600;
    color: #52525299
}

.chat-conversation li {
    clear: both;
    list-style: none
}

.chat-conversation .conversation-list {
    margin-bottom: 24px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.chat-conversation .conversation-list .ctext-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px
}

.chat-conversation .conversation-list .ctext-wrap-content {
    padding: 12px 20px;
    background-color: #fff0f0;
    border-radius: 8px 8px 8px 0;
    color: #fff;
    position: relative
}

.chat-conversation .conversation-list .ctext-wrap-content:before {
    content: "";
    position: absolute;
    border: 5px solid transparent;
    border-left-color: #fff0f0;
    border-top-color: #fff0f0;
    left: 0;
    right: auto;
    bottom: -10px
}

.chat-conversation .conversation-list .ctext-wrap-content p {
    color: #606060;
    font-size: 14px
}

.chat-conversation .conversation-list .ctext-wrap-content .chat-time {
    color: #999
}

.chat-conversation .conversation-list .chat-time {
    font-size: 12px;
    margin-top: 4px;
    text-align: right;
    color: rgba(255, 255, 255, .5)
}

.chat-conversation .conversation-list .conversation-name {
    font-weight: 500;
    font-size: 14px
}

.chat-conversation .right .conversation-list {
    float: right;
    text-align: right
}

.chat-conversation .right .conversation-list .ctext-wrap {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    background-color: #eee;
    color: var(--bs-dark);
    text-align: right;
    border-radius: 8px 8px 0
}

.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content:before {
    border: 5px solid transparent;
    border-top-color: #eee;
    border-right-color: #eee;
    left: auto;
    right: 0
}

.chat-conversation .right .chat-time {
    text-align: left;
    color: var(--bs-gray-600)
}

.chat-input-section {
    background-color: #f9f9f9
}

.chat-send-box {
    border: 1px solid #eee !important;
    height: 50px !important;
    padding: 0 10px 10px !important
}

.faq-section {
    background: #fdfdfd;
    min-height: 100vh;
    padding: 10vh 0 0
}

.faq-title h2 {
    position: relative;
    margin-bottom: 20px;
    display: inline-block;
    font-weight: 600;
    line-height: 1
}

.faq-title p {
    font-size: 15px;
    margin-bottom: 10px
}

.faq {
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, .06);
    border-radius: 4px
}

.faq .card {
    border: none;
    background: 0 0;
    border-bottom: 1px dashed #cee1f8
}

.faq .card .card-header {
    padding: 0;
    border: none;
    background: 0 0;
    transition: all .3s ease 0s
}

.faq .card .card-header:hover {
    background: rgba(233, 30, 99, .1);
    padding-left: 10px
}

.faq .card .card-header .faq-title {
    width: 100%;
    text-align: left;
    padding: 20px 30px;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    color: #3b566e;
    text-decoration: none !important;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    cursor: pointer
}

.faq .card .card-header .faq-title .badge {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 10px;
    float: left;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    text-align: center;
    background: #e81c28;
    color: #fff;
    font-size: 12px;
    margin-right: 20px
}

.faq .card .card-body {
    padding: 30px 30px 16px 35px;
    font-weight: 400;
    font-size: 16px;
    color: #6f8ba4;
    line-height: 28px;
    letter-spacing: 1px;
    border-top: 1px solid #f3f8ff
}

.apply-for-loan-modal button,
.default-about-btn {
    display: inline-block;
    transition: var(--transition);
    font-weight: 700;
    position: relative;
    text-align: center;
    z-index: 1
}

.faq .card .card-body p {
    margin-bottom: 14px;
    color: #3b566e;
    font-size: 14px
}

.default-about-btn {
    color: #e81c28 !important;
    padding: 10px 35px;
    border-radius: 30px;
    font-size: 16px;
    border: 1px solid #e81c28
}

.faq-section .faq .card .card-body ul li {
    color: #3b566e;
    font-size: 14px;
    letter-spacing: 0
}

.faq-section .faq .card .card-body ul {
    padding-left: 15px;
    margin-bottom: 20px
}

.education-loans-title .modal-header h5 {
    font-size: 20px;
    margin-bottom: 0;
    margin-left: 17px
}

.apply-for-loan-modal button {
    background-color: #e81c28;
    border: none;
    border-radius: 5px;
    color: #fff !important;
    font-size: 16px;
    margin-top: 30px;
    padding: 10px 35px;
}

.uk-work-bg {
    background: url(../images/work-in-uk.jpg) no-repeat rgba(0, 0, 0, 0)
}

.usa-work-bg {
    background: url(../images/work-in-usa.jpg) no-repeat rgba(0, 0, 0, 0);
    position: relative
}

.ireland-work-bg {
    background: url(../images/work-in-ireland.jpg) no-repeat rgba(0, 0, 0, 0);
    position: relative
}

.work-in-new-zealand-bg {
    background: url(../images/work-in-new-zealand.jpg) no-repeat rgba(0, 0, 0, 0);
    position: relative
}

.work-in-singapore-bg {
    background: url(../images/work-in-singapore.jpg) no-repeat rgba(0, 0, 0, 0);
    position: relative
}

.work-in-malaysia-bg {
    background: url(../images/work-in-malaysia.jpg) no-repeat rgba(0, 0, 0, 0);
    position: relative
}

.apply-for-loan-modal .form-group select {
    width: 100%
}

@media screen and (min-width:769px) {
    .mobilehide {
        display: none !important
    }
}

@media screen and (max-width:769px) {
    .mobilehView {
        height: 85vh;
        width: 100vw;
        background-color: #fff
    }

    .top-menu.top-menu-new {
        display: none
    }

    .rg-logo a img {
        margin-top: 0
    }

    .mobilehide {
        position: relative
    }

    .mobilehide.full-screen {
        height: 100%;
        width: 100%;
        position: relative;
        background: #203342 url(../images/slider/mobileveiwpic.jpg) no-repeat, no-repeat unset 0 0;
        margin-top: -20px;
        background-size: 100vw 100vh
    }

    .rg-heading {
        padding: 0 20px;
        position: absolute;
        top: 32%;
        text-align: center;
        width: 100%
    }

    .rg-heading h2 {
        font-size: 20px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 300
    }

    .rg-heading p {
        font-size: 16px;
        color: #fff
    }

    .rg-mobietext {
        padding: 0 6px 20px;
        position: absolute;
        width: 340px;
        bottom: 70px;
        left: 0;
        right: 0;
        margin: 0 auto
    }

    .btnlogin,
    .btnregisternow {
        font-size: 18px;
        padding: 10px 30px;
        margin: 0 10px;
        color: #fff
    }

    .btnlogin {
        background: 0 0;
        border-radius: 2px;
        border: 1px solid #fff
    }

    .btnregisternow {
        background: #e81c28;
        border-radius: 2px;
        border: 1px solid #fff
    }

    .rg-wrapper {
        max-width: 300px;
        padding: 10% 0;
        margin: 0 auto
    }

    .rg-wrapper .rg-search {
        background: 0 0;
        border: 1px solid #fff;
        width: 100%;
        border-radius: 5px;
        position: relative;
        box-shadow: 0 1px 5px 3px rgba(0, 0, 0, .12)
    }

    .rg-search input {
        height: 45px;
        color: #fff !important;
        width: 100%;
        background: 0 0;
        outline: 0;
        border: none;
        border-radius: 5px;
        padding: 0 60px 0 20px;
        font-size: 16px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, .1)
    }

    .rg-search.active input {
        border-radius: 5px 5px 0 0
    }

    .rg-search .icon {
        position: absolute;
        left: 0;
        top: -10px;
        height: 55px;
        width: 55px;
        text-align: center;
        line-height: 70px;
        font-size: 20px;
        color: #fff;
        cursor: pointer
    }
}

.byCourseSection .form-control {
    padding: 10px 20px 10px 15px
}

.rg-professionalcontent h3 .rg-onMouseHover:hover {
    color: red
}

.rg-onMouseHover:hover {
    color: red !important
}

li .rg-backgroundHover:hover {
    background-color: #ffeaec !important
}

.rg-onHoverButton:hover {
    background-color: #e62e2d !important;
    color: #fff !important
}

.appstatusofferbox {
    text-align: center;
    background: #fbeded url(../images/contratebg.jpg);
    background-size: cover;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    padding: 12px 20px;
    background-blend-mode: color-burn;
}

.appstatusofferbox::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: 0 0;
    left: 0;
    top: 0;
    z-index: -1
}

.appstatusclick {
    color: #2c50a1 !important;
    cursor: pointer;
    border-bottom: 1px dashed #2c50a1
}

.appstatusbtn {
    color: #fff !important;
    padding: 2px 30px;
    color: #fff !important;
    cursor: pointer;
    border-radius: 50px;
    background-color: #e81c28;
}

.clickcheckbox {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center
}

.contratetext {
    font-size: 18px;
    color: #1fa71f
}

.rg-onButtonHover {
    color: #fff !important;
    font-weight: 500;
    padding: 10px 25px;
    color: #fff !important;
    border: 1px solid #e81c28;
    border-radius: 5px;
    transition: all .3s ease-in-out;
    background: #e62e2d
}

.rg-onButtonHover:hover {
    background-color: #fff !important;
    border: 1px solid #e62e2d;
    color: #000 !important
}

.pdfwidth70 {
    width: 75% !important
}

.pdfwidth30 {
    width: 25% !important
}

.pdftemp3width75 {
    width: 70% !important;
    max-width: 70% !important
}

.pdftemp3width25 {
    width: 30% !important;
    max-width: 30% !important
}

.resume-template-one {
    max-width: 960px;
    position: relative;
    display: flow-root;
    width: 100%;
    padding: 0 15px 30px;
    border: 3px dotted #000;
    margin: 50px auto
}

.resume-four-with-box,
.resume-with-box,
.resume-with-three-box {
    box-shadow: 0 6px 20px #eee;
    max-width: 1000px
}

.top-header-resume h4 {
    padding: 30px;
    display: inline-block;
    background: #ee2c45;
    color: #fff;
    position: relative;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 0;
    height: 100px;
    margin-top: -1px
}

.top-header-resume h4:after {
    position: absolute;
    content: '';
    width: 131px;
    height: 0;
    left: 0;
    bottom: -1px;
    background-color: transparent;
    border-left: 65px solid #ddd0;
    border-right: 65px solid #ddd0;
    border-bottom: 20px solid #fff
}

.body-resume-right-section .user-name {
    padding: 10px 0
}

.body-resume-right-section .user-name h5 {
    font-size: 45px;
    font-weight: 300;
    color: #ee2c45
}

.topoic-head-section {
    display: flex;
    flex-direction: row;
    align-items: center
}

.topoic-head-section .div-year-bx {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    width: 90px
}

.topoic-head-section img {
    width: 40px
}

.topoic-head-section .div-img-bx {
    padding: 5px 15px
}

.top-hd-social-bx ul li {
    list-style: none;
    font-size: 13px;
    display: flex;
    align-items: center
}

.top-hd-social-bx ul {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.top-hd-social-bx ul li.tw-bx img {
    width: 24px;
    margin-right: 5px
}

.top-hd-social-bx ul li.tum-bx img {
    width: 14px;
    margin-right: 5px
}

.top-hd-social-bx ul li.mob-bx img {
    width: 16px;
    margin-right: 5px
}

.top-hd-social-bx ul li.em-bx img {
    width: 27px;
    margin-right: 5px
}

.top-hd-social-bx ul li.add-box img {
    width: 25px;
    margin-right: 5px
}

.resume-wrap-bx {
    display: flex;
    margin-top: 30px
}

.diploma-project-bx h5,
.project-info-bx h5 {
    display: inline-block;
    border-bottom: 1px solid #000
}

.top-hd-social-bx ul li.add-box {
    width: 180px;
    line-height: 19px
}

.resume-right-temp-three-bx,
.right-section-resume-three {
    width: 50%;
    float: left
}

.resume-with-three-box {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin: 50px auto
}

.achievement-list-ul-bx,
.achievement-sec-box,
.professional-experience-bx {
    margin-top: 30px
}

.name-user-bx h4 {
    margin-bottom: 0;
    font-size: 30px
}

.name-user-bx p {
    color: #9d9a9a;
    font-size: 18px
}

.about-user-bx p,
.project-info-bx p {
    font-size: 15px
}

.project-info-bx h5 {
    font-size: 17px
}

.about-user-bx {
    margin-bottom: 30px
}

.diploma-project-bx p {
    font-size: 15px
}

.diploma-project-bx p span {
    font-weight: 600
}

.diploma-project-bx h5 {
    font-size: 17px
}

.achievement-list-ul-bx li {
    list-style: none;
    margin-bottom: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 600
}

.edu-name-uni-bx p,
.edu-year-bx p {
    margin-bottom: 0
}

.achievement-list-ul-bx li span {
    margin-left: 20px;
    line-height: 20px
}

.achievement-sec-box h4 {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700
}

.achievement-list-ul-bx li img {
    width: 45px
}

.education-three-box {
    display: flex;
    flex-direction: row;
    align-items: center
}

.education-three-box .edu-year-bx {
    margin-right: 20px;
    width: 80px
}

.edu-name-uni-bx {
    position: relative;
    padding-left: 20px;
    padding-bottom: 10px
}

.edu-name-uni-bx:after,
.edu-name-uni-bx:before {
    position: absolute;
    content: '';
    top: 7px;
    border-radius: 30px;
    background: #000
}

.edu-year-bx p {
    font-weight: 600;
    font-size: 15px
}

.experience-item-bx h5,
.experience-item-bx h6 {
    margin-bottom: 4px;
    font-size: 15px;
    font-weight: 400
}

.edu-name-uni-bx:before {
    width: 10px;
    height: 10px;
    left: 0
}

.edu-name-uni-bx:after {
    width: 2px;
    height: 100%;
    left: 5px
}

.education-three-box.education-three-last .edu-name-uni-bx:after {
    display: none
}

.education-three-box.education-three-last .edu-name-uni-bx:before {
    width: 7px;
    height: 7px;
    left: 2px
}

.education-three-box.education-three-last .edu-name-uni-bx {
    padding-bottom: 0
}

.professional-experience-bx h4 {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700
}

.experience-item-bx h6 {
    border-bottom: 1px solid #000;
    display: inline-block
}

.experience-item-bx p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400
}

.experience-item-bx {
    margin-bottom: 15px
}

.tools-item-box,
.tools-item-box .tools-mark-bx {
    display: flex
}

.tools-mark-bx ul li.active,
.tools-mark-bx ul li.dactive {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 9px;
    border-radius: 100%
}

.tools-item-box .tools-mark-bx ul li {
    list-style: none
}

.tools-mark-bx {
    margin-left: 20px
}

.tools-mark-bx ul li.active {
    background: #000
}

.tools-mark-bx ul li.dactive {
    background: #d5d4d5
}

.tools-item-box .tools-name {
    width: 160px;
    text-align: right
}

.tools-item-box .tools-name h4 {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500
}

.tools-box h4 {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700
}

.edu-name-uni-bx h5 {
    margin-bottom: 0
}

.interests-list-bx li {
    list-style: none;
    display: flex;
    border: 1px solid #000;
    border-radius: 100%;
    margin-right: 10px;
    height: 100px;
    width: 100px;
    text-align: center;
    padding: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center
}

.interests-list-bx li img {
    width: 40px
}

.interests-list-bx li span {
    line-height: 14px;
    font-size: 12px;
    font-weight: 500;
    margin-top: 2px;
    display: block
}

.interests-resume-bx .interests-list-bx {
    display: flex
}

.interests-resume-bx h4 {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700
}

.interests-resume-bx {
    margin-top: 30px
}

.right-section-resume-three {
    width: 48%;
    float: left;
    margin-left: 40px
}

.education-in-bx .education-item h4 span,
.education-in-bx .education-item p span,
.had-py-sec h4 .hd-rgt {
    float: right
}

.top-had-resume4 {
    display: flex;
    background: #ee3739
}

.top-had-resume4 .user-profile-bx {
    width: 15%;
    margin-right: 20px
}

.top-had-resume4 .name-user-bx h3 {
    font-size: 30px;
    color: #fff
}

.top-had-resume4 .name-user-bx p {
    font-size: 20px;
    color: #fff;
    margin-bottom: 0
}

.top-had-resume4 .name-user-bx {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center
}

.top-had-resume4 .user-info-bx {
    padding-right: 40px;
    width: 25%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column
}

.top-had-resume4 .user-info-bx p {
    margin-bottom: 0;
    text-align: right
}

.top-had-resume4 .user-info-bx p a {
    color: #fff
}

.top-had-resume4 .user-info-bx p.com-box a {
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color: #fff
}

.top-had-resume4 .user-info-bx p.com-box,
.top-had-resume4 .user-info-bx p.mail-box {
    margin-bottom: 5px
}

.top-had-resume4 .user-info-bx p.mail-box a,
.top-had-resume4 .user-info-bx p.phon-box a {
    text-decoration: none;
    font-size: 16px
}

.temp-about-me-bx p {
    text-align: center;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 0;
    position: relative
}

.temp-about-me-bx {
    padding: 40px;
    position: relative
}

.temp-about-me-bx p:after,
.temp-about-me-bx p:before {
    position: absolute;
    width: 100px;
    height: 20px;
    color: #cdcdce;
    top: 0;
    font-family: FontAwesome
}

.temp-about-me-bx p:before {
    left: -50px;
    content: '\f10d'
}

.temp-about-me-bx p:after {
    right: -50px;
    content: '\f10e'
}

.part-designer-bx .wrapper .d1 div span,
.part-designer-bx .wrapper .d2 div span {
    display: block;
    font-size: 16px;
    text-align: center;
    z-index: 100;
    color: #fff;
    font-weight: 700
}

.part-designer-bx .wrapper {
    width: 200px;
    height: 200px;
    background: #fc5b3f;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    transform: rotate(108deg)
}

.part-designer-bx .wrapper .d1 {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 20px;
    transform: rotate(0)
}

.part-designer-bx .wrapper .d1 div {
    width: 300px;
    height: 300px
}

.part-designer-bx .wrapper .d1 div:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    border: solid transparent;
    border-width: 400px;
    border-top-color: #5c8bc6;
    position: relative;
    transform: scaleX(-3.07768)
}

.part-designer-bx .wrapper .d1 div span {
    width: 100%;
    position: absolute;
    left: -81px;
    top: 14%;
    transform: rotate(-108deg)
}

.part-designer-bx .wrapper .d2 {
    width: 770px;
    height: 770px;
    position: absolute;
    top: -252px;
    left: -173px;
    transform: rotate(156deg)
}

.part-designer-bx .wrapper .d2 div {
    width: 400px;
    height: 400px
}

.part-designer-bx .wrapper .d2 div:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    border: solid transparent;
    border-width: 480px;
    border-top-color: #3f77bc;
    position: relative;
    transform: scaleX(12.82727)
}

.part-designer-bx .wrapper .d2 div span {
    width: 100%;
    position: absolute;
    left: 92px;
    top: 54%;
    transform: rotate(-266.6deg)
}

.had-py-sec h4 {
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px
}

.left-designer-bx ul li {
    list-style: none
}

.right-designer-bx ul li {
    list-style: none;
    text-align: right
}

.ma-section-box {
    display: flex;
    justify-content: space-between
}

.part-designer-bx .about-profile-bx .skills-history-box .progress-bar-box {
    display: flex
}

.part-designer-bx .about-profile-bx .skills-history-box .progress-bar-box h5 {
    width: 150px
}

.part-designer-bx .skills-history-box .progress {
    height: 5px
}

.part-designer-bx .skills-history-box .bar {
    background: #4798d2
}

.bar-yellow {
    background: #f89920 !important
}

.bar-green {
    background: #8fc741 !important
}

.bar-red {
    background: #f16285 !important
}

.bar-greenblue {
    background: #19bcb8 !important
}

.education-in-bx .education-item p {
    margin-bottom: 0;
    font-size: 15px
}

.education-in-bx .education-item {
    margin-bottom: 15px
}

.personal-info-lft-bx p {
    display: flex;
    font-size: 15px;
    margin-bottom: 15px
}

.personal-info-lft-bx p span {
    width: 150px;
    color: #5b5b5b
}

.education-section-bx,
.part-designer-bx,
.personal-information-sec {
    margin-bottom: 30px
}

.education-in-bx,
.personal-info-lft-bx {
    padding: 20px 0
}

.pr-experience-item-bx h4 {
    font-size: 20px;
    font-weight: 400
}

.pr-experience-item-bx h5 {
    font-size: 18px;
    color: #515151;
    font-weight: 400
}

.pr-experience-item-bx p {
    margin-bottom: 0;
    font-size: 15px;
    margin-top: 5px
}

.pr-experience-item-bx {
    margin-top: 20px
}

.join-me-link-bx p {
    margin-bottom: 5px;
    font-size: 15px;
    display: flex
}

.join-me-link-bx p span {
    margin-right: 5px;
    width: 80px
}

.join-me-link-bx p a,
.join-me-link-bx p a:hover {
    color: #222
}

.join-me-link-bx {
    padding: 30px 0
}

.resume-four-with-box {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin: 50px auto
}

.signature-bootom-box {
    text-align: right;
    margin-bottom: 30px;
    margin-top: 30px
}

.signature-bootom-box p {
    margin-bottom: 5px;
    font-size: 20px
}

.signature-bootom-box h5 {
    font-size: 20px;
    font-weight: 400
}

.topoic-head-section .div-hd-bx h4 {
    color: #ee2c45;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700
}

.topoic-head-section .div-hd-bx p {
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 600
}

.left-title-hd,
.right-title-hd {
    margin-bottom: 30px;
    margin-top: 30px
}

.topoic-head-section .div-hd-bx h4 span {
    text-transform: initial
}

.topoic-about-section {
    margin-left: 123px;
    border-left: 4px dotted #ee2c45;
    padding-left: 38px;
    display: flex;
    align-items: center;
    min-height: 150px
}

.body-resume-right-section {
    width: 60%;
    float: left
}

.resume-user-img img {
    border-radius: 100%;
    border: 10px solid;
    height: 130px
}

.resume-user-img {
    text-align: center;
    margin-top: -60px
}

.body-resume-left-section {
    float: left;
    width: 40%;
    background: #ee2c45;
    padding-right: 20px;
    padding-left: 20px
}

.body-resume-left-section .right-title-hd h4 {
    font-size: 18px;
    color: #fff;
    text-transform: uppercase
}

.resume-skills-list-bx li {
    list-style: none;
    background: #fff;
    width: 40px;
    height: 20px;
    display: inline-block;
    border-right: 1px solid #ee2c45
}

.resume-skills-list-bx {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-bottom: 10px
}

.resume-skills-list-bx .skills-img-bx {
    height: 45px;
    background: #f2bea8;
    padding: 5px;
    width: 45px;
    border-radius: 100%;
    display: flex;
    margin-right: -5px;
    z-index: 9;
    border: none;
    align-items: center;
    justify-content: center
}

.resume-skills-list-bx .skills-img-bx img {
    width: 30px
}

.resume-skills-list-bx li:last-child {
    border-right: 0
}

.love-filed-list-bx li {
    list-style: none;
    display: inline-block;
    padding: 0 18px;
    border-radius: 1px;
    background: #f2bea8;
    margin-right: 10px;
    font-size: 15px;
    margin-bottom: 10px
}

.get-in-resume-bx {
    display: flex;
    align-items: center;
    background: #f2bea8
}

.get-resume-img-bx {
    padding: 10px
}

.get-resume-img-bx img {
    height: 45px;
    width: 40px
}

.get-resume-text-bx p {
    margin-bottom: 5px;
    font-size: 16px;
    word-break: break-all
}

.social-list-box li {
    list-style: none;
    margin-bottom: 10px;
    font-size: 15px
}

.social-icons-box {
    background: #fff;
    margin-top: 30px;
    padding: 10px;
    margin-bottom: 30px;
    display: flex
}

.social-list-box li span {
    height: 26px;
    width: 26px;
    line-height: 26px;
    text-align: center;
    border: 1px solid #000;
    font-size: 14px
}

.topoic-about-section ul li {
    list-style: none;
    line-height: 24px;
    font-size: 15px
}

.social-icons-box .social-left-bx,
.social-icons-box .social-right-bx {
    width: 50%;
    float: left
}

.topoic-about-section ul li img {
    width: 20px;
    margin-top: -3px
}

.candidate-info-bx .candidate-img-info {
    width: 115px;
    float: left
}

.candidate-info-bx .candidate-img-info img {
    border-radius: 100%;
    height: 100px
}

.candidate-name-info ul li {
    display: inline-flex;
    font-size: 20px;
    margin-right: 30px
}

.candidate-name-info h4 {
    color: #9f9f9f;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 0
}

.candidate-name-info {
    width: 80%;
    float: left;
    padding-left: 20px
}

.candidate-info-bx {
    display: flex
}

.about-profile-bx h4 {
    font-size: 26px;
    padding: 8px 0;
    font-weight: 400;
    border-bottom: 2px solid #1e1359;
    color: #16027e
}

.about-profile-bx p {
    font-size: 15px
}

.employment-history-box {
    margin-bottom: 30px
}

.candidate-name-info h5 {
    color: #16027e;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700
}

.candidate-name-info p,
.candidate-name-info p a {
    color: #16027e;
    font-size: 20px;
    margin-bottom: 0
}

.about-profile-bx {
    margin-top: 35px
}

.about-profile-bx ul li {
    font-size: 15px;
    list-style: none;
    position: relative;
    padding-left: 15px
}

.about-profile-bx ul li:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    left: 0;
    top: 11px;
    background: #222;
    border-radius: 30px
}

.duration-work-bx {
    float: right
}

.employment-history-box h5 {
    font-size: 20px
}

.employment-history-box h5 span {
    font-size: 16px
}

.employment-history-box h6 span {
    color: #16027e;
    font-weight: 700;
    font-size: 18px
}

.emp-history-border {
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd
}

.right-contact-bx ul li {
    line-height: 28px;
    list-style: none;
    font-size: 16px;
    color: #1f106c;
    font-weight: 500;
    margin-bottom: 10px
}

.right-contact-bx ul li .fa-envelope {
    font-size: 18px
}

.right-contact-bx ul li .fa-map-marker,
.right-contact-bx ul li .fa-mobile {
    font-size: 28px
}

.right-contact-bx ul li span {
    margin-right: 10px
}

.skills-history-box .progress {
    padding: 0;
    width: 100%;
    height: 15px;
    overflow: hidden;
    background: #ebebeb;
    border-radius: 0
}

.skills-history-box .bar {
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: #210f7e
}

.skills-history-box .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-size: 12px;
    color: #fff
}

.progress-bar-box h5 {
    margin-bottom: 0;
    font-weight: 400
}

.progress-bar-box {
    margin-bottom: 15px
}

.resume-right-temp-two-bx {
    width: 68%;
    float: left
}

.right-section-resume-two {
    margin-left: 40px
}

.resume-with-box {
    display: flex;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin: 50px auto
}

.imas-history-box {
    position: relative;
    display: flow-root;
    min-height: 230px;
    margin-top: 40px
}

.imas-history-box .one,
.imas-history-box .two {
    color: #fff;
    border-radius: 100%;
    display: flex;
    position: absolute;
    top: 0
}

.imas-history-box .one {
    background: #211174;
    width: 150px;
    height: 150px;
    align-items: center;
    justify-content: center;
    left: 0;
    text-align: center;
    padding: 10px 10px 40px;
    font-size: 15px
}

.imas-history-box .two {
    background: #3d27aa;
    width: 130px;
    height: 130px;
    align-items: center;
    justify-content: center;
    left: 120px;
    text-align: center;
    padding: 10px
}

.imas-history-box .four,
.imas-history-box .three {
    color: #fff;
    border-radius: 100%;
    display: flex;
    text-align: center;
    position: absolute;
    top: 90px;
    padding: 10px;
    font-size: 15px
}

.imas-history-box .three {
    background: #322189;
    width: 100px;
    height: 100px;
    align-items: center;
    justify-content: center;
    left: 40px;
    z-index: 9
}

.imas-history-box .four {
    background: #0f0447;
    width: 140px;
    height: 140px;
    align-items: center;
    justify-content: center;
    left: 116px
}

button.sendme-jobslike {
    outline: 0;
    background: 0 0;
    color: #ea3333;
    font-weight: 500
}

.other-services-section h4 {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding-left: 14px
}

.other-services-section ul li {
    list-style: none;
    position: relative
}

.other-services-section {
    margin: 0 0 5px;
    border: 1px solid #eff2f5;
    background: #f5f5f5;
    padding: 10px 0 0
}

.seo-list-link-section .functional-area-list li {
    list-style: none;
    display: block;
    width: 100%
}

.seo-list-link-section .brows-by-locations-bx {
    margin-top: 0
}

.seo-list-link-section .functional-area-list li a {
    text-decoration: underline
}

.other-services-section ul li a {
    position: relative;
    background: #fff;
    border-bottom: 1px solid #ccc;
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px;
    text-decoration: none;
    transition: .2s linear
}

.other-services-section ul li a:before {
    color: #048189;
    content: "\f105";
    font: 600 12px FontAwesome;
    left: 8px;
    position: absolute;
    top: 10px
}

.seo-about-sec-bx {
    padding: 20px
}

.seo-about-sec-bx p {
    margin-bottom: 10px
}

.seo-list-link-section .by-locations-head h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 0;
    text-transform: inherit
}

.other-services-section ul li:last-child a {
    border-bottom: 0
}

.other-services-section ul li a:hover {
    background: #4c4c4c;
    color: #fff
}

.swiggyjobs-bx img {
    padding: 0 0 !important;
    width: auto;
    margin: 0 auto;
    display: block;
    background: #fff;
    border: 1px solid #eff2f5
}

.swiggyjobs-bx {
    padding: 0;
    margin-top: 5px
}

.add-bootom-box,
.rightform-right {
    margin-top: 20px
}

.add-bootom-box {
    margin-bottom: 20px
}

.add-bootom-box img {
    border-radius: 2px
}

.jobs-name-section li a {
    color: #333
}

.jobs-name-section li {
    width: 50% !important;
    float: left
}

.swiggyjobs-bx.mb {
    margin-bottom: 15px
}

.app-status-bx .statusCont::-webkit-scrollbar,
.app-status-bx .statusCont::-webkit-scrollbar-track {
    background-color: #d9d8d88a;
    display: block;
    height: 4px
}

.create-jobs-seo-box,
.create-jobs-seo-box .create-free-job-alert {
    position: relative
}

.create-free-job-alert.new-create-free {
    position: relative;
    display: flow-root;
    float: left;
    width: 100%;
    margin-top: 3px
}

.swiggy-about-box .jobs-lakh {
    font-size: 22px !important
}

.swiggy-about-box .jobs-lakh span {
    color: #e81c28
}

.pm-had-banners .container-fluid {
    padding: 0
}

.azadi-banner-box {
    width: 45%;
    float: left;
    margin-right: 15px
}

.overview-table h2 {
    clear: both;
    margin-top: 20px;
    margin-bottom: 30px
}

.overview-table .table tr td,
.overview-table .table tr th {
    border: none;
    text-align: left;
    font-size: 14px
}

.overview-table .table thead {
    background: #d0d0d0
}

.list-benefits-box li {
    font-size: 14px;
    position: relative;
    list-style: none;
    padding-left: 20px
}

.job-list-section {
    display: flow-root;
    float: left;
    width: 100%
}

.job-list-section .job-title-bx {
    font-size: 24px;
    color: #464646;
    margin-top: 30px;
    margin-bottom: 20px
}

.list-benefits-box li:before {
    position: absolute;
    content: '';
    left: 0;
    top: 11px;
    width: 7px;
    height: 7px;
    background: #aa3d68;
    border-radius: 1px
}

.pm-rozgar-mela-section img {
    border-radius: 3px
}

.pm-rozgarmela-blog .blogcategoryscroll {
    min-height: 1500px;
    max-height: 1500px
}

.rg-categories-rozgarmela {
    margin-top: 20px
}

.pm-rozgar-mela-section {
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    border: 1px solid #eff2f5;
    border-radius: 3px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%)
}

.roz-company-hiring.pm-roz-company-hiring {
    margin-top: 10px
}

.pm-rozgarmela-blog .blogcategoryscroll li a {
    display: flex;
    line-height: 22px
}

.pm-rozgarmela-blog .blogcategoryscroll li a i {
    padding-top: 5px
}

.qaaddmore {
    color: #2899cc !important;
    color: #2899cc !important;
    border-radius: 50px;
    padding: 2px 10px;
    margin-top: 6px;
    display: inline-block;
    line-height: 16px
}

.qaaddmore:hover {
    font-size: 13px;
    border-bottom: 0 solid #0d7eb1;
    color: #0d7eb1;
    background: #e5f7ff
}

.addmultiqus {
    background: #ffeff7;
    border-radius: 50px;
    padding: 4px;
    width: 30px;
    height: 30px;
    line-height: 22px;
    text-align: center;
    display: inline-block
}

.showalljob {
    background: linear-gradient(45deg, #e3efff, #ffeded);
    border-radius: 70% 30% 30% 70% / 60% 40% 60% 50%;
    padding: 10px;
}

.rg-ourprofessionals a:hover .showalljob {
    background: #fff0f0
}

.loginwithotp {
    display: inline-block;
    background: #777;
    border-radius: 50px;
    margin-bottom: 20px;
    padding: 2px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer
}

@media (max-width:767px) {
    #error_page h2 {
        font-size: 4.5rem
    }
}

@media (max-width:767px) {
    #error_page p {
        font-size: 1.125rem
    }

    #error_page {
        padding: 0;
        height: 500px
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes blinker {
    50% {
        opacity: 1;
        background: #000;
        color: #fff
    }
}

@media only screen and (max-width:1199px) {
    .fxt-template-layout4 .fxt-bg-wrap {
        padding: 8vh 0 8vh 10vw
    }
}

@media only screen and (max-width:991px) {
    .fxt-template-layout4 .fxt-bg-wrap {
        padding: 6vh 0 6vh 6vw
    }
}

@media only screen and (max-width:767px) {
    .fxt-template-layout4 .fxt-bg-wrap {
        padding: 5vh 4vw
    }
}

@media only screen and (max-width:991px) {
    .fxt-template-layout4 .fxt-bg-color {
        padding: 15px
    }
}

@media only screen and (max-width:767px) {
    .fxt-template-layout4 .fxt-bg-color {
        min-height: 100%;
        padding: 20px 30px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media only screen and (max-width:991px) {
    .fxt-template-layout4 .fxt-bg-img {
        padding: 30px
    }

    .fxt-template-layout4 .fxt-header h1 {
        font-size: 30px
    }
}

@media only screen and (max-width:767px) {
    .fxt-template-layout4 .fxt-header h1 {
        font-size: 28px
    }
}

@media only screen and (max-width:1199px) {

    .fxt-template-layout4 .fxt-content,
    .fxt-template-layout4 .fxt-content-reg {
        padding: 0
    }
}

@media only screen and (max-width:575px) {
    .fxt-template-layout4 ul.fxt-socials li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%
    }
}

@media only screen and (max-width:350px) {
    .fxt-template-layout4 ul.fxt-socials li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%
    }
}

@keyframes moveLeftRight {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px)
    }
}

@-webkit-keyframes zoomInOut {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-moz-keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%)
    }
}

@-webkit-keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%)
    }
}

@-o-keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%)
    }
}

@keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -o-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%)
    }
}

@keyframes spin {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}

@media screen and (min-width:769px) {
    .mobilehide {
        display: none !important
    }
}

@media screen and (max-width:769px) {
    .mobilehView {
        height: 85vh;
        width: 100vw;
        background-color: #fff
    }

    .top-menu.top-menu-new {
        display: none
    }

    .rg-logo a img {
        margin-top: 0
    }

    .mobilehide {
        position: relative
    }

    .mobilehide.full-screen {
        height: 100%;
        width: 100%;
        position: relative;
        background: #203342 url(../images/slider/mobileveiwpic.jpg) no-repeat;
        margin-top: -20px;
        background-size: 100vw 100vh
    }

    .rg-heading {
        padding: 0 20px;
        position: absolute;
        top: 32%;
        text-align: center;
        width: 100%
    }

    .rg-heading h2 {
        font-size: 20px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 300
    }

    .rg-heading p {
        font-size: 16px;
        color: #fff
    }

    .rg-mobietext {
        padding: 0 6px 20px;
        position: absolute;
        width: 340px;
        bottom: 70px;
        left: 0;
        right: 0;
        margin: 0 auto
    }

    .btnlogin,
    .btnregisternow {
        font-size: 18px;
        padding: 10px 30px;
        margin: 0 10px;
        color: #fff
    }

    .btnlogin {
        background: 0 0;
        border-radius: 2px;
        border: 1px solid #fff
    }

    .btnregisternow {
        background: #e81c28;
        border-radius: 2px;
        border: 1px solid #fff
    }

    .rg-wrapper {
        max-width: 300px;
        padding: 10% 0;
        margin: 0 auto
    }

    .rg-wrapper .rg-search {
        background: 0 0;
        border: 1px solid #fff;
        width: 100%;
        border-radius: 5px;
        position: relative;
        box-shadow: 0 1px 5px 3px rgba(0, 0, 0, .12)
    }

    .rg-search input {
        height: 45px;
        color: #fff !important;
        width: 100%;
        background: 0 0;
        outline: 0;
        border: none;
        border-radius: 5px;
        padding: 0 60px 0 20px;
        font-size: 16px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, .1)
    }

    .rg-search.active input {
        border-radius: 5px 5px 0 0
    }

    .rg-search .icon {
        position: absolute;
        left: 0;
        top: -10px;
        height: 55px;
        width: 55px;
        text-align: center;
        line-height: 70px;
        font-size: 20px;
        color: #fff;
        cursor: pointer
    }
}

.loginwithotp-btn {
    margin: 0px auto;
    display: inline-block;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center
}



/* latest premium feature jobs area */

.rozgar-latespremiu-featurejob-area {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%)
}

.latespremiu-company-jobtitle {
    position: relative;
}

.latespremiu-company-jobtitle h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 6px;
}

.latespremiu-company-logo {
    position: relative;
    margin: 0px;
    padding-right: 8px;
    max-width: 100px;
}

.latespremiu-company-logo img {
    object-fit: contain;
    height: 40px;
    max-width: inherit;
    border: 1px solid #eee;
    border-radius: 6px;
    margin-right: 8px;
    padding: 0px 4px;
    width: 100%;
    object-fit: contain;
}

.latespremiu-company-head {
    position: relative;
}

.latespremiu-company-head h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}

.latespremiu-company-head h5 {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    margin: 0px;
}

.latespremiu-company-head h5 a {
    color: #333333;
}

.latespremiu-company-review {
    position: relative;
}

.latespremiu-company-review .star-r {
    position: relative;
}

.latespremiu-company-review .star-r .fa-star {
    color: #ff9200;
    padding-right: 2px;
}

.latespremiu-company-review .rating,
.latespremiu-company-review .reviews {
    color: #767676;
    font-weight: 600;
    padding: 0px 4px;
}

.latespremiu-company-job-area {
    position: relative;
    margin-top: 4px;
}

.latespremiu-company-job-para {
    padding-bottom: 4px;
}

.latespremiu-company-job-para span {
    color: #666666;
}

.latespremiu-company-job-skills a {
    background: #f9f9f9;
    color: #4c4c4c;
    padding: 0px 6px;
    border-radius: 28px;
    display: inline-flex;
    margin-right: 2px;
    height: 20px;
    line-height: 17px;
    border: 1px solid #eeeeee;
    font-size: 11px;
}

.latespremiu-company-job-skills a {
    color: #4c4c4c !important;
}

.latespremiu-company-allview {
    position: relative;
    text-align: center;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.latespremiu-company-allview a.hartbtn {
    color: #a6a6a6 !important;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    border: 1px solid #eeeeee;
    border-radius: 50px;
    display: inline-block;
    width: 23px;
    height: 23px;
    text-align: center;
    margin-right: 10px;
}

.latespremiu-company-allview a.hartbtn:hover {
    color: #e81c28 !important;
    border: 1px solid #e81c28;
}

.latespremiu-company-allview a.premiumbox {
    color: #777777;
    padding: 0 8px;
    font-size: 10px;
    line-height: 17px;
    height: 20px;
    font-weight: 500;
    border: 1px solid #ccc;
    border-radius: 50px;
    display: inline-block;
    background: #eee;
}

.latespremiu-company-allview a.premiumbox i {
    font-size: 10px;
    padding-right: 4px;
}

.latespremiu-company-allview a.arrowclick {
    color: #777777;
    padding: 0px 0px 0px 8px;
    font-size: 30px;
    line-height: 18px;
    font-weight: 500;
    border: 0px solid #ccc;
    border-radius: 50px;
    display: inline-block;
    background: transparent;
}

.latespremiu-company-allview a.premiumbox:hover,
.latespremiu-company-allview a.arrowclick:hover {
    color: #333;
}

/* premium companies hirings area */

.rozgar-premium-companies-hirings-area {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%)
}

/* .rozgar-premium-companies-hirings-area:hover{box-shadow: 0 0 10px 0 rgb(0 24 128 / 10%);} */

.premium-companies-hirings-logo {
    position: relative;
    margin: 0px;
}

.premium-companies-hirings-logo img {
    width: 75px;
    height: 75px;
    max-width: inherit;
    border: 1px solid #eee;
    border-radius: 100px;
    margin-right: 10px;
    padding: 6px;
    object-fit: contain;
}

.premium-companies-hirings-head {
    position: relative;
}

.premium-companies-hirings-head h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin: 0px;
}

.premium-companies-hirings-review {
    position: relative;
}

.premium-companies-hirings-review .star-r {
    position: relative;
}

.premium-companies-hirings-review .star-r .fa-star {
    color: #ff9200;
    padding-right: 2px;
}

.premium-companies-hirings-review .rating,
.premium-companies-hirings-review .reviews {
    color: #767676;
    font-weight: 600;
    padding: 0px 4px;
}

.premium-companies-hirings-job-area {
    position: relative;
    margin-top: 4px;
}

.premium-companies-hirings-job-para {
    padding-bottom: 4px;
}

.premium-companies-hirings-job-para span {
    color: #666666;
}

.premium-companies-hirings-job-skills a {
    background: #f9f9f9;
    color: #4c4c4c;
    padding: 0px 7px;
    border-radius: 28px;
    display: inline-flex;
    margin-right: 3px;
    height: 20px;
    line-height: 17px;
    border: 1px solid #eeeeee;
    font-size: 12px;
}

.premium-companies-hirings-job-skills a {
    color: #4c4c4c !important;
}

.premium-companies-hirings-allview {
    position: relative;
    text-align: center;
    padding-top: 10px;
}

.premium-companies-hirings-allview a {
    color: #303233;
    padding: 0 10px;
    font-size: 11px;
    line-height: 20px;
    font-weight: 500;
    border: 1px solid #303233;
    border-radius: 50px;
    display: inline-block;
}

.premium-companies-hirings-allview a:hover {
    color: #fff;
    background: #303233;
}



/* Latest video jds area */

.rozgar-latest-video-jds-area {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%);
    display: flow-root;
    width: 100%;
    float: left;
}

.latest-video-img {
    position: relative;
    margin-bottom: 10px;
    height: 200px;
}

.latest-video-img img {
    border-radius: 4px;
}

.latest-video-img .img-overlay-video {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: rgba(0, 0, 0, .6);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.latest-video-img .img-overlay-video i {
    border: 4px solid #ffffffb8;
    border-radius: 100px;
    width: 38px;
    height: 38px;
    text-align: center;
    line-height: 30px;
    background: #000000b8;
    font-size: 20px;
    padding-left: 4px;
}

.latest-video-jds-logo {
    position: relative;
    margin: 0px;
}

.latest-video-jds-logo img {
    width: 90px;
    height: 40px;
    max-width: inherit;
    border: 1px solid #eee;
    border-radius: 4px;
    margin-right: 10px;
    padding: 0px 2px;
    object-fit: contain;
}

.latest-video-jds-head {
    position: relative;
}

.latest-video-jds-head h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin: 0px;
}

.latest-video-jds-review {
    position: relative;
}

.latest-video-jds-review .star-r {
    position: relative;
}

.latest-video-jds-review .star-r .fa-star {
    color: #ff9200;
    padding-right: 2px;
}

.latest-video-jds-review .rating,
.latest-video-jds-review .reviews {
    color: #767676;
    font-weight: 600;
    padding: 0px 4px;
}

.latest-video-jds-job-area {
    position: relative;
    margin-top: 8px;
    display: flow-root;
    float: left;
    width: 100%;
}

.latest-video-jds-job-para {
    padding-bottom: 4px;
}

.latest-video-jds-job-para span {
    color: #666666;
}

.latest-video-jds-job-skills a {
    background: #f9f9f9;
    color: #4c4c4c;
    padding: 0px 7px;
    border-radius: 28px;
    display: inline-flex;
    margin-right: 3px;
    height: 20px;
    line-height: 17px;
    border: 1px solid #eeeeee;
    font-size: 12px;
}

.latest-video-jds-job-skills a {
    color: #4c4c4c !important;
}

.latest-video-jds-allview {
    position: relative;
    text-align: center;
    padding-top: 10px;
    display: flow-root;
    float: left;
    width: 100%;
}

.latest-video-jds-allview a {
    color: #303233;
    padding: 0 10px;
    font-size: 11px;
    line-height: 20px;
    font-weight: 500;
    border: 1px solid #303233;
    border-radius: 50px;
    display: inline-block;
}

.latest-video-jds-allview a:hover {
    color: #fff;
    background: #303233;
}

.latest-video-jds-job-skills {
    display: flow-root;
    float: left;
    width: 100%;
}

/* featured sponsored companies area */

.rozgar-featured-spon-companies-area {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    -webkit-box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%);
    box-shadow: 0 0 8px 0 rgba(0 0 0 / 3%);
    margin-bottom: 15px;
}

.featured-spon-companies-logo {
    position: relative;
    margin: 0px;
}

.featured-spon-companies-logo img {
    width: 75px;
    height: 75px;
    max-width: inherit;
    border: 1px solid #eee;
    border-radius: 100px;
    margin-right: 10px;
    padding: 6px;
    object-fit: contain;
}

.featured-spon-companies-head {
    position: relative;
}

.featured-spon-companies-head h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin: 0px;
}

.featured-spon-companies-review {
    position: relative;
}

.featured-spon-companies-review .star-r {
    position: relative;
}

.featured-spon-companies-review .star-r .fa-star {
    color: #ff9200;
    padding-right: 2px;
}

.featured-spon-companies-review .rating,
.featured-spon-companies-review .reviews {
    color: #767676;
    font-weight: 600;
    padding: 0px 4px;
}

.featured-spon-companies-job-area {
    position: relative;
    margin-top: 4px;
}

.featured-spon-companies-job-para {
    padding-bottom: 4px;
}

.featured-spon-companies-job-para span {
    color: #666666;
}

.featured-spon-companies-job-skills a {
    background: #f9f9f9;
    color: #4c4c4c;
    padding: 0px 7px;
    border-radius: 28px;
    display: inline-flex;
    margin-right: 3px;
    height: 20px;
    line-height: 17px;
    border: 1px solid #eeeeee;
    font-size: 12px;
}

.featured-spon-companies-job-skills a {
    color: #4c4c4c !important;
}

.featured-spon-companies-allview {
    position: relative;
    text-align: center;
    padding-top: 10px;
}

.featured-spon-companies-allview a {
    color: #e81c28;
    padding: 0 10px;
    font-size: 11px;
    line-height: 20px;
    font-weight: 500;
    border: 1px solid #e81c28;
    border-radius: 50px;
    display: inline-block;
}



/* footer section */
.footer-head {
    position: relative;
    padding-top: 40px;
}

.footer-head h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    margin-bottom: 6px;
}

.footer-headOne {
    position: relative;
    padding-top: 0px;
}

.footer-headOne h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    margin-bottom: 6px;
}

.footer-headTwo {
    position: relative;
    padding-top: 0px;
}

.footer-headTwo h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    margin-bottom: 6px;
}

.footer-headThree {
    position: relative;
    padding-top: 0px;
}

.footer-headThree h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    margin-bottom: 6px;
}

.footer-menu {
    padding-bottom: 14px;
}

.footer-menu ul {
    margin: 0px;
}

.footer-menu ul li {
    display: inline-block;
    padding-right: 6px;
    line-height: 14px;
}

.footer-menu ul li a {
    text-decoration: none;
    font-size: 14px;
    color: #444444;
    border-right: 1px solid #444444;
    line-height: 12px;
    display: block;
    padding-right: 6px;
}

.footer-menu ul li a:hover {
    text-decoration: underline;
    color: #333333;
}

.footer-menu ul li a.last-child {
    border-right: none;
}

.footer-jobbyloc-head {
    position: relative;
    padding-top: 40px;
}

.footer-jobbyloc-head h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #333333;
    margin-bottom: 6px;
}

.footer-jobbyloc-menu {
    margin: 0px;
}

.footer-jobbyloc-menu li {
    display: inline-block;
    padding-right: 6px;
}

.footer-jobbyloc-menu li a {
    text-decoration: none;
    font-size: 14px;
    color: #444444;
    border-right: 1px solid #444444;
    line-height: 12px;
    display: block;
    padding-right: 6px;
}

.footer-jobbyloc-menu li a:hover {
    text-decoration: underline;
    color: #333333;
}

.footer-jobbyloc-menu li a.last-child {
    border-right: none;
}


.rozgar-footerbottomheadh4 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    padding: 8px 0px 2px;
    margin: 0px;
}

.rozgar-footerbottom-menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

.rozgar-footerbottom-menu li {
    display: inline-block;
    padding-right: 5px;
}

.rozgar-footerbottom-menu li a {
    text-decoration: none;
    font-size: 14px;
    color: #888;
    border-right: 1px solid #333333;
    line-height: 12px;
    display: block;
    padding-right: 6px;
}

.rozgar-footerbottom-menu li a:hover {
    text-decoration: underline;
    color: #ffffff;
}

.rozgar-footerbottom-menu li a.last-child {
    border-right: none;
}



/* rozgar statistics area */

.services-block-three {
    margin-bottom: 2px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
}

.services-block-three i {
    font-size: 32px;
    background: linear-gradient(45deg, #e3e3e3, #ffffff0a);
    padding: 12px;
    border-radius: 80% 50% 100% 90% / 100% 150% 85% 100%;
    color: #656565;
}

.services-block-three>a {
    display: block;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    text-align: center;
    background: #fff;
    padding: 20px;
    position: relative;
    margin-bottom: 2px;

}

.services-block-three>a:before {
    display: block;
    content: "";
    width: 9%;
    height: 17%;
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-bottom: 1px solid #e81c28;
    border-right: 1px solid #e81c28;
    border-bottom-right-radius: 4px;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
}

.services-block-three>a:after {
    display: block;
    content: "";
    width: 9%;
    height: 17%;
    position: absolute;
    top: -1px;
    left: -1px;
    border-top: 1px solid #e81c28;
    border-left: 1px solid #e81c28;
    border-top-left-radius: 4px;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
}

.padding-15px-bottom {
    background: #ededed;
    padding: 12px;
    border-radius: 70px;
    display: inline-block;
    margin-bottom: 2px;
}

.padding-15px-bottom img {
    max-width: 45px;
}

.services-block-three h4 {
    color: #6f6f6f;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.services-block-three p {
    margin-bottom: 0;
    padding-top: 0px;
    color: #666;
    font-size: 16px;
}

.services-block-three>a:hover {
    opacity: 1;
    border-color: #d5d5d5;
}

a:hover,
a:active {
    color: #03a9f5;
    text-decoration: none;
}

.services-block-three>a:hover:before,
.services-block-three>a:hover:after {
    width: 95%;
    height: 90%;
}

.services-block-three>a:hover i {
    background: #ffe6e6;
}


/* Worried about your Next Interview? */

#rg-sectionhead h2 {
    font-weight: 500;
    font-size: 18px;
}

.rg-interview-questions-role-area {
    position: relative;
    float: left;
    width: 100%;
}

.rg-interview-questions-role-area .ulbox {
    position: relative;
    margin-top: 15px;
}

.rg-interview-questions-role-area .ulbox li {
    width: 49%;
    padding: 18px 4px;
    background: #fbfcff;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    margin-top: 4px;
    text-align: center;
}

.rg-interview-questions-role-area .ulbox li:hover {
    border: 1px solid #e4e4e4;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
    background: #fafafa;
}

.rg-interview-questions-role-area .ulbox li:nth-of-type(odd) {
    margin-right: 6px;
}

.rg-interview-questions-role-textarea {
    position: relative;
}

.rg-interview-questions-role-textarea h3 {
    font-size: 16px;
}

.rg-interview-questions-role-textarea span {
    color: #666;
}

.rg-interview-questions-skills-area {
    position: relative;
    float: left;
    width: 100%;
}

.rg-interview-questions-skills-area .ulbox {
    position: relative;
    margin-top: 15px;
}

.rg-interview-questions-skills-area .ulbox li {
    width: 49%;
    padding: 4px;
    background: #fff;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    margin-top: 4px;
    text-align: center;
}

.rg-interview-questions-skills-area .ulbox li:hover {
    border: 1px solid #e4e4e4;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
}

.rg-interview-questions-skills-area .ulbox li:nth-of-type(odd) {
    margin-right: 6px;
}

.iq-skills-imagebox {
    position: relative;
}

.iq-skills-imagebox img {
    max-width: 50px;
    margin-bottom: 10px;
}

.iq-skills-imagebox-textarea {
    position: relative;
}

.iq-skills-imagebox-textarea h3 {
    margin: 0px;
    line-height: 12px;
    font-size: 16px;
}

.iq-skills-imagebox-textarea span {
    color: #666;
}

.rg-interview-questions-com-area {
    position: relative;
    float: left;
    width: 100%;
}

.rg-interview-questions-com-area .ulbox {
    position: relative;
    margin-top: 15px;
}

.rg-interview-questions-com-area .ulbox li {
    width: 49%;
    padding: 4px;
    background: #fff;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #eff2f5;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 3%);
    margin-top: 4px;
    text-align: center;
}

.rg-interview-questions-com-area .ulbox li:hover {
    border: 1px solid #e4e4e4;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 6%);
}

.rg-interview-questions-com-area .ulbox li:nth-of-type(odd) {
    margin-right: 6px;
}

.iq-com-imagebox {
    position: relative;
}

.iq-com-imagebox img {
    max-width: 75px;
    margin-bottom: 10px;
    height: 35px;
    object-fit: contain;
}

.iq-com-imagebox-textarea {
    position: relative;
}

.iq-com-imagebox-textarea h3 {
    margin: 0px;
    line-height: 12px;
    font-size: 16px;
}

.iq-com-imagebox-textarea span {
    color: #666;
}







.home-search-bar-main #keyword {
    transform: translate3d(28px, 22.4px, 0px) !important;
    top: 18px !important;
}

.home-search-bar-main #keyword .dropdown-item,
.home-search-bar-main #location .dropdown-item {
    padding: 0rem 1.5rem !important;
}

.home-search-bar-main #keyword,
.home-search-bar-main #location {
    transform: translate3d(20px, 22.4px, 0px) !important;
    top: 20px !important;
    overflow-x: hidden !important;
}

.home-search-bar-main #keyword .rbt-token-label {
    line-height: 16px !important;
}

.home-search-bar-main .rbt-input-wrapper {
    width: 100%;
    overflow-x: auto;
}

.rbt-input-wrapper div {
    flex: auto !important;
}

.home-search-bar-main .rbt-input-wrapper::-webkit-scrollbar {
    width: 100%;
    height: 0px;
}

.home-search-bar-main .rbt-input-wrapper::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 8px;
}

.home-search-bar-main .rbt-input-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgb(0 0 0 / 1%);
    background-color: #ccc;
    border-radius: 8px;
}

.rbt-input-wrapper .close .rbt-close-content {
    font-weight: 400;
}

.rg-featuredjobsvtwo .rg-featurejobholder .rg-featurejob .rg-companycontent .ti-crown,
.ti-crown {
    font-size: inherit;
    margin-right: inherit;
    width: inherit;
    height: 40px;
    background: transparent;
    text-align: center;
    border-radius: 50px;
    line-height: inherit;
}

.rg-featurejobholder .rg-companycontent .rg-rightarea .rg-btnjobtag {
    background: #e0e0e0;
    border-radius: 30px;
    font-weight: 400;
}

.rg-featurejobholder .rg-companycontent .rg-rightarea .rg-btnjobtag .ti-crown {
    margin-right: 3px;
    background: transparent;
}

.rg-jobapplycenter.rg-jobapplycentervfour .rg-companycontent .rg-likebtnbox .rg-btn {
    border-radius: 4px;
}

.rg-jobapplycenter.rg-jobapplycentervfour .rg-companycontent .rg-rightarea .rg-btnjobtag {
    background: #e0e0e0;
    border-radius: 30px;
    font-weight: 400;
}

.rg-jobapplycenter.rg-jobapplycentervfour .rg-companycontent .rg-rightarea .rg-btnjobtag .ti-crown {
    font-size: 12px;
    margin-right: 2px;
    line-height: 30px;
    color: #ff8a00;
    background: transparent;
}

.rg-jobapplynowbtn .rg-likebtnbox button {
    border-radius: 4px;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, .75) !important;
    z-index: 9999;
}

#bgcoltran {
    background: 0 0 !important;
    border: none !important;
}

.rozgar-jobbylocsearchcontent #keyword .dropdown-item,
#location .dropdown-item {
    padding: 0rem 1.5rem !important;
}

.rg-formbannersearch.rg-formbannersearchinner #keyword .dropdown-item,
#location .dropdown-item {
    padding: 0rem 1.5rem !important;
}

.rg-widget .rg-employerdetails .rg-btn {
    border-radius: 5px;
}

.rg-companycontent .rg-jobapplybtnlike .rg-likebtnbox a {
    border-radius: 5px;
}

.col-apply {
    background: #e81c28;
    border-radius: 2px;
    box-shadow: inset 0 2px 5px rgb(193 0 0 / 79%);
    color: #fff !important;
    font-size: 14px;
    padding: 4px 14px;
    white-space: nowrap;
}

.multiselect-container .search-wrapper.searchWrapper input {
    border: none;
}

.navigation__link {
    background-color: rgba(0, 0, 0, .1);
    color: #000;
    display: block;
    float: left;
    font-weight: 400;
    margin-bottom: 1px;
    padding: 0.4em 1.4em;
    text-decoration: none;
    width: 100%;
}

.navigation__link:hover {
    background-color: #ffeeef;
    color: #000;
}

.rg-sliderholder-sparkrle:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-image: url(../images/top-had-img.jpg);
    width: 100%;
    height: 100%;
}

.rg-sliderholder-sparkrle {
    padding-top: 50px;
    padding-bottom: 50px;
}

.rbt-input-wrapper {
    width: 100%;
}


.home-search-bar-main .rbt-input-multi .rbt-input-wrapper:first-child div {
    width: 100%;
}

.home-search-bar-main .rbt-input-multi .rbt-input-wrapper .rbt-token {
    width: auto !important;
}

.re-freejob-alert a {
    background: #02aa41;
    border-radius: 2px;
    color: #fff !important;
    display: inline-block;
    font-size: 16px;
    padding: 6px 20px;
}

.freejob-alert-resume a {
    background: #424242;
    border-radius: 2px;
    color: #fff !important;
    display: inline-block;
    padding: 20px 50px;
}

.rozgar-faqssearchbox .rozgar-searchbtn a {
    background: #e81c28;
    padding: 15px 40px;
    color: #fff !important;
    border-radius: 4px;
    margin-left: 20px;
}

.rozgar-faqssearchbox .rozgar-searchcontent input {
    height: 48px;
}

.rozgar-faqssearchbox .rozgar-searchbtn {
    margin-top: -8px;
}

.home-search-bar-main .srchjob #keyword {
    top: 12px !important;
}

.home-search-bar-main .loc-bx #location {
    top: 12px !important;
}

.rg-searcharea .rg-searchholder .fa-rupee {
    font-size: 17px;
    color: #aaa;
}

.rg-create-job-alert-form #keyword .dropdown-item,
.rg-create-job-alert-form #location .dropdown-item {
    padding: 0rem 1.5rem !important;
}

.company-hiring-logo.inner-company-logo a {
    width: 31.5%;
}

.company-hiring-logo.inner-company-logo a:nth-child(3) {
    margin-right: 0px;
}

.company-hiring-logo.inner-company-logo a:nth-child(6) {
    margin-right: 0px;
}

.company-hiring-logo.inner-company-logo a img {
    width: 100%;
    object-fit: contain;
}

.similarjobs li .lnr-clock {
    margin-right: 3px;
}

.comtop-pr-box.personal-recruiter-box {
    padding-top: 0;
    padding-bottom: 50px;
    display: block;
    float: left;
    width: 100%;
}

.comtop-pr-box.personal-recruiter-box .rg-btnsjobstags {
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    margin-top: 15px;
    padding: 20px;
    box-shadow: 0 4px 16px rgb(0 0 0 / 7%);
}

.comtop-pr-box.personal-recruiter-box .rg-btnjobtag.rg-fulltimejob,
.comtop-pr-box.personal-recruiter-box .rg-btnjobtag.rg-internship {
    background: #f7f7f7;
    border: 1px solid #eff2f5;
}


.comtop-pr-box.personal-recruiter-box .rg-btnjobtags {
    box-shadow: none;
    border: none;
    border-radius: 4px;
}

.comtop-pr-box.top-comtop-pr-box {
    padding-bottom: 30px;
    display: flow-root;
    width: 100%;
    float: left;
}

.rg-featuredjobs .slick-next:before,
.rg-featuredjobs .slick-prev:before {
    color: #606060 !important;
    font-size: 33px;
    background: #ffffff;
    padding: 0px 10px;
    border-radius: 50%;
    font-family: monospace;
    box-shadow: -2px 6px 13px -10px #000;
    border: 3px solid #e4e4e466;

}

.rg-featuredjobs .slick-next,
.rg-featuredjobs .slick-prev {
    height: auto;
}

.rg-sliderholder-sparkrle .rg-formtheme {
    padding-bottom: 5px !important;
}

.com-icon-bx {
    font-size: 14px;
    margin-top: -6px;
    margin-bottom: 2px;
    margin-right: 10px;
}

.roz-sectiontext p {
    margin-bottom: 4px;
}

.roz-cam-ban-bx {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

.camp-key-ponts li {
    padding: 4px 8px 4px 15px !important;
    border: none !important;
    background: #fff7f7 !important;
    border-right: 2px solid #fdcbce !important;
    border-radius: 41px !important;
}

.camp-key-ponts {
    margin-top: 10px !important;
}

.tab-home-search {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    /* margin-left: 326px; */
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.tab-home-search .tab-div a {
    display: inline-block;
    line-height: 24px;
    padding: 0 5px 5px 5px;
    font-size: 14px;
    color: #303030;
    font-weight: 600;
    cursor: pointer;
    border-bottom: 3px solid transparent;
}

/* .tab-home-search .tab-div a:hover{border-bottom: 3px solid #e81c28;
        border-radius: 2px;   transition: all 0.8s cubic-bezier(0.39, 0.58, 0.57, 1);}
        
        .tab-home-search .tab-div.active a{border-bottom: 3px solid #e81c28;
            border-radius: 2px;   transition: all 0.8s cubic-bezier(0.39, 0.58, 0.57, 1);}
            .tab-home-search .tab-div{margin-right: 30px;}            
            
.tab-home-search .tab-div:last-child{margin-right: 0px;}  */





#primary .tab-div {
    margin: 0 20px;
    position: relative;
}

#primary .tab-div a {
    text-decoration: none;
    display: block;
    padding: 5px 0;

    position: relative;
    z-index: 1;
    text-align: center;

    background-size: 200% 100%;
    background-position: -100%;
    transition: all 0.3s ease-in-out;
}

#primary .tab-div a:before {
    display: block;
    content: "";
    width: 0;
    height: 3px;
    bottom: 5px;
    left: 0;
    bottom: -3px;
    z-index: 0;
    position: absolute;
    background: #e81c28;
    transition: all 0.3s ease-in-out;
}

#primary .tab-div a:hover {
    background-position: 0%;
}

#primary .tab-div a:hover:before {
    width: 100%;
}

#primary .tab-div a.active:before {
    width: 100%;
}




.rozgar-news-section .marquee {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    min-width: 100%;
    overflow: hidden;
    height: 40px;
    padding-left: 200px;
    z-index: 1;
}

.rozgar-news-section .marquee__inner {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    animation: slide-up 43000ms linear infinite;
    overflow: hidden;
    padding: 0;
}

.rozgar-news-section .marquee li {
    font-size: 16px;
    padding: 0;
    flex-shrink: 0;
    list-style-type: none;
}

.rozgar-news-section li a:after {
    content: "/";
    padding: 0 1vw;
    color: #e81c28;
}

.rozgar-news-section a:link {
    text-decoration: none;
}

.rozgar-news-section .marquee:hover .marquee__inner {
    animation-play-state: paused;
}

@keyframes slide-left {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.rozgar-main-new {
    display: flex;
    width: 100%;
    position: relative;
}

.rozgar-main-new .marquee-hed-sec {
    position: absolute;
    background-color: #e81c28;
    width: 145px;
    z-index: 2;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.rozgar-main-new .marquee-hed-sec h5 {
    margin-bottom: 0;
    color: #fff;
}

.rozgar-main-new .marquee-hed-sec::before {
    content: "";
    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    top: 0;
    left: -12px;
    border-left: 12px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 40px solid #e81c28;
}

.rozgar-main-new .marquee-hed-sec:after {
    content: "";
    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    right: -12px;
    top: 0;
    border-right: 12px solid transparent;
    border-left: 0px solid transparent;
    border-top: 40px solid #e81c28;
}

.rozgar-news-section {
    display: flow-root;
    position: relative;
    width: 100%;
    background: #efefef;
    margin-top: 20px;
}

.rozgar-news-section .marquee li a {
    color: #000000;
}

.rozgar-news-section .marquee li a span {
    font-size: 14px;
    margin-left: 5px;
    color: #ff9292;
}

.rozgar-news-section li:last-child a:after {
    display: none;
}

.rozgar-news-section .marquee li a img {
    width: 50px;
}

.news-read-more {
    color: #e81c28;
}

.loader {
    transform: translate(0, -50%);
    left: 50% !important;
}

.profile-name {
    color: #222;
    margin-left: 7px;
    font-size: 16px;
    font-weight: 400;
}

/* Prelaoder */

.mobilehView-comtop {
    display: none;
}





.enquiry-form-area {
    position: relative;
    z-index: 2;
    padding-bottom: 75px;
}

.inner-enquiry-form-box {
    margin-top: -50px;
    float: left;
    width: 100%;
    background: transparent;
    padding-bottom: 16px;
}

.inner-enquiry-form-box h2 {
    font-size: 20px;
}

.inner-enquiry-form-box h2:before {
    content: "";
    display: block;
    height: 3px;
    width: 48px;
    margin: 0 0 .2em;
    background-color: #df1415;
    border-radius: 100px;
}

.inner-enquiry-form-area {
    background: #fff url(../images/enquiry-bg.png) no-repeat;
    background-size: 75%;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 16px rgb(0 0 0 / 7%);
    background-position: center right 80px;
}

.form-col-2 {
    display: flex;
}

.enquiry-input-visa-box {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.enquiry-input-visa-box .form-group {
    margin: 0px;
}

.enquiry-input-visa-box input {
    margin-right: 10px;
    padding: 4px 14px;
    width: 98%;
    border-radius: 50px;
    height: 33px;
    box-shadow: 0 2px 3px rgb(27 27 27 / 7%);
}

.enquiry-input-visa-box input {
    margin-right: 10px;
    padding: 4px 14px;
    width: 90%;
    border-radius: 50px;
    height: 33px;
    box-shadow: 0 2px 3px rgb(27 27 27 / 7%);
}

.enquiry-input-visa-box input[type=checkbox]:checked {
    background-color: #07ad00;
}

.enquiry-input-visa-box .pb-visa {
    margin-bottom: 10px;
}

.enquiry-input-visa-box .pb0-visa {
    margin-bottom: 0px;
}

.enquiry-input-box {
    position: relative;
    width: 100%;
    display: flex;
}

.enquiry-input-box .form-group {
    margin: 0px;
}

.enquiry-input-visa-box input {
    margin-right: 10px;
    padding: 4px 14px;
    width: 98%;
    border-radius: 50px;
    height: 33px;
    box-shadow: 0 2px 3px rgb(27 27 27 / 7%);
}

.enquiry-input-visa-box button {
    margin-left: 20px;
    border-radius: 40px;
    padding: 2px 35px;
    height: 42px;
    line-height: 10px;
    font-weight: 500;
    font-size: 18px;
    background: #e81c28;
    color: #fff;
}

.enquiry-input-box input {
    margin-right: 10px;
    padding: 4px 14px;
    width: 90%;
    border-radius: 50px;
    height: 33px;
    box-shadow: 0 2px 3px rgb(27 27 27 / 7%);
}

.enquiry-input-box input[type=checkbox]:checked {
    background-color: #07ad00;
}

.enquiry-textarea-visa-box {
    position: relative;
    display: flex;
    margin-left: 2px;
    width: 99%;
}

.enquiry-textarea-visa-box textarea {
    padding: 4px 14px;
    width: 100%;
    border-radius: 50px;
    height: 33px !important;
    resize: none;
    box-shadow: 0 2px 3px rgb(27 27 27 / 7%);
}


.enquiry-textarea-box {
    position: relative;
    display: flex;
    margin-left: 2px;
    width: 60%;
}

.enquiry-textarea-box textarea {
    padding: 4px 14px;
    width: 100%;
    border-radius: 50px;
    height: 33px !important;
    resize: none;
    box-shadow: 0 2px 3px rgb(27 27 27 / 7%);
    line-height: 24px;
}

.enquiry-select-box select {
    padding: 4px 14px;
    width: 98%;
    border-radius: 50px;
    height: 33px !important;
    margin-bottom: 0px;
    resize: none;
    box-shadow: 0 2px 3px rgb(27 27 27 / 7%);
}

.enquiry-textarea-box button {
    margin-left: 20px;
    border-radius: 40px;
    padding: 2px 40px;
    height: 33px;
    line-height: 10px;
    font-weight: 500;
    font-size: 18px;
    background: #e81c28;
    color: #fff;
}

.inner-enquiry-form-area .sendmeimfo {
    padding: 10px 0px 0px;
    display: flex;
    align-items: center;
    color: #595959;
    font-size: 14px;
}

.inner-enquiry-form-area .sendmeimfo input {
    margin-right: 6px;
    position: relative;
}

.inner-enquiry-form-area .sendmeimfo input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    text-align: center;
    height: 18px;
    width: 18px;
    left: 0;
    top: -1px;
    background-color: #000;
    font-family: "Montserrat";
    border-radius: 2px;
    border: 1px solid rgb(150 150 150 / 30%);
}

.inner-enquiry-form-area .sendmeimfo input[type=checkbox]:after {
    content: '\f00c';
    display: block;
    position: absolute;
    left: 3px;
    top: 1px;
    font-family: 'FontAwesome';
    color: #fff;
}

.inner-enquiry-form-area .sendmeimfo span {
    padding-left: 6px;
}

.inner-enquiry-form-area .sendmeimfo span i {
    color: #159a0b;
    font-weight: 900;
}

.enquiry-textarea-box textarea::placeholder {
    color: #b3b3b3;
    font-size: 14px;
}

.enquiry-textarea-box textarea::-ms-placeholder {
    color: #b3b3b3;
    font-size: 14px;
}

.enquiry-textarea-box textarea::-webkit-placeholder {
    color: #b3b3b3;
    font-size: 14px;
}

.enquiry-input-box input::placeholder {
    color: #b3b3b3;
    font-size: 14px;
}

.enquiry-input-box input::-ms-placeholder {
    color: #b3b3b3;
    font-size: 14px;
}

.enquiry-input-box input::-webkit-placeholder {
    color: #b3b3b3;
    font-size: 14px;
}

.mobilehView-comtop {
    display: none !important;
}

.post-jobs strong {
    color: #e81c28;
}

.rozgar-media-section .slick-next,
.rozgar-media-section .slick-prev {
    background: #ffffff;
    top: 34%;
}

.rozgar-media-section .slick-next:before,
.rozgar-media-section .slick-prev:before {
    background: #fff;
    border-radius: 50%;
    box-shadow: -2px 6px 13px -10px #000;
    color: #606060 !important;
    font-family: monospace;
    font-size: 33px;
    padding: 0 10px;
}

.rozgar-media-section .slick-next {
    right: -2px !important;
}

.rozgar-media-section .slick-prev {
    left: -30px !important;
}

.rozgar-jobbylocsearchcontent .hdh .rbt-input-multi .rbt-input-wrapper:first-child div {
    width: 100%;
}

.rozgar-jobbylocsearchcontent .hdh .rbt-input-multi .rbt-input-wrapper .rbt-token {
    width: auto !important;
}

.table-pricing {
    font-size: 16px;
    border-right: none;
    overflow: hidden;
    padding: 5px;
    background: #fff;
    -webkit-box-shadow: 0 30px 60px rgb(0 19 87 / 6%);
    -moz-box-shadow: 0 30px 60px rgb(0 19 87 / 6%);
    box-shadow: 0 30px 60px rgb(0 19 87 / 6%);
}

.table-pricing thead {
    color: #ffffff;
    font-size: 18px;
    background: rgb(68, 68, 68);
}

.table-pricing thead tr th {
    font-weight: 500;
    padding: 5px 0 5px 0;
    line-height: 38px;
}

.table-pricing tbody tr th .lbl,
.table-pricing tbody tr td .lbl {
    display: none;
}

.table-pricing tbody tr th {
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    vertical-align: middle;
}

a.btn-line,
a.btn-line:hover {
    background: none;
    color: #35404e;
    border: solid 2px rgba(0, 0, 0, .1);
}

.table-pricing .btn-main,
.table-pricing .btn-line {
    display: inline-block;
    line-height: 1.1em;
}

.pricing-table-section {
    padding: 50px 0;
    display: flow-root;
    float: left;
    width: 100%;
    background: #fff;
}

.table-pricing tbody tr td .fa-close {
    font-weight: 100;
    color: #9e9e9e;
}

.table-pricing tbody tr td .fa-check {
    font-weight: 100;
    color: #27dc27;
}

.table-pricing tbody tr td .fa-minus {
    font-weight: 100;
    color: #9e9e9e;
}

.table-pricing tbody tr td img {
    width: 25px;
}

.table-price-hed h4 {
    font-size: 22px;
    margin-bottom: 0;
    font-weight: 500;
    position: relative;
    padding-bottom: 5px;
}

.table-price-hed {
    margin-bottom: 20px;
}

.table-price-hed h4:before {
    position: absolute;
    content: '';
    width: 80px;
    height: 1px;
    left: 0;
    bottom: 0px;
    background: #7e7f80;
}

.buy-now-btn {
    display: inline-block;
    text-align: center;
    color: #e81c28;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    outline: 0;
    font-weight: 600;
    text-decoration: none;
    padding: 0px 40px 0px 40px;
    font-size: 14px;
    border: none;
    background: transparent;
    border: 2px solid #e81c28;
    line-height: 25px;
}

.Upgrade-Skill-box .skills-text-box {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
    padding: 10px 10px 10px;
}

.skills-text-box h5 {
    font-size: 20px;
    margin-bottom: 0;
    text-align: center;
}

.Upgrade-Skill-box .upgrade-skills-img {
    align-content: center;
    align-items: center;
    background: #f2f2f2;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
}

.Upgrade-Skill-box .upgrade-skills-img img {
    border-radius: 5px 5px 0 0;
}

.skills-had-update {
    text-align: center;
    margin-bottom: 30px;
}

.skills-had-update h4 {
    font-size: 22px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    padding-bottom: 5px;
}

.skills-had-update h4:before {
    position: absolute;
    content: '';
    width: 80px;
    height: 1px;
    left: 38px;
    bottom: 0px;
    background: #7e7f80;
}

.main-section-box.upgrade-sec {
    border-top: 1px solid #e5e5e5;
}

.skills-had-update p {
    padding-top: 10px;
    font-size: 15px;
}

.static-hero-s3 .slide-title h2 span {
    color: #e81c28;
}

.static-hero-s3 .slide-title p {
    font-size: 34px;
    color: #fff;
    line-height: 35px;
    margin-bottom: 3px;
}

.faqscontent .answers {
    z-index: 1;
}

.static-hero-s3 .slide-title p {
    font-size: 34px;
    color: #fff;
    line-height: 35px;
    margin-bottom: 3px;
}

.jd-dec-box {
    display: block;
    float: left;
    width: 100%;
}

.resume-making-tab-box ul li {
    list-style: none;
}

.resume-making-tab-box ul li a  {
    color: #000;
    display: block;
    padding: 7px 40px;
    background-color: transparent;
    margin-bottom: 2px;
    font-size: 15px;
    position: relative;
    line-height: 24px;
    border-bottom: 1px solid #f6f6f6;
}

.resume-making-tab-box {
    padding: 0px;
    margin-top: 3px;
}

/* 
.resume-making-tab-box ul li a.active::before  {    content: '';
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid rgba(0,0,0,.1);
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -9px;
    transform: translateY(-50%);
       } */
.cv-prev.cv-prev-right-bx {
    height: 550px;
    background-color: #ffffff !important;
    position: fixed;
    max-width: 436px;
    float: left;
    width: 100%;
    top: 15%;
    border: 1px solid #eee;
    box-shadow: 0px 2px 27px 0px rgb(154 161 171 / 18%);
    padding: 20px 20px 30px;
    margin-bottom: 30px;
    background: #fff;
}


.resume-making-tab-box .left-had-sec {
    background: #008ab2;
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    border-radius: 5px 5px 0 0;
}

.left-had-sec h1 {
    font-size: 20px;
    margin-bottom: auto;
    color: #000;
    font-weight: 500;
}

.left-had-sec {
    background: #f7f7f7;
    display: flex;
    justify-content: flex-start;
    border-radius: 0;
    padding: 13px 20px;
    margin-left: -23px;
    margin-right: -23px;
    margin-top: -23px;
}


.resume-making-forms-bx h2 {
    font-size: 20px;
    background: #f4f4f4;
    padding: 10px 20px;
    margin-bottom: 3px;
}

.resume-making-tab-box ul li a {
    position: relative;
}

.resume-making-tab-box ul li a:before {
    font: 10px/16px FontAwesome;
    position: absolute;
    left: 10px;
    top: 11px;
    color: #fff;
    background: #b6b6b6;
    border-radius: 50px;
    width: 16px;
    height: 16px;
    text-align: center;
}

.resume-making-tab-box ul li a {
    color: #000;
    display: block;
}

.resume-making-tab-box ul li a:before {
    content: "\f00c"
}

.resume-making-tab-box ul li:hover:before {
    color: #f9f9f9
}

.resume-making-tab-box ul li a.active:before {
    background: #18c1a3;
    
}

.resume-making-tab-box ul li a.active{background-color: transparent;}
.clipboard_btn{background-color: #f0f0f0;
    border: none;
    padding: 1px;
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    font-size: 16px;
    line-height: inherit;
    font-family: inherit;
    color: #000;
    cursor: pointer;
    text-align: center;
    margin: 2px;
    transition: all 0.3s ease 0s;
    transform: translateY(-20px);
    padding: 4px 8px;
    border-radius: 50%;
}
button:focus {
     outline: none; 
}

.swiggynavbg.greenlamnavbg{background: #e6f1e1;}
.bag-white-bx li{background: #ffffffc2 !important;}
.swiggy-mission.grernlam-mission{background: #d8ffc6;}
.swiggy-mission.grernlam-mission .mission-text{color: #292929 !important;}
.rg-feature-full-width.green-slider .slick-slide img{height: 170px !important;border-radius: 5px; }
.about-abroad-section h4{font-weight: 600;font-size: 17px;}
.mobile-view-block{display: none;}
.rg-downloadApp{padding: 0 6px 20px;
    position: absolute;
    width: 340px;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;}
.btndownloadApp{background: #ff7a00;
    border-radius: 5px;
    padding: 10px 24px;
    width: 92%;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    display: block;
    margin-right: auto;
    margin-left: auto;}    
  

.rg-downloadApp {display: flex;align-items: center;}
.rg-downloadApp .btndownloadApp .arrowed{margin-left: 20px;}
.rg-downloadApp .btndownloadApp{display: flex;align-items: center;justify-content: center;}
.DownloadAppCta_arrowAnimtion__3DGlm {
    visibility: hidden;
    opacity: 0;
    background-color: unset;
    -webkit-animation: DownloadAppCta_browseJobFloatButtonExpandLeft__2Lk6d 1s linear 1.5s forwards;
    -moz-animation: DownloadAppCta_browseJobFloatButtonExpandLeft__2Lk6d 1s linear 1.5s forwards;
    animation: DownloadAppCta_browseJobFloatButtonExpandLeft__2Lk6d 1s linear 1.5s forwards;
    position: relative;
    top: -2px;
    left: 8px;
}

.DownloadAppCta_arrowAnimtion__3DGlm span {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    margin-left: -6px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-animation: DownloadAppCta_sdb07__1ruMS 2s infinite;
    -moz-animation: DownloadAppCta_sdb07__1ruMS 2s infinite;
    animation: DownloadAppCta_sdb07__1ruMS 2s infinite;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
 .DownloadAppCta_arrowAnimtion__3DGlm span:first-of-type {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    animation-delay: 0s;
}
.DownloadAppCta_arrowAnimtion__3DGlm span:nth-of-type(2) {
    left: 8px;
    -webkit-animation-delay: .15s;
    -moz-animation-delay: .15s;
    animation-delay: .15s;
}


 .DownloadAppCta_arrowAnimtion__3DGlm span:nth-of-type(3) {
    left: 16px;
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    animation-delay: .3s;
}

@-webkit-keyframes DownloadAppCta_sdb07__1ruMS {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-moz-keyframes DownloadAppCta_sdb07__1ruMS {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes DownloadAppCta_sdb07__1ruMS {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes DownloadAppCta_browseJobFloatButtonExpandLeft__2Lk6d {
    0%,10%,20% {
        visibility: hidden;
        opacity: 0;
        width: 0
    }

    40%,50%,60%,70%,80% {
        opacity: 0;
        width: 25px;
        visibility: hidden
    }

    to {
        width: 25px;
        visibility: visible;
        opacity: 1
    }
}

@-moz-keyframes DownloadAppCta_browseJobFloatButtonExpandLeft__2Lk6d {
    0%,10%,20% {
        visibility: hidden;
        opacity: 0;
        width: 0
    }

    40%,50%,60%,70%,80% {
        opacity: 0;
        width: 25px;
        visibility: hidden
    }

    to {
        width: 25px;
        visibility: visible;
        opacity: 1
    }
}

@keyframes DownloadAppCta_browseJobFloatButtonExpandLeft__2Lk6d {
    0%,10%,20% {
        visibility: hidden;
        opacity: 0;
        width: 0
    }

    40%,50%,60%,70%,80% {
        opacity: 0;
        width: 25px;
        visibility: hidden
    }

    to {
        width: 25px;
        visibility: visible;
        opacity: 1
    }
}



.rg_fab-container__22WmJ {
    display: -moz-box;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    position: fixed;
    bottom: 30px;
    width: 100%;
    z-index: 2;
    pointer-events: none
}

.rg_fab-container__22WmJ.rg_FixedActionButton_slideUp__10w8J {
    visibility: visible;
    -webkit-animation-name: FixedActionButton_browseJobFabMoveUp__3uEiA;
    -moz-animation-name: FixedActionButton_browseJobFabMoveUp__3uEiA;
    animation-name: FixedActionButton_browseJobFabMoveUp__3uEiA;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}

.rg_fab-container__22WmJ.FixedActionButton_slideDown__2A758 {
    visibility: visible;
    -webkit-animation-name: FixedActionButton_browseJobFabMoveDown__uzuFG;
    -moz-animation-name: FixedActionButton_browseJobFabMoveDown__uzuFG;
    animation-name: FixedActionButton_browseJobFabMoveDown__uzuFG;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}

.rg_fab-container__22WmJ.FixedActionButton_hidden__sL17j {
    visibility: hidden
}

.rg_fab-container__22WmJ .FixedActionButton_btn-container__1bJDw {
    pointer-events: auto;
    -webkit-box-shadow: 0 0 1px rgba(24,193,163,.05);
    box-shadow: 0 0 1px rgba(24,193,163,.05);
    height: 32px;
    background: #e81c28;
    border-radius: 5px;
    display: -moz-box;
    display: flex;
    -moz-box-pack: left;
    justify-content: left;
    -moz-box-align: center;
    align-items: center;
    border: none;
    outline: none;
    padding: 6px 12px
}

.rg_fab-container__22WmJ .FixedActionButton_btn-container__1bJDw .FixedActionButton_btn-content__6kVvO {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
    -webkit-animation-name: FixedActionButton_browseJobFabGrow__23Qut;
    -moz-animation-name: FixedActionButton_browseJobFabGrow__23Qut;
    animation-name: FixedActionButton_browseJobFabGrow__23Qut;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: .5s;
    -moz-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-transition: width;
    -moz-transition: width;
    transition: width
}

.rg_fab-container__22WmJ .FixedActionButton_btn-container__1bJDw .FixedActionButton_arrowAnimtion__zhciW {
    visibility: hidden;
    opacity: 0;
    background-color: unset;
    -webkit-animation: FixedActionButton_browseJobFloatButtonExpandLeft__3Q4YM 1s linear 1.5s forwards;
    -moz-animation: FixedActionButton_browseJobFloatButtonExpandLeft__3Q4YM 1s linear 1.5s forwards;
    animation: FixedActionButton_browseJobFloatButtonExpandLeft__3Q4YM 1s linear 1.5s forwards;
    position: relative;
    top: -2px
}

.rg_fab-container__22WmJ .FixedActionButton_btn-container__1bJDw .FixedActionButton_arrowAnimtion__zhciW span {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    margin-left: -6px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-animation: FixedActionButton_sdb07__21Vct 2s infinite;
    -moz-animation: FixedActionButton_sdb07__21Vct 2s infinite;
    animation: FixedActionButton_sdb07__21Vct 2s infinite;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.rg_fab-container__22WmJ .FixedActionButton_btn-container__1bJDw .FixedActionButton_arrowAnimtion__zhciW span:first-of-type {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    animation-delay: 0s
}

.rg_fab-container__22WmJ .FixedActionButton_btn-container__1bJDw .FixedActionButton_arrowAnimtion__zhciW span:nth-of-type(2) {
    left: 8px;
    -webkit-animation-delay: .15s;
    -moz-animation-delay: .15s;
    animation-delay: .15s
}

.rg_fab-container__22WmJ .FixedActionButton_btn-container__1bJDw .FixedActionButton_arrowAnimtion__zhciW span:nth-of-type(3) {
    left: 16px;
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    animation-delay: .3s
}

@-webkit-keyframes FixedActionButton_sdb07__21Vct {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-moz-keyframes FixedActionButton_sdb07__21Vct {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes FixedActionButton_sdb07__21Vct {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes FixedActionButton_browseJobFabGrow__23Qut {
    0% {
        margin-right: 0
    }

    to {
        margin-right: 10px
    }
}

@-moz-keyframes FixedActionButton_browseJobFabGrow__23Qut {
    0% {
        margin-right: 0
    }

    to {
        margin-right: 10px
    }
}

@keyframes FixedActionButton_browseJobFabGrow__23Qut {
    0% {
        margin-right: 0
    }

    to {
        margin-right: 10px
    }
}

@-webkit-keyframes FixedActionButton_browseJobFloatButtonExpandLeft__3Q4YM {
    0%,10%,20% {
        visibility: hidden;
        opacity: 0;
        width: 0
    }

    40%,50%,60%,70%,80% {
        opacity: 0;
        width: 25px;
        visibility: hidden
    }

    to {
        width: 25px;
        visibility: visible;
        opacity: 1
    }
}

@-moz-keyframes FixedActionButton_browseJobFloatButtonExpandLeft__3Q4YM {
    0%,10%,20% {
        visibility: hidden;
        opacity: 0;
        width: 0
    }

    40%,50%,60%,70%,80% {
        opacity: 0;
        width: 25px;
        visibility: hidden
    }

    to {
        width: 25px;
        visibility: visible;
        opacity: 1
    }
}

@keyframes FixedActionButton_browseJobFloatButtonExpandLeft__3Q4YM {
    0%,10%,20% {
        visibility: hidden;
        opacity: 0;
        width: 0
    }

    40%,50%,60%,70%,80% {
        opacity: 0;
        width: 25px;
        visibility: hidden
    }

    to {
        width: 25px;
        visibility: visible;
        opacity: 1
    }
}

@-webkit-keyframes FixedActionButton_browseJobFabMoveUp__3uEiA {
    0% {
        -webkit-transform: translateY(400%);
        transform: translateY(400%)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-moz-keyframes FixedActionButton_browseJobFabMoveUp__3uEiA {
    0% {
        -moz-transform: translateY(400%);
        transform: translateY(400%)
    }

    to {
        -moz-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes FixedActionButton_browseJobFabMoveUp__3uEiA {
    0% {
        -webkit-transform: translateY(400%);
        -moz-transform: translateY(400%);
        transform: translateY(400%)
    }

    to {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes FixedActionButton_browseJobFabMoveDown__uzuFG {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        -webkit-transform: translateY(400%);
        transform: translateY(400%)
    }
}

@-moz-keyframes FixedActionButton_browseJobFabMoveDown__uzuFG {
    0% {
        -moz-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        -moz-transform: translateY(400%);
        transform: translateY(400%)
    }
}

@keyframes FixedActionButton_browseJobFabMoveDown__uzuFG {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        -webkit-transform: translateY(400%);
        -moz-transform: translateY(400%);
        transform: translateY(400%)
    }
}
.rg_fab-container__22WmJ{display: none;}